import React, { useState, useEffect } from "react";
import "./ServiceScreen.css";
import db from "../../firebase/database";
import Invoice from "../../Components/Invoice";
import CardForPackage from "../../Components/CardForPackage";
import ProductCard from "../ProductCard";
import "./HomeScreens/style.css";
import { FcMenu } from "react-icons/fc";
export default function ServicesScreen({
  iscNumber,
  customerServicesInfo,
  servicesInfo,
  beta,
  customerDetails,
  currentKM,
  count,
  totalServiceCount,
}) {
  const [data, setData] = useState([]);
  const [checkMini, setcheckMini] = useState(false);
  // console.log(data);
  const minimizer = () => {
    setcheckMini(!checkMini);
  };

  const [masterData, setMasterData] = useState([]);
  const [bill, setBill] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [userServices, setUserServices] = useState("");
  const [tab, setTab] = useState("Wheel Services");
  const [pending, setpending] = useState(0);
  const [showPending, setshowPending] = useState(false);
  const [animate, setAnimate] = useState(true);

  // console.log("beta", beta);
  const cardGandler = (data, customerDetails) => {
    if (data == []) {
      return <h1></h1>;
    } else {
      return data.map((item) => {
        return (
          <CardForPackage
            setter={setBill}
            val={bill}
            tempItem={{ ...item, time: 0 }}
            button={bill ? (!bill.includes ? false : true) : []}
            det={item}
            dat={customerDetails}
            add={() => {
              let tempArr = bill;
              const index = tempArr.indexOf(item);
              if (index > -1) {
                tempArr.splice(index, 1);
                tempArr.push({ ...item, time: 0 });
                setBill([...tempArr]);
              }

              setBill([...bill, item]);
            }}
            remove={() => {
              let tempArr = bill;
              const index = tempArr.indexOf(item);
              if (index > -1) {
                tempArr.splice(index, 1);
              }
              setBill([...tempArr]);
            }}
            onPress={() => {
              var found = false;
              var index = 0;
              for (var i = 0; i < bill.length; i++) {
                if (bill[i].name == item.name) {
                  found = true;
                  index = i;
                  break;
                }
              }
              if (!found) {
                customerDetails.PACKAGE === "NORMAL 500" && item.count > 0
                  ? setBill([
                      ...bill,
                      { ...item, large: "100", medium: "100", small: "100" },
                    ])
                  : setBill([...bill, item]);
                // console.log("here is bill if u can see =>",bill);
              } else {
                let tempArr = bill;
                // const index = tempArr.indexOf(item);
                if (index > -1) {
                  tempArr.splice(index, 1);
                }
                setBill([...tempArr]);
              }
            }}
          />
        );
      });
    }
  };
  useEffect(() => {
    console.log("here is bill if u can see =>", bill);
  }, [bill]);
  useEffect(() => {
    let temp = [];
    beta.forEach((item) => {
      if (item.count > 0) {
        temp.push({ ...item, small: 0, medium: 0, large: 0 });
      } else temp.push({ ...item });
    });

    setMasterData(temp);
  }, [beta]);
  useEffect(() => {
    let temp = [];
    masterData.forEach((item) => {
      if (item.category === "Wheel Services") {
        temp.push(item);
      }
    });
    setData(temp);
  }, [masterData]);

  const [show1, set1] = useState(false);
  const b1 = () => {
    set1(true);
    set2(false);
    set3(false);
    set4(false);
    set5(false);
    let temp = [];
    masterData.forEach((item) => {
      if (item.category === "Wheel Service") {
        temp.push(item);
      }
    });
    setData(temp);
  };
  const [show2, set2] = useState(false);
  const b2 = () => {
    set1(false);
    set2(true);
    set3(false);
    set4(false);
    set5(false);
    let temp = [];
    masterData.forEach((item) => {
      if (item.category === "Exterior Cleaning") {
        temp.push(item);
      }
    });
    setData(temp);
  };
  const [show3, set3] = useState(false);
  const b3 = () => {
    set1(false);
    set2(false);
    set3(true);
    set4(false);
    set5(false);
    let temp = [];
    masterData.forEach((item) => {
      if (item.category === "Interior Cleaning") {
        temp.push(item);
      }
    });
    setData(temp);
  };
  const [show4, set4] = useState(false);
  const b4 = () => {
    set1(false);
    set2(false);
    set3(false);
    set4(true);
    set5(false);
    let temp = [];
    masterData.forEach((item) => {
      if (item.category === "Engine Service") {
        temp.push(item);
      }
    });
    setData(temp);
  };
  const [show5, set5] = useState(false);
  const b5 = () => {
    set1(false);
    set2(false);
    set3(false);
    set4(false);
    set5(true);
    let temp = [];
    masterData.forEach((item) => {
      if (item.category === "Other Services") {
        temp.push(item);
      }
    });
    setData(temp);
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div class="service-container-package">
      <div class="service-header-package">
        <span
          style={{
            fontWeight: "300",
          }}
        >
          Choose Your&nbsp;
        </span>
        Services
      </div>
      {/* <div
          style={{ height: windowDimensions.height / 25,top: '30.5%' ,right: '31.5%'}}
          class={
            animate ? "bookingsAnimation bookingsButton" : "bookingsButton"
          }
          onClick={() => setshowPending(!showPending)}
        >
          <div style={{ fontWeight: "400" }}>
            Service <span style={{ fontWeight: "700" }}>Bookings</span>
          </div>
          {pending > 0 ? <div className="circle">{pending}</div> : null}
        </div> */}
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            width: windowDimensions.width / 1.05,
            height:
              window.innerWidth < 520
                ? windowDimensions.width * 1.5
                : windowDimensions.height / 1.15,
            backgroundColor: "#EEEEEE",
            marginTop: 25,
          }}
          class="mainSabsePeeche"
        >
          {window.innerWidth < 520 ? (
            <label
              style={{
                position: "absolute",
                fontSize: "7vw",
                marginLeft: "2vw",
                cursor: "pointer",
              }}
              onClick={minimizer}
            >
              <FcMenu />
            </label>
          ) : null}
          {window.innerWidth < 520 && checkMini === true ? null : (
            <>
              <div
                className="aniDraw"
                style={{
                  backgroundColor: "#DBDBDB",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  flex: window.innerWidth < 520 ? 2 : 1,
                  borderTopLeftRadius: 24,
                  borderBottomLeftRadius: 24,
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    height: "20%",
                    borderTopLeftRadius: 24,
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  class={
                    show1 === true
                      ? "drawerActiveColorPack"
                      : "drawerItemLeftPack"
                  }
                  onClick={b1}
                >
                  <text
                    style={{
                      fontSize:
                        window.innerWidth < 520
                          ? windowDimensions.width / 30
                          : windowDimensions.width / 60,
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      opacity:
                        window.innerWidth < 520 && checkMini === true ? 0 : 1,
                    }}
                  >
                    Wheel<text style={{ fontWeight: "400" }}> Services</text>
                  </text>
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    height: "20%",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  class={
                    show2 === true
                      ? "drawerActiveColorPack"
                      : "drawerItemLeftPack"
                  }
                  onClick={b2}
                >
                  <text
                    style={{
                      fontSize:
                        window.innerWidth < 520
                          ? windowDimensions.width / 30
                          : windowDimensions.width / 60,
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      opacity:
                        window.innerWidth < 520 && checkMini === true ? 0 : 1,
                    }}
                  >
                    Exerior<text style={{ fontWeight: "400" }}> Cleaning</text>
                  </text>
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    height: "20%",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  class={
                    show3 === true
                      ? "drawerActiveColorPack"
                      : "drawerItemLeftPack"
                  }
                  onClick={b3}
                >
                  <text
                    style={{
                      fontSize:
                        window.innerWidth < 520
                          ? windowDimensions.width / 30
                          : windowDimensions.width / 60,
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      opacity:
                        window.innerWidth < 520 && checkMini === true ? 0 : 1,
                    }}
                  >
                    Interior<text style={{ fontWeight: "400" }}> Cleaning</text>
                  </text>
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    height: "20%",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  class={
                    show4 === true
                      ? "drawerActiveColorPack"
                      : "drawerItemLeftPack"
                  }
                  onClick={b4}
                >
                  <text
                    style={{
                      fontSize:
                        window.innerWidth < 520
                          ? windowDimensions.width / 30
                          : windowDimensions.width / 60,
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      opacity:
                        window.innerWidth < 520 && checkMini === true ? 0 : 1,
                    }}
                  >
                    Engine<text style={{ fontWeight: "400" }}> Services</text>
                  </text>
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    borderBottomLeftRadius: 24,
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  class={
                    show5 === true
                      ? "drawerActiveColorPack"
                      : "drawerItemLeftPack"
                  }
                  onClick={b5}
                >
                  <text
                    style={{
                      fontSize:
                        window.innerWidth < 520
                          ? windowDimensions.width / 30
                          : windowDimensions.width / 60,
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      opacity:
                        window.innerWidth < 520 && checkMini === true ? 0 : 1,
                    }}
                  >
                    Other<text style={{ fontWeight: "400" }}> Services</text>
                  </text>
                </div>
              </div>
            </>
          )}
          <div
            style={{
              display: "flex",
              flex: window.innerWidth < 520 ? 1 : 4,
              borderTopRightRadius: 24,
              borderBottomRightRadius: 24,
              marginLeft:
                window.innerWidth < 520 && checkMini === true ? "8vw" : 0,
            }}
            // class="servicesSection"
          >
            {show1 === true ||
            show2 === true ||
            show3 === true ||
            show4 === true ? (
              <div className="servicesSection">
                {" "}
                {cardGandler(data, customerDetails)}
              </div>
            ) : null}
            {show5 === true ? (
              <ProductCard
                bill={bill}
                setter={setBill}
                vehicleTypeDecider={customerDetails.SIZE}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div>
        <Invoice
          customerDetails={customerDetails}
          bill={bill}
          customerServicesInfo={customerServicesInfo}
          setter={setBill}
          currentKM={currentKM}
          count={count}
          totalServiceCount={totalServiceCount}
        />
      </div>
      {/* <Bookings setpending={setpending} showPending={showPending} iscNumber={iscNumber} /> */}
    </div>
  );
}

// function Bookings({ setpending, showPending,iscNumber }) {
//   const [servicesList, setservicesList] = useState([]);
//   useEffect(() => {
//     const subscriber = db
//       .collection("bookings")
//       .where("isc","==",iscNumber)
//       .where("status", "==", "Confirmed")
//       .onSnapshot((querySnapshot) => {
//         const tmp = [];

//         querySnapshot.forEach((documentSnapshot) => {
//           tmp.push({
//             ...documentSnapshot.data(),
//             key: documentSnapshot.id,
//           });
//         });
//         setservicesList(tmp);
//         setpending(querySnapshot.size);
//       });

//     return () => subscriber();
//   }, []);

//   const bookingsDisplay = (item) => {
//     const completeBooking = () => {
//       db.collection("bookings").doc(item.isc).update({
//         completed: true,
//         status: "Completed",
//       });
//     };
//     return (
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           justifyContent: "flex-start",
//           alignItems: "center",
//           width: "100%",
//           height: "10vw",
//         }}
//       >
//         {servicesList ? (
//           <>
//             <div
//               style={{
//                 display: "flex",
//                 width: "100%",
//                 paddingLeft: "3.5vw",
//                 flex: 0.4,
//                 paddingRight: "1.5vw",
//                 paddingTop: "1.5vw",
//               }}
//             >
//               <label
//                 style={{
//                   textAlign: "right",
//                   fontSize: "0.65vw",
//                   color: "black",
//                   fontWeight: "400",
//                 }}
//               >
//                 <span style={{ fontWeight: "700" }}>
//                   {item.isc} , {item.name} , {item.vehicleNumber}
//                 </span>{" "}
//                 has booked service on{" "}
//                 <span style={{ fontWeight: "700" }}>{item.date}</span> , contact
//                 on <span style={{ fontWeight: "700" }}>{item.mobile}</span>
//               </label>
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 flexDirection: "row",
//                 width: "100%",
//                 flex: 1,
//               }}
//             >
//               <div
//                 style={{
//                   display: "flex",
//                   width: "100%",
//                   flex: 0.9,
//                   flexDirection: "column",
//                   paddingLeft: "1.5vw",
//                 }}
//               >
//                 <label
//                   style={{
//                     fontSize: "0.65vw",
//                     fontWeight: "400",
//                     color: " black",
//                   }}
//                 >
//                   Services Booked
//                 </label>
//                 <label
//                   style={{
//                     fontSize: "0.8vw",
//                     fontWeight: "700",
//                     color: " black",
//                   }}
//                 >
//                   {item.services.toString()}
//                 </label>
//               </div>
//               <div
//                 style={{
//                   display: "flex",
//                   width: "100%",
//                   flex: 0.1,
//                   padding: "1vw",
//                   cursor: "pointer",
//                 }}
//                 onClick={() => completeBooking()}
//               >
//                 <img
//                   alt="tick"
//                   src={require("../../assets/tick.svg")}
//                   width={"100%"}
//                   height={"100%"}
//                 />
//               </div>
//             </div>
//             <hr className="hrline" />
//           </>
//         ) : null}
//       </div>
//     );
//   };
//   return (
//     <div
//     style={{top: '35%',right: '24%'}}
//       className={showPending === true ? "glass glassActive" : "glass"}
//       // className="glass"
//     >
//       <div className="bookingsMapper">
//         {servicesList ? servicesList.map(bookingsDisplay) : null}
//         {servicesList.length < 1 ? (
//           <label
//             style={{
//               width: "100%",
//               textAlign: "center",
//               lineHeight: "100%",
//               marginTop: "1.5vw",
//               fontSize: "1vw",
//               fontWeight: "400",
//               color: "black",
//             }}
//           >
//             No Bookings Yet
//           </label>
//         ) : null}
//       </div>
//     </div>
//   );
// }
