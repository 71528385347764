import React, { useState, useEffect } from "react";
import "./InvoiceNonPackage/InvoiceNonPackage.css";
import db from "../firebase/database";
import { useHistory } from "react-router-dom";
import firebase from "../firebase/firebase";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { AiTwotoneDelete } from "react-icons/ai";
import moment from "moment";
import IcoButton from "./IcoButton";
import IcoInput from "./IcoInput";
import Drop from "./Drop";
import {
  FcMoneyTransfer,
  FcCurrencyExchange,
  FcCalendar,
  FcCheckmark,
} from "react-icons/fc";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";
import Loading from "./Loading/Loading";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

function MyVerticallyCenteredModal(props) {
  console.log("vertical", props.customerDetails.CITY);
  const history = useHistory();
  const [loading, setloading] = useState(0);
  const [empPIN, setempPIN] = useState("0000");
  const [empName, setempName] = useState("");
  const [modeOfTransaction, setModeOfTransaction] = useState("Cash");
  const modeOfTransactionList = ["Cash", "Card", "UPI", "Other"];
  const [disableButton, setdisableButton] = useState(false);

  // const [newcustomer, setnewcustomer] = useState(false);
  // const handleChangeNew = (event) => {
  //   setnewcustomer(!newcustomer);
  // };

  let bachiHuiServices = 0;
  const something = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      console.log("enter");
      getEmpDetails();
    }
  };
  const handleChange = (event) => {
    setempPIN(event.target.value);
  };
  const [empBanda, setEmpBanda] = useState(false);
  const getEmpDetails = async () => {
    await db
      .collection("employee")
      .doc(empPIN)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          if (doc.data().employeePin !== "deactivated") {
            setempName(doc.data().employeeName);
            if (doc.data().city === "banda") {
              setEmpBanda(true);
              console.log("banda city servicng");
            }
          } else if (doc.data().employeePin === "deactivated") {
            setempName("Deactivated Employee, Contact Owner");
          } else {
            setempName("No Employee With This PIN, Contact Owner");
          }
        } else {
          setempName("No Employee With This PIN, Contact Owner");
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };

  let checkIfFree = props.amount + props.gst - props.discount;

  const completePayment = async () => {
    if (checkIfFree === 0) {
      setdisableButton(true);
      console.log("free start" + checkIfFree);
      props.bill.forEach((item) => {
        if (item.count > 0) {
          db.collection("customers")
            .doc(props.customerDetails.ISCNO)
            .collection("services")
            .doc(item.name)
            .update({
              count: firebase.firestore.FieldValue.increment(-1),
            });
          bachiHuiServices = bachiHuiServices + 1;
        }
        if (item.category === "Products") {
          console.log("this thngs starte ", item);
          if (empBanda === true) {
            db.collection("products")
              .doc(item.PartNumber)
              .update({
                countBanda: firebase.firestore.FieldValue.increment(-1),
              });
          } else {
            db.collection("products")
              .doc(item.PartNumber)
              .update({
                count: firebase.firestore.FieldValue.increment(-1),
              });
          }
        } else {
          db.collection("services")
            .doc(item.name)
            .collection("history")
            .doc()
            .set({
              name: item.name,
              price: 0,
              type: "Free",
              category: "Service",
              servicedCity: empBanda === true ? "banda" : "sagar",
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            });
        }
      });
      console.log("free end" + checkIfFree);
      setloading(20);
      await db
        .collection("sales")
        .doc(props.freeInvoice)
        .set({
          invoice: props.freeInvoice,
          vehicleNumber: props.customerDetails.VEHICLENUMBER,
          vehicleCompany: props.customerDetails.COMPANY,
          vehicleModel: props.customerDetails.MODEL,
          gst: props.gst,
          discount: props.discount,
          count: props.count - bachiHuiServices,
          totalServiceCount: props.totalServiceCount,
          totalAmount: (props.amount + props.gst - props.discount).toFixed(2),
          customerName: props.customerDetails.NAME,
          customerMobile: props.customerDetails.MOBILE,
          vehicleKilometer: props.currentKM,
          isc: props.customerDetails.ISCNO,
          vehicleSize: props.customerDetails.SIZE,
          package: props.customerDetails.PACKAGE,
          mode: "Free",
          employeeName: empName,
          employeePin: empPIN,
          approve: false,
          date: props.ShowparchaBackdate,
          bill: props.bill,
          amount: props.amount,
          // timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          timestamp: props.tmpTimestamp,
          purchase: props.customerDetails.PURCHASED,
          expiry: props.customerDetails.EXPIRY,
          city: props.customerDetails.CITY || "",
          servicedCity: empBanda === false ? "sagar" : "banda",
        })
        .then(async () => {
          setloading(35);
          const dateee = new Date();
          let amt = 0;
          let tempDate = firebase.firestore.FieldValue.serverTimestamp();
          // await db
          //   .collection("extras")
          //   .doc("sales")
          //   .update({
          //     total: firebase.firestore.FieldValue.increment(0),
          //     allSales: firebase.firestore.FieldValue.arrayUnion({
          //       invoice: props.freeInvoice,
          //       vehicleNumber: props.customerDetails.VEHICLENUMBER,
          //       vehicleCompany: props.customerDetails.COMPANY,
          //       vehicleModel: props.customerDetails.MODEL,
          //       vehicleKilometer: props.customerDetails.KILOMETERDRIVEN,
          //       gst: 0,
          //       discount: 0,
          //       count: props.count - bachiHuiServices,
          //       totalServiceCount: props.totalServiceCount,
          //       totalAmount: amt,
          //       customerName: props.customerDetails.NAME,
          //       customerMobile: props.customerDetails.MOBILE,
          //       isc: props.customerDetails.ISCNO,
          //       vehicleSize: props.customerDetails.SIZE,
          //       package: props.customerDetails.PACKAGE,
          //       mode: "Free",
          //       employeeName: empName,
          //       employeePin: empPIN,
          //       approve: false,
          //       date: props.ShowparchaBackdate,
          //       bill: props.ShowparchaBackdate,
          //       amount: amt,
          //       purchase: props.customerDetails.PURCHASED,
          //       expiry: props.customerDetails.EXPIRY,
          //       // timestamp: dateee,
          //     }),
          //   });
          db.collection("employee")
            .doc(empPIN)
            .collection("transactions")
            .doc(props.freeInvoice)
            .set({
              invoice: props.freeInvoice,
              employeeName: empName,
              approve: false,
              employeePin: empPIN,
              mode: "Free",
              vehicleNumber: props.customerDetails.VEHICLENUMBER,
              customerName: props.customerDetails.NAME,
              customerMobile: props.customerDetails.MOBILE,
              customerCity: props.customerDetails.CITY || "",
              date: props.ShowparchaBackdate,
              amount: (props.amount + props.gst - props.discount).toFixed(2),
              timestamp: props.tmpTimestamp,
              servicedCity: empBanda === false ? "sagar" : "banda",
            })
            .then(() => {
              setloading(50);
              db.collection("extras")
                .doc("free")
                .update({
                  // total: firebase.firestore.FieldValue.increment(amount+gst-discount),
                  base: firebase.firestore.FieldValue.increment(+1),
                })
                .then(() => {
                  setloading(70);

                  db.collection("customers")
                    .doc(props.customerDetails.ISCNO)
                    .update({
                      KILOMETERDRIVEN: props.currentKM,
                      SERVICES: props.count - bachiHuiServices,
                      LASTVISIT: props.ShowparchaBackdate,
                    })
                    .then(() => {
                      setloading(100);
                      history.push({
                        pathname: "/receipt",
                        invoice: props.freeInvoice,
                        city: props.customerDetails.CITY || "sagar",
                        vehicleNumber: props.customerDetails.VEHICLENUMBER,
                        customerName: props.customerDetails.NAME,
                        customerMobile: props.customerDetails.MOBILE,
                        vehicleCompany: props.customerDetails.COMPANY,
                        vehicleModel: props.customerDetails.MODEL,
                        packageDetails: props.customerDetails.PACKAGE,
                        date: props.ShowparchaBackdate,
                        amount: props.amount,
                        gst: props.gst,
                        mode: "Free",
                        discount: props.discount,
                        totalAmount: (
                          props.amount +
                          props.gst -
                          props.discount
                        ).toFixed(2),
                        billList: props.bill,
                        isc: props.customerDetails.ISCNO,
                        vehicleSize: props.customerDetails.SIZE,
                        count: props.count - bachiHuiServices,
                        totalServiceCount: props.totalServiceCount,
                        productGST5: props.productGST5,
                        productGST12: props.productGST12,
                        productGST18: props.productGST18,
                        productGST28: props.productGST28,
                        purchase: props.customerDetails.PURCHASED,
                        expiry: props.customerDetails.EXPIRY,
                        checkPage: "sale",
                        kilmil: props.currentKM,
                        tmpTimestamp: props.tmpTimestamp,
                      });
                    });
                });
            })
            .catch((error) => alert(error));
        });
      //   console.log("free start"+checkIfFree)
      // props.bill.forEach((item) => {
      //   if (item.count > 0) {
      //     db.collection("customers")
      //       .doc(props.customerDetails.ISCNO)
      //       .collection("services")
      //       .doc(item.name)
      //       .update({
      //         count: firebase.firestore.FieldValue.increment(-1),
      //       });
      //     bachiHuiServices = bachiHuiServices + 1;
      //   }
      // });
      // console.log("free end"+checkIfFree)
    } else {
      setdisableButton(true);
      let tempDate = firebase.firestore.FieldValue.serverTimestamp();
      const dateee = new Date();
      console.log("paid start" + checkIfFree);
      props.bill.forEach((item) => {
        if (item.count > 0) {
          db.collection("customers")
            .doc(props.customerDetails.ISCNO)
            .collection("services")
            .doc(item.name)
            .update({
              count: firebase.firestore.FieldValue.increment(-1),
            });
          bachiHuiServices = bachiHuiServices + 1;
        }
        if (item.category === "Products") {
          console.log("this thngs starte ", item);
          if (empBanda === true) {
            db.collection("products")
              .doc(item.PartNumber)
              .update({
                countBanda: firebase.firestore.FieldValue.increment(-1),
              });
          } else {
            db.collection("products")
              .doc(item.PartNumber)
              .update({
                count: firebase.firestore.FieldValue.increment(-1),
              });
          }
        } else {
          db.collection("services")
            .doc(item.name)
            .collection("history")
            .doc()
            .set({
              name: item.name,
              price: parseInt(item.large, 10),
              type: "Paid",
              category: "Service",
              servicedCity: empBanda === true ? "banda" : "sagar",
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            });
        }
      });
      console.log("paid end" + checkIfFree);
      setloading(10);
      // await db
      //   .collection("extras")
      //   .doc("sales")
      //   .update({
      //     total: firebase.firestore.FieldValue.increment(
      //       parseFloat((props.amount + props.gst - props.discount).toFixed(2))
      //     ),
      //     sales: firebase.firestore.FieldValue.arrayUnion({
      //       invoice: props.newcustomer===true ? (props.newInvoice).toString() : props.invoice,
      //       vehicleNumber: props.customerDetails.VEHICLENUMBER,
      //       vehicleCompany: props.customerDetails.COMPANY,
      //       vehicleModel: props.customerDetails.MODEL,
      //       vehicleKilometer: props.customerDetails.KILOMETERDRIVEN,
      //       gst: props.gst,
      //       discount: props.discount,
      //       count: props.count - bachiHuiServices,
      //       totalServiceCount: props.totalServiceCount,
      //       totalAmount: (props.amount + props.gst - props.discount).toFixed(2),
      //       customerName: props.customerDetails.NAME,
      //       customerMobile: props.customerDetails.MOBILE,
      //       isc: props.customerDetails.ISCNO,
      //       vehicleSize: props.customerDetails.SIZE,
      //       package: props.customerDetails.PACKAGE,
      //       mode: modeOfTransaction.value || "Cash",
      //       employeeName: empName,
      //       employeePin: empPIN,
      //       approve: false,
      //       date: props.ShowparchaBackdate,
      //       bill: props.bill,
      //       amount: props.amount,
      //       purchase: props.customerDetails.PURCHASED,
      //       expiry: props.customerDetails.EXPIRY,
      //       // timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      //     }),
      //   });
      setloading(20);

      await db
        .collection("sales")
        .doc(
          props.newcustomer === true
            ? props.newInvoice.toString()
            : props.invoice
        )
        .set({
          invoice:
            props.newcustomer === true
              ? props.newInvoice.toString()
              : props.invoice,
          vehicleNumber: props.customerDetails.VEHICLENUMBER,
          vehicleCompany: props.customerDetails.COMPANY,
          vehicleModel: props.customerDetails.MODEL,
          vehicleKilometer: props.currentKM,
          gst: props.gst,
          discount: props.discount,
          count: props.count - bachiHuiServices,
          totalServiceCount: props.totalServiceCount,
          totalAmount: (props.amount + props.gst - props.discount).toFixed(2),
          customerName: props.customerDetails.NAME,
          customerMobile: props.customerDetails.MOBILE,
          isc: props.customerDetails.ISCNO,
          vehicleSize: props.customerDetails.SIZE,
          package: props.customerDetails.PACKAGE,
          mode: modeOfTransaction.value || "Cash",
          employeeName: empName,
          employeePin: empPIN,
          approve: false,
          date: props.ShowparchaBackdate,
          bill: props.bill,
          amount: props.amount + props.gst,
          purchase: props.customerDetails.PURCHASED,
          expiry: props.customerDetails.EXPIRY,
          // timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          timestamp: props.tmpTimestamp,
          city: props.customerDetails.CITY || "",
          servicedCity: empBanda === false ? "sagar" : "banda",
        })
        .then(() => {
          setloading(40);
          db.collection("employee")
            .doc(empPIN)
            .collection("transactions")
            .doc(
              props.newcustomer === true
                ? props.newInvoice.toString()
                : props.invoice
            )
            .set({
              invoice:
                props.newcustomer === true
                  ? props.newInvoice.toString()
                  : props.invoice,
              employeeName: empName,
              approve: false,
              employeePin: empPIN,
              mode: modeOfTransaction.value || "Cash",
              vehicleNumber: props.customerDetails.VEHICLENUMBER,
              customerName: props.customerDetails.NAME,
              customerMobile: props.customerDetails.MOBILE,
              customerCity: props.customerDetails.CITY || "",
              date: props.ShowparchaBackdate,
              amount: (props.amount + props.gst - props.discount).toFixed(2),
              // timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              timestamp: props.tmpTimestamp,
            })
            .then(() => {
              setloading(60);
              db.collection("extras")
                .doc("invoice")
                .update({
                  base:
                    props.newcustomer === true
                      ? props.invoiceNum
                      : firebase.firestore.FieldValue.increment(+1),
                  newbase:
                    props.newcustomer === true
                      ? firebase.firestore.FieldValue.increment(+1)
                      : props.newInvoice,
                })
                .then(() => {
                  setloading(80);
                  db.collection("customers")
                    .doc(props.customerDetails.ISCNO)
                    .update({
                      KILOMETERDRIVEN: props.currentKM,
                      SERVICES: props.count - bachiHuiServices,
                      LASTVISIT: props.ShowparchaBackdate,
                    })
                    .then(props.handleClose)
                    .then(() => {
                      setloading(100);
                      history.push({
                        pathname: "/receipt",
                        invoice:
                          props.newcustomer === true
                            ? props.newInvoice.toString()
                            : props.invoice,
                        vehicleNumber: props.customerDetails.VEHICLENUMBER,
                        city: props.customerDetails.CITY || "sagar",
                        customerName: props.customerDetails.NAME,
                        customerMobile: props.customerDetails.MOBILE,
                        vehicleCompany: props.customerDetails.COMPANY,
                        vehicleModel: props.customerDetails.MODEL,
                        packageDetails: props.customerDetails.PACKAGE,
                        date: props.ShowparchaBackdate,
                        amount: props.amount + props.gst,
                        gst: props.gst,
                        mode: modeOfTransaction.value || "Cash",
                        discount: props.discount,
                        totalAmount: (
                          props.amount +
                          props.gst -
                          props.discount
                        ).toFixed(2),
                        billList: props.bill,
                        isc: props.customerDetails.ISCNO,
                        vehicleSize: props.customerDetails.SIZE,
                        count: props.count - bachiHuiServices,
                        totalServiceCount: props.totalServiceCount,
                        productGST5: props.productGST5,
                        productGST12: props.productGST12,
                        productGST18: props.productGST18,
                        productGST28: props.productGST28,
                        purchase: props.customerDetails.PURCHASED,
                        expiry: props.customerDetails.EXPIRY,
                        checkPage: "sale",
                        tmpTimestamp: props.tmpTimestamp,
                        kilmil: props.currentKM,
                      });
                    });
                });
            })
            .catch((error) => alert(error));
        });
      //   console.log("paid start"+checkIfFree)
      // props.bill.forEach((item) => {
      //   if (item.count > 0) {
      //     db.collection("customers")
      //       .doc(props.customerDetails.ISCNO)
      //       .collection("services")
      //       .doc(item.name)
      //       .update({
      //         count: firebase.firestore.FieldValue.increment(-1),
      //       });
      //     bachiHuiServices = bachiHuiServices + 1;
      //   }
      // });
      // console.log("paid end"+checkIfFree)
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Loading load={loading} />
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Cash Payment
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <form>
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                id="pinInput"
                aria-describedby="pinHelp"
                placeholder="0000"
                onChange={handleChange}
                onKeyDown={(e) => something(e)}
              />
              <small id="pinHelp" class="form-text text-muted">
                Please Enter Unique PIN Provided by Owner.
              </small>
              <button
                type="button"
                class="btn btn-warning"
                onClick={getEmpDetails}
              >
                Check PIN
              </button>
              <br />
              {!empName ? null : (
                <div>
                  <label>Employee Name : {empName}</label>
                  {empName === "No Employee With This PIN, Contact Owner" ||
                  !empName ||
                  empName === "Deactivated Employee, Contact Owner" ? null : (
                    <>
                      <Drop
                        list={modeOfTransactionList}
                        val={"modeOfTransaction"}
                        setter={setModeOfTransaction}
                        icon={<FcCurrencyExchange />}
                        holder={"Cash"}
                      />
                      {/* <FormControlLabel
                    control={
                      <Checkbox
                        icon={<RiCheckboxBlankCircleLine style={{ color: "red" }} />}
                        checkedIcon={<FcCheckmark />}
                        checked={newcustomer}
                        onChange={handleChangeNew}
                        name="checkedH"
                      />
                    }
                    label="New Customer"
                  /> */}
                    </>
                  )}
                </div>
              )}
            </div>
            <h4>
              Amount Collected : {props.amount + props.gst - props.discount}
            </h4>
          </form>
        </div>
      </Modal.Body>
      {empName === "No Employee With This PIN, Contact Owner" ||
      !empName ||
      empName === "Deactivated Employee, Contact Owner" ||
      disableButton === true ? null : (
        <Modal.Footer>
          <button
            type="submit"
            class="btn btn-success"
            onClick={completePayment}
          >
            Complete Payment
          </button>
        </Modal.Footer>
      )}
    </Modal>
  );
}

function Invoice({
  customerDetails,
  bill,
  currentKM,
  setter,
  count,
  totalServiceCount,
}) {
  const history = useHistory();

  const [newcustomer, setnewcustomer] = useState(false);
  const handleChangeNew = (event) => {
    setnewcustomer(!newcustomer);
  };

  const [parchaBackdate, setparchaBackdate] = useState("");
  console.log(parchaBackdate);

  let ShowparchaBackdate = moment(parchaBackdate).format("DD/MM/YYYY");
  console.log(ShowparchaBackdate);
  let TimestampparchaBackdate = moment(parchaBackdate).format("MM/DD/YYYY");

  const [modalShow, setModalShow] = React.useState(false);
  const [finalAMT, setfinalAMT] = useState(0);
  const handleClose = () => {
    setModalShow(false);
  };
  const handleShow = () => {
    if (bill.length > 0) {
      setModalShow(true);
    } else {
      alert("Please Add Atleast 1 Service to Continue");
    }
  };
  const handleDelete = (item) => {
    let tempArr = bill;
    const index = tempArr.indexOf(item);
    if (index > -1) {
      tempArr.splice(index, 1);
    }
    setter([...tempArr]);

    // const index = bill.indexOf(item);
    // if (index > -1) {
    //   bill.splice(index, 1);
    //   setBillList([...bill]);
    // }
  };
  let estTime = 0;
  bill.forEach((item) => {
    estTime = estTime + parseInt(item.time, 10);
  });
  function getDaysFromMins(mins) {
    return moment
      .utc(moment.duration(estTime, "minutes").asMilliseconds())
      .format("D");
  }
  function getHourFromMins(mins) {
    return moment
      .utc(moment.duration(estTime, "minutes").asMilliseconds())
      .format("H");
  }
  function getMinFromMins(mins) {
    return moment
      .utc(moment.duration(estTime, "minutes").asMilliseconds())
      .format("m");
  }
  const estTimeDisplay = () => {
    if (getDaysFromMins(estTime) <= 1 && getHourFromMins(estTime) <= 0) {
      return (
        <span className="contain-span">{getMinFromMins(estTime)} Minutes</span>
      );
    } else if (getDaysFromMins(estTime) <= 1 && getHourFromMins(estTime) > 0) {
      return (
        <span className="contain-span">
          {getHourFromMins(estTime)} Hours {getMinFromMins(estTime)} Minutes
        </span>
      );
    } else if (getDaysFromMins(estTime) > 1) {
      return (
        <span className="contain-span">
          {getDaysFromMins(estTime) - 1} Days {getHourFromMins(estTime)} Hours{" "}
          {getMinFromMins(estTime)} Mins
        </span>
      );
    }
  };

  //Modal Code Ends Here

  const [users, setUsers] = useState([]); // Initial empty array of users
  // const [bill, setBill] = useState([]);
  // const [discount, setdiscount] = useState(0);

  let amount = 0;
  let vehicleSize = customerDetails.SIZE;

  //Invoice Fetch and Format Code Starts Here
  const [invoiceNum, setInvoiceNum] = useState();
  const [invoiceFormatted, setInvoiceFormatted] = useState("");
  const [freeNum, setFreeNum] = useState();
  const [freeNumFormatted, setFreeNumFormatted] = useState("");
  const [newInvoice, setnewInvoice] = useState("");
  useEffect(() => {
    db.collection("extras")
      .doc("invoice")
      .get()
      .then(function (doc) {
        setInvoiceNum(doc.data().base);
        setInvoiceFormatted("IN" + invoiceNum);
        setnewInvoice(doc.data().newbase);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });

    db.collection("extras")
      .doc("free")
      .get()
      .then(function (doc) {
        setFreeNum(doc.data().base);
        setFreeNumFormatted("FREE" + freeNum);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }, [invoiceNum, freeNum]);

  let FinalAmount = localStorage.getItem("amount");
  let FinalLabour = localStorage.getItem("labour");
  let FinalDiscount = localStorage.getItem("discount");
  let gst = 0;
  let discount = 0;
  let productGST5 = 0;
  let productGST12 = 0;
  let productGST18 = 0;
  let productGST28 = 0;

  let totalAmount = FinalAmount + gst;

  useEffect(() => {
    setfinalAMT(FinalAmount);
  }, [FinalAmount]);
  //Invoice Fetch and Format Code Ends Here

  // useEffect(() => {
  //   setBill(billIn);
  // });

  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  let today = date + "/" + month + "/" + year;

  const showEstimate = (item) => {
    return (
      <div className="bill-tabs-service">
        <div style={{ position: "absolute", width: 0, height: 0, opacity: 0 }}>
          {vehicleSize === "small" && item.category === "Labour Cost"
            ? (gst = gst + parseInt(item.small, 10))
            : null}
          {vehicleSize === "medium" && item.category === "Labour Cost"
            ? (gst = gst + parseInt(item.medium, 10))
            : null}
          {vehicleSize === "large" && item.category === "Labour Cost"
            ? (gst = gst + parseInt(item.large, 10))
            : null}
          {item.category === "Products"
            ? (discount =
                discount + (parseInt(item.MRP, 10) - parseInt(item.price, 10)))
            : null}
          {item.category === "Products" && item.GST === "5%"
            ? (productGST5 =
                parseInt(item.price, 10) - parseInt(item.price, 10) / 1.05)
            : null}
          {item.category === "Products" && item.GST === "12%"
            ? (productGST12 =
                parseInt(item.price, 10) - parseInt(item.price, 10) / 1.12)
            : null}
          {item.category === "Products" && item.GST === "18%"
            ? (productGST18 =
                parseInt(item.price, 10) - parseInt(item.price, 10) / 1.18)
            : null}
          {item.category === "Products" && item.GST === "28%"
            ? (productGST28 =
                parseInt(item.price, 10) - parseInt(item.price, 10) / 1.28)
            : null}
          {vehicleSize === "small" && item.category !== "Labour Cost"
            ? (amount = amount + parseInt(item.small, 10))
            : null}
          {vehicleSize === "medium" && item.category !== "Labour Cost"
            ? (amount = amount + parseInt(item.medium, 10))
            : null}
          {vehicleSize === "large" && item.category !== "Labour Cost"
            ? (amount = amount + parseInt(item.large, 10))
            : null}
        </div>
        <div
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h5>{item.name}</h5>
          <div
            style={{
              flex: 1,
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {vehicleSize === "small" ? (
              <div className="amount-div-with-delete">
                <h5>{item.small} </h5>
                <div
                  className=" amount-div-with-delete-button"
                  onClick={() => handleDelete(item)}
                >
                  <AiTwotoneDelete />
                </div>
              </div>
            ) : null}
            {vehicleSize === "medium" ? (
              <div className="amount-div-with-delete">
                <h5>{item.medium} </h5>
                <div
                  className=" amount-div-with-delete-button"
                  onClick={() => handleDelete(item)}
                >
                  <AiTwotoneDelete />
                </div>
              </div>
            ) : null}
            {vehicleSize === "large" ? (
              <div className="amount-div-with-delete">
                <h5>{item.large} </h5>
                <div
                  className=" amount-div-with-delete-button"
                  onClick={() => handleDelete(item)}
                >
                  <AiTwotoneDelete />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container-div-main-hello">
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={handleClose}
        dialogClassName="employeeModal"
        backdrop="static"
        keyboard={false}
        amount={parseFloat(FinalAmount)}
        invoice={invoiceFormatted}
        freeInvoice={freeNumFormatted}
        newInvoice={newInvoice}
        invoiceNum={invoiceNum}
        customerDetails={customerDetails}
        date={today}
        gst={parseFloat(FinalLabour)}
        discount={parseFloat(FinalDiscount)}
        count={count}
        totalServiceCount={totalServiceCount}
        bill={bill}
        currentKM={currentKM}
        productGST5={productGST5}
        productGST12={productGST12}
        productGST18={productGST18}
        productGST28={productGST28}
        ShowparchaBackdate={ShowparchaBackdate}
        newcustomer={newcustomer}
        tmpTimestamp={firebase.firestore.Timestamp.fromDate(
          new Date(TimestampparchaBackdate)
        )}
      />
      <div className="row-1">
        <div className="invoice">
          <img
            alt=""
            src={require("./invoice.svg")}
            className="invoice-image"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row ",
            width: "100%",
          }}
        >
          <div className="Contain-left-div">
            <text className="h4-heading">
              Invoice Number :
              <span className="contain-span">
                {newcustomer === true ? newInvoice : invoiceFormatted}{" "}
              </span>
            </text>
            <text className="h4-heading">
              Customer Name :
              <span className="contain-span">{customerDetails.NAME}</span>
            </text>
            <text className="h4-heading">
              Vehicle Number :
              <span className="contain-span">
                {customerDetails.VEHICLENUMBER}
              </span>
            </text>
            <text className="h4-heading">
              Date : <span className="contain-span"> {today}</span>
            </text>
            <div className="h4-heading">Service Time :{estTimeDisplay()}</div>
            <text className="h4-heading">
              Back Date Invoice :
              <span className="contain-span">
                <IcoInput
                  value={parchaBackdate}
                  holder={"Back Date"}
                  type={"date"}
                  icon={<FcCalendar />}
                  setter={setparchaBackdate}
                  fontsize={"0.75vw"}
                />
              </span>
            </text>
            <div className="h4-heading">
              New Customer:{" "}
              <FormControlLabel
                control={
                  <Checkbox
                    icon={
                      <RiCheckboxBlankCircleLine style={{ color: "red" }} />
                    }
                    checkedIcon={<FcCheckmark />}
                    checked={newcustomer}
                    onChange={handleChangeNew}
                    name="checkedH"
                  />
                }
                label="New Customer"
              />
            </div>
            {parchaBackdate !== "" ? (
              <div
                onClick={handleShow}
                className={window.innerWidth < 520 ? null : "cash-button"}
              >
                <IcoButton icon={<FcMoneyTransfer />} start="Pay" end="Now" />
              </div>
            ) : null}
            {/* <text className= "h4-heading"> To be Paid : {FinalAmount}</text> */}
          </div>
        </div>
        {/* <div className="cash-button">
          <img
            alt=""
            src={require("../assets/PayButton.svg")}
            onClick={handleShow}
            className="cash-button-image"
          />
        </div> */}
      </div>

      <div className="row-2-service">
        <div className="row-2-headerDiv-service">
          <div className="service-div">Service</div>
          <div className=" amount-div">Amount</div>
        </div>

        <div className="row-2Contain">
          {bill === undefined ? null : bill.map(showEstimate)}
        </div>
        <div>
          <hr className="hr-line" />
          <div className="Total-div">
            <div className="Total-div-amt-heading">Total is </div>
            <div className="Total-div-amt-val ">
              {" "}
              &#8377;{amount.toFixed(2)}
            </div>
            {localStorage.setItem("amount", amount)}
          </div>
          <div className="gst-div">
            <div className="gst-heading-div">Labour Charge</div>
            <div className="gst-value-div"> &#8377; {gst.toFixed(2)}</div>
            {localStorage.setItem("labour", gst)}
          </div>
          <div className="gst-div">
            <div className="gst-heading-div">Discount</div>
            <div className="gst-value-div">- &#8377; {discount.toFixed(2)}</div>
            {localStorage.setItem("discount", discount)}
          </div>
          <hr className="hr-line" />
          <div className="net-amt-div">
            <div className="net-heading-div"> Total Amount</div>
            <div className="net-value-div">
              &#8377; {(amount + gst - discount).toFixed(2)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invoice;
