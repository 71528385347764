import React, { useEffect, useState } from "react";
import IcoInput from "../Components/IcoInput";
import IcoButton from "../Components/IcoButton";
import Drop from "../Components/Drop";
import moment from "moment";
import "./CreateCustomer.css";
import {
  FcPackage,
  FcPrint,
  FcBusinessman,
  FcHome,
  FcDisplay,
  FcAddressBook,
  FcCalendar,
  FcCheckmark,
} from "react-icons/fc";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";
import { AiOutlineNumber, AiFillMobile, AiFillCar } from "react-icons/ai";
import { BiCreditCardFront } from "react-icons/bi";
import { FcMoneyTransfer } from "react-icons/fc";
import db from "../firebase/database";
import firebase from "../firebase/firebase";
import { useHistory } from "react-router-dom";
import Loading from "../Components/Loading/Loading";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
export default function CreateCustomer() {
  const history = useHistory();

  const [selected, setSelected] = React.useState("");
  const [selected2, setSelected2] = React.useState("");

  const [customerName, setcustomerName] = useState("");
  const [customerMobile, setcustomerMobile] = useState("");
  const [customerEmail, setcustomerEmail] = useState("");
  const [customerAddress, setcustomerAddress] = useState("");

  const [vehicleCompany, setvehicleCompany] = useState("");
  const [vehicleCompany1, setvehicleCompany1] = useState("");
  const [vehicalList, setVehivalList] = useState([]);
  const [vehicalList1, setVehivalList1] = useState([]);
  const [vehicleName, setvehicleName] = useState("");
  const [vehicleName1, setvehicleName1] = useState("");
  const [vehicleNameList, setVehicleNameList] = useState([]);
  const [vehicleNameList1, setVehicleNameList1] = useState([]);
  const [vehicleType, setvehicleType] = useState("");
  const [vehicleType1, setvehicleType1] = useState("");
  const [adminType, setAdminType] = useState();
  const [vehicleNumber, setvehicleNumber] = useState("");
  const [vehicleKM, setvehicleKM] = useState("");
  const [vehicleNumber1, setvehicleNumber1] = useState("");
  const [vehicleKM1, setvehicleKM1] = useState("");
  const [iscNumber, setiscNumber] = useState();
  const [iscNumberBanda, setiscNumberBanda] = useState();
  const [servicePackage, setservicePackage] = useState({});
  const [servicePackage2, setservicePackage2] = useState({});
  const [packageList, setPackageList] = useState([]);
  const [iscNum, setiscNum] = useState([]);
  const [data, setData] = useState([]);
  const [gotData, setGotData] = useState(false);
  const priceList = ["small", "medium", "large"];
  const [customerBackDate, setCustomerBackDate] = useState("");

  const [updateName, setUpdateName] = useState("");
  const [updateNumber, setUpdateNumber] = useState("");
  const [updateEmail, setUpdateEmail] = useState("");
  const [updateAddress, setUpdateAddress] = useState("");
  const [updateVehicleCompany, setUpdateVechicleCompany] = useState("");
  const [updateVehicleName, setUpdateVehicleName] = useState("");
  const [updateVehicleType, setUpdateVehicleType] = useState("");
  const [updateVehicleNumber, setUpdateVehicleNumber] = useState("");
  const [updateVehicleKM, setUpdateVehicleKM] = useState("");
  const [vechileCompanyDropList, setVechileCompanyDropList] = useState([]);
  const [vehicleNameListDrop, setVehicleNameListDrop] = useState([]);

  const [dateFrom, setdateFrom] = useState("");
  const [dateTo, setdateTo] = useState("");

  let formatDateFrom = moment(dateFrom).format("MM/DD/YYYY");
  let formatDateTo = moment(dateTo).add(1, "days").format("MM/DD/YYYY");

  let ShowformatDateFrom = moment(dateFrom).format("DD/MM/YYYY");
  let ShowformatDateTo = moment(dateTo).format("DD/MM/YYYY");

  const [packPriceForShow, setpackPriceForShow] = useState();

  const [viewallReceipts, setviewallReceipts] = useState([]);

  const [modeOfTransaction, setmodeOfTransaction] = useState("Cash");

  const [totalCustomerServiceCount, setTotalCustomerServiceCount] = useState();
  useEffect(() => {
    console.log(fromDate);
    console.log(monthSelect);
  });
  useEffect(() => {
    try {
      db.collection("VehicleCompany")
        .doc(vehicleCompany.value)
        .get()
        .then((item) => {
          item.data().VehicleName.forEach((det) => {
            if (det.name === vehicleName.value) {
              setvehicleType(det.type);
            }
          });
        });
      if (
        selected.value === "SILVER 2 CAR PACKAGE" ||
        selected.value === "COIN 2 CAR PACKAGE"
      ) {
        db.collection("VehicleCompany")
          .doc(vehicleCompany1.value)
          .get()
          .then((item) => {
            item.data().VehicleName.forEach((det) => {
              if (det.name === vehicleName1.value) {
                setvehicleType1(det.type);
              }
            });
          });
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    vehicleName,
    vehicleCompany,
    vehicleCompany1,
    vehicleName1,
    selected,
    selected2,
  ]);
  const [newcustomer, setnewcustomer] = useState(
    adminType === "banda" ? true : false
  );
  const handleChangeNew = (event) => {
    setnewcustomer(!newcustomer);
  };

  const [showCustomerButton, setshowCustomerButton] = useState(false);

  useEffect(() => {
    setAdminType(window.sessionStorage.getItem("adminType"));
  }, []);
  useEffect(() => {
    if (adminType === "banda") {
      db.collection("extras")
        .doc("bandaCustomerISC")
        .get()
        .then(function (doc) {
          setiscNumberBanda(doc.data().baseISC);
          settempISC(doc.data().baseISC);
        });
    }
  }, [adminType]);
  console.log("base ISC", iscNumberBanda);
  console.log("admin type", adminType);
  useEffect(() => {
    if (
      customerName &&
      customerMobile &&
      vehicleCompany &&
      vehicleKM &&
      vehicleName &&
      vehicleNumber &&
      packPriceForShow &&
      customerBackDate
    ) {
      setshowCustomerButton(true);
    }
    console.log({
      customerName: customerName,
      customerMobile: customerMobile,
      vehicleCompany: vehicleCompany,
      vehicleKM: vehicleKM,
      vehicleName: vehicleName,
      vehicleNumber: vehicleNumber,
      packPriceForShow: packPriceForShow,
      vehicleType: vehicleType,
    });
  }, [
    customerName,
    customerMobile,
    vehicleCompany,
    vehicleKM,
    vehicleName,
    vehicleNumber,
    packPriceForShow,
    customerBackDate,
    vehicleType,
  ]);

  //Invoice Fetch and Format Code Starts Here
  const [newInvoice, setnewInvoice] = useState("");
  const [invoiceNum, setInvoiceNum] = useState();
  const [invoiceFormatted, setInvoiceFormatted] = useState("");
  useEffect(() => {
    db.collection("extras")
      .doc("invoice")
      .get()
      .then(function (doc) {
        setInvoiceNum(doc.data().base);
        setInvoiceFormatted("IN" + invoiceNum);
        setnewInvoice(doc.data().newbase);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }, [invoiceNum]);
  //Invoice Fetch and Format Code Ends Here

  useEffect(() => {
    db.collection("VehicleCompany")
      .get()
      .then((item) => {
        let temp = [];
        item.forEach((item) => {
          temp.push(item.data().name);
        });
        setVechileCompanyDropList(temp);
      });

    if (updateVehicleCompany !== "") {
      db.collection("VehicleCompany")
        .doc(updateVehicleCompany.value)
        .get()
        .then((item) => {
          setVehicleNameListDrop(item.data().ModelName);
        });
    }
    // Unsubscribe from events when no longer in use
  }, [updateVehicleCompany]);

  const label = <FcPackage />;

  useEffect(() => {
    db.collection("packages")
      .get()
      .then((item) => {
        let temp = [];
        item.forEach((item) => {
          temp.push(item.data().name);
        });
        setPackageList(temp);
      });
  }, []);

  useEffect(() => {
    db.collection("VehicleCompany")
      .get()
      .then((item) => {
        let temp = [];
        item.forEach((item) => {
          temp.push(item.data().name);
        });
        setVehivalList(temp);
        setVehivalList1(temp);
      });

    if (vehicleCompany !== "") {
      db.collection("VehicleCompany")
        .doc(vehicleCompany.value)
        .get()
        .then((item) => {
          setVehicleNameList(item.data().ModelName);
        });
      if (vehicleCompany1 !== "") {
        db.collection("VehicleCompany")
          .doc(vehicleCompany1.value)
          .get()
          .then((item) => {
            setVehicleNameList1(item.data().ModelName);
          });
      }
    }
    const subscriber = db.collection("packages").onSnapshot((querySnapshot) => {
      const tmp = [];

      querySnapshot.forEach((documentSnapshot) => {
        tmp.push({
          ...documentSnapshot.data(),
          key: documentSnapshot.id,
        });
      });

      setservicePackage(tmp);
      setservicePackage2(tmp);
    });

    // Unsubscribe from events when no longer in use
    return () => subscriber();
  }, [vehicleCompany, vehicleCompany1]);

  db.collection("extras")
    .doc("customerISC")
    .get()
    .then(function (doc) {
      setiscNumber(doc.data().baseISC);
    })
    .catch(function (error) {});
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  const [ser, setSer] = useState([]);

  const [fromDate, setFromDate] = useState("");
  const [monthSelect, setMonthSelect] = useState("");

  function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  const [loading, setloading] = useState(0);

  const uploadCustomer = async () => {
    const index = findWithAttr(servicePackage, "name", selected.value);
    // const index2 = findWithAttr(servicePackage2, "name", selected2.value);

    try {
      // await alert('Your ISC is ISC-'+tempISC)
      setloading(10);

      await db
        .collection("customers")
        .doc("ISC-" + tempISC)
        .set({
          NAME: customerName,
          MOBILE: customerMobile,
          EMAIL: customerEmail,
          ADDRESS: customerAddress,
          COMPANY: vehicleCompany.value,
          MODEL: vehicleName.value,
          SIZE: vehicleType,
          VEHICLENUMBER: vehicleNumber,
          KILOMETERDRIVEN: vehicleKM,
          PACKAGE: selected.value,
          CITY: adminType === "banda" ? "banda" : "sagar",
          LASTPRICE:
            selected.value === "SILVER 2 CAR PACKAGE" ||
            selected.value === "COIN 2 CAR PACKAGE"
              ? packPriceForShow * 2
              : packPriceForShow,
          SERVICES: totalCustomerServiceCount,
          EXPIRY: packageExpiry,
          ISCNO: "ISC-" + tempISC,
          PURCHASED: moment(customerBackDate).format("DD/MM/YYYY"),
          LASTVISIT: date + "/" + month + "/" + year,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          const dateee = new Date();
          setloading(30);
          console.warn("Customer create");
          // db.collection("extras")
          //   .doc("sales")
          //   .update({
          //     customer: firebase.firestore.FieldValue.arrayUnion({
          //       invoice: newcustomer===true?newInvoice:invoiceFormatted,
          //       vehicleNumber: vehicleNumber,
          //       vehicleCompany: vehicleCompany.value, //need
          //       vehicleModel: vehicleName.value, //need
          //       vehicleKilometer: vehicleKM,
          //       gst: 0, //need
          //       discount: 0, //need
          //       totalAmount: selected.value==="SILVER 2 CAR PACKAGE" ? packPriceForShow*2 : packPriceForShow, //need
          //       count: totalCustomerServiceCount, //need
          //       totalServiceCount: totalCustomerServiceCount, //need
          //       customerName: customerName,
          //       customerMobile: customerMobile,
          //       vehicleSize: vehicleType,
          //       package: selected.value,
          //       mode: modeOfTransaction.value || "Cash",
          //       bill: [
          //         {
          //           name: selected.value,
          //           small: selected.value==="SILVER 2 CAR PACKAGE" ? packPriceForShow*2 : packPriceForShow,
          //           medium: selected.value==="SILVER 2 CAR PACKAGE" ? packPriceForShow*2 : packPriceForShow,
          //           large: selected.value==="SILVER 2 CAR PACKAGE" ? packPriceForShow*2 : packPriceForShow,
          //           amount: selected.value==="SILVER 2 CAR PACKAGE" ? packPriceForShow*2 : packPriceForShow,
          //           key: selected.value,
          //           category: "Create Customer",
          //         },
          //       ],
          //       employeeName: "Admin",
          //       employeePin: "0",
          //       approve: true,
          //       date: date + "/" + month + "/" + year,
          //       amount: selected.value==="SILVER 2 CAR PACKAGE" ? packPriceForShow*2 : packPriceForShow,
          //       // timestamp: dateee,
          //       purchase: moment(customerBackDate).format("DD/MM/YYYY"),
          //       expiry: packageExpiry,
          //     }),
          //   });
          db.collection("sales")
            .doc(
              newcustomer === true ? newInvoice.toString() : invoiceFormatted
            )
            .set({
              invoice: newcustomer === true ? newInvoice : invoiceFormatted,
              vehicleNumber: vehicleNumber,
              vehicleCompany: vehicleCompany.value, //need
              vehicleModel: vehicleName.value, //need
              vehicleKilometer: vehicleKM,
              gst: 0, //need
              discount: 0, //need
              totalAmount:
                selected.value === "SILVER 2 CAR PACKAGE" ||
                selected.value === "COIN 2 CAR PACKAGE"
                  ? packPriceForShow * 2
                  : packPriceForShow, //need
              count: totalCustomerServiceCount, //need
              totalServiceCount: totalCustomerServiceCount, //need
              customerName: customerName,
              customerMobile: customerMobile,
              vehicleSize: vehicleType,
              package: selected.value,
              CITY: adminType === "banda" ? "banda" : "sagar",
              mode: modeOfTransaction.value || "Cash",
              bill: [
                {
                  name: selected.value,
                  small:
                    selected.value === "SILVER 2 CAR PACKAGE" ||
                    selected.value === "COIN 2 CAR PACKAGE"
                      ? packPriceForShow * 2
                      : packPriceForShow,
                  medium:
                    selected.value === "SILVER 2 CAR PACKAGE" ||
                    selected.value === "COIN 2 CAR PACKAGE"
                      ? packPriceForShow * 2
                      : packPriceForShow,
                  large:
                    selected.value === "SILVER 2 CAR PACKAGE" ||
                    selected.value === "COIN 2 CAR PACKAGE"
                      ? packPriceForShow * 2
                      : packPriceForShow,
                  amount:
                    selected.value === "SILVER 2 CAR PACKAGE" ||
                    selected.value === "COIN 2 CAR PACKAGE"
                      ? packPriceForShow * 2
                      : packPriceForShow,
                  key: selected.value,
                  category: "Create Customer",
                },
              ],
              employeeName: "Admin",
              employeePin: "0",
              approve: true,
              date: date + "/" + month + "/" + year,
              amount:
                selected.value === "SILVER 2 CAR PACKAGE" ||
                selected.value === "COIN 2 CAR PACKAGE"
                  ? packPriceForShow * 2
                  : packPriceForShow,
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              purchase: moment(customerBackDate).format("DD/MM/YYYY"),
              expiry: packageExpiry,
            });
          console.warn("Sale create");
        })
        .then(() => {
          setloading(50);
          const temp = servicePackage[index].services || [];
          temp.forEach(async (item) => {
            await db
              .collection("customers")
              .doc("ISC-" + tempISC)
              .collection("services")
              .doc(item.name)
              .set(item);
          });
          console.warn("Service Sub create");
          setloading(60);

          db.collection("extras")
            .doc(adminType === "banda" ? "bandaCustomerISC" : "customerISC")
            .update({
              baseISC: firebase.firestore.FieldValue.increment(+1),
            })
            .then(() => {
              if (
                selected.value === "SILVER 2 CAR PACKAGE" ||
                selected.value === "COIN 2 CAR PACKAGE"
              ) {
                //Second ISC Starts Here
                db.collection("customers")
                  .doc("ISC-" + tempISC2) //change to base isc
                  .set({
                    NAME: customerName,

                    MOBILE: customerMobile,
                    EMAIL: customerEmail,
                    ADDRESS: customerAddress,
                    COMPANY: vehicleCompany1.value,
                    MODEL: vehicleName1.value,
                    SIZE: vehicleType1,
                    CITY: adminType === "banda" ? "banda" : "sagar",
                    VEHICLENUMBER: vehicleNumber1,
                    KILOMETERDRIVEN: vehicleKM1,
                    PACKAGE: selected.value,
                    SERVICES: totalCustomerServiceCount,
                    EXPIRY: packageExpiry,
                    ISCNO: "ISC-" + tempISC2,
                    PURCHASED: moment(customerBackDate).format("DD/MM/YYYY"),
                    LASTVISIT: date + "/" + month + "/" + year,
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                  })
                  .then(() => {
                    const dateee = new Date();
                    console.warn("Customer create");
                    // db.collection("extras")
                    //   .doc("sales")
                    //   .update({
                    //     customer: firebase.firestore.FieldValue.arrayUnion({
                    //       invoice: invoiceFormatted,
                    //       vehicleNumber: vehicleNumber1,
                    //       vehicleCompany: vehicleCompany1.value, //need
                    //       vehicleModel: vehicleName1.value, //need
                    //       gst: 0, //need
                    //       discount: 0, //need
                    //       totalAmount: packPriceForShow, //need
                    //       count: totalCustomerServiceCount/2, //need
                    //       totalServiceCount: totalCustomerServiceCount/2, //need
                    //       customerName: customerName,
                    //       customerMobile: customerMobile,
                    //       vehicleSize: vehicleType1,
                    //       package: selected.value,
                    //       mode: modeOfTransaction.value || "Cash",
                    //       bill: [
                    //         {
                    //           name: selected.value,
                    //           small: packPriceForShow,
                    //           medium: packPriceForShow,
                    //           large: packPriceForShow,
                    //           amount: packPriceForShow,
                    //           key: selected.value,
                    //         },
                    //       ],
                    //       employeeName: "Admin",
                    //       employeePin: "0",
                    //       approve: true,
                    //       date: date + "/" + month + "/" + year,
                    //       amount: packPriceForShow,
                    //       // timestamp: dateee,
                    //       purchase: moment(customerBackDate).format("DD/MM/YYYY"),
                    //       expiry: packageExpiry,
                    //     }),
                    //   });
                    // db.collection("sales")
                    //   .doc(invoiceFormatted)
                    //   .set({
                    //     invoice: invoiceFormatted,
                    //     vehicleNumber: vehicleNumber+"/"+vehicleNumber1,
                    //     vehicleCompany: vehicleCompany.value+"/"+vehicleCompany1.value, //need
                    //     vehicleModel: vehicleName.value+"/"+vehicleName1.value, //need
                    //     gst: 0, //need
                    //     discount: 0, //need
                    //     totalAmount: packPriceForShow, //need
                    //     count: totalCustomerServiceCount, //need
                    //     totalServiceCount: totalCustomerServiceCount, //need
                    //     customerName: customerName,
                    //     customerMobile: customerMobile,
                    //     vehicleSize: vehicleType+"/"+vehicleType1,
                    //     package: selected.value,
                    //     mode: modeOfTransaction.value || "Cash",
                    //     bill: [
                    //       {
                    //         name: selected.value,
                    //         small: packPriceForShow,
                    //         medium: packPriceForShow,
                    //         large: packPriceForShow,
                    //         amount: packPriceForShow,
                    //         key: selected.value,
                    //       },
                    //     ],
                    //     employeeName: "Admin",
                    //     employeePin: "0",
                    //     approve: true,
                    //     date: date + "/" + month + "/" + year,
                    //     amount: packPriceForShow,
                    //     timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    //     purchase: moment(customerBackDate).format("DD/MM/YYYY"),
                    //     expiry: packageExpiry,
                    //   });
                    console.warn("Sale create");
                  })
                  .then(() => {
                    const temp = servicePackage[index].services || [];
                    temp.forEach(async (item) => {
                      await db
                        .collection("customers")
                        .doc("ISC-" + tempISC2)
                        .collection("services")
                        .doc(item.name)
                        .set(item);
                    });
                    console.warn("Service Sub create");
                    if (adminType === "banda") {
                      db.collection("extras")
                        .doc("bandaCustomerISC")
                        .update({
                          baseISC: firebase.firestore.FieldValue.increment(+1),
                        });
                    } else {
                      db.collection("extras")
                        .doc("customerISC")
                        .update({
                          baseISC: firebase.firestore.FieldValue.increment(+1),
                        });
                    }
                  });
                //Second ISC End Here
              }
            });
          console.warn("ISC up create");
          setloading(70);
          db.collection("extras")
            .doc("invoice")
            .update({
              base:
                newcustomer === true
                  ? invoiceNum
                  : firebase.firestore.FieldValue.increment(+1),
              newbase:
                newcustomer === true
                  ? firebase.firestore.FieldValue.increment(+1)
                  : newInvoice,
            });
          console.warn("Invoice up create");
          setloading(85);
        })
        .then(() => {
          console.warn("Bye");
          setloading(100);
          db.collection("packages")
            .doc(selected.value)
            .collection("history")
            .doc()
            .set({
              name: selected.value,
              price:
                selected.value === "SILVER 2 CAR PACKAGE" ||
                selected.value === "COIN 2 CAR PACKAGE"
                  ? parseInt(packPriceForShow * 2, 10)
                  : parseInt(packPriceForShow, 10),
              type: "Paid",
              category: "Package",
              servicedCity: adminType === "banda" ? "banda" : "sagar",
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            });
          if (
            selected.value === "SILVER 2 CAR PACKAGE" ||
            selected.value === "COIN 2 CAR PACKAGE"
          ) {
            history.push({
              pathname: "/receipt",
              mode: modeOfTransaction.value || "Cash",
              billList: [
                {
                  name: selected.value,
                  small: packPriceForShow * 2,
                  medium: packPriceForShow * 2,
                  large: packPriceForShow * 2,
                  key: selected.value,
                },
              ],
              invoice: newcustomer === true ? newInvoice : invoiceFormatted,
              vehicleCompany: vehicleCompany.value + "-" + vehicleName.value, //need
              vehicleModel: vehicleCompany1.value + "-" + vehicleName1.value, //need
              vehicleSize: vehicleType,
              gst: 0, //need
              discount: 0, //need
              totalAmount: packPriceForShow * 2, //need
              count: totalCustomerServiceCount * 2, //need
              totalServiceCount: totalCustomerServiceCount * 2, //need
              vehicleNumber: vehicleNumber + "/" + vehicleNumber1,
              customerName: customerName,
              customerMobile: customerMobile,
              packageDetails: selected.value,
              city: adminType === "banda" ? "banda" : "sagar",
              date: date + "/" + month + "/" + year,
              amount: packPriceForShow * 2,
              isc: "ISC-" + tempISC + " , ISC-" + tempISC2,
              purchase: moment(customerBackDate).format("DD/MM/YYYY"),
              expiry: packageExpiry,
              checkPage: "cust",
              kilmil:
                vehicleName.value +
                " : " +
                vehicleKM +
                " / " +
                vehicleName1.value +
                " : " +
                vehicleKM1,
            });
          } else {
            history.push({
              pathname: "/receipt",
              mode: modeOfTransaction.value || "Cash",
              billList: [
                {
                  name: selected.value,
                  small: packPriceForShow,
                  medium: packPriceForShow,
                  large: packPriceForShow,
                  key: selected.value,
                },
              ],
              invoice: newcustomer === true ? newInvoice : invoiceFormatted,
              vehicleCompany: vehicleCompany.value, //need
              vehicleModel: vehicleName.value, //need
              gst: 0, //need
              discount: 0, //need
              totalAmount: packPriceForShow, //need
              count: totalCustomerServiceCount, //need
              totalServiceCount: totalCustomerServiceCount, //need
              vehicleNumber: vehicleNumber,
              customerName: customerName,
              customerMobile: customerMobile,
              packageDetails: selected.value,
              date: date + "/" + month + "/" + year,
              amount: packPriceForShow,
              isc: "ISC-" + tempISC,
              vehicleSize: vehicleType,
              purchase: moment(customerBackDate).format("DD/MM/YYYY"),
              expiry: packageExpiry,
              checkPage: "cust",
              kilmil: vehicleKM,
            });
          }
        });
    } catch (error) {
      alert(" Failed Try again" + error);
    }
  };

  // const uploadCustomer = async () => {
  //   const index = findWithAttr(servicePackage, "name", selected.value);

  //   try {
  //     // await alert('Your ISC is ISC-'+tempISC)
  //     setloading(10);

  //     await db
  //       .collection("customers")
  //       .doc("ISC-" + tempISC)
  //       .set({
  //         NAME: customerName,
  //         MOBILE: customerMobile,
  //         EMAIL: customerEmail,
  //         ADDRESS: customerAddress,
  //         COMPANY: vehicleCompany.value,
  //         MODEL: vehicleName.value,
  //         SIZE: vehicleType,
  //         VEHICLENUMBER: vehicleNumber,
  //         KILOMETERDRIVEN: vehicleKM,
  //         PACKAGE: selected.value,
  //         SERVICES: totalCustomerServiceCount,
  //         EXPIRY: packageExpiry,
  //         ISCNO: "ISC-" + tempISC,
  //         PURCHASED: moment(customerBackDate).format("DD/MM/YYYY"),
  //         LASTVISIT: date + "/" + month + "/" + year,
  //         timestamp: firebase.firestore.FieldValue.serverTimestamp(),
  //       })
  //       .then(() => {
  //         const dateee = new Date();
  //         setloading(30);
  //         console.warn("Customer create");
  //         db.collection("extras")
  //           .doc("sales")
  //           .update({
  //             customer: firebase.firestore.FieldValue.arrayUnion({
  //               invoice: invoiceFormatted,
  //               vehicleNumber: vehicleNumber,
  //               vehicleCompany: vehicleCompany.value, //need
  //               vehicleModel: vehicleName.value, //need
  //               gst: 0, //need
  //               discount: 0, //need
  //               totalAmount: packPriceForShow, //need
  //               count: totalCustomerServiceCount, //need
  //               totalServiceCount: totalCustomerServiceCount, //need
  //               customerName: customerName,
  //               customerMobile: customerMobile,
  //               vehicleSize: vehicleType,
  //               package: selected.value,
  //               mode: modeOfTransaction.value || "Cash",
  //               bill: [
  //                 {
  //                   name: selected.value,
  //                   small: packPriceForShow,
  //                   medium: packPriceForShow,
  //                   large: packPriceForShow,
  //                   amount: packPriceForShow,
  //                   key: selected.value,
  //                 },
  //               ],
  //               employeeName: "Admin",
  //               employeePin: "0",
  //               approve: true,
  //               date: date + "/" + month + "/" + year,
  //               amount: packPriceForShow,
  //               // timestamp: dateee,
  //               purchase: moment(customerBackDate).format("DD/MM/YYYY"),
  //               expiry: packageExpiry,
  //             }),
  //           });
  //         db.collection("sales")
  //           .doc(invoiceFormatted)
  //           .set({
  //             invoice: invoiceFormatted,
  //             vehicleNumber: vehicleNumber,
  //             vehicleCompany: vehicleCompany.value, //need
  //             vehicleModel: vehicleName.value, //need
  //             gst: 0, //need
  //             discount: 0, //need
  //             totalAmount: packPriceForShow, //need
  //             count: totalCustomerServiceCount, //need
  //             totalServiceCount: totalCustomerServiceCount, //need
  //             customerName: customerName,
  //             customerMobile: customerMobile,
  //             vehicleSize: vehicleType,
  //             package: selected.value,
  //             mode: modeOfTransaction.value || "Cash",
  //             bill: [
  //               {
  //                 name: selected.value,
  //                 small: packPriceForShow,
  //                 medium: packPriceForShow,
  //                 large: packPriceForShow,
  //                 amount: packPriceForShow,
  //                 key: selected.value,
  //               },
  //             ],
  //             employeeName: "Admin",
  //             employeePin: "0",
  //             approve: true,
  //             date: date + "/" + month + "/" + year,
  //             amount: packPriceForShow,
  //             timestamp: firebase.firestore.FieldValue.serverTimestamp(),
  //             purchase: moment(customerBackDate).format("DD/MM/YYYY"),
  //             expiry: packageExpiry,
  //           });
  //         console.warn("Sale create");
  //       })
  //       .then(() => {
  //         setloading(50);
  //         const temp = servicePackage[index].services || [];
  //         temp.forEach(async (item) => {
  //           await db
  //             .collection("customers")
  //             .doc("ISC-" + tempISC)
  //             .collection("services")
  //             .doc(item.name)
  //             .set(item);
  //         });
  //         console.warn("Service Sub create");
  //         setloading(60);
  //         db.collection("extras")
  //           .doc("customerISC")
  //           .update({
  //             baseISC: firebase.firestore.FieldValue.increment(+1),
  //           });
  //         console.warn("ISC up create");
  //         setloading(70);
  //         db.collection("extras")
  //           .doc("invoice")
  //           .update({
  //             base: firebase.firestore.FieldValue.increment(+1),
  //           });
  //         console.warn("Invoice up create");
  //         setloading(85);
  //       })
  //       .then(() => {
  //         console.warn("Bye");
  //         setloading(100);
  //         history.push({
  //           pathname: "/receipt",
  //           mode: modeOfTransaction.value || "Cash",
  //           billList: [
  //             {
  //               name: selected.value,
  //               small: packPriceForShow,
  //               medium: packPriceForShow,
  //               large: packPriceForShow,
  //             },
  //           ],
  //           invoice: invoiceFormatted,
  //           vehicleCompany: vehicleCompany.value, //need
  //           vehicleModel: vehicleName.value, //need
  //           gst: 0, //need
  //           discount: 0, //need
  //           totalAmount: packPriceForShow, //need
  //           count: totalCustomerServiceCount, //need
  //           totalServiceCount: totalCustomerServiceCount, //need
  //           vehicleNumber: vehicleNumber,
  //           customerName: customerName,
  //           customerMobile: customerMobile,
  //           packageDetails: selected.value,
  //           date: date + "/" + month + "/" + year,
  //           amount: packPriceForShow,
  //           isc: "ISC-" + tempISC,
  //           vehicleSize: vehicleType,
  //           purchase: moment(customerBackDate).format("DD/MM/YYYY"),
  //           expiry: packageExpiry,
  //           checkPage: "cust",
  //         });
  //       });
  //   } catch (error) {
  //     alert(" Failed Try again" + error);
  //   }
  // };

  //Packages Dropdown
  const [pack, setPack] = React.useState([]);

  useEffect(() => {
    const subscriber = db.collection("packages").onSnapshot((querySnapshot) => {
      const tmp = [];

      querySnapshot.forEach((documentSnapshot) => {
        tmp.push({
          ...documentSnapshot.data(),
          key: documentSnapshot.id,
        });
      });
      const list = [];

      tmp.forEach((item, index) => {
        list.push({ value: item.name, label: item.name });
      });

      setPack(list);
    });

    // Unsubscribe from events when no longer in use
    return () => subscriber();
  }, []);

  const [tempISC, settempISC] = useState(
    adminType === "banda" ? iscNumberBanda : ""
  );
  const [tempISC2, settempISC2] = useState("");
  useEffect(() => {
    let farjiISC = parseInt(tempISC, "10") + 1;
    settempISC2(farjiISC);
  }, [tempISC]);
  console.log("tempISC", tempISC);
  console.log("tempISC2", tempISC2);

  const getDetArr = [
    adminType === "banda"
      ? null
      : {
          value: tempISC,
          holder: "Customer ISC",
          setter: settempISC,
          icon: <FcBusinessman />,
          type: "text",
          do: "input",
        },
    {
      value: customerName,
      holder: "Customer Name",
      setter: setcustomerName,
      icon: <FcBusinessman />,
      type: "text",
      do: "input",
    },
    {
      value: customerMobile,
      holder: "Mobile Number",
      setter: setcustomerMobile,
      icon: <AiFillMobile />,
      type: "number",
      do: "input",
    },
    {
      value: customerEmail,
      holder: "Email",
      setter: setcustomerEmail,
      icon: <FcAddressBook />,
      type: "email",
      do: "input",
    },
    {
      value: customerAddress,
      holder: "Address",
      setter: setcustomerAddress,
      icon: <FcHome />,
      type: "text",
      do: "input",
    },
    {
      icon: <AiFillCar />,
      list: vehicalList,
      val: vehicleCompany,
      setter: setvehicleCompany,
      holder: "Vehicle Company",
      do: "drop",
    },
    {
      icon: <AiFillCar />,
      list: vehicleNameList,
      val: vehicleName,
      setter: setvehicleName,
      holder: "Vehicle Model",
      do: "drop",
    },
    {
      value: vehicleNumber,
      holder: "Vehicle Number",
      setter: setvehicleNumber,
      icon: <BiCreditCardFront />,
      type: "text",
      do: "input",
    },
    {
      value: vehicleKM,
      holder: "Kilo Meter",
      setter: setvehicleKM,
      icon: <FcDisplay />,
      type: "number",
      do: "input",
    },
    {
      icon: <FcPackage />,
      list: packageList,
      val: selected,
      setter: setSelected,
      holder: "Select Package",
      do: "drop",
    },
    {
      icon: <FcMoneyTransfer />,
      list: ["Cash", "Card", "UPI", "Other"],
      val: modeOfTransaction,
      setter: setmodeOfTransaction,
      holder: "Cash",
      do: "drop",
    },
    {
      value: customerBackDate,
      holder: "Purchase Date",
      setter: setCustomerBackDate,
      icon: <FcCalendar />,
      type: "date",
      do: "input",
    },
  ];

  const inputHandler = (item) => {
    return item.map((item) => {
      if (item !== null) {
        if (item.do === "input") {
          return (
            <IcoInput
              value={item.value}
              holder={item.holder}
              setter={item.setter}
              icon={item.icon}
              type={item.type}
            />
          );
        } else if (item.do === "drop") {
          return (
            <Drop
              list={item.list}
              val={item.val}
              setter={item.setter}
              icon={item.icon}
              holder={item.holder}
            />
          );
        }
      }
    });
  };

  const [packageSelectedDuration, setpackageSelectedDuration] = useState("");

  useEffect(() => {
    if (selected.value === undefined) {
      return;
    } else {
      db.collection("packages")
        .doc(selected.value)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            setpackageSelectedDuration(doc.data().duration);
          } else {
            alert("No such document!");
          }
        })
        .catch(function (error) {
          alert("Error getting document:", error);
        });
    }
  }, [selected.value]);

  let expiry = moment(customerBackDate, "YYYY-MM-DD");
  expiry.month();
  expiry.add(packageSelectedDuration, "month");
  let packageExpiry = expiry.subtract(1, "days").format("DD/MM/YYYY");

  const [mobileForReceipt, setmobileForReceipt] = useState();

  const [show1, setShow1] = useState(true);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const tab1 = () => {
    setShow1(true);
    setShow2(false);
    setShow3(false);
  };
  const tab2 = () => {
    setShow1(false);
    setShow2(true);
    setShow3(false);
  };
  const tab3 = () => {
    setShow1(false);
    setShow2(false);
    setShow3(true);
  };

  const searchCustomers = async () => {
    adminType === "banda"
      ? await db
          .collection("customers")
          .doc("ISC-" + iscNum)
          .get()
          .then(function (doc) {
            if (doc.exists && doc.data().CITY === "banda") {
              setData(doc.data());
              setGotData(true);
            } else {
              alert("No Customer with this ISC Number!");
            }
          })
      : await db
          .collection("customers")
          .doc("ISC-" + iscNum)
          .get()
          .then(function (doc) {
            if (doc.exists) {
              setData(doc.data());
              setGotData(true);
            } else {
              alert("No Customer with this ISC Number!");
            }
          });
  };

  const reprintReceipt = (item) => {
    history.push({
      pathname: "/receipt",
      invoice: item.invoice,
      vehicleNumber: item.vehicleNumber,
      customerName: item.customerName,
      customerMobile: item.customerMobile,
      vehicleCompany: item.vehicleCompany, //need
      vehicleModel: item.vehicleModel, //need
      gst: item.gst, //need
      discount: item.discount, //need
      totalAmount: item.amount, //need
      count: item.count, //need
      totalServiceCount: item.totalServiceCount, //need
      packageDetails: item.package,
      date: item.date,
      amount: item.amount,
      mode: item.mode,
      billList: item.bill,
      isc: item.isc,
      vehicleSize: item.vehicleSize,
      purchase: item.purchase,
      expiry: item.expiry,
      checkPage: "cust",
      kilmil: item.vehicleKilometer,
    });
  };

  const viewReceipts = async () => {
    if (mobileForReceipt) {
      await db
        .collection("sales")
        .where("vehicleNumber", "==", mobileForReceipt.toUpperCase())
        .where(
          "timestamp",
          ">=",
          firebase.firestore.Timestamp.fromDate(new Date(formatDateFrom))
        )
        .where(
          "timestamp",
          "<=",
          firebase.firestore.Timestamp.fromDate(new Date(formatDateTo))
        )
        .get()
        .then((querySnapshot) => {
          const tmp = [];
          querySnapshot.forEach((documentSnapshot) => {
            tmp.push({
              ...documentSnapshot.data(),
              key: documentSnapshot.id,
            });
          });
          setviewallReceipts(tmp);
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    }
  };

  const showReceiptsForPrint = (item) => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          marginTop: "1vw",
          padding: "1vw",
          paddingLeft: "2vw",
          paddingRight: "2vw",
          borderRadius: "0.5vw",
          backgroundColor: "white",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <label
          style={{
            width: "25%",
            fontFamily: "Montserrat",
            fontSize: "1.25vw",
            fontWeight: "400",
          }}
        >
          {item.date}
        </label>
        <label
          style={{
            width: "25%",
            fontFamily: "Montserrat",
            fontSize: "1.25vw",
            fontWeight: "400",
          }}
        >
          {item.customerName}
        </label>
        <label
          style={{
            width: "25%",
            fontFamily: "Montserrat",
            fontSize: "1.25vw",
            fontWeight: "400",
          }}
        >
          {item.invoice}
        </label>
        <label
          style={{
            width: "25%",
            fontFamily: "Montserrat",
            fontSize: "1.25vw",
            fontWeight: "400",
          }}
        >
          {item.amount}
        </label>
        <FcPrint
          className="printerButton"
          onClick={() => reprintReceipt(item)}
        />
      </div>
    );
  };

  const updateCustomerDetails = () => {
    if (updateName !== "") {
      db.collection("customers")
        .doc("ISC-" + iscNum)
        .update({ NAME: updateName })
        .then(() => {
          alert("Customer Details are updated : " + updateName);
        });
    }
    if (updateNumber !== "") {
      db.collection("customers")
        .doc("ISC-" + iscNum)
        .update({ MOBILE: updateNumber })
        .then(() => {
          alert("Customer Details are updated : " + updateNumber);
        });
    }
    if (updateEmail !== "") {
      db.collection("customers")
        .doc("ISC-" + iscNum)
        .update({ EMAIL: updateEmail })
        .then(() => {
          alert("Customer Details are updated : " + updateEmail);
        });
    }
    if (updateAddress !== "") {
      db.collection("customers")
        .doc("ISC-" + iscNum)
        .update({ ADDRESS: updateAddress })
        .then(() => {
          alert("Customer Details are updated : " + updateAddress);
        });
    }
    if (updateVehicleCompany !== "") {
      db.collection("customers")
        .doc("ISC-" + iscNum)
        .update({ COMPANY: updateVehicleCompany.value })
        .then(() => {
          alert("Customer Details are updated : " + updateVehicleCompany);
        });
    }
    if (updateVehicleName !== "") {
      db.collection("customers")
        .doc("ISC-" + iscNum)
        .update({ MODEL: updateVehicleName.value })
        .then(() => {
          alert("Customer Details are updated : " + updateVehicleName);
        });
    }
    if (updateVehicleType !== "") {
      db.collection("customers")
        .doc("ISC-" + iscNum)
        .update({ SIZE: updateVehicleType.value })
        .then(() => {
          alert("Customer Details are updated : " + updateVehicleType.value);
        });
    }
    if (updateVehicleNumber !== "") {
      db.collection("customers")
        .doc("ISC-" + iscNum)
        .update({ VEHICLENUMBER: updateVehicleNumber })
        .then(() => {
          alert("Customer Details are updated : " + updateVehicleNumber);
        });
    }

    if (updateVehicleKM !== "") {
      db.collection("customers")
        .doc("ISC-" + iscNum)
        .update({ KILOMETERDRIVEN: updateVehicleKM })
        .then(() => {
          alert("Customer Details are updated : " + updateVehicleKM);
        });
    }
    setGotData(false);
  };

  useEffect(() => {
    console.log("i selceted", selected.value);
    if (selected) {
      db.collection("packages")
        .doc(selected.value)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            if (vehicleType === "small" || "Small")
              setpackPriceForShow(doc.data().cost.small);
            if (vehicleType === "medium")
              setpackPriceForShow(doc.data().cost.medium);
            if (vehicleType === "large")
              setpackPriceForShow(doc.data().cost.large);
          } else {
            alert("No such Package!");
          }
        })
        .catch(function (error) {
          alert("Error getting document:", error);
        });
    }
  }, [selected, vehicleType]);

  useEffect(() => {
    if (selected.value) {
      db.collection("packages")
        .doc(selected.value)
        .get()
        .then((item) => {
          setTotalCustomerServiceCount(item.data().count);
        });
    }
  }, [selected]);

  return (
    <div>
      <Loading load={loading} />
      <div className="top-tap-bar">
        <div
          style={{ cursor: "pointer" }}
          className={show1 === true ? "ActiveCard-customer" : "InactiveCard"}
          onClick={tab1}
        >
          <p className="tile1cust">
            Create <b className="tile2cust">Customer</b>
          </p>
        </div>
        <div
          style={{ cursor: "pointer" }}
          className={show2 === true ? "ActiveCard-customer" : "InactiveCard"}
          onClick={tab2}
        >
          <p className="tile1cust">
            Edit <b className="tile2cust">Customer</b>
          </p>
        </div>
        <div
          style={{ cursor: "pointer" }}
          className={show3 === true ? "ActiveCard-customer" : "InactiveCard"}
          onClick={tab3}
        >
          <p className="tile1cust">
            View <b className="tile2cust">Receipts</b>
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          marginLeft: "4.25%",
        }}
      >
        <hr
          style={{
            width: "50%",
            marginTop: "25px",
            backgroundColor: "red",
            opacity: 0.2,
          }}
        />
      </div>
      <label
        style={{
          marginTop: "10px",
          fontFamily: "Montserrat",
          fontWeight: "400",
          marginLeft: "4.25%",
          fontSize: window.innerWidth < 520 ? "2.5vw" : "1vw",
        }}
      >
        Enter The Required Customer Details Below
      </label>
      <br />
      {adminType === "banda" ? (
        <label
          style={{
            marginTop: "10px",
            fontFamily: "Montserrat",
            fontWeight: "700",
            marginLeft: "4.25%",
            fontSize: window.innerWidth < 520 ? "2.5vw" : "1vw",
          }}
        >
          Alloted ISC number : {iscNumberBanda}
        </label>
      ) : null}
      {show1 === true ? (
        <div>
          <div className="cust-box-customer">{inputHandler(getDetArr)}</div>
          <div style={{ paddingLeft: "5.5%" }}>
            {" "}
            {adminType === "banda" ? (
              <></>
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    icon={
                      <RiCheckboxBlankCircleLine style={{ color: "red" }} />
                    }
                    checkedIcon={<FcCheckmark />}
                    checked={newcustomer}
                    onChange={handleChangeNew}
                    name="checkedH"
                  />
                }
                label="New Customer"
              />
            )}
          </div>

          {selected.value === "SILVER 2 CAR PACKAGE" ||
          selected.value === "COIN 2 CAR PACKAGE" ? (
            <>
              <label
                style={{
                  marginTop: "10px",
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  marginLeft: "4.5%",
                  fontSize: window.innerWidth < 520 ? "2.5vw" : "1vw",
                }}
              >
                Enter 2nd Vehicle Details
              </label>
              <div className="cust-box-customer">
                {/* <Drop
                  icon={<FcPackage />}
                  list={["DP-2nd Vehicle"]}
                  val={"2nd Vehicle Pack"}
                  setter={setSelected2}
                  holder={"Select 2nd Package"}
                /> */}
                <Drop
                  icon={<AiFillCar />}
                  list={vehicalList1}
                  val={vehicleCompany1}
                  setter={setvehicleCompany1}
                  holder={"2nd Vehicle Company"}
                />
                <Drop
                  icon={<AiFillCar />}
                  list={vehicleNameList1}
                  val={vehicleName1}
                  setter={setvehicleName1}
                  holder={"2nd Vehicle Model"}
                />
                <IcoInput
                  value={vehicleNumber1}
                  holder={"2nd Vehicle Number"}
                  setter={setvehicleNumber1}
                  icon={<BiCreditCardFront />}
                  type={"text"}
                />
                <IcoInput
                  value={vehicleKM1}
                  holder={"2nd Vehicle KM"}
                  setter={setvehicleKM1}
                  icon={<FcDisplay />}
                  type={"number"}
                />
              </div>
            </>
          ) : null}

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <hr
              style={{
                width: "75%",
                marginTop: "25px",
                backgroundColor: "red",
                opacity: 0.2,
              }}
            />
          </div>
          {showCustomerButton === true ? (
            <div onClick={() => uploadCustomer()} className="button-Customer">
              <IcoButton icon={label} start="Create" end="Customer" />
            </div>
          ) : null}
        </div>
      ) : null}
      {show2 === true ? (
        gotData === false ? (
          <div className="check-details">
            <div>
              <IcoInput
                value={iscNum}
                holder="ISC-"
                setter={setiscNum}
                icon={<FcDisplay />}
                type="text"
              />
            </div>
            <div onClick={searchCustomers} style={{ marginLeft: "7.5vw" }}>
              <IcoButton icon={label} start="Search" end="Customer" />
            </div>
          </div>
        ) : (
          <div>
            <div className="All-edit-field-div">
              <div>
                <IcoInput
                  value={updateName}
                  holder={data.NAME}
                  setter={setUpdateName}
                  icon={<FcBusinessman />}
                />
              </div>
              <div>
                <IcoInput
                  value={updateNumber}
                  holder={data.MOBILE}
                  setter={setUpdateNumber}
                  icon={<AiFillMobile />}
                />
              </div>

              <div>
                <IcoInput
                  value={updateEmail}
                  holder={data.EMAIL}
                  setter={setUpdateEmail}
                  icon={<FcAddressBook />}
                />
              </div>
              <div>
                <IcoInput
                  value={updateAddress}
                  holder={data.ADDRESS}
                  setter={setUpdateAddress}
                  icon={<FcHome />}
                />
              </div>

              <div>
                <IcoInput
                  value={updateVehicleNumber}
                  holder={data.VEHICLENUMBER}
                  setter={setUpdateVehicleNumber}
                  icon={<BiCreditCardFront />}
                />
              </div>
              <div>
                <IcoInput
                  value={updateVehicleKM}
                  holder={data.KILOMETERDRIVEN}
                  setter={setUpdateVehicleKM}
                  icon={<FcDisplay />}
                  type="number"
                />
              </div>
              <div>
                <Drop
                  list={vechileCompanyDropList}
                  val={updateVehicleCompany}
                  setter={setUpdateVechicleCompany}
                  icon={<AiFillCar />}
                  holder={data.COMPANY}
                />
              </div>
              <div>
                <Drop
                  list={vehicleNameListDrop}
                  val={updateVehicleName}
                  setter={setUpdateVehicleName}
                  icon={<AiFillCar />}
                  holder={data.MODEL}
                />
              </div>
              <div>
                <Drop
                  list={priceList}
                  val={updateVehicleType}
                  setter={setUpdateVehicleType}
                  icon={<AiFillCar />}
                  holder={data.SIZE}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <hr
                style={{
                  width: "75%",
                  marginTop: "25px",
                  backgroundColor: "red",
                  opacity: 0.2,
                }}
              />
            </div>
            <div className="button-Customer" onClick={updateCustomerDetails}>
              <IcoButton icon={label} start="Update" end="Customer" />
            </div>
          </div>
        )
      ) : null}
      {show3 === true ? (
        <div>
          <div className="check-details">
            <div style={{ marginTop: "1vh", marginLeft: "1vw" }}>
              <IcoInput
                value={mobileForReceipt}
                holder="Enter Vehicle No "
                setter={setmobileForReceipt}
                icon={<AiFillMobile />}
                type="text"
              />
            </div>
            {/* <div style={{marginTop: "1vh" , marginLeft :"1vw"}}>
            <Drop
                  list={["2020","2021","2022","2023","2024","2025","2026","2027","2028","2029","2030",]}
                  val={fromDate}
                  setter={setFromDate}
                  icon={<AiFillCar />}
                  holder="Select Year"
                />
            </div>
            <div style={{marginTop: "1vh", marginLeft :"1vw" }}>
            <Drop
                  list={["01","02","03","04","05","06","07","08","09","10","11","12"]}
                  val={monthSelect}
                  setter={setMonthSelect}
                  icon={<AiFillCar />}
                  holder="Select Month"
                />
            </div> */}
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                marginLeft: "1vw",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "1vw",
                    alignItems: "center",
                    marginRight: "1vw",
                  }}
                >
                  Date From
                </label>
                <IcoInput
                  value={dateFrom}
                  holder={"Date From"}
                  type={"date"}
                  icon={<FcCalendar />}
                  setter={setdateFrom}
                  fontsize={window.innerWidth < 520 ? " 2vw" : "0.75vw"}
                  width={window.innerWidth < 520 ? 100 : null}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "1vw",
                }}
              >
                <label
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "1vw",
                    alignItems: "center",
                    marginRight: "1vw",
                  }}
                >
                  Date To
                </label>
                <IcoInput
                  value={dateTo}
                  holder={"Date To"}
                  type={"date"}
                  icon={<FcCalendar />}
                  setter={setdateTo}
                  fontsize={window.innerWidth < 520 ? " 2vw" : "0.75vw"}
                  width={window.innerWidth < 520 ? 100 : null}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: "5.1%",
              marginTop: window.innerWidth < 520 ? "1vw" : null,
            }}
            onClick={viewReceipts}
          >
            <IcoButton icon={label} start="View" end="Receipts" />
          </div>
          <hr
            style={{
              width: "90%",
              backgroundColor: "red",
              opacity: 0.2,
              marginLeft: "4%",
            }}
          />
          <div
            style={{
              display: "flex",
              flex: 1,
              width: "90%",
              flexDirection: "column",
              marginLeft: "4%",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "1vw",
                paddingLeft: "2vw",
                paddingRight: "2vw",
                backgroundColor: "#fe5236",
                borderRadius: "0.5vw",
                marginTop: "1vh",
              }}
            >
              <label
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "1vw",
                  color: "white",
                }}
              >
                Service Date
              </label>
              <label
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "1vw",
                  color: "white",
                }}
              >
                Customer Name
              </label>
              <label
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "1vw",
                  color: "white",
                }}
              >
                Invoice No
              </label>
              <label
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "1vw",
                  color: "white",
                }}
              >
                Amount
              </label>
              <label
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "1vw",
                  color: "white",
                }}
              >
                Reprint Receipt
              </label>
            </div>
            {viewallReceipts.map(showReceiptsForPrint)}
          </div>
        </div>
      ) : null}
    </div>
  );
}
