import React, { useState, useEffect } from "react";
import "./AdminServiceCard.css";
import db from "../../firebase/database";
import { FcClock, FcMoneyTransfer, FcEngineering } from "react-icons/fc";
export default function card(props) {
  // const card = (props) => {
  console.log(props.type);

  const deleteService = async () => {
    if (props.type === "Labour Cost") {
      db.collection("labourCost")
        .doc(props.name)
        .delete()
        .then(function () {
          var filtered = [];
          db.collection("extras")
            .doc("services")
            .get()
            .then((det) => {
              filtered = det.data().labourCost.filter(function (el) {
                return el.name != props.name;
              });
            })
            .then(() => {
              db.collection("extras").doc("services").update({
                labourCost: filtered,
              });
            });

          console.log(props.name + " Labour Charge successfully deleted!");
        })
        .catch(function (error) {
          console.error("Error removing document: ", error);
        });
    } else if (props.type === "Paint Service") {
      db.collection("paintCost")
        .doc(props.name)
        .delete()
        .then(function () {
          var filtered = [];
          db.collection("extras")
            .doc("services")
            .get()
            .then((det) => {
              filtered = det.data().paintCost.filter(function (el) {
                return el.name != props.name;
              });
            })
            .then(() => {
              db.collection("extras").doc("services").update({
                paintCost: filtered,
              });
            });
          console.log(props.name + " Paint Service successfully deleted!");
        })
        .catch(function (error) {
          console.error("Error removing document: ", error);
        });
    } else {
      db.collection("services")
        .doc(props.name)
        .delete()
        .then(function () {
          var filtered = [];
          db.collection("extras")
            .doc("services")
            .get()
            .then((det) => {
              filtered = det.data().services.filter(function (el) {
                return el.name != props.name;
              });
            })
            .then(() => {
              db.collection("extras").doc("services").update({
                services: filtered,
              });
            });
          console.log(props.name + " Service successfully deleted!");
        })
        .catch(function (error) {
          console.error("Error removing document: ", error);
        });
    }
  };

  return (
    <div className="card-container-admin">
      <div className="mini-card-top">
        <serv class="serviceTitle">{props.name}</serv>
        {props.adminType === "banda" ? null : (
          <button
            type="button"
            class="buttonDelete"
            onClick={() => {
              if (
                window.confirm("Are you sure you wish to delete this Service?")
              ) {
                deleteService();
              }
            }}
          >
            Delete
          </button>
        )}
      </div>
      <div className="bottom-mini-card-Service">
        <div className="bottom-mini-div">
          <FcClock
            style={{
              fontSize: "1.5vw",
              marginLeft: "3vw",
            }}
          />

          <time className="time">{props.time}-min </time>
        </div>
        <div className="bottom-mini-div">
          <price class="price">Small ₹{props.small}</price>
          <price class="price">Medium ₹{props.medium}</price>
          <price class="price">Large ₹{props.large}</price>
        </div>
      </div>
    </div>
  );
}
