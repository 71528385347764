import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import db from "../../firebase/database";
import dbr from "../../firebase/database";
import "./SalesTable.css";
import firebase from "../../firebase/firebase";
import IcoInput from "../IcoInput";
import IcoButton from "../IcoButton";
import Drop from "../Drop";
import moment from "moment";
import { FcCalendar, FcSalesPerformance } from "react-icons/fc";
import { useHistory, useLocation } from "react-router-dom";
import Loading from "../Loading/Loading";

export default function SalesTable() {
  const [adminType, setAdminType] = useState();
  useEffect(() => {
    setAdminType(window.sessionStorage.getItem("adminType"));
  }, []);

  const history = useHistory();
  const location = useLocation();
  const [spe, setSpe] = useState([]);
  const [loading, setloading] = useState(0);
  const [serviceDrop, setserviceDrop] = useState("");
  const [packageDrop, setpackageDrop] = useState("");

  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("400px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");

  const [set1, setset1] = useState(false);
  const b1 = () => {
    setset1(true);
    setset2(false);
    setset3(false);
    setset4(false);
    setset5(false);
    setset6(false);
    setset7(false);
  };
  const [set2, setset2] = useState(false);
  const b2 = () => {
    setset1(false);
    setset2(true);
    setset3(false);
    setset4(false);
    setset5(false);
    setset6(false);
    setset7(false);
  };
  const [set3, setset3] = useState(false);
  const b3 = () => {
    setset1(false);
    setset2(false);
    setset3(true);
    setset4(false);
    setset5(false);
    setset6(false);
    setset7(false);
  };
  const [set4, setset4] = useState(false);
  const b4 = () => {
    setset1(false);
    setset2(false);
    setset3(false);
    setset4(true);
    setset5(false);
    setset6(false);
    setset7(false);
  };
  const [set5, setset5] = useState(false);
  const b5 = () => {
    setset1(false);
    setset2(false);
    setset3(false);
    setset4(false);
    setset5(true);
    setset6(false);
    setset7(false);
  };
  const [set6, setset6] = useState(false);
  const b6 = () => {
    setset1(false);
    setset2(false);
    setset3(false);
    setset4(false);
    setset5(false);
    setset6(true);
    setset7(false);
  };
  const [set7, setset7] = useState(false);
  const b7 = () => {
    setset1(false);
    setset2(false);
    setset3(false);
    setset4(false);
    setset5(false);
    setset6(false);
    setset7(true);
  };
  const closeAll = () => {
    setset1(false);
    setset2(false);
    setset3(false);
    setset4(false);
    setset5(false);
    setset6(false);
    setset7(false);
  };

  //Customer Table
  const [customerDetails, setcustomerDetails] = React.useState([]);
  useEffect(() => {
    if (adminType === "banda") {
      if (set2 === true) {
        const subscriber = db
          .collection("customers")
          .where("CITY", "==", "banda")
          .onSnapshot((querySnapshot) => {
            const tmp = [];

            querySnapshot.forEach((documentSnapshot) => {
              if (documentSnapshot.data().CITY === "banda") {
                tmp.push({
                  ...documentSnapshot.data(),
                  key: documentSnapshot.id,
                });
              }
            });

            setcustomerDetails(tmp);
          });

        // Unsubscribe from events when no longer in use
        return () => subscriber();
      }
    } else {
      if (set2 === true) {
        const subscriber = db
          .collection("customers")
          .onSnapshot((querySnapshot) => {
            const tmp = [];

            querySnapshot.forEach((documentSnapshot) => {
              tmp.push({
                ...documentSnapshot.data(),
                key: documentSnapshot.id,
              });
            });

            setcustomerDetails(tmp);
          });

        // Unsubscribe from events when no longer in use
        return () => subscriber();
      }
    }
  }, [set2]);

  // //Services Table
  // const [pack, setpack] = React.useState([]);
  // useEffect(() => {
  //   const subscriber = db.collection("services").onSnapshot((querySnapshot) => {
  //     const tmp = [];

  //     querySnapshot.forEach((documentSnapshot) => {
  //       tmp.push({
  //         ...documentSnapshot.data(),
  //         key: documentSnapshot.id,
  //       });
  //     });

  //     setpack(tmp);
  //   });

  //   // Unsubscribe from events when no longer in use
  //   return () => subscriber();
  // }, []);

  //Employee Table
  const [employeeDetails, setemployeeDetails] = React.useState([]);
  useEffect(() => {
    if (adminType === "banda") {
      const subscriber = db
        .collection("employee")
        .onSnapshot((querySnapshot) => {
          const tmp = [];

          querySnapshot.forEach((documentSnapshot) => {
            if (documentSnapshot.data().CITY === "banda") {
              tmp.push({
                ...documentSnapshot.data(),
                key: documentSnapshot.id,
              });
            }
          });

          setemployeeDetails(tmp);
        });
    } else {
      const subscriber = db
        .collection("employee")
        .onSnapshot((querySnapshot) => {
          const tmp = [];

          querySnapshot.forEach((documentSnapshot) => {
            tmp.push({
              ...documentSnapshot.data(),
              key: documentSnapshot.id,
            });
          });

          setemployeeDetails(tmp);
        });
    }

    // Unsubscribe from events when no longer in use
    // return () => subscriber();
  }, [adminType]);

  //Packages Table Starts Here

  const [shows1, setShows1] = useState(false);
  const [shows2, setShows2] = useState(true);
  const [shows3, setShows3] = useState(false);
  const [shows4, setShows4] = useState(false);
  const bt1 = () => {
    setShows1(true);
    setShows2(false);
    setShows3(false);
    setShows4(false);
  };
  const bt2 = () => {
    setShows1(false);
    setShows2(true);
    setShows3(false);
    setShows4(false);
  };
  const bt3 = () => {
    setShows1(false);
    setShows2(false);
    setShows3(true);
    setShows4(false);
  };
  const bt4 = () => {
    setShows1(false);
    setShows2(false);
    setShows3(false);
    setShows4(true);
  };
  function filterArr(data, key) {
    return data.reduce((result, current) => {
      const size = current.vehicleSize;
      if (!result[current[key]]) {
        result[current[key]] = {
          Name: current.name,
          Count: 1,
          Cash: parseInt(current[current.vehicleSize]),
          Free: parseInt(current[current.vehicleSize]) === 0 ? 1 : 0,
        };
      } else {
        result[current[key]].Count += 1;
        result[current[key]].Cash += parseInt(current[current.vehicleSize]);
        result[current[key]].Free =
          parseInt(current[current.vehicleSize]) === 0
            ? result[current[key]].Free + 1
            : result[current[key]].Free + 0;
      }
      return result;
    }, {});
  }
  function filterArrService(data, key) {
    return data.reduce((result, current) => {
      const size = current.vehicleSize;
      if (!result[current[key]]) {
        result[current[key]] = {
          Name: current.name,
          Count: 1,
          Cash: parseInt(current[current.vehicleSize]),
          Free: parseInt(current[current.vehicleSize]) === 0 ? 1 : 0,
        };
      } else {
        result[current[key]].Count += 1;
        result[current[key]].Cash += parseInt(current[current.vehicleSize]);
        result[current[key]].Free =
          parseInt(current[current.vehicleSize]) === 0
            ? result[current[key]].Free + 1
            : result[current[key]].Free + 0;
      }
      return result;
    }, {});
  }
  const [dateFromCount, setdateFromCount] = useState();
  const [dateToCount, setdateToCount] = useState();
  let formatDateFromCount = moment(dateFromCount).format("MM/DD/YYYY");
  let formatDateToCount = moment(dateToCount).format("MM/DD/YYYY");

  const getDentingPaintingCount = () => {
    if (adminType == "banda") {
      console.log(" Getting-------------");

      let billDentPaint = [];

      if (set4 === true) {
        db.collection("sales")
          .where(
            "timestamp",
            ">=",
            firebase.firestore.Timestamp.fromDate(new Date(formatDateFromCount))
          )
          .where(
            "timestamp",
            "<=",
            firebase.firestore.Timestamp.fromDate(new Date(formatDateToCount))
          )
          .where("servicedCity", "==", "banda")
          .get()
          .then((doc) => {
            doc.forEach((det) => {
              if (det.data().vehicleSize) {
                det.data().bill.forEach((e) => {
                  if (
                    e.category == "Denting Service" ||
                    e.category == "Painting Service"
                  )
                    billDentPaint.push({
                      ...e,
                      vehicleSize: det.data().vehicleSize,
                    });
                });
              }
            });

            setpackageDetails4(Object.values(filterArr(billDentPaint, "name")));
            console.log(" dent paint", billDentPaint);
          });
      }
    } else {
      console.log(" Getting-------------");

      let billDentPaint = [];

      if (set4 === true) {
        db.collection("sales")
          .where(
            "timestamp",
            ">=",
            firebase.firestore.Timestamp.fromDate(new Date(formatDateFromCount))
          )
          .where(
            "timestamp",
            "<=",
            firebase.firestore.Timestamp.fromDate(new Date(formatDateToCount))
          )

          .get()
          .then((doc) => {
            doc.forEach((det) => {
              if (det.data().vehicleSize) {
                det.data().bill.forEach((e) => {
                  if (
                    e.category == "Denting Service" ||
                    e.category == "Painting Service"
                  )
                    billDentPaint.push({
                      ...e,
                      vehicleSize: det.data().vehicleSize,
                    });
                });
              }
            });

            setpackageDetails4(Object.values(filterArr(billDentPaint, "name")));
            console.log(" dent paint", billDentPaint);
          });
      }
    }
  };
  const getLabourCostCount = () => {
    if (adminType === "banda") {
      console.log(" Getting Labur thing");

      let billLabour = [];

      if (set4 === true) {
        db.collection("sales")
          .where(
            "timestamp",
            ">=",
            firebase.firestore.Timestamp.fromDate(new Date(formatDateFromCount))
          )
          .where(
            "timestamp",
            "<=",
            firebase.firestore.Timestamp.fromDate(new Date(formatDateToCount))
          )
          .where("servicedCity", "==", "banda")
          .get()
          .then((doc) => {
            doc.forEach((det) => {
              if (det.data().vehicleSize) {
                det.data().bill.forEach((e) => {
                  if (e.category == "Labour Cost") {
                    billLabour.push({
                      ...e,
                      vehicleSize: det.data().vehicleSize,
                    });
                  }
                });
              }
            });

            setpackageDetails3(Object.values(filterArr(billLabour, "name")));
            console.log("Bill Baliyr", billLabour);
          });
      }
    } else {
      console.log(" Getting Labur thing");

      let billLabour = [];

      if (set4 === true) {
        db.collection("sales")
          .where(
            "timestamp",
            ">=",
            firebase.firestore.Timestamp.fromDate(new Date(formatDateFromCount))
          )
          .where(
            "timestamp",
            "<=",
            firebase.firestore.Timestamp.fromDate(new Date(formatDateToCount))
          )

          .get()
          .then((doc) => {
            doc.forEach((det) => {
              if (det.data().vehicleSize) {
                det.data().bill.forEach((e) => {
                  if (e.category == "Labour Cost") {
                    billLabour.push({
                      ...e,
                      vehicleSize: det.data().vehicleSize,
                    });
                  }
                });
              }
            });

            setpackageDetails3(Object.values(filterArr(billLabour, "name")));
            console.log("Bill Baliyr", billLabour);
          });
      }
    }
  };
  const getProductCount = () => {
    if (adminType === "banda") {
      let billProduct = [];
      console.log("getting products");
      db.collection("sales")
        .where(
          "timestamp",
          ">=",
          firebase.firestore.Timestamp.fromDate(new Date(formatDateFromCount))
        )
        .where(
          "timestamp",
          "<=",
          firebase.firestore.Timestamp.fromDate(new Date(formatDateToCount))
        )
        .where("servicedCity", "==", "banda")
        .get()
        .then((doc) => {
          doc.forEach((det) => {
            if (det.data().vehicleSize) {
              det.data().bill.forEach((e) => {
                if (e.category == "Products") {
                  billProduct.push({
                    ...e,
                    vehicleSize: det.data().vehicleSize,
                  });
                }
              });
            }
          });

          setpackageDetails2(Object.values(filterArr(billProduct, "name")));
          console.log("bill Produ", billProduct);
        });
    } else {
      let billProduct = [];
      console.log("getting products");
      db.collection("sales")
        .where(
          "timestamp",
          ">=",
          firebase.firestore.Timestamp.fromDate(new Date(formatDateFromCount))
        )
        .where(
          "timestamp",
          "<=",
          firebase.firestore.Timestamp.fromDate(new Date(formatDateToCount))
        )
        .get()
        .then((doc) => {
          doc.forEach((det) => {
            if (det.data().vehicleSize) {
              det.data().bill.forEach((e) => {
                if (e.category == "Products") {
                  billProduct.push({
                    ...e,
                    vehicleSize: det.data().vehicleSize,
                  });
                }
              });
            }
          });

          setpackageDetails2(Object.values(filterArr(billProduct, "name")));
          console.log("bill Produ", billProduct);
        });
    }
  };
  //! !*!!
  const getServiceCount = () => {
    if (adminType === "banda") {
      let billEach = [];
      console.log("from date", formatDateFromCount);
      console.log("to date", formatDateToCount);
      db.collection("sales")
        .where(
          "timestamp",
          ">=",
          firebase.firestore.Timestamp.fromDate(new Date(formatDateFromCount))
        )
        .where(
          "timestamp",
          "<=",
          firebase.firestore.Timestamp.fromDate(new Date(formatDateToCount))
        )
        .where("servicedCity", "==", "banda")
        .get()
        .then((doc) => {
          doc.forEach((det) => {
            if (det.data().vehicleSize) {
              det.data().bill.forEach((e) => {
                if (
                  e.category !== "Products" &&
                  e.category !== "Labour Cost" &&
                  e.category !== "Denting Service" &&
                  e.category !== "Painting Service"
                )
                  if (det.data().vehicleSize) {
                    billEach.push({
                      ...e,
                      vehicleSize: det.data().vehicleSize,
                    });
                  }
              });
            }
          });

          setpackageDetails1(Object.values(filterArr(billEach, "name")));
        });
    } else {
      let billEach = [];
      console.log("from date", formatDateFromCount);
      console.log("to date", formatDateToCount);
      db.collection("sales")
        .where(
          "timestamp",
          ">=",
          firebase.firestore.Timestamp.fromDate(new Date(formatDateFromCount))
        )
        .where(
          "timestamp",
          "<=",
          firebase.firestore.Timestamp.fromDate(new Date(formatDateToCount))
        )
        .get()
        .then((doc) => {
          doc.forEach((det) => {
            if (det.data().vehicleSize) {
              det.data().bill.forEach((e) => {
                if (
                  e.category !== "Products" &&
                  e.category !== "Labour Cost" &&
                  e.category !== "Denting Service" &&
                  e.category !== "Painting Service"
                )
                  if (det.data().vehicleSize) {
                    billEach.push({
                      ...e,
                      vehicleSize: det.data().vehicleSize,
                    });
                  }
              });
            }
          });

          setpackageDetails1(Object.values(filterArr(billEach, "name")));
        });
    }
  };
  const DatePickerForCountTables = (type) => {
    return (
      <div
        style={{
          padding: "1vw",
          borderRadius: "0.5vw",
          marginBottom: "1vw",
          display: "flex",
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "#EEEEEE",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 2,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <label
            style={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "1vw",
              alignItems: "center",
            }}
          >
            Date From
          </label>
          <IcoInput
            value={dateFromSales}
            holder={"Date From"}
            type={"date"}
            icon={<FcCalendar />}
            setter={setdateFromCount}
            fontsize={"0.75vw"}
            width={window.innerWidth < 520 ? 100 : null}
          />
          <label
            style={{
              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "1vw",
              alignItems: "center",
            }}
          >
            Date To
          </label>
          <IcoInput
            value={dateToSales}
            holder={"Date To"}
            type={"date"}
            icon={<FcCalendar />}
            setter={setdateToCount}
            fontsize={"0.75vw"}
            width={window.innerWidth < 520 ? 100 : null}
          />
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
          }}
        >
          {window.innerWidth < 520 ? null : (
            <div
              style={{ marginLeft: "2vw" }}
              onClick={() => {
                switch (type) {
                  case "Service Table":
                    {
                      getServiceCount();
                      checkMe();
                    }
                    break;
                  case "Product Table":
                    getProductCount();
                    break;
                  case "Labour Table":
                    getLabourCostCount();
                    break;
                  case "Denting/Painting Table":
                    getDentingPaintingCount();
                    break;
                }
              }}
            >
              <IcoButton
                icon={<FcSalesPerformance />}
                start="View"
                end="Report"
              />
            </div>
          )}
        </div>
      </div>
    );
  };
  // useEffect(, [set4]);
  const [packageDetails1, setpackageDetails1] = React.useState([
    { Service: "", Count: "", Cash: "", Free: "" },
  ]);
  
  const [packageDetails2, setpackageDetails2] = React.useState([
    { Service: "", Count: "", Cash: "" },
  ]);

  const [packageDetails3, setpackageDetails3] = React.useState([
    { Service: "", Count: "", Cash: "" },
  ]);
  const [packageDetails4, setpackageDetails4] = React.useState([
    { Service: "", Count: "", Cash: "" },
  ]);

  //Sales Table
  const [dateFromSales, setdateFromSales] = useState();
  const [dateToSales, setdateToSales] = useState();
  const [salesDetails, setSalesDetails] = useState([]);

  let formatDateFromSales = moment(dateFromSales).format("MM/DD/YYYY");
  let formatDateToSales = moment(dateToSales).format("MM/DD/YYYY");

  let ShowformatDateFromSales = moment(dateFromSales).format("DD/MM/YYYY");
  let ShowformatDateToSales = moment(dateToSales).format("DD/MM/YYYY");
  const checkMe = () => {
    let billEach = [];
    console.log("from date", formatDateFromCount);
    console.log("to date", formatDateToCount);
    db.collection("sales")
      .where(
        "timestamp",
        ">=",
        firebase.firestore.Timestamp.fromDate(new Date(formatDateFromCount))
      )
      .where(
        "timestamp",
        "<=",
        firebase.firestore.Timestamp.fromDate(new Date(formatDateToCount))
      )
      .get()
      .then((doc) => {
        doc.forEach((det) => {
          if (det.data().vehicleSize) {
            det.data().bill.forEach((e) => {
              if (
                e.category !== "Products" &&
                e.category !== "Labour Cost" &&
                e.category !== "Denting Service" &&
                e.category !== "Painting Service"
              )
                if (det.data().vehicleSize) {
                  billEach.push({
                    ...e,
                    vehicleSize: det.data().vehicleSize,
                  });
                }
            });
          }
        });
        console.log(billEach);

        setpackageDetails1(Object.values(filterArr(billEach, "name")));
      });
  };
  const getSalesInfo = () => {
    setloading(25);
    if (adminType === "banda") {
      db.collection("sales")
        .where(
          "timestamp",
          ">=",
          firebase.firestore.Timestamp.fromDate(new Date(formatDateFromSales))
        )
        .where(
          "timestamp",
          "<=",
          firebase.firestore.Timestamp.fromDate(new Date(formatDateToSales))
        )
        .where("servicedCity", "==", "banda")
        .orderBy("timestamp", "asc")
        .get()
        .then((querySnapshot) => {
          const tmp = [];
          querySnapshot.forEach((documentSnapshot) => {
            tmp.push({
              ...documentSnapshot.data(),
              key: documentSnapshot.id,
            });
          });
          setSalesDetails(tmp);
        })
        .then(() => {
          setloading(100);
        });
    } else {
      db.collection("sales")
        .where(
          "timestamp",
          ">=",
          firebase.firestore.Timestamp.fromDate(new Date(formatDateFromSales))
        )
        .where(
          "timestamp",
          "<=",
          firebase.firestore.Timestamp.fromDate(new Date(formatDateToSales))
        )
        .orderBy("timestamp", "asc")
        .get()
        .then((querySnapshot) => {
          const tmp = [];
          querySnapshot.forEach((documentSnapshot) => {
            tmp.push({
              ...documentSnapshot.data(),
              key: documentSnapshot.id,
            });
          });
          setSalesDetails(tmp);
        })
        .then(() => {
          setloading(100);
        });
    }
  };

  // const [invoiceDetails, setinvoiceDetails] = React.useState([]);
  // useEffect(() => {
  //   const subscriber = db.collection("sales").onSnapshot((querySnapshot) => {
  //     const tmp = [];

  //     querySnapshot.forEach((documentSnapshot) => {
  //       tmp.push({
  //         ...documentSnapshot.data(),
  //         key: documentSnapshot.id,
  //       });
  //     });
  //     setinvoiceDetails(tmp);
  //   });

  //   // Unsubscribe from events when no longer in use
  //   return () => subscriber();
  // }, []);

  //Products Table
  const [productDetails, setproductDetails] = React.useState([]);
  useEffect(() => {
    const subscriber = db.collection("products").onSnapshot((querySnapshot) => {
      const tmp = [];

      querySnapshot.forEach((documentSnapshot) => {
        tmp.push({
          ...documentSnapshot.data(),
          key: documentSnapshot.id,
        });
      });
      setproductDetails(tmp);
    });

    // Unsubscribe from events when no longer in use
    return () => subscriber();
  }, []);

  //GST Details Table
  const [dateFrom, setdateFrom] = useState();
  const [dateTo, setdateTo] = useState();

  let formatDateFrom = moment(dateFrom).format("MM/DD/YYYY");
  let formatDateTo = moment(dateTo).format("MM/DD/YYYY");

  let ShowformatDateFrom = moment(dateFrom).format("DD/MM/YYYY");
  let ShowformatDateTo = moment(dateTo).format("DD/MM/YYYY");

  const [baseTotal5, setbaseTotal5] = useState(0);
  const [baseTotal12, setbaseTotal12] = useState(0);
  const [baseTotal18, setbaseTotal18] = useState(0);
  const [baseTotal28, setbaseTotal28] = useState(0);
  const [cgstTotal5, setcgstTotal5] = useState(0);
  const [cgstTotal12, setcgstTotal12] = useState(0);
  const [cgstTotal18, setcgstTotal18] = useState(0);
  const [cgstTotal28, setcgstTotal28] = useState(0);
  const [sgstTotal5, setsgstTotal5] = useState(0);
  const [sgstTotal12, setsgstTotal12] = useState(0);
  const [sgstTotal18, setsgstTotal18] = useState(0);
  const [sgstTotal28, setsgstTotal28] = useState(0);
  const [gstTotal5, setgstTotal5] = useState(0);
  const [gstTotal12, setgstTotal12] = useState(0);
  const [gstTotal18, setgstTotal18] = useState(0);
  const [gstTotal28, setgstTotal28] = useState(0);

  const sendToGstPage = () => {
    history.push({
      pathname: "/gstdetails",
      baseTotal5: parseFloat(baseTotal5.toFixed(2)),
      baseTotal12: parseFloat(baseTotal12.toFixed(2)),
      baseTotal18: parseFloat(baseTotal18.toFixed(2)),
      baseTotal28: parseFloat(baseTotal28.toFixed(2)),
      cgstTotal5: parseFloat(cgstTotal5.toFixed(2)),
      cgstTotal12: parseFloat(cgstTotal12.toFixed(2)),
      cgstTotal18: parseFloat(cgstTotal18.toFixed(2)),
      cgstTotal28: parseFloat(cgstTotal28.toFixed(2)),
      sgstTotal5: parseFloat(sgstTotal5.toFixed(2)),
      sgstTotal12: parseFloat(sgstTotal12.toFixed(2)),
      sgstTotal18: parseFloat(sgstTotal18.toFixed(2)),
      sgstTotal28: parseFloat(sgstTotal28.toFixed(2)),
      gstTotal5: parseFloat(gstTotal5.toFixed(2)),
      gstTotal12: parseFloat(gstTotal12.toFixed(2)),
      gstTotal18: parseFloat(gstTotal18.toFixed(2)),
      gstTotal28: parseFloat(gstTotal28.toFixed(2)),
      ShowformatDateFrom: ShowformatDateFrom,
      ShowformatDateTo: ShowformatDateTo,
    });
  };

  const [gstDetails, setGstDetails] = React.useState([]);

  const getGstInfo = () => {
    setloading(25);
    if (adminType === "banda") {
      db.collection("gst")
        .where(
          "timestamp",
          ">=",
          firebase.firestore.Timestamp.fromDate(new Date(formatDateFrom))
        )
        .where(
          "timestamp",
          "<=",
          firebase.firestore.Timestamp.fromDate(new Date(formatDateTo))
        )
        .where("servicedCity", "==", "banda")
        .orderBy("timestamp", "asc")
        .get()
        .then((querySnapshot) => {
          const tmp = [];
          let baseTotal5 = 0;
          let baseTotal12 = 0;
          let baseTotal18 = 0;
          let baseTotal28 = 0;
          let cgstTotal5 = 0;
          let cgstTotal12 = 0;
          let cgstTotal18 = 0;
          let cgstTotal28 = 0;
          let sgstTotal5 = 0;
          let sgstTotal12 = 0;
          let sgstTotal18 = 0;
          let sgstTotal28 = 0;
          let gstTotal5 = 0;
          let gstTotal12 = 0;
          let gstTotal18 = 0;
          let gstTotal28 = 0;
          querySnapshot.forEach((documentSnapshot) => {
            //Totals Calculation Starts
            setloading(0);
            baseTotal5 = baseTotal5 + documentSnapshot.data().base5;
            baseTotal12 = baseTotal12 + documentSnapshot.data().base12;
            baseTotal18 = baseTotal18 + documentSnapshot.data().base18;
            baseTotal28 = baseTotal28 + documentSnapshot.data().base28;

            cgstTotal5 = cgstTotal5 + documentSnapshot.data().gst5 / 2;
            cgstTotal12 = cgstTotal12 + documentSnapshot.data().gst12 / 2;
            cgstTotal18 = cgstTotal18 + documentSnapshot.data().gst18 / 2;
            cgstTotal28 = cgstTotal28 + documentSnapshot.data().gst28 / 2;

            sgstTotal5 = sgstTotal5 + documentSnapshot.data().gst5 / 2;
            sgstTotal12 = sgstTotal12 + documentSnapshot.data().gst12 / 2;
            sgstTotal18 = sgstTotal18 + documentSnapshot.data().gst18 / 2;
            sgstTotal28 = sgstTotal28 + documentSnapshot.data().gst28 / 2;

            gstTotal5 = gstTotal5 + documentSnapshot.data().gst5;
            gstTotal12 = gstTotal12 + documentSnapshot.data().gst12;
            gstTotal18 = gstTotal18 + documentSnapshot.data().gst18;
            gstTotal28 = gstTotal28 + documentSnapshot.data().gst28;
            //Totals Calculation Ends

            tmp.push({
              BillNo: documentSnapshot.data().invoice,
              Date: documentSnapshot.data().date,
              Customer: documentSnapshot.data().customerName,
              AMOUNT: documentSnapshot.data().amount,
              BASE5: documentSnapshot.data().base5,
              BASE12: documentSnapshot.data().base12,
              BASE18: documentSnapshot.data().base18,
              BASE28: documentSnapshot.data().base28,
              CGST5: documentSnapshot.data().gst5 / 2,
              SGST5: documentSnapshot.data().gst5 / 2,
              CGST12: documentSnapshot.data().gst12 / 2,
              SGST12: documentSnapshot.data().gst12 / 2,
              CGST18: documentSnapshot.data().gst18 / 2,
              SGST18: documentSnapshot.data().gst18 / 2,
              CGST28: documentSnapshot.data().gst28 / 2,
              SGST28: documentSnapshot.data().gst28 / 2,
              TotalBase: documentSnapshot.data().totalBase,
              TotalGST: documentSnapshot.data().totalGST,
            });
            //Set Totals Starts

            setbaseTotal5(baseTotal5);
            setbaseTotal12(baseTotal12);
            setbaseTotal18(baseTotal18);
            setbaseTotal28(baseTotal28);

            setcgstTotal5(cgstTotal5);
            setcgstTotal12(cgstTotal12);
            setcgstTotal18(cgstTotal18);
            setcgstTotal28(cgstTotal28);

            setsgstTotal5(sgstTotal5);
            setsgstTotal12(sgstTotal12);
            setsgstTotal18(sgstTotal18);
            setsgstTotal28(sgstTotal28);

            setgstTotal5(gstTotal5);
            setgstTotal12(gstTotal12);
            setgstTotal18(gstTotal18);
            setgstTotal28(gstTotal28);
            //Set Totals Ends
          });

          setGstDetails(tmp);
        })
        .then(() => {
          setloading(100);
        });
    } else {
      db.collection("gst")
        .where(
          "timestamp",
          ">=",
          firebase.firestore.Timestamp.fromDate(new Date(formatDateFrom))
        )
        .where(
          "timestamp",
          "<=",
          firebase.firestore.Timestamp.fromDate(new Date(formatDateTo))
        )
        .orderBy("timestamp", "asc")
        .get()
        .then((querySnapshot) => {
          const tmp = [];
          let baseTotal5 = 0;
          let baseTotal12 = 0;
          let baseTotal18 = 0;
          let baseTotal28 = 0;
          let cgstTotal5 = 0;
          let cgstTotal12 = 0;
          let cgstTotal18 = 0;
          let cgstTotal28 = 0;
          let sgstTotal5 = 0;
          let sgstTotal12 = 0;
          let sgstTotal18 = 0;
          let sgstTotal28 = 0;
          let gstTotal5 = 0;
          let gstTotal12 = 0;
          let gstTotal18 = 0;
          let gstTotal28 = 0;
          querySnapshot.forEach((documentSnapshot) => {
            //Totals Calculation Starts
            setloading(0);
            baseTotal5 = baseTotal5 + documentSnapshot.data().base5;
            baseTotal12 = baseTotal12 + documentSnapshot.data().base12;
            baseTotal18 = baseTotal18 + documentSnapshot.data().base18;
            baseTotal28 = baseTotal28 + documentSnapshot.data().base28;

            cgstTotal5 = cgstTotal5 + documentSnapshot.data().gst5 / 2;
            cgstTotal12 = cgstTotal12 + documentSnapshot.data().gst12 / 2;
            cgstTotal18 = cgstTotal18 + documentSnapshot.data().gst18 / 2;
            cgstTotal28 = cgstTotal28 + documentSnapshot.data().gst28 / 2;

            sgstTotal5 = sgstTotal5 + documentSnapshot.data().gst5 / 2;
            sgstTotal12 = sgstTotal12 + documentSnapshot.data().gst12 / 2;
            sgstTotal18 = sgstTotal18 + documentSnapshot.data().gst18 / 2;
            sgstTotal28 = sgstTotal28 + documentSnapshot.data().gst28 / 2;

            gstTotal5 = gstTotal5 + documentSnapshot.data().gst5;
            gstTotal12 = gstTotal12 + documentSnapshot.data().gst12;
            gstTotal18 = gstTotal18 + documentSnapshot.data().gst18;
            gstTotal28 = gstTotal28 + documentSnapshot.data().gst28;
            //Totals Calculation Ends

            tmp.push({
              BillNo: documentSnapshot.data().invoice,
              Date: documentSnapshot.data().date,
              Customer: documentSnapshot.data().customerName,
              AMOUNT: documentSnapshot.data().amount,
              BASE5: documentSnapshot.data().base5,
              BASE12: documentSnapshot.data().base12,
              BASE18: documentSnapshot.data().base18,
              BASE28: documentSnapshot.data().base28,
              CGST5: documentSnapshot.data().gst5 / 2,
              SGST5: documentSnapshot.data().gst5 / 2,
              CGST12: documentSnapshot.data().gst12 / 2,
              SGST12: documentSnapshot.data().gst12 / 2,
              CGST18: documentSnapshot.data().gst18 / 2,
              SGST18: documentSnapshot.data().gst18 / 2,
              CGST28: documentSnapshot.data().gst28 / 2,
              SGST28: documentSnapshot.data().gst28 / 2,
              TotalBase: documentSnapshot.data().totalBase,
              TotalGST: documentSnapshot.data().totalGST,
            });
            //Set Totals Starts

            setbaseTotal5(baseTotal5);
            setbaseTotal12(baseTotal12);
            setbaseTotal18(baseTotal18);
            setbaseTotal28(baseTotal28);

            setcgstTotal5(cgstTotal5);
            setcgstTotal12(cgstTotal12);
            setcgstTotal18(cgstTotal18);
            setcgstTotal28(cgstTotal28);

            setsgstTotal5(sgstTotal5);
            setsgstTotal12(sgstTotal12);
            setsgstTotal18(sgstTotal18);
            setsgstTotal28(sgstTotal28);

            setgstTotal5(gstTotal5);
            setgstTotal12(gstTotal12);
            setgstTotal18(gstTotal18);
            setgstTotal28(gstTotal28);
            //Set Totals Ends
          });

          setGstDetails(tmp);
        })
        .then(() => {
          setloading(100);
        });
    }
  };

  // useEffect(() => {
  //     const subscriber = db
  //     .collection("gst")
  //     .where(
  //       "timestamp",
  //       ">=",
  //       firebase.firestore.Timestamp.fromDate(new Date(formatDateFrom))
  //     )
  //     .where(
  //       "timestamp",
  //       "<=",
  //       firebase.firestore.Timestamp.fromDate(new Date(formatDateTo))
  //     )
  //     .onSnapshot((querySnapshot) => {
  //       const tmp = [];

  //       let baseTotal5 = 0;
  //       let baseTotal12 = 0;
  //       let baseTotal18 = 0;
  //       let baseTotal28 = 0;
  //       let cgstTotal5 = 0;
  //       let cgstTotal12 = 0;
  //       let cgstTotal18 = 0;
  //       let cgstTotal28 = 0;
  //       let sgstTotal5 = 0;
  //       let sgstTotal12 = 0;
  //       let sgstTotal18 = 0;
  //       let sgstTotal28 = 0;
  //       let gstTotal5 = 0;
  //       let gstTotal12 = 0;
  //       let gstTotal18 = 0;
  //       let gstTotal28 = 0;

  //       querySnapshot.forEach((documentSnapshot) => {
  //         //Totals Calculation Starts
  //         baseTotal5 = baseTotal5 + documentSnapshot.data().base5;
  //         baseTotal12 = baseTotal12 + documentSnapshot.data().base12;
  //         baseTotal18 = baseTotal18 + documentSnapshot.data().base18;
  //         baseTotal28 = baseTotal28 + documentSnapshot.data().base28;

  //         cgstTotal5 = cgstTotal5 + documentSnapshot.data().gst5 / 2;
  //         cgstTotal12 = cgstTotal12 + documentSnapshot.data().gst12 / 2;
  //         cgstTotal18 = cgstTotal18 + documentSnapshot.data().gst18 / 2;
  //         cgstTotal28 = cgstTotal28 + documentSnapshot.data().gst28 / 2;

  //         sgstTotal5 = sgstTotal5 + documentSnapshot.data().gst5 / 2;
  //         sgstTotal12 = sgstTotal12 + documentSnapshot.data().gst12 / 2;
  //         sgstTotal18 = sgstTotal18 + documentSnapshot.data().gst18 / 2;
  //         sgstTotal28 = sgstTotal28 + documentSnapshot.data().gst28 / 2;

  //         gstTotal5 = gstTotal5 + documentSnapshot.data().gst5;
  //         gstTotal12 = gstTotal12 + documentSnapshot.data().gst12;
  //         gstTotal18 = gstTotal18 + documentSnapshot.data().gst18;
  //         gstTotal28 = gstTotal28 + documentSnapshot.data().gst28;
  //         //Totals Calculation Ends

  //         tmp.push({
  //           BillNo: documentSnapshot.data().invoice,
  //           Date: documentSnapshot.data().date,
  //           Customer: documentSnapshot.data().customerName,
  //           AMOUNT: documentSnapshot.data().amount,
  //           BASE5: documentSnapshot.data().base5,
  //           BASE12: documentSnapshot.data().base12,
  //           BASE18: documentSnapshot.data().base18,
  //           BASE28: documentSnapshot.data().base28,
  //           CGST5: documentSnapshot.data().gst5 / 2,
  //           SGST5: documentSnapshot.data().gst5 / 2,
  //           CGST12: documentSnapshot.data().gst12 / 2,
  //           SGST12: documentSnapshot.data().gst12 / 2,
  //           CGST18: documentSnapshot.data().gst18 / 2,
  //           SGST18: documentSnapshot.data().gst18 / 2,
  //           CGST28: documentSnapshot.data().gst28 / 2,
  //           SGST28: documentSnapshot.data().gst28 / 2,
  //           TotalBase: documentSnapshot.data().totalBase,
  //           TotalGST: documentSnapshot.data().totalGST,
  //         });
  //         //Set Totals Starts
  //         setbaseTotal5(baseTotal5);
  //         setbaseTotal12(baseTotal12);
  //         setbaseTotal18(baseTotal18);
  //         setbaseTotal28(baseTotal28);

  //         setcgstTotal5(cgstTotal5);
  //         setcgstTotal12(cgstTotal12);
  //         setcgstTotal18(cgstTotal18);
  //         setcgstTotal28(cgstTotal28);

  //         setsgstTotal5(sgstTotal12);
  //         setsgstTotal12(sgstTotal12);
  //         setsgstTotal18(sgstTotal12);
  //         setsgstTotal28(sgstTotal12);

  //         setgstTotal5(gstTotal5);
  //         setgstTotal12(gstTotal12);
  //         setgstTotal18(gstTotal18);
  //         setgstTotal28(gstTotal28);
  //         //Set Totals Ends
  //       });

  //       setGstDetails(tmp);
  //     });

  //   // Unsubscribe from events when no longer in use
  //   return () => subscriber();
  // }, []);

  //Profit Details Starts Here
  const [serviceList, setserviceList] = useState([]);
  const [packageList, setpackageList] = useState([]);
  const [servicePaidCount, setservicePaidCount] = useState();
  const [serviceFreeCount, setserviceFreeCount] = useState();
  const [profitservice, setprofitservice] = useState();
  const [profitpackage, setprofitpackage] = useState();
  const [packagePaidCount, setpackagePaidCount] = useState();

  const [dateFromProfit, setdateFromProfit] = useState();
  const [dateToProfit, setdateToProfit] = useState();
  const [profitDetails, setprofitDetails] = useState([]);

  let formatDateFromProfit = moment(dateFromProfit).format("MM/DD/YYYY");
  let formatDateToProfit = moment(dateToProfit)
    .add(1, "days")
    .format("MM/DD/YYYY");

  useEffect(() => {
    if (set7 === true) {
      db.collection("services")
        .get()
        .then((querySnapshot) => {
          const list = [];
          querySnapshot.forEach((item) => {
            list.push(item.data().name);
          });
          setserviceList(list);
        });
    }
  }, [set7]);

  useEffect(() => {
    if (set7 === true) {
      db.collection("packages")
        .get()
        .then((querySnapshot) => {
          const list = [];
          querySnapshot.forEach((item) => {
            list.push(item.data().name);
          });
          setpackageList(list);
        });
    }
  }, [set7]);

  const getProfitInfoService = () => {
    let profitService = 0;
    let paidCount = 0;
    let freeCount = 0;

    if (serviceDrop.value !== "") {
    
      if (adminType === "banda") {
       
        db.collection("sales")
          .where(
            "timestamp",
            ">=",
            firebase.firestore.Timestamp.fromDate(
              new Date(formatDateFromProfit)
            )
          )
          .where(
            "timestamp",
            "<=",
            firebase.firestore.Timestamp.fromDate(new Date(formatDateToProfit))
          )
          .where("servicedCity", "==", "banda")
          .get()
          .then((results) => {
         
            results.forEach((item) => {
          

             if (item.data().vehicleSize) {
              item.data().bill.forEach((e) => {
                if(e.name===serviceDrop.value){
                  console.log("item",e)
                  profitService =   profitService + parseInt(e[item.data().vehicleSize]);
               
                  if (e.large!==0) {
                    paidCount = paidCount + 1;
                  }
                  else{
                    freeCount = freeCount + 1;
                  }
                
                }
              });
            }
            
            });
            setservicePaidCount(paidCount);
            setserviceFreeCount(freeCount);
            setprofitservice(profitService);
          });
      } else {
        console.log("here")
        db.collection("sales")
          
          .where(
            "timestamp",
            ">=",
            firebase.firestore.Timestamp.fromDate(
              new Date(formatDateFromProfit)
            )
          )
          .where(
            "timestamp",
            "<=",
            firebase.firestore.Timestamp.fromDate(new Date(formatDateToProfit))
          )
          .get()
          .then((results) => {
            results.forEach((item) => {
             
              if (item.data().vehicleSize) {
                item.data().bill.forEach((e) => {
                  if(e.name===serviceDrop.value){
                    console.log("item",e)
                    profitService =   profitService + parseInt(e[item.data().vehicleSize]);
                 
                    if (e.large!==0) {
                      paidCount = paidCount + 1;
                    }
                    else{
                      freeCount = freeCount + 1;
                    }
                  
                  }
                });
              }
            });
            setservicePaidCount(paidCount);
            setserviceFreeCount(freeCount);
            setprofitservice(profitService);
          });
      }
    }
  };
  // ! To change for package !*!

  const getProfitInfoPackage = () => {
    let profitPackage = 0;
    let paidPackCount = 0;

    if (packageDrop.value !== "") {
      if (adminType === "banda") {
        db.collection("sales")

          .where(
            "timestamp",
            ">=",
            firebase.firestore.Timestamp.fromDate(
              new Date(formatDateFromProfit)
            )
          )
          .where(
            "timestamp",
            "<=",
            firebase.firestore.Timestamp.fromDate(new Date(formatDateToProfit))
          )
          .where("servicedCity", "==", "banda")
          .get()
          .then((doc) => {
            doc.forEach((det) => {
              if (det.data()) {
                det.data().bill.forEach((e) => {
                  if (e.category == "Create Customer") {
                    if(e.name===packageDrop.value){
                      paidPackCount = paidPackCount + 1;
                      profitPackage =
                       profitPackage + parseInt(e[det.data().vehicleSize])
                   
                    }
                  
                  }
                });
              }
            });

            setpackagePaidCount(paidPackCount);
            setprofitpackage(profitPackage);
          });
      } else {
        db.collection("sales")

          .where(
            "timestamp",
            ">=",
            firebase.firestore.Timestamp.fromDate(
              new Date(formatDateFromProfit)
            )
          )
          .where(
            "timestamp",
            "<=",
            firebase.firestore.Timestamp.fromDate(new Date(formatDateToProfit))
          )
          .get()
          .then((doc) => {
            doc.forEach((det) => {
console.log(det.data)
              if (det.data().vehicleSize) {
                det.data().bill.forEach((e) => {
                  if (e.category == "Create Customer") {
             
                    if(e.name===packageDrop.value){
                      paidPackCount = paidPackCount + 1;
                      profitPackage = e[det.data().vehicleSize] ?  
                        profitPackage + parseInt(e[det.data().vehicleSize]) : profitPackage
                    
                    }
                  
                  }
                });
              }
            });

            setpackagePaidCount(paidPackCount);
            setprofitpackage(profitPackage);
          });
      }
    }
  };

  const columns = [
    "ISCNO",
    "NAME",
    "MOBILE",
    "ADDRESS",
    "EMAIL",
    "COMPANY",
    "MODEL",
    "VEHICLENUMBER",
    "KILOMETERDRIVEN",
    "SIZE",
    "PACKAGE",
    "LASTPRICE",
    "SERVICES",
    "LASTVISIT",
    "PURCHASED",
    "EXPIRY",
  ];
  const columns1 = ["name", "time", "category", "large", "medium", "small"];

  const columnsPackage = ["Name", "Count", "Cash", "Free"];

  const columnsEmployee = ["employeeName", "employeePin"];

  const columnsInvoice = [
    "invoice",
    "customerName",
    "customerMobile",
    "vehicleNumber",
    "package",
    "amount",
    "mode",
    "date",
  ];

  const columnsProduct =
    adminType === "banda"
      ? [
          "partNumber",
          "name",
          {
            name: "countBanda",
            label: "Quantity",
            options: {
              filter: true,
              sort: true,
            },
          },

          "price",
          "MRP",
          "vendorPrice",
        ]
      : [
          "partNumber",
          "name",
          {
            name: "count",
            label: "Quantity",
            options: {
              filter: true,
              sort: true,
            },
          },

          "price",
          "MRP",
          "vendorPrice",
          "countBanda",
        ];

  const columnsGST = [
    "BillNo",
    "Date",
    "Customer",
    "AMOUNT",
    {
      name: "BASE5",
      label: "BASE(5%)",
      options: { display: baseTotal5 > 0 ? true : false },
    },
    {
      name: "CGST5",
      label: "CGST(2.5%)",
      options: { display: gstTotal5 > 0 ? true : false },
    },
    {
      name: "SGST5",
      label: "SGST(2.5%)",
      options: { display: gstTotal5 > 0 ? true : false },
    },
    {
      name: "BASE12",
      label: "BASE(12%)",
      options: { display: baseTotal12 > 0 ? true : false },
    },
    {
      name: "CGST12",
      label: "CGST(6%)",
      options: { display: gstTotal12 > 0 ? true : false },
    },
    {
      name: "SGST12",
      label: "SGST(6%)",
      options: { display: gstTotal12 > 0 ? true : false },
    },
    {
      name: "BASE18",
      label: "BASE(18%)",
      options: { display: baseTotal18 > 0 ? true : false },
    },
    {
      name: "CGST18",
      label: "CGST(9%)",
      options: { display: gstTotal18 > 0 ? true : false },
    },
    {
      name: "SGST18",
      label: "SGST(9%)",
      options: { display: gstTotal18 > 0 ? true : false },
    },
    {
      name: "BASE28",
      label: "BASE(28%)",
      options: { display: baseTotal28 > 0 ? true : false },
    },
    {
      name: "CGST28",
      label: "CGST(14%)",
      options: { display: gstTotal28 > 0 ? true : false },
    },
    {
      name: "SGST28",
      label: "SGST(14%)",
      options: { display: gstTotal28 > 0 ? true : false },
    },
    "TotalBase",
    "TotalGST",
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
  };

  const optionsTest = {
    filter: true,
    filterType: "multiselect",
    responsive,
    tableBodyHeight: "auto",
    tableBodyMaxHeight: "auto",
    print: true,
    sort: true,
  };
  const serviceDropFun =()=>{
 
  }

  return (
    <div>
      <div
        style={{
          marginTop: "2%",
          marginLeft: "5vw",
          width: "90%",
          height: "8vh",
          display: "flex",
        }}
        className="reportsColor"
      >
        <label
          className="reportsLabel"
          style={{ fontSize: window.innerWidth < 520 ? "5vw" : null }}
        >
          R E P O R T S{" "}
        </label>
      </div>
      <div
        style={{
          marginTop: "2%",
          flex: 1,
          marginLeft: "6.1%",
          width: "90%",
          height: window.innerWidth < 520 ? "40vh" : "80vh",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <div
            style={{
              position: "relative",
              flex: 1.4,
              display: "flex",
              marginRight: "3.5vw",
              marginTop: "1.5vw",
              marginBottom: "1.5vw",
            }}
            className="salesBG"
            onClick={b1}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
              }}
              className="overlayCards"
            >
              <label className="dsrLabel">
                Daily Sales
                <br />
                Reports
              </label>
            </div>
          </div>
          <div
            style={{
              position: "relative",
              flex: 0.6,
              display: "flex",
              marginRight: "3.5vw",
              marginTop: "1.5vw",
              marginBottom: "1.5vw",
            }}
            className="profitBG"
            onClick={b7}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
              }}
              className="overlayCards"
            >
              <label className="crLabel">
                Profit
                <br />
                Reports
              </label>
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            marginRight: "3.5vw",
          }}
        >
          <div
            style={{
              position: "relative",
              flex: 0.7,
              display: "flex",
              marginTop: "1.5vw",
              marginBottom: "1.5vw",
            }}
            className="customerBG"
            onClick={b2}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
              }}
              className="overlayCards"
            >
              <label className="crLabel">
                Customer
                <br />
                Reports
              </label>
            </div>
          </div>
          <div
            style={{
              position: "relative",
              flex: 0.3,
              display: "flex",
              backgroundColor: "blue",
              marginTop: "1.5vw",
              marginBottom: "1.5vw",
            }}
            className="serviceBG"
            onClick={b3}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
              }}
              className="overlayCards"
            >
              <label className="crLabel">
                GST
                <br />
                Details
              </label>
            </div>
          </div>
        </div>
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <div
            style={{
              position: "relative",
              flex: 1,
              display: "flex",
              backgroundColor: "red",
              marginTop: "1.5vw",
              marginBottom: "1.5vw",
            }}
            className="packageBG"
            onClick={b4}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
              }}
              className="overlayCards"
            >
              <label className="crLabel">
                Count
                <br />
                Report
              </label>
            </div>
          </div>
          <div
            style={{
              position: "relative",
              flex: 1,
              display: "flex",
              backgroundColor: "blue",
              marginTop: "1.5vw",
              marginBottom: "1.5vw",
            }}
            className="employeeBG"
            onClick={b5}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
              }}
              className="overlayCards"
            >
              <label className="crLabel">
                Employee
                <br />
                Reports
              </label>
            </div>
          </div>
          <div
            style={{
              position: "relative",
              flex: 1,
              display: "flex",
              backgroundColor: "blue",
              marginTop: "1.5vw",
              marginBottom: "1.5vw",
            }}
            className="inventoryBG"
            onClick={b6}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
              }}
              className="overlayCards"
            >
              <label className="crLabel">
                Inventory
                <br />
                Reports
              </label>
            </div>
          </div>
        </div>
      </div>
      {set1 === true ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            padding: "3.5vw",
            paddingTop: "7.5vw",
            height: "100vh",
          }}
          className="halkagrey"
        >
          <div
            style={{
              position: "absolute",
              top: "2%",
              left: "47%",
              cursor: "pointer",
            }}
          >
            <img
              alt="Close"
              style={{ width: "5vw", height: "5vw" }}
              src={require("../../assets/cancel.png")}
              onClick={closeAll}
              className="apnaClose"
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "2%",
              left: "35%",
              cursor: "pointer",
            }}
            onClick={b7}
          >
            <img
              alt="Next"
              style={{ width: "5vw" }}
              className="rotateForward apnaBack"
              src={require("../../assets/forward.svg")}
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "2%",
              right: "35%",
              cursor: "pointer",
            }}
            onClick={b2}
          >
            <img
              alt="Next"
              className="apnaForward"
              style={{ width: "5vw" }}
              src={require("../../assets/forward.svg")}
            />
          </div>
          <div
            style={{
              padding: "1vw",
              marginTop: window.innerWidth < 520 ? "6vw" : 0,
              borderRadius: "0.5vw",
              marginBottom: "1vw",
              display: "flex",
              flex: 1,
              flexDirection: "column",
              backgroundColor: "#EEEEEE",
            }}
          >
            <div
              style={{
                padding: "1vw",
                borderRadius: "0.5vw",
                marginBottom: "1vw",
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                backgroundColor: "#EEEEEE",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 2,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "1vw",
                    alignItems: "center",
                  }}
                >
                  Date From
                </label>
                <IcoInput
                  value={dateFromSales}
                  holder={"Date From"}
                  type={"date"}
                  icon={<FcCalendar />}
                  setter={setdateFromSales}
                  fontsize={"0.75vw"}
                  width={window.innerWidth < 520 ? 100 : null}
                />
                <label
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "1vw",
                    alignItems: "center",
                  }}
                >
                  Date To
                </label>
                <IcoInput
                  value={dateToSales}
                  holder={"Date To"}
                  type={"date"}
                  icon={<FcCalendar />}
                  setter={setdateToSales}
                  fontsize={"0.75vw"}
                  width={window.innerWidth < 520 ? 100 : null}
                />
              </div>
              <div
                style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              >
                {window.innerWidth < 520 ? null : (
                  <div style={{ marginLeft: "2vw" }} onClick={getSalesInfo}>
                    <IcoButton
                      icon={<FcSalesPerformance />}
                      start="View"
                      end="Sales"
                    />
                  </div>
                )}
              </div>
            </div>
            {window.innerWidth > 520 ? null : (
              <div onClick={getSalesInfo}>
                <IcoButton
                  icon={<FcSalesPerformance />}
                  start="View"
                  end="Sales"
                />
              </div>
            )}
          </div>
          <React.Fragment>
            <MUIDataTable
              title={"Sales"}
              data={salesDetails}
              columns={columnsInvoice}
              options={options}
            />
          </React.Fragment>
        </div>
      ) : null}
      {set2 === true ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            padding: "3.5vw",
            paddingTop: "7.5vw",
            height: "100vh",
          }}
          className="halkagrey"
        >
          <div
            style={{
              position: "absolute",
              top: "2%",
              left: "47%",
              cursor: "pointer",
            }}
          >
            <img
              alt="Close"
              style={{ width: "5vw", height: "5vw" }}
              src={require("../../assets/cancel.png")}
              onClick={closeAll}
              className="apnaClose"
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "2%",
              left: "35%",
              cursor: "pointer",
            }}
          >
            <img
              alt="Next"
              style={{ width: "5vw" }}
              className="rotateForward apnaBack"
              src={require("../../assets/forward.svg")}
              onClick={b1}
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "2%",
              right: "35%",
              cursor: "pointer",
            }}
            onClick={b3}
          >
            <img
              alt="Next"
              className="apnaForward"
              style={{ width: "5vw" }}
              src={require("../../assets/forward.svg")}
            />
          </div>
          <div style={{ marginTop: window.innerWidth < 520 ? "6vw" : 0 }}>
            <React.Fragment>
              <MUIDataTable
                title={"CustomersDetails"}
                data={customerDetails}
                columns={columns}
                options={options}
              />
            </React.Fragment>
          </div>
        </div>
      ) : null}
      {set3 === true ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            padding: "3.5vw",
            paddingTop: "7.5vw",
            height: "100vh",
          }}
          className="halkagrey"
        >
          <div
            style={{
              position: "absolute",
              top: "2%",
              left: "47%",
              cursor: "pointer",
            }}
          >
            <img
              alt="Close"
              style={{ width: "5vw", height: "5vw" }}
              src={require("../../assets/cancel.png")}
              onClick={closeAll}
              className="apnaClose"
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "2%",
              left: "35%",
              cursor: "pointer",
            }}
          >
            <img
              alt="Next"
              style={{ width: "5vw" }}
              className="rotateForward apnaBack"
              src={require("../../assets/forward.svg")}
              onClick={b2}
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "2%",
              right: "35%",
              cursor: "pointer",
            }}
            onClick={b4}
          >
            <img
              alt="Next"
              className="apnaForward"
              style={{ width: "5vw" }}
              src={require("../../assets/forward.svg")}
            />
          </div>
          <div
            style={{
              padding: "1vw",
              borderRadius: "0.5vw",
              marginBottom: "1vw",
              display: "flex",
              flex: 1,
              marginTop: window.innerWidth < 520 ? "6vw" : 0,
              flexDirection: "column",
              backgroundColor: "#EEEEEE",
            }}
          >
            <div
              style={{
                padding: "1vw",
                borderRadius: "0.5vw",
                marginBottom: "1vw",
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                backgroundColor: "#EEEEEE",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 2,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "1vw",
                    alignItems: "center",
                  }}
                >
                  Date From
                </label>
                <IcoInput
                  value={dateFrom}
                  holder={"Date From"}
                  type={"date"}
                  icon={<FcCalendar />}
                  setter={setdateFrom}
                  fontsize={"0.75vw"}
                  width={window.innerWidth < 520 ? 100 : null}
                />
                <label
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "1vw",
                    alignItems: "center",
                  }}
                >
                  Date To
                </label>
                <IcoInput
                  value={dateTo}
                  holder={"Date To"}
                  type={"date"}
                  icon={<FcCalendar />}
                  setter={setdateTo}
                  fontsize={"0.75vw"}
                  width={window.innerWidth < 520 ? 100 : null}
                />
              </div>
              <div
                style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              >
                {window.innerWidth < 520 ? null : (
                  <>
                    <div
                      style={{ marginLeft: "2vw", marginRight: "2vw" }}
                      onClick={getGstInfo}
                    >
                      <IcoButton
                        icon={<FcSalesPerformance />}
                        start="Cust. GST "
                        end="Report"
                      />
                    </div>
                    {gstDetails.length > 0 ? (
                      <div
                        style={{ marginLeft: "2vw" }}
                        onClick={sendToGstPage}
                      >
                        <IcoButton
                          icon={<FcSalesPerformance />}
                          start="Total GST"
                          end="Report"
                        />
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </div>
            {window.innerWidth > 520 ? null : (
              <>
                <div
                  style={{
                    marginLeft: "2vw",
                    marginRight: "2vw",
                    marginBottom: "2vw",
                  }}
                  onClick={getGstInfo}
                >
                  <IcoButton
                    icon={<FcSalesPerformance />}
                    start="View"
                    end="Receipts"
                  />
                </div>
                <div style={{ marginLeft: "2vw" }} onClick={sendToGstPage}>
                  <IcoButton
                    icon={<FcSalesPerformance />}
                    start="Detailed"
                    end="Report"
                  />
                </div>
              </>
            )}
          </div>
          {loading < 100 && loading > 0 ? (
            <div
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: "white",
                display: "flex",
                flex: 1,
                flexDirection: "column",
                width: "100vw",
                height: "100vh",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <label
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "1.25vw",
                  alignSelf: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                Loading And Calculating GST Details From{" "}
                <b>
                  {formatDateFrom} to {formatDateTo}
                </b>
              </label>
              <img
                alt=""
                style={{
                  alignSelf: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "2.5vw",
                  height: "2.5vw",
                  marginTop: "1vw",
                }}
                src={require("../../assets/tempLoad.gif")}
              />
            </div>
          ) : (
            <React.Fragment>
              <MUIDataTable
                title={"GST Details"}
                data={gstDetails}
                columns={columnsGST}
                options={optionsTest}
              />
            </React.Fragment>
          )}
        </div>
      ) : null}
      {set4 === true ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            padding: "3.5vw",
            paddingTop: "7.5vw",
            height: "100vh",
          }}
          className="halkagrey"
        >
          <div
            style={{
              position: "absolute",
              top: "2%",
              left: "47%",
              cursor: "pointer",
            }}
          >
            <img
              alt="Close"
              style={{ width: "5vw", height: "5vw" }}
              src={require("../../assets/cancel.png")}
              onClick={closeAll}
              className="apnaClose"
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "2%",
              left: "35%",
              cursor: "pointer",
            }}
          >
            <img
              alt="Next"
              style={{ width: "5vw" }}
              className="rotateForward apnaBack"
              src={require("../../assets/forward.svg")}
              onClick={b3}
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "2%",
              right: "35%",
              cursor: "pointer",
            }}
            onClick={b5}
          >
            <img
              alt="Next"
              className="apnaForward"
              style={{ width: "5vw" }}
              src={require("../../assets/forward.svg")}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100vw",
              justifyContent: "center",
            }}
          >
            <div
              className="Top-tap-package"
              style={{
                width: window.innerWidth < 520 ? "92%" : "100%",
                justifyContent: "center",
                marginLeft: "-7.5%",
                borderRadius: window.innerWidth < 520 ? "1vw" : null,
                marginTop: window.innerWidth < 520 ? "6vw" : 0,
                marginBottom: window.innerWidth < 520 ? "3vw" : 0,
              }}
            >
              <div
                className="drawerBackThing"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "white",
                }}
              >
                <div
                  style={{ cursor: "pointer" }}
                  className={
                    shows2 === true ? "ActiveCard-package" : "InactiveCardPack"
                  }
                  onClick={bt2}
                >
                  <p className="tile1Pack">
                    Product <b className="tile2Pack">Table</b>
                  </p>
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  className={
                    shows3 === true ? "ActiveCard-package" : "InactiveCardPack"
                  }
                  onClick={bt3}
                >
                  <p className="tile1Pack">
                    Labour <b className="tile2Pack">Table</b>
                  </p>
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  className={
                    shows4 === true ? "ActiveCard-package" : "InactiveCardPack"
                  }
                  onClick={bt4}
                >
                  <p className="tile1Pack">
                    Denting/Painting <b className="tile2Pack">Table</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* {shows1 === true ? (
            // <React.Fragment>
            //   //! Count table
            //   {DatePickerForCountTables("Service Table")}
            //   <MUIDataTable
            //     title={"Service Table"}
            //     data={packageDetails1}
            //     columns={columnsPackage}
            //     options={options}
            //   />
            // </React.Fragment>
          ) : null} */}
          {shows2 === true ? (
            <React.Fragment>
              {DatePickerForCountTables("Product Table")}
              <MUIDataTable
                title={"Product Table"}
                data={packageDetails2}
                columns={columnsPackage}
                options={options}
              />
            </React.Fragment>
          ) : null}
          {shows3 === true ? (
            <React.Fragment>
              {DatePickerForCountTables("Labour Table")}
              <MUIDataTable
                title={"Labour Table"}
                data={packageDetails3}
                columns={columnsPackage}
                options={options}
              />
            </React.Fragment>
          ) : null}
          {shows4 === true ? (
            <React.Fragment>
              {DatePickerForCountTables("Denting/Painting Table")}
              <MUIDataTable
                title={"Denting/Painting Table"}
                data={packageDetails4}
                columns={columnsPackage}
                options={options}
              />
            </React.Fragment>
          ) : null}
        </div>
      ) : null}
      {set5 === true ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            padding: "3.5vw",
            paddingTop: "7.5vw",
            height: "100vh",
          }}
          className="halkagrey"
        >
          <div
            style={{
              position: "absolute",
              top: "2%",
              left: "47%",
              cursor: "pointer",
            }}
          >
            <img
              alt="Close"
              style={{ width: "5vw", height: "5vw" }}
              src={require("../../assets/cancel.png")}
              onClick={closeAll}
              className="apnaClose"
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "2%",
              left: "35%",
              cursor: "pointer",
            }}
          >
            <img
              alt="Next"
              style={{ width: "5vw" }}
              className="rotateForward apnaBack"
              src={require("../../assets/forward.svg")}
              onClick={b4}
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "2%",
              right: "35%",
              cursor: "pointer",
            }}
            onClick={b6}
          >
            <img
              alt="Next"
              className="apnaForward"
              style={{ width: "5vw" }}
              src={require("../../assets/forward.svg")}
            />
          </div>
          <div style={{ marginTop: window.innerWidth < 520 ? "6vw" : 0 }}>
            <React.Fragment>
              <MUIDataTable
                title={"Employee Details"}
                data={employeeDetails}
                columns={columnsEmployee}
                options={options}
              />
            </React.Fragment>
          </div>
        </div>
      ) : null}

      {set6 === true ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            padding: "3.5vw",
            paddingTop: "7.5vw",
            height: "100vh",
          }}
          className="halkagrey"
        >
          <div
            style={{
              position: "absolute",
              top: "2%",
              left: "47%",
              cursor: "pointer",
            }}
          >
            <img
              alt="Close"
              style={{ width: "5vw", height: "5vw" }}
              src={require("../../assets/cancel.png")}
              onClick={closeAll}
              className="apnaClose"
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "2%",
              left: "35%",
              cursor: "pointer",
            }}
          >
            <img
              alt="Next"
              style={{ width: "5vw" }}
              className="rotateForward apnaBack"
              src={require("../../assets/forward.svg")}
              onClick={b5}
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "2%",
              right: "35%",
              cursor: "pointer",
            }}
            onClick={b7}
          >
            <img
              alt="Next"
              className="apnaForward"
              style={{ width: "5vw" }}
              src={require("../../assets/forward.svg")}
            />
          </div>
          <div style={{ marginTop: window.innerWidth < 520 ? "6vw" : 0 }}>
            <React.Fragment>
              <MUIDataTable
                title={"Inventory Details"}
                data={productDetails}
                columns={columnsProduct}
                options={options}
              />
            </React.Fragment>
          </div>
        </div>
      ) : null}

      {set7 === true ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            padding: "3.5vw",
            paddingTop: "7.5vw",
            height: "100vh",
          }}
          className="halkagrey"
        >
          <div
            style={{
              position: "absolute",
              top: "2%",
              left: "47%",
              cursor: "pointer",
            }}
          >
            <img
              alt="Close"
              style={{ width: "5vw", height: "5vw" }}
              src={require("../../assets/cancel.png")}
              onClick={() => {
                closeAll();
                setserviceDrop("");
                setpackageDrop("");
              }}
              className="apnaClose"
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "2%",
              left: "35%",
              cursor: "pointer",
            }}
          >
            <img
              alt="Next"
              style={{ width: "5vw" }}
              className="rotateForward apnaBack"
              src={require("../../assets/forward.svg")}
              onClick={b6}
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "2%",
              right: "35%",
              cursor: "pointer",
            }}
            onClick={b1}
          >
            <img
              alt="Next"
              className="apnaForward"
              style={{ width: "5vw" }}
              src={require("../../assets/forward.svg")}
            />
          </div>
          <div
            style={{
              padding: "1vw",
              borderRadius: "0.5vw",
              marginBottom: "1vw",
              display: "flex",
              flex: 1,
              marginTop: window.innerWidth < 520 ? "6vw" : 0,
              flexDirection: "column",
              backgroundColor: "#EEEEEE",
            }}
          >
            <div
              style={{
                padding: "1vw",
                borderRadius: "0.5vw",
                marginBottom: "1vw",
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                backgroundColor: "#EEEEEE",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 2,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "1vw",
                    alignItems: "center",
                  }}
                >
                  Select Service
                </label>
  
  <Drop
    list={serviceList}
    val={serviceDrop}
    setter={setserviceDrop}
    remover = {setpackageDrop}
    icon={<FcSalesPerformance />}
    holder={"Service"}
  />

                <label
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "1vw",
                    alignItems: "center",
                  }}
                >
                  Select Package
                </label>
                <Drop
                  list={packageList}
                  val={packageDrop}
                  setter={setpackageDrop}
                  remover = {setserviceDrop}
                  icon={<FcSalesPerformance />}
                  holder={"Package"}
                />
              </div>
              <div
                style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              >
                {window.innerWidth < 520 ? null : (
                  <>
                    {packageDrop === "" && dateToProfit ? (
                      <div
                        style={{ marginLeft: "2vw", marginRight: "2vw" }}
                        onClick={()=>{console.log("getting reports of servie")
                        getProfitInfoService()}}
                      >
                        <IcoButton
                          icon={<FcSalesPerformance />}
                          start=" Service"
                          end="Profits"
                        />
                      </div>
                    ) : null}
                    {serviceDrop === "" && dateToProfit ? (
                      <div
                        style={{ marginLeft: "2vw" }}
                        onClick={()=>{
                          console.log("hello")
                          getProfitInfoPackage()
                        }}
                      >
                        <IcoButton
                          icon={<FcSalesPerformance />}
                          start="Package"
                          end="Profits"
                        />
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </div>
            {window.innerWidth > 520 ? null : (
              <>
                {packageDrop === "" && dateToProfit ? (
                  <div
                    style={{
                      marginLeft: "2vw",
                      marginRight: "2vw",
                      marginBottom: "2vw",
                    }}
                    onClick={getProfitInfoService}
                  >
                    <IcoButton
                      icon={<FcSalesPerformance />}
                      start="Service"
                      end="Profits"
                    />
                  </div>
                ) : null}
                {serviceDrop === "" && dateToProfit ? (
                  <div
                    style={{ marginLeft: "2vw" }}
                    onClick={getProfitInfoPackage}
                  >
                    <IcoButton
                      icon={<FcSalesPerformance />}
                      start="Package"
                      end="Profits"
                    />
                  </div>
                ) : null}
              </>
            )}
            <div
              style={{
                padding: "1vw",
                borderRadius: "0.5vw",
                marginBottom: "1vw",
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                backgroundColor: "#EEEEEE",
              }}
            >
              {serviceDrop !== "" || packageDrop !== "" ? (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <label
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: "1vw",
                      alignItems: "center",
                      marginRight: "1vw",
                    }}
                  >
                    Date From
                  </label>
                  <IcoInput
                    value={dateFromProfit}
                    holder={"Date From"}
                    type={"date"}
                    icon={<FcCalendar />}
                    setter={setdateFromProfit}
                    fontsize={"0.75vw"}
                    width={window.innerWidth < 520 ? 100 : null}
                  />
                  <label
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: "1vw",
                      alignItems: "center",
                      marginLeft: "2vw",
                      marginRight: "1vw",
                    }}
                  >
                    Date To
                  </label>
                  <IcoInput
                    value={dateToProfit}
                    holder={"Date To"}
                    type={"date"}
                    icon={<FcCalendar />}
                    setter={setdateToProfit}
                    fontsize={"0.75vw"}
                    width={window.innerWidth < 520 ? 100 : null}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div style={{ marginTop: window.innerWidth < 520 ? "6vw" : 0 }}>
            {/* <div style={{display: 'flex',width: '75vw',height: '70%',backgroundColor: 'white'}}>
              Profits
            </div> */}
            <React.Fragment>
              {/* <MUIDataTable
                title={"Profit Details"}
                // data={productDetails}
                // columns={columnsProduct}
                options={options}
              /> */}
              {
                serviceDrop !== "" ?
              
              <div
                style={{
                  display: "block",
                  flex: 1,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "white",
                  borderRadius: "0.5vw",
                  padding: "2vw",
                }}
              >
                {
                serviceFreeCount > 0 ||
                servicePaidCount > 0 ? null : (
                  <label
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      fontSize: "1.75vw",
                      width: "100%",
                      textAlign: "center",
                      color: "black",
                      opacity: 0.2,
                    }}
                  >
                    Select Service or Package and Duration to Get Profit Details
                  </label>
                )}
                {servicePaidCount > 0 || serviceFreeCount > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "2.5vw",
                        fontWeight: "900",
                        color: "black",
                        opacity: 0.4,
                      }}
                    >
                      <span style={{ fontSize: "1.5vw", opacity: 1 }}>
                        Name
                      </span>
                      <br />
                      {serviceDrop.value}
                    </label>
                    <label
                      style={{
                        fontSize: "6.5vw",
                        fontWeight: "900",
                        color: "red",
                      }}
                    >
                      <span style={{ fontSize: "1.5vw", color: "black" }}>
                        Uses from Package
                      </span>
                      <br />
                      {serviceFreeCount}
                    </label>
                    <label
                      style={{
                        fontSize: "6.5vw",
                        fontWeight: "900",
                        color: "green",
                      }}
                    >
                      <span style={{ fontSize: "1.5vw", color: "black" }}>
                        Total Profit
                      </span>
                      <br />
                      {profitservice}
                    </label>
                    <label style={{ fontSize: "6.5vw", fontWeight: "900" }}>
                      <span style={{ fontSize: "1.5vw" }}>Total Sold</span>
                      <br />
                      {servicePaidCount}
                    </label>
                  </div>
                ) : null}
                
              </div> : <div
                style={{
                  display: "block",
                  flex: 1,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "white",
                  borderRadius: "0.5vw",
                  padding: "2vw",
                }}
              >
             
              
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        style={{
                          fontSize: "2.5vw",
                          fontWeight: "900",
                          color: "black",
                          opacity: 0.4,
                        }}
                      >
                        <span style={{ fontSize: "1.5vw", opacity: 1 }}>
                          Name
                        </span>
                        <br />
                        {packageDrop.value}
                      </label>
                      <label
                        style={{
                          fontSize: "6.5vw",
                          fontWeight: "900",
                          color: "green",
                        }}
                      >
                        <span style={{ fontSize: "1.5vw", color: "black" }}>
                          Total Profit
                        </span>
                        <br />
                        {profitpackage}
                      </label>
                      <label style={{ fontSize: "6.5vw", fontWeight: "900" }}>
                        <span style={{ fontSize: "1.5vw" }}>Total Sold</span>
                        <br />
                        {packagePaidCount}
                      </label>
                    </div>
                  </div>
             
              </div>}
            </React.Fragment>
          </div>
        </div>
      ) : null}
    </div>
  );
}
