import React, { useEffect, useState, createRef } from "react";
import ReactToPdf from "react-to-pdf";
import IcoButton from "../IcoButton.jsx";
import { FcDownload } from "react-icons/fc";
import { useHistory, useLocation } from "react-router-dom";

export default function GstReports() {
  const history = useHistory();
  const location = useLocation();

  const [baseTotal5, setbaseTotal5] = useState(location.baseTotal5);
  const [baseTotal12, setbaseTotal12] = useState(location.baseTotal12);
  const [baseTotal18, setbaseTotal18] = useState(location.baseTotal18);
  const [baseTotal28, setbaseTotal28] = useState(location.baseTotal28);
  const [cgstTotal5, setcgstTotal5] = useState(location.cgstTotal5);
  const [cgstTotal12, setcgstTotal12] = useState(location.cgstTotal12);
  const [cgstTotal18, setcgstTotal18] = useState(location.cgstTotal18);
  const [cgstTotal28, setcgstTotal28] = useState(location.cgstTotal28);
  const [sgstTotal5, setsgstTotal5] = useState(location.sgstTotal5);
  const [sgstTotal12, setsgstTotal12] = useState(location.sgstTotal12);
  const [sgstTotal18, setsgstTotal18] = useState(location.sgstTotal18);
  const [sgstTotal28, setsgstTotal28] = useState(location.sgstTotal28);
  const [gstTotal5, setgstTotal5] = useState(location.gstTotal5);
  const [gstTotal12, setgstTotal12] = useState(location.gstTotal12);
  const [gstTotal18, setgstTotal18] = useState(location.gstTotal18);
  const [gstTotal28, setgstTotal28] = useState(location.gstTotal28);
  const [ShowformatDateFrom, setShowformatDateFrom] = useState(
    location.ShowformatDateFrom
  );
  const [ShowformatDateTo, setShowformatDateTo] = useState(
    location.ShowformatDateTo
  );


  const ref = createRef();
  const dateFrom = "12-10-2010";
  const dateTo = "25-12-2011";

  const zero = () => {
    return (
      <div
        style={{
          display: "flex",
          height: "5vw",
          padding: "2vw",
          marginLeft: "2vw",
          marginRight: "2vw",
          marginTop: "1vw",
          backgroundColor: "#EEEEEE",
          borderRadius: "0.75vw",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          fontFamily: "Montserrat",
          fontWeight: "700",
          textAlign: "left",
        }}
      >
        {/* <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          1
        </label> */}
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          0 %
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          0
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          0
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          0
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          0
        </label>
      </div>
    );
  };

  const five = () => {
    return (
      <div
        style={{
          display: "flex",
          height: "5vw",
          padding: "2vw",
          marginLeft: "2vw",
          marginRight: "2vw",
          marginTop: "1vw",
          backgroundColor: "#EEEEEE",
          borderRadius: "0.75vw",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          fontFamily: "Montserrat",
          fontWeight: "700",
        }}
      >
        {/* <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          2
        </label> */}
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          5 %
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          {baseTotal5}
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          {cgstTotal5}
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          {sgstTotal5}
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          {gstTotal5}
        </label>
      </div>
    );
  };

  const twelve = () => {
    return (
      <div
        style={{
          display: "flex",
          height: "5vw",
          padding: "2vw",
          marginLeft: "2vw",
          marginRight: "2vw",
          marginTop: "1vw",
          backgroundColor: "#EEEEEE",
          borderRadius: "0.75vw",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          fontFamily: "Montserrat",
          fontWeight: "700",
        }}
      >
        {/* <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          3
        </label> */}
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          12 %
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          {baseTotal12}
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          {cgstTotal12}
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          {sgstTotal12}
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          {gstTotal12}
        </label>
      </div>
    );
  };

  const eighteen = () => {
    return (
      <div
        style={{
          display: "flex",
          height: "5vw",
          padding: "2vw",
          marginLeft: "2vw",
          marginRight: "2vw",
          marginTop: "1vw",
          backgroundColor: "#EEEEEE",
          borderRadius: "0.75vw",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          fontFamily: "Montserrat",
          fontWeight: "700",
        }}
      >
        {/* <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          4
        </label> */}
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          18 %
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          {baseTotal18}
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          {cgstTotal18}
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          {sgstTotal18}
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          {gstTotal18}
        </label>
      </div>
    );
  };

  const twentyeight = () => {
    return (
      <div
        style={{
          display: "flex",
          height: "5vw",
          padding: "2vw",
          marginLeft: "2vw",
          marginRight: "2vw",
          marginTop: "1vw",
          backgroundColor: "#EEEEEE",
          borderRadius: "0.75vw",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          fontFamily: "Montserrat",
          fontWeight: "700",
        }}
      >
        {/* <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          5
        </label> */}
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          28 %
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          {baseTotal28}
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          {cgstTotal28}
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          {sgstTotal28}
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          {gstTotal28}
        </label>
      </div>
    );
  };

  const grandtotal = () => {
    return (
      <div
        style={{
          display: "flex",
          height: "5vw",
          padding: "2vw",
          marginLeft: "2vw",
          marginRight: "2vw",
          marginTop: "1vw",
          backgroundColor: "#EEEEEE",
          borderRadius: "0.75vw",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          fontFamily: "Montserrat",
          fontWeight: "700",
        }}
      >
        {/* <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          6
        </label> */}
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          <b>GRAND TOTAL</b>
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          {baseTotal5 + baseTotal12 + baseTotal18 + baseTotal28}
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          {cgstTotal5 + cgstTotal12 + cgstTotal18 + cgstTotal28}
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          {sgstTotal5 + sgstTotal12 + sgstTotal18 + sgstTotal28}
        </label>
        <label
          style={{
            color: "black",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "1vw",
          }}
        >
          {gstTotal5 + gstTotal12 + gstTotal18 + gstTotal28}
        </label>
      </div>
    );
  };

  return (
    <div>
      <div>
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              backgroundColor: "#EEEEEE",
              marginLeft: "2vw",
              marginRight: "2vw",
              borderBottomLeftRadius: "1vw",
              borderBottomRightRadius: "1vw",
            }}
          >
            <label
              style={{
                flex: 1,
                marginTop: "2vw",
                paddingLeft: "2.35vw",
                fontFamily: "Montserrat",
                fontWeight: "400",
                color: "grey",
                fontSize: "1.5vw",
              }}
            >
              Innovative Service Center GST REPORT
            </label>
            {/* <ReactToPdf targetRef={ref} filename={`${ShowformatDateFrom} to ${ShowformatDateTo}`}>
              {({ toPdf }) => (
                <div
                  style={{
                    marginTop: "1vw",
                    alignItems: "flex-end",
                    marginRight: "2.35vw",
                  }}
                  onClick={toPdf}
                >
                  <IcoButton icon={<FcDownload />} start="Download" end="PDF" />
                </div>
              )}
            </ReactToPdf> */}
                            <div
                  style={{
                    marginTop: "1vw",
                    alignItems: "flex-end",
                    marginRight: "2.35vw",
                  }}
                  onClick={()=>window.print()}
                >
                  <IcoButton icon={<FcDownload />} start="Download" end="PDF" />
                </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              height: "4vw",
              padding: "0.35vw",
              paddingRight: "3.75vw",
              borderRadius: "0.75vw",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              fontFamily: "Montserrat",
              fontWeight: "700",
            }}
          >
            <label style={{ marginRight: "5vw", textAlign: "left" }}>
              <span style={{ color: "grey" }}>Date From : </span>
              {ShowformatDateFrom}
            </label>
            <label style={{ textAlign: "right" }}>
              <span style={{ color: "grey" }}>Date To : </span>
              {ShowformatDateTo}
            </label>
          </div>
          <div
            style={{
              display: "flex",
              height: "5vw",
              padding: "2vw",
              marginLeft: "2vw",
              marginRight: "2vw",
              backgroundColor: "#EEEEEE",
              borderRadius: "0.75vw",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              fontFamily: "Montserrat",
              fontWeight: "700",
            }}
          >
            {/* <label
              style={{
                color: "grey",
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "1vw",
              }}
            >
              S No
            </label> */}
            <label
              style={{
                color: "grey",
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "1vw",
              }}
            >
              GST Tax %
            </label>
            <label
              style={{
                color: "grey",
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "1vw",
              }}
            >
              Base Amount (₹)
            </label>
            <label
              style={{
                color: "grey",
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "1vw",
              }}
            >
              CGST Amount (₹)
            </label>
            <label
              style={{
                color: "grey",
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "1vw",
              }}
            >
              SGST Amount (₹)
            </label>
            <label
              style={{
                color: "grey",
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "1vw",
              }}
            >
              Total GST Amount (₹)
            </label>
          </div>
            {baseTotal5>0 ? (<>{five()}</>) : null}
            {baseTotal12>0 ? (<>{twelve()}</>) : null}
            {baseTotal18>0 ? (<>{eighteen()}</>) : null}
            {baseTotal28>0 ? (<>{twentyeight()}</>) : null}
          {grandtotal()}
        </div>
      </div>
    </div>
  );
}
