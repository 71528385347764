import React, { useState, useEffect } from "react";
import "./Inventory.css";
import IcoInput from "../IcoInput";
import IcoButton from "../IcoButton";
import Drop from "../Drop";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import firebase from "../../firebase/firebase";
import {
  FcPackage,
  FcDataRecovery,
  FcDataBackup,
  FcDataConfiguration,
  FcNext,
  FcAutomatic,
  FcBusinessman,
} from "react-icons/fc";
import { CgRename } from "react-icons/cg";
import { FcDisplay, FcFactory, FcTrademark } from "react-icons/fc";
import { RiNumbersFill } from "react-icons/ri";
import { BiRupee } from "react-icons/bi";
import { AiOutlineNumber, AiFillMobile } from "react-icons/ai";
import db from "../../firebase/database";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Phone";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PersonPinIcon from "@material-ui/icons/PersonPin";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: "100%",
  },
});
export default function AddProduct() {
  const [adminType, setAdminType] = useState();
  useEffect(() => {
    setAdminType(window.sessionStorage.getItem("adminType"));
  }, []);
  console.log("admin type Inverntory", adminType);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [itemNumber, setitemNumber] = useState("");
  const [itemName, setitemName] = useState("");
  const [itemAmount, setitemAmount] = useState(0);
  const [itemPrice, setitemPrice] = useState();
  const [itemMrp, setitemMrp] = useState();
  const [vendor, setVendor] = useState("");
  const [vendorGSTN, setVendorGSTN] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [vendorListName, setVendorListName] = useState([]);
  const [vendorListNameInit, setVendorListNameInit] = useState([]);
  const [partList, setPartList] = useState([]);
  const [productID, setProductID] = useState();
  const [productDet, setProductDet] = useState();

  const update = () => {
    const data = {
      name: vendor.value,
      count: itemAmount,
      GSTN: vendorGSTN,
    };

    const increment = firebase.firestore.FieldValue.increment(
      parseInt(itemAmount)
    );
    if (adminType === "banda") {
      console.log("banda wala");
      db.collection("products")
        .doc(productID.value)
        .update({ countBanda: increment })
        .then(() => {
          db.collection("products")
            .doc(productID.value)
            .collection("vendor")
            .doc(vendor.value)
            .update({ countBanda: increment })
            .then(() => {
              alert("Added");
            });
        });
    } else {
      if (vendorListNameInit.includes(vendor.value)) {
        db.collection("products")
          .doc(productID.value)
          .update({ count: increment })
          .then(() => {
            db.collection("products")
              .doc(productID.value)
              .collection("vendor")
              .doc(vendor.value)
              .update({ count: increment })
              .then(() => {
                alert("Added");
              });
          });
      } else {
        try {
          if (vendor.value) {
            db.collection("products")
              .doc(productID.value)
              .update({ count: increment })
              .then(() => {
                try {
                  db.collection("products")
                    .doc(productID.value)
                    .collection("vendor")
                    .doc(vendor.value)
                    .set(data);
                } catch (error) {
                  alert("fill Details");
                }
              });
          }
        } catch (error) {
          console.log("vendor ID", vendor.value);
          console.log("Product", productID.value);
        }
      }
    }
  };
  useEffect(() => {
    db.collection("products")
      .get()
      .then((item) => {
        const list = [];
        item.forEach((item) => {
          list.push(item.data().partNumber);
        });
        setPartList(list);
      });

    return () => {};
  }, []);

  useEffect(() => {
    try {
      if (vendorListName.includes(vendor.value)) {
        try {
          db.collection("products")
            .doc(productID.value)
            .collection("vendor")
            .doc(vendor.value)
            .get()
            .then((item) => {
              if (item.data()) {
                setVendorGSTN(item.data().GSTN);
              } else {
                setVendorGSTN("");
              }
            });
        } catch (error) {
          setVendorGSTN("");
        }
      }
    } catch (error) {}

    return () => {};
  }, [vendor]);

  useEffect(() => {
    try {
      db.collection("products")
        .doc(productID.value)
        .get()
        .then((item) => {
          setProductDet(item.data());
        });
      db.collection("products")
        .doc(productID.value)
        .collection("vendor")
        .get()
        .then((item) => {
          const list = [];
          const listNames = [];
          item.forEach((item) => {
            list.push(item.data());
            listNames.push(item.data().name);
          });
          setVendorList(list);
          setVendorListName(listNames);
          setVendorListNameInit(listNames);
        });
    } catch (error) {}
  }, [productID]);
  return (
    <div>
      <Grid
        style={{
          padding: "1rem",
        }}
        container
        className={classes.root}
        spacing={2}
      >
        <Grid item xs={12} sm={12} md={12}>
          <text
            style={{
              fontSize: "2rem",
              color: "#fe5236",
            }}
          >
            Product Number
          </text>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Drop
            list={partList}
            val={productID}
            setterList={setPartList}
            setter={setProductID}
            icon={<FcDisplay />}
            holder="Enter Product ID"
          />
        </Grid>
        {productDet ? (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <hr />
              <text
                style={{
                  fontSize: "2rem",
                  color: "#fe5236",
                }}
              >
                Product Details
              </text>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                  flexDirection: "row",
                }}
              >
                <text
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  Product Name
                </text>
                <text
                  style={{
                    fontSize: "1rem",
                    color: "#fe5236",
                  }}
                >
                  {productDet.name}
                </text>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                  flexDirection: "row",
                }}
              >
                <text
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  MRP
                </text>
                <text
                  style={{
                    fontSize: "1rem",
                    color: "#fe5236",
                  }}
                >
                  {productDet.MRP}
                </text>
              </div>
            </Grid>
            <Grid
              style={{
                borderWidth: "2px",
                borderColor: "#fe5236",
                borderRadius: "5px",
              }}
              item
              xs={12}
              sm={6}
              md={4}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                  flexDirection: "row",
                  borderWidth: "2px",
                  borderColor: "#fe5236",
                  borderRadius: "5px",
                }}
              >
                <text
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  Price
                </text>
                <text
                  style={{
                    fontSize: "1rem",
                    color: "#fe5236",
                  }}
                >
                  {productDet.price}
                </text>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                  flexDirection: "row",
                  border: "2px",
                  borderColor: "#fe5236",
                  borderRadius: "5px",
                }}
              >
                <text
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  Inventory
                </text>
                <text
                  style={{
                    fontSize: "1rem",
                    color: "#fe5236",
                  }}
                >
                  {adminType === "banda"
                    ? productDet.countBanda || 0
                    : productDet.count}
                </text>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <hr />
              <text
                style={{
                  fontSize: "2rem",
                  color: "#fe5236",
                }}
              >
                Vendor and Count
              </text>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Drop
                list={vendorListName}
                val={vendor}
                setterList={setVendorListName}
                setter={setVendor}
                icon={<FcBusinessman />}
                holder="Vendor"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <IcoInput
                value={vendorGSTN}
                holder={vendorGSTN ? vendorGSTN : "GSTN"}
                setter={setVendorGSTN}
                icon={<AiOutlineNumber />}
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <IcoInput
                value={itemAmount}
                holder="Quantity"
                setter={setitemAmount}
                icon={<RiNumbersFill />}
                type="number"
              />
            </Grid>
          </>
        ) : null}
      </Grid>
      <div
        style={{ position: "absolute", right: "5vw", bottom: "5vh" }}
        onClick={() => update()}
      >
        <IcoButton end="Product" start="Add" icon={<FcDataBackup />} />
      </div>
    </div>
  );
}
