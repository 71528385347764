import React, { useState, useEffect } from "react";
import "./Inventory.css";
import IcoInput from "../IcoInput";
import IcoButton from "../IcoButton";
import Drop from "../Drop";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { FcPackage, FcDataBackup, FcBusinessman } from "react-icons/fc";
import { CgRename } from "react-icons/cg";
import { FcDisplay, FcFactory, FcTrademark } from "react-icons/fc";
import { RiNumbersFill } from "react-icons/ri";
import { BiRupee } from "react-icons/bi";
import { AiOutlineNumber, AiFillMobile } from "react-icons/ai";
import db from "../../firebase/database";
import firebase from "../../firebase/firebase";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: "100%",
  },
});
export default function NewProduct() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [itemNumber, setitemNumber] = useState("");
  const [itemName, setitemName] = useState("");
  const [itemAmount, setitemAmount] = useState();
  const [itemPrice, setitemPrice] = useState();
  const [itemMrp, setitemMrp] = useState();
  const [vendorPrice, setVendorPrice] = useState();
  const [discount, setDiscount] = useState();
  const [vendor, setVendor] = useState("");
  const [vendorGSTN, setVendorGSTN] = useState("");
  const [HSN, setHSN] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [address, setAddress] = useState("");
  const [mobileNumber, setMobileNumber] = useState();
  const [firmName, setFirmName] = useState("");
  const [gstSlab, setGstSlab] = useState("");

  useEffect(() => {
    let ven = {};

    db.collection("extras")
      .doc("vendors")
      .get()
      .then((item) => {
        item.data().vendors.forEach((det) => {
          if (det.name == vendor.value) {
            setAddress(det.Address);
            setMobileNumber(det.Mobile);
            setFirmName(det.FirmName);
            setVendorGSTN(det.GSTN);
          }
        });
      });

    return () => {};
  }, [vendor]);

  useEffect(() => {
    let temp = [];
    db.collection("extras")
      .doc("vendors")
      .get()
      .then((item) => {
        item.data().vendors.forEach((det) => {
          temp.push(det.name);
        });
      });

    setVendorList(temp);
    return () => {};
  }, []);
  const addProduct = () => {
    const data = {
      MRP: itemMrp,
      vendorPrice: vendorPrice,
      count: parseInt(itemAmount),
      name: itemName,
      partNumber: itemNumber,
      price: itemPrice,
      HSNnumber: HSN,
      GST: gstSlab.value,
    };
    try {
      db.collection("products")
        .doc(itemNumber)
        .set(data)
        .then(() => {
          db.collection("extras")
            .doc("products")
            .update({
              products: firebase.firestore.FieldValue.arrayUnion(data),
            });
          db.collection("extras")
            .doc("vendors")
            .get()
            .then((item) => {
              if (
                !item.data().vendors.some(
                  (e) =>
                    e ==
                    {
                      name: vendor.value,

                      GSTN: vendorGSTN,
                      Address: address,
                      Mobile: mobileNumber,
                      FirmName: firmName,
                    }
                )
              ) {
                db.collection("extras")
                  .doc("vendors")
                  .update({
                    vendors: firebase.firestore.FieldValue.arrayUnion({
                      name: vendor.value,

                      GSTN: vendorGSTN,
                      Address: address,
                      Mobile: mobileNumber,
                      FirmName: firmName,
                    }),
                  });
              }
            });

          db.collection("products")
            .doc(itemNumber)
            .collection("vendor")
            .doc(vendor.value)
            .set({
              name: vendor.value,
              count: itemAmount,
              GSTN: vendorGSTN,
              Address: address,
              Mobile: mobileNumber,
              FirmName: firmName,
            })
            .then(() => {
              alert("Product Added");
              setitemMrp("");
              setitemName("");
              setitemNumber("");
              setitemPrice("");
              setitemAmount();
              setVendorPrice("");
            });
        });
    } catch (error) {
      alert("Fill All Details first.");
    }
  };

  return (
    <div>
      <Grid
        style={{
          padding: "1rem",
        }}
        container
        className={classes.root}
        spacing={2}
      >
        <Grid item xs={12} sm={6} md={4}>
          <IcoInput
            value={itemName}
            holder="Product Name"
            setter={setitemName}
            icon={<CgRename />}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <IcoInput
            value={itemNumber}
            holder="Product Number"
            setter={setitemNumber}
            icon={<FcDisplay />}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <IcoInput
            value={itemAmount}
            holder="Quantity"
            setter={setitemAmount}
            icon={<RiNumbersFill />}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Drop
            list={["5%", "12%", "18%", "28%"]}
            val={gstSlab}
            setter={setGstSlab}
            icon={<FcPackage />}
            holder={"GST  Slab"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <IcoInput
            value={HSN}
            holder="HSN number"
            setter={setHSN}
            icon={<FcDisplay />}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <IcoInput
            value={itemPrice}
            holder="Price"
            setter={setitemPrice}
            icon={<BiRupee />}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <IcoInput
            value={itemMrp}
            holder="MRP"
            setter={setitemMrp}
            icon={<BiRupee />}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <IcoInput
            value={vendorPrice}
            holder="Vendor Price"
            setter={setVendorPrice}
            icon={<BiRupee />}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Drop
            list={vendorList}
            val={vendor}
            setterList={setVendorList}
            setter={setVendor}
            icon={<FcBusinessman />}
            holder={vendor !== "" ? vendor : "Enter Vendor"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <IcoInput
            value={vendorGSTN}
            holder="GSTN"
            setter={setVendorGSTN}
            icon={<AiOutlineNumber />}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <IcoInput
            value={mobileNumber}
            holder="Mobile Number"
            setter={setMobileNumber}
            icon={<AiFillMobile />}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <IcoInput
            value={address}
            holder="Address"
            setter={setAddress}
            icon={<FcFactory />}
            type="text"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <IcoInput
            value={firmName}
            holder="Firm Name"
            setter={setFirmName}
            icon={<FcTrademark />}
            type="text"
          />
        </Grid>
      </Grid>
      <div
        style={{ position: "absolute", right: "5vw", bottom: "5vh" }}
        onClick={() => addProduct()}
      >
        <IcoButton end="Product" start="Add" icon={<FcDataBackup />} />
      </div>
    </div>
  );
}
