import React, { useEffect, useState } from "react";
import IcoInput from "../Components/IcoInput";
import st from "./DashBoard.module.css";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";
import Grid from "@material-ui/core/Grid";
import db from "../firebase/database";
import Drop from "../Components/Drop";
import IcoButton from "../Components/IcoButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import "bootstrap/dist/css/bootstrap.min.css";
import { FcBusinessman } from "react-icons/fc";
import {
  FcAutomotive,
  FcConferenceCall,
  FcBriefcase,
  FcSettings,
  FcMindMap,
  FcCheckmark,
} from "react-icons/fc";
function CreateEmp() {
  const [newEmpName, setNewEmpName] = useState("");
  const [pin, setPin] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeListPin, setEmployeeListPin] = useState([]);
  const [selectEmployee, setSelectEmployee] = useState("");
  useEffect(() => {
    db.collection("employee")
      .where("employeePin", "!=", "deactivated")
      .get()
      .then(function (querySnapshot) {
        let temp = [];
        let tempPin = [];
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots

          temp.push(doc.data().employeeName);
          tempPin.push(doc.data().employeePin);
        });
        setEmployeeList(temp);
        setEmployeeListPin(tempPin);
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }, []);
  const deactivateEmployee = () => {
    db.collection("employee")
      .where("employeeName", "==", selectEmployee.value)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          db.collection("employee")
            .doc(doc.id)
            .update({
              employeePin: "deactivated",
            })
            .then(() => {
              alert(selectEmployee.value + "" + "is Deactivated");
            });
        });
      });
  };
  console.log(employeeList);
  const [empBanda, setEmpBanda] = useState(false);
  const handleChangeNew = (event) => {
    setEmpBanda(!empBanda);
  };
  const uploadEmployee = (evt) => {
    if (!employeeListPin.includes(pin)) {
      try {
        db.collection("employee")
          .doc(pin)
          .set({
            employeeName: newEmpName,
            employeePin: pin,
            city: empBanda === true ? "banda" : "sagar",
          })
          .then(function () {
            alert("Employee Added");
          });
      } catch (error) {}
    } else {
      alert(
        "Employee with this pin already exist, Please chnage pin to continue."
      );
    }
  };
  return (
    <div>
      <Grid container xs={12} className={st.AddEmp}>
        <div className={st.AddHeading}>Add Employee</div>

        <Grid container xs={12} className={st.insideDiv}>
          <Grid item xs={12} lg={6} className={st.inputIn}>
            <IcoInput
              value={newEmpName}
              holder="Employee Name"
              setter={setNewEmpName}
              icon={<FcBusinessman />}
              type="text"
            />
          </Grid>
          <Grid item xs={12} lg={6} className={st.inputIn}>
            <IcoInput
              value={pin}
              holder="Employee Pin"
              setter={setPin}
              icon={<FcSettings />}
              type="text"
            />
          </Grid>
          <FormControlLabel
            control={
              <Checkbox
                icon={<RiCheckboxBlankCircleLine style={{ color: "red" }} />}
                checkedIcon={<FcCheckmark />}
                checked={empBanda}
                onChange={handleChangeNew}
                name="checkedH"
              />
            }
            label="Banda Employee"
          />
        </Grid>
        <div className={st.inputDiv}>
          <div onClick={() => uploadEmployee()}>
            <IcoButton icon={<FcBusinessman />} start="Create" end="Employee" />
          </div>
        </div>
        <hr />
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <Grid item xs={12} lg={6} className={st.inputIn}>
            <Drop
              list={employeeList}
              val={selectEmployee}
              setter={setSelectEmployee}
              icon={<FcSettings />}
              holder={"Select Employee"}
            />
          </Grid>
          <div className={st.inputDiv}>
            <div onClick={deactivateEmployee}>
              <IcoButton
                icon={<FcBusinessman />}
                start="Deactivate"
                end="Employee"
              />
            </div>
          </div>
        </div>
      </Grid>
    </div>
  );
}

export default CreateEmp;
