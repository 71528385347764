import React, { useState, useEffect } from "react";
import "./CardForPackage.css";
import { FcClock, FcMoneyTransfer, FcEngineering } from "react-icons/fc";

export default function CardForPackage({
  det,
  dat,
  onPress,
  val,
  setter,
  button,
  add,
  remove,
}) {
  let occurrences = val.reduce(function (obj, item) {
    obj[item] = (obj[item] || 0) + 1;
    return obj;
  }, {});
  const [num, setNum] = useState(0);
  const countItem = (data, item) => {
    let num = 0;
    data.forEach((det) => {
      if (det.name == item.name) {
        num = num + 1;
      }
    });
    setNum(num);
    return num;
  };
  useEffect(() => {
    countItem(val, det);
  }, [val]);
  let vari = det.count > 0 ? "card-upper-part-green" : "card-upper-part";
  let varTow = button ? "card-top-button" : "card-top-button-selected";
  return (
    <div
      className="mother-card"
      style={{ width: window.innerWidth < 520 ? "35vw" : null }}
    >
      <div
        className="card-for-package-container"
        style={{ width: window.innerWidth < 520 ? "35vw" : null }}
      >
        <div
          className={vari}
          style={{
            height: window.innerWidth < 520 ? "16vw" : null,
            borderRadius: window.innerWidth < 520 ? "2vw" : null,
          }}
        >
          <div
            className="card-top-text"
            style={{
              width: window.innerWidth < 520 ? "30vw" : null,
              fontSize: window.innerWidth < 520 ? "2vw" : null,
            }}
          >
            {det.name}
          </div>
          {det.plus === true ? (
            <text style={{ fontSize: window.innerWidth < 520 ? "2vw" : null }}>
              {" "}
              {num}{" "}
            </text>
          ) : null}
          {det.count > 0 ? (
            <div
              style={{
                position: "absolute",
                top: window.innerWidth < 520 ? "1vw" : "0.2vw",
                right: window.innerWidth < 520 ? "1vw" : "0.2vw",
                background: "white",
                color: "#459258",
                borderRadius: "50%",
                padding: window.innerWidth < 520 ? "1.5vw" : "1vw",
                fontSize: window.innerWidth < 520 ? "3vw" : null,
                width: window.innerWidth < 520 ? "3vw" : "1vw",
                height: window.innerWidth < 520 ? "3vw" : "1vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="card-top-count"
            >
              {det.count}
            </div>
          ) : null}

          {det.plus === true ? (
            <div className={varTow}>
              <text
                style={{ fontSize: window.innerWidth < 520 ? "2.5vw" : null }}
                onClick={() => {
                  remove();
                }}
              >
                -
              </text>

              <text
                style={{ fontSize: window.innerWidth < 520 ? "2.5vw" : null }}
                onClick={() => {
                  add();
                }}
              >
                +
              </text>
            </div>
          ) : (
            <div
              className={varTow}
              onClick={() => {
                onPress();
              }}
            >
              <text
                style={{ fontSize: window.innerWidth < 520 ? "2.5vw" : null }}
              >
                {button ? "Select" : "Remove"}
              </text>
            </div>
          )}
        </div>
        <div
          className="card-lower-part"
          style={{
            height: window.innerWidth < 520 ? "25vw" : null,
            borderBottomLeftRadius: window.innerWidth < 520 ? "2vw" : null,
            borderBottomRightRadius: window.innerWidth < 520 ? "2vw" : null,
          }}
        >
          <div className="card-mini-div">
            <FcClock
              style={{
                fontSize: window.innerWidth < 520 ? "5vw" : null,
              }}
            />

            <text
              className="card-mini-text"
              style={{
                fontSize: window.innerWidth < 520 ? "2.5vw" : null,
                fontWeight: "600",
              }}
            >
              {det.time}-{parseInt(det.time) + 10} min
            </text>
          </div>
          <div className="card-mini-div">
            <FcEngineering
              style={{
                fontSize: window.innerWidth < 520 ? "5vw" : null,
              }}
            />

            <text
              className="card-mini-text"
              style={{
                fontSize: window.innerWidth < 520 ? "2.5vw" : null,
                fontWeight: "600",
              }}
            >
              <span
                style={{
                  fontWeight: "600",
                  fontSize: window.innerWidth < 520 ? "2.5vw" : null,
                }}
              >
                Type &nbsp;
              </span>
              {dat.vehicleType}
            </text>
          </div>
          <div className="card-mini-div">
            <FcMoneyTransfer
              style={{
                fontSize: window.innerWidth < 520 ? "5vw" : null,
              }}
            />

            <text
              className="card-mini-text"
              style={{ fontSize: window.innerWidth < 520 ? "2.5vw" : null }}
            >
              <span style={{ fontWeight: "600" }}>₹ &nbsp;</span>
              {dat.vehicleType === "large" ? det.large : null}
              {dat.vehicleType === "small" ? det.small : null}
              {dat.vehicleType === "medium" ? det.medium : null}
            </text>
          </div>
        </div>
      </div>
    </div>
  );
}
