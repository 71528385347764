import React, { useEffect, useState } from "react";
import IcoInput from "../Components/IcoInput";
import IcoButton from "../Components/IcoButton";
import Drop from "../Components/Drop";
import Checkbox from "@material-ui/core/Checkbox";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import "./CreateService.css";
import { FcServices, FcClock, FcAlphabeticalSortingAz } from "react-icons/fc";
import { BiRupee } from "react-icons/bi";
import { HiSelector } from "react-icons/hi";
import { IoIosSend } from "react-icons/io";
import db from "../firebase/database";
import firebase from "../firebase/firebase";

import { RiCheckboxBlankCircleLine } from "react-icons/ri";
import { FcCheckmark } from "react-icons/fc";

export default function CreateService() {
  const [adminType, setAdminType] = useState();
  useEffect(() => {
    setAdminType(window.sessionStorage.getItem("adminType"));
  }, []);
  const [serviceName, setserviceName] = useState("");
  const [serviceCategory, setserviceCategory] = useState("");
  const [serviceTime, setserviceTime] = useState("");
  const [smallCost, setsmallCost] = useState("");
  const [mediumCost, setmediumCost] = useState("");
  const [largeCost, setlargeCost] = useState("");

  const [multi, setMulti] = useState(false);
  const handleChange = (event) => {
    setMulti(!multi);
  };
  const label = <IoIosSend />;

  const catList = [
    "Engine Service",
    "Exterior Cleaning",
    "Interior Cleaning",
    "Wheel Service",
    "Paint Service",
    "Labour Cost",
  ];

  const [showServiceButton, setshowServiceButton] = useState(false);
  useEffect(() => {
    if (serviceName && serviceTime && smallCost && mediumCost && largeCost)
      setshowServiceButton(true);
  }, [serviceName, serviceTime, smallCost, mediumCost, largeCost]);

  const uploadCustomer = async () => {
    if (
      serviceCategory.value === "Engine Service" ||
      serviceCategory.value === "Exterior Cleaning" ||
      serviceCategory.value === "Interior Cleaning" ||
      serviceCategory.value === "Wheel Service"
    ) {
      await db
        .collection("services")
        .doc(serviceName)
        .set({
          name: serviceName,
          category: serviceCategory.value,
          time: serviceTime,
          small: smallCost,
          medium: mediumCost,
          large: largeCost,
          plus: multi,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .catch(function (error) {
          alert(error);
        });
      db.collection("extras")
        .doc("services")
        .update({
          services: firebase.firestore.FieldValue.arrayUnion({
            name: serviceName,
            category: serviceCategory.value,
            time: serviceTime,
            small: smallCost,
            medium: mediumCost,
            large: largeCost,
            plus: multi,
          }),
        });
      alert(serviceName + " Added");
    } else if (serviceCategory.value === "Paint Service") {
      await db
        .collection("paintCost")
        .doc(serviceName)
        .set({
          name: serviceName,
          category: serviceCategory.value,
          time: serviceTime,
          small: smallCost,
          medium: mediumCost,
          large: largeCost,
        })
        .catch(function (error) {
          alert(error);
        });
      db.collection("extras")
        .doc("services")
        .update({
          paintCost: firebase.firestore.FieldValue.arrayUnion({
            name: serviceName,
            category: serviceCategory.value,
            time: serviceTime,
            small: smallCost,
            medium: mediumCost,
            large: largeCost,
          }),
        });
      alert("Paint Service Added");
    } else if (serviceCategory.value === "Labour Cost") {
      await db
        .collection("labourCost")
        .doc(serviceName)
        .set({
          name: serviceName,
          category: serviceCategory.value,
          time: serviceTime,
          small: smallCost,
          medium: mediumCost,
          large: largeCost,
        })
        .catch(function (error) {
          alert(error);
        });
      db.collection("extras")
        .doc("services")
        .update({
          labourCost: firebase.firestore.FieldValue.arrayUnion({
            name: serviceName,
            category: serviceCategory.value,
            time: serviceTime,
            small: smallCost,
            medium: mediumCost,
            large: largeCost,
          }),
        });
      alert("Labour Charge Added");
    }
  };

  //Packages Dropdown
  const [pack, setPack] = React.useState([]);
  const [selected, setSelected] = React.useState("");
  useEffect(() => {
    const subscriber = db.collection("category").onSnapshot((querySnapshot) => {
      const tmp = [];

      querySnapshot.forEach((documentSnapshot) => {
        tmp.push({
          ...documentSnapshot.data(),
          key: documentSnapshot.id,
        });
      });
      const list = [];

      tmp.forEach((item, index) => {
        list.push({ value: item.name, label: item.name });
      });

      setPack(list);
    });

    // Unsubscribe from events when no longer in use
    return () => subscriber();
  }, []);

  const getDetArr = [
    {
      value: serviceName,
      holder: "Service Name",
      setter: setserviceName,
      icon: <FcServices />,
      type: "text",
      do: "input",
    },
    {
      value: serviceTime,
      holder: "Service Time",
      setter: setserviceTime,
      icon: <FcClock />,
      type: "number",
      do: "input",
    },
    {
      value: smallCost,
      holder: "Small Vehicle Cost",
      setter: setsmallCost,
      icon: <BiRupee />,
      type: "number",
      do: "input",
    },
    {
      value: mediumCost,
      holder: "Medium Vehicle Cost",
      setter: setmediumCost,
      icon: <BiRupee />,
      type: "number",
      do: "input",
    },

    {
      value: largeCost,
      holder: "Large Vehicle Cost",
      setter: setlargeCost,
      icon: <BiRupee />,
      type: "number",
      do: "input",
    },
    {
      icon: <HiSelector />,
      list: catList,
      val: serviceCategory,
      setter: setserviceCategory,
      holder: "Select Category",
      do: "drop",
      width: "275px",
    },
  ];

  const inputHandler = (item) => {
    return item.map((item) => {
      if (item.do === "input") {
        return (
          <IcoInput
            value={item.value}
            holder={item.holder}
            setter={item.setter}
            icon={item.icon}
            type={item.type}
          />
        );
      } else if (item.do === "drop") {
        return (
          <Drop
            list={item.list}
            val={item.val}
            setter={item.setter}
            icon={item.icon}
            holder={item.holder}
            width={item.width}
          />
        );
      }
    });
  };
  return (
    <div>
      {adminType === "banda" ? null : (
        <>
          <label
            style={{
              paddingLeft: "4.5%",
              width: "100%",
              marginTop: showServiceButton === true ? "2%" : "8%",
              fontFamily: "Montserrat",
              transition: "0.5s",
              fontWeight: "400",
              fontSize: window.innerWidth < 520 ? "2.5vw" : "1vw",
              color: "rgba(52, 50, 76, 0.8)",
            }}
          >
            Enter The Required Service Details Below
          </label>
          <div
            style={{ marginTop: window.innerWidth < 520 ? "1vw" : null }}
            className="serviceGridWork"
          >
            {inputHandler(getDetArr)}
          </div>
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              paddingLeft: "6%",
              width: "100%",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  icon={
                    <RiCheckboxBlankCircleLine style={{ color: "green" }} />
                  }
                  checkedIcon={<FcCheckmark />}
                  checked={multi}
                  onChange={handleChange}
                  name="checkedH"
                />
              }
              label="Multiple ? "
            />
          </div>{" "}
        </>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <hr
          style={{
            width: "65vw",
            backgroundColor: "#fe5236",
            opacity: 0.2,
            marginTop: "9px",
          }}
        />
      </div>

      {showServiceButton === true && window.innerWidth > 520 ? (
        <div
          style={{
            marginLeft: "80%",
            marginBottom: "-4%",
          }}
          onClick={() => uploadCustomer()}
        >
          <IcoButton icon={label} start="Create" end="Service" />
        </div>
      ) : null}
      {showServiceButton === true && window.innerWidth < 520 ? (
        <div
          style={{
            marginLeft: "6%",
            marginBottom: "2vw",
          }}
          onClick={() => uploadCustomer()}
        >
          <IcoButton icon={label} start="Create" end="Service" />
        </div>
      ) : null}
    </div>
  );
}
