import React, { useEffect, useState } from "react";
import db from "../firebase/database";
import Barcode from "react-barcode";
import { useHistory, useLocation } from "react-router-dom";
import firebase from "../firebase/firebase";
import "../Components/Invoice/NewInvoice.css";

function NewInvoice() {
  const [gstSended, setgstSended] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const checkAndBack = () => {
    if (checkPage === "sale") {
      history.push({ pathname: "/" });
    } else if (checkPage === "cust") {
      history.goBack();
    }
  };

  const whatsapp = () => {
    window.open(urlDone);
  };

  const [invoice, setinvoice] = useState(location.invoice);
  const [city, setcity] = useState(location.city||"sagar");
  const [vehicleNumber, setvehicleNumber] = useState(location.vehicleNumber);
  const [customerName, setcustomerName] = useState(location.customerName);
  const [customerMobile, setcustomerMobile] = useState(location.customerMobile);
  const [packageDetails, setpackage] = useState(location.packageDetails);
  const [date, setdate] = useState(location.date);
  const [tmpTimestamp, settmpTimestamp] = useState(location.tmpTimestamp);
  const [amount, setamount] = useState(location.amount);
  const [billList, setbillList] = useState(
    location.billList ? Object.values(filterArr(location.billList, "name")) : []
  );
  const [vehicleSize, setvehicleSize] = useState(location.vehicleSize);
  const [vehicleCompany, setvehicleCompany] = useState(location.vehicleCompany);
  const [vehicleModel, setvehicleModel] = useState(location.vehicleModel);
  const [iscForShow, setIscShow] = useState(location.isc || null);
  const [gst, setgst] = useState(location.gst || 0);
  const [totalAmount, settotalAmount] = useState(location.totalAmount);
  const [discount, setdiscount] = useState(location.discount || 0);
  const [modeOfTransaction, setmodeOfTransaction] = useState(location.mode);
  const [count, setcount] = useState(location.count || 0);
  const [productGST5, setproductGST5] = useState(location.productGST5 || 0);
  const [productGST12, setproductGST12] = useState(location.productGST12 || 0);
  const [productGST18, setproductGST18] = useState(location.productGST18 || 0);
  const [productGST28, setproductGST28] = useState(location.productGST28 || 0);
  const [purchase, setpurchase] = useState(location.purchase);
  const [expiry, setexpiry] = useState(location.expiry);
  const [kilmil, setkilmil] = useState(location.kilmil);
  const [totalServiceCount, settotalServiceCount] = useState(
    location.totalServiceCount || 0
  );
  const [checkPage, setCheckPage] = useState(location.checkPage);
  const [paperSize, setpaperSize] = useState("Choose");

  const newBill = [];
  const newBill1 = [];

  billList.forEach((item) => {
    const t = [];
    newBill.push({
      name: item.name,
      qty: item.num,
      amount:
        vehicleSize === "small"
          ? item.small
          : vehicleSize === "medium"
          ? item.medium
          : vehicleSize === "large"
          ? item.large
          : 0,
    });
    newBill1.push(
      item.name,
      vehicleSize === "small"
        ? "Amt:" + item.small
        : vehicleSize === "medium"
        ? "Amt:" + item.medium
        : vehicleSize === "large"
        ? "Amt:" + item.large
        : 0,
      "%0D%0A"
    );
  });
  console.log("new bill here =>", newBill);
  console.log("new bill here", newBill1);

  let number = "+91" + customerMobile;
  let message =
    "Thank You For Visiting Innovative Service Center%0D%0A========================%0D%0AISC Number : " +
    iscForShow +
    "%0D%0ACustomer : " +
    customerName +
    "%0D%0AInvoice :  " +
    invoice +
    "%0D%0ADate : " +
    date +
    "%0D%0AMobile : " +
    customerMobile +
    "%0D%0AVehicle : " +
    vehicleCompany +
    "-" +
    vehicleModel +
    "%0D%0APackage : " +
    packageDetails +
    "%0D%0APurchased : " +
    purchase +
    "%0D%0AExpiry : " +
    expiry +
    "%0D%0AMode : " +
    modeOfTransaction +
    "%0D%0AServices Left : " +
    count +
    "%0D%0ATotal Services : " +
    totalServiceCount +
    "%0D%0A========================%0D%0AServices Description%0D%0A" +
    String(newBill1) +
    "%0D%0A========================%0D%0AAMOUNT PAID : " +
    totalAmount;
  let urlDone =
    "https://api.whatsapp.com/send?phone=" + number + "&text=" + message;

  const [printing, setprinting] = useState(false);
  const printInvoice = () => {
    setprinting(true);
    setTimeout(goBackNow, 100);
  };
  const goBackNow = () => {
    window.print();
    setprinting(false);
  };
  function filterArr(data, key) {
    return data.reduce((result, current) => {
      const size = current.vehicleSize;
      if (!result[current[key]]) {
        result[current[key]] = { ...current, num: 1 };
      } else {
        result[current[key]].num += 1;
      }
      return result;
    }, {});
  }

  const showData = (item) => {
    return (
      <div className="newInvoice-section3-headingContain">
        <span className="newInvoice-section2-billedSpanContain">
          {item.name} x {item.num}
        </span>
        <span className="newInvoice-section2-billedSpanContain">
          {vehicleSize === "small"
            ? item.small * item.num
            : vehicleSize === "medium"
            ? item.medium * item.num
            : vehicleSize === "large"
            ? item.large * item.num
            : 0}
        </span>
      </div>
    );
  };

  const gstSender = async () => {
    await db
      .collection("gst")
      .doc(invoice.toString())
      .set({
        amount: parseFloat(totalAmount, 10),
        servicedCity: city==="sagar" ? "sagar" : "banda",
        base12: parseFloat((productGST12 / 0.12).toFixed(2), 10),
        base18: parseFloat(
          (
            (totalAmount -
              (productGST5 / 0.05 +
                productGST12 / 0.12 +
                productGST28 / 0.28 +
                productGST5 +
                productGST12 +
                productGST28) -
              (totalAmount -
                (productGST5 / 0.05 +
                  productGST12 / 0.12 +
                  productGST28 / 0.28 +
                  productGST5 +
                  productGST12 +
                  productGST28)) /
                1.18) /
            0.18
          ).toFixed(2),
          10
        ),
        base28: parseFloat((productGST28 / 0.28).toFixed(2), 10),
        base5: parseFloat((productGST5 / 0.05).toFixed(2), 10),
        customerName: customerName,
        date: date,
        gst12: parseFloat(productGST12.toFixed(2), 10),
        gst18: parseFloat(
          (
            totalAmount -
            (productGST5 / 0.05 +
              productGST12 / 0.12 +
              productGST28 / 0.28 +
              productGST5 +
              productGST12 +
              productGST28) -
            (totalAmount -
              (productGST5 / 0.05 +
                productGST12 / 0.12 +
                productGST28 / 0.28 +
                productGST5 +
                productGST12 +
                productGST28)) /
              1.18
          ).toFixed(2),
          10
        ),
        gst28: parseFloat(productGST28.toFixed(2), 10),
        gst5: parseFloat(productGST5.toFixed(2), 10),
        invoice: invoice,
        timestamp:
          tmpTimestamp || firebase.firestore.FieldValue.serverTimestamp(),
        totalBase: parseFloat(
          (
            productGST5 / 2 +
            productGST12 / 2 +
            productGST28 / 2 +
            (totalAmount -
              (productGST5 / 0.05 +
                productGST12 / 0.12 +
                productGST28 / 0.28 +
                productGST5 +
                productGST12 +
                productGST28) -
              (totalAmount -
                (productGST5 / 0.05 +
                  productGST12 / 0.12 +
                  productGST28 / 0.28 +
                  productGST5 +
                  productGST12 +
                  productGST28)) /
                1.18) /
              0.18 +
            productGST5 / 0.05 +
            productGST12 / 0.12 +
            productGST28 / 0.28
          ).toFixed(2),
          10
        ),
        totalGST: parseFloat(
          (
            productGST5 +
            productGST12 +
            productGST28 +
            (totalAmount -
              (productGST5 / 0.05 +
                productGST12 / 0.12 +
                productGST28 / 0.28 +
                productGST5 +
                productGST12 +
                productGST28) -
              (totalAmount -
                (productGST5 / 0.05 +
                  productGST12 / 0.12 +
                  productGST28 / 0.28 +
                  productGST5 +
                  productGST12 +
                  productGST28)) /
                1.18)
          ).toFixed(2),
          10
        ),
      });
    setgstSended(true);
  };

  return (
    <div>
      <div>
        {paperSize === "Choose" ? (
          <div>
            <button
              style={{
                width: "50vw",
                height: "100vh",
                fontSize: "5vw",
                textAlign: "center",
              }}
              onClick={async () => {
                await setpaperSize("Thermal");
                await gstSender();
              }}
            >
              Thermal
            </button>
            <button
              style={{
                width: "50vw",
                height: "100vh",
                fontSize: "5vw",
                textAlign: "center",
              }}
              onClick={async () => {
                await setpaperSize("A4");
                await gstSender();
              }}
            >
              A4 Size
            </button>
          </div>
        ) : null}
        {paperSize === "Thermal" ? (
          <div>
            <div
              style={{
                width: "100vw",
                height: "512vw",
                display: "block",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              {/* TopHeader */}
              {/* {printing === false ? (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    alt="Print"
                    style={{
                      width: "25vw",
                      cursor: "pointer",
                    }}
                    src={require("../assets/print.png")}
                    onClick={printInvoice}
                  />
                  <button
                    style={{ width: "8vw", height: "4vw", textAlign: "center" }}
                    onClick={() => history.push({ pathname: "/" })}
                  >
                    Back
                  </button>
                </div>
              ) : null} */}
              <img
                alt="Logo"
                style={{ width: "100vw" }}
                src={require("../assets/logo.png")}
              />
              <div>
                <Barcode value={invoice} />
              </div>
              {/* <label
            style={{
              fontSize: "10vw",
              width: '100vw',
                        fontFamily: "Inconsolata",
          fontStyle: "monospace",
              fontWeight: "700",
              textAlign: 'center'
            }}
          >
            Invoice : {invoice}
          </label> */}

              <hr style={{ width: "100%" }} />
              {/* Bill Details */}
              <div
                style={{
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                <div>
                  <span
                    style={{
                      color: "red",
                      fontSize: "12vw",
                      width: "100vw",
                      display: "flex",
                      fontFamily: "Inconsolata",
                      fontStyle: "monospace",
                      fontWeight: "900",
                    }}
                  >
                    Bill To
                  </span>

                  <label
                    style={{
                      fontSize: "8vw",
                      display: "flex",
                      width: "100vw",
                      fontFamily: "Inconsolata",
                      fontStyle: "monospace",
                      fontWeight: "900",
                      textAlign: "left",
                    }}
                  >
                    {iscForShow}
                    {iscForShow !== null ? <br /> : null}
                    {customerName} , {customerMobile}
                    <br />
                    {vehicleCompany} - {vehicleModel} , {vehicleNumber}
                    {purchase && expiry && packageDetails ? (
                      <>
                        <br />
                        Package : {packageDetails}
                        <br />
                        Purchased : {purchase}
                        <br />
                        Expiry : {expiry}
                        <br />
                        Kilometer : {kilmil || 0}
                      </>
                    ) : (
                      <>
                        <br />
                        Kilometer : {kilmil || 0}
                      </>
                    )}
                  </label>
                </div>
                <div>
                  <span
                    style={{
                      color: "red",
                      fontSize: "12vw",
                      width: "100vw",
                      display: "flex",
                      fontFamily: "Inconsolata",
                      fontStyle: "monospace",
                      fontWeight: "900",
                    }}
                  >
                    Invoice Details
                  </span>
                  <label
                    style={{
                      fontSize: "8vw",
                      display: "flex",
                      width: "100vw",
                      fontFamily: "Inconsolata",
                      fontStyle: "monospace",
                      fontWeight: "900",
                      textAlign: "left",
                    }}
                  >
                    Invoice No : {invoice}
                    <br />
                    Date : {date}
                    <br />
                    Amount : {totalAmount}
                    <br />
                    Mode : {modeOfTransaction}
                    <br />
                  </label>
                  {count === 0 ? null : (
                    <label
                      style={{
                        fontSize: "8vw",
                        display: "flex",
                        width: "100vw",
                        fontFamily: "Inconsolata",
                        fontStyle: "monospace",
                        fontWeight: "900",
                        textAlign: "left",
                      }}
                    >
                      Total Services : {totalServiceCount}
                      <br />
                      Services Left : {count}
                    </label>
                  )}
                </div>
              </div>
              <br />
              {/* Service Amount Header Table */}
              <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    display: "flex",
                    backgroundColor: "#EEEEEE",
                    padding: "1vw",
                    fontSize: "7vw",
                    fontFamily: "Inconsolata",
                    fontStyle: "monospace",
                    fontWeight: "900",
                    textAlign: "left",
                    paddingLeft: "3%",
                  }}
                >
                  Services
                </div>
                <div
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    display: "flex",
                    backgroundColor: "#DCDCDC",
                    padding: "1vw",
                    fontSize: "7vw",
                    fontFamily: "Inconsolata",
                    fontStyle: "monospace",
                    fontWeight: "900",
                    textAlign: "right",
                    paddingRight: "3%",
                  }}
                >
                  Amount
                </div>
              </div>
              {/* Invoice Data */}
              {billList.map(showData)}
              {/* Taxes */}
              <hr style={{ width: "100%", borderColor: "red" }} />

              <div
                style={{
                  flex: 1,
                  display: "flex",
                  fontSize: "5vw",
                  fontFamily: "Inconsolata",
                  fontStyle: "monospace",
                  fontWeight: "700",
                  flexDirection: "column",
                  padding: "1vw",
                  borderWidth: "1vw",
                  borderColor: "red",
                }}
              >
                <label
                  style={{
                    width: "100%",
                    flex: 1,
                    fontFamily: "Montserrat",
                    fontWeight: "900",
                  }}
                >
                  GST Details
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  {productGST5 > 0 ? (
                    <>
                      <label
                        style={{ display: "flex", flex: 1, width: "100%" }}
                      >
                        Base Amount (5%) : ₹ {(productGST5 / 0.05).toFixed(2)}
                      </label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flex: 1,
                          justifyContent: "space-between",
                        }}
                      >
                        <label>
                          CGST (2.5%) | <br />₹ {(productGST5 * 0.5).toFixed(2)}
                        </label>
                        <label>
                          SGST (2.5%) | <br />₹ {(productGST5 * 0.5).toFixed(2)}
                        </label>
                        <label>
                          <b>
                            GST (5%) | <br />₹ {productGST5.toFixed(2)}
                          </b>
                        </label>
                      </div>
                    </>
                  ) : null}
                  {productGST12 > 0 ? (
                    <>
                      <label
                        style={{ display: "flex", flex: 1, width: "100%" }}
                      >
                        Base Amount (12%) : ₹ {(productGST12 / 0.12).toFixed(2)}
                      </label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flex: 1,
                          justifyContent: "space-between",
                        }}
                      >
                        <label>
                          CGST (6%) | <br />₹ {(productGST12 * 0.5).toFixed(2)}
                        </label>
                        <label>
                          SGST (6%) | <br />₹ {(productGST12 * 0.5).toFixed(2)}
                        </label>
                        <label>
                          <b>
                            GST (12%) | <br />₹ {productGST12.toFixed(2)}
                          </b>
                        </label>
                      </div>
                    </>
                  ) : null}
                  {productGST18 > 0 ? (
                    <>
                      <label
                        style={{ display: "flex", flex: 1, width: "100%" }}
                      >
                        Base Amount (18%) : ₹
                        {(
                          (totalAmount -
                            (productGST5 / 0.05 +
                              productGST12 / 0.12 +
                              productGST28 / 0.28 +
                              productGST5 +
                              productGST12 +
                              productGST28) -
                            (totalAmount -
                              (productGST5 / 0.05 +
                                productGST12 / 0.12 +
                                productGST28 / 0.28 +
                                productGST5 +
                                productGST12 +
                                productGST28)) /
                              1.18) /
                          0.18
                        ).toFixed(2)}
                      </label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flex: 1,
                          justifyContent: "space-between",
                        }}
                      >
                        <label>
                          CGST (9%) | <br />₹{" "}
                          {(
                            (totalAmount -
                              (productGST5 / 0.05 +
                                productGST12 / 0.12 +
                                productGST28 / 0.28 +
                                productGST5 +
                                productGST12 +
                                productGST28) -
                              (totalAmount -
                                (productGST5 / 0.05 +
                                  productGST12 / 0.12 +
                                  productGST28 / 0.28 +
                                  productGST5 +
                                  productGST12 +
                                  productGST28)) /
                                1.18) *
                            0.5
                          ).toFixed(2)}
                        </label>
                        <label>
                          SGST (9%) | <br />₹{" "}
                          {(
                            (totalAmount -
                              (productGST5 / 0.05 +
                                productGST12 / 0.12 +
                                productGST28 / 0.28 +
                                productGST5 +
                                productGST12 +
                                productGST28) -
                              (totalAmount -
                                (productGST5 / 0.05 +
                                  productGST12 / 0.12 +
                                  productGST28 / 0.28 +
                                  productGST5 +
                                  productGST12 +
                                  productGST28)) /
                                1.18) *
                            0.5
                          ).toFixed(2)}
                        </label>
                        <label>
                          <b>
                            GST (18%) | <br />₹{" "}
                            {(
                              totalAmount -
                              (productGST5 / 0.05 +
                                productGST12 / 0.12 +
                                productGST28 / 0.28 +
                                productGST5 +
                                productGST12 +
                                productGST28) -
                              (totalAmount -
                                (productGST5 / 0.05 +
                                  productGST12 / 0.12 +
                                  productGST28 / 0.28 +
                                  productGST5 +
                                  productGST12 +
                                  productGST28)) /
                                1.18
                            ).toFixed(2)}
                          </b>
                        </label>
                      </div>
                    </>
                  ) : (
                    <>
                      <label
                        style={{ display: "flex", flex: 1, width: "100%" }}
                      >
                        Base Amount (18%) : ₹
                        {(
                          (totalAmount -
                            (productGST5 / 0.05 +
                              productGST12 / 0.12 +
                              productGST28 / 0.28 +
                              productGST5 +
                              productGST12 +
                              productGST28) -
                            (totalAmount -
                              (productGST5 / 0.05 +
                                productGST12 / 0.12 +
                                productGST28 / 0.28 +
                                productGST5 +
                                productGST12 +
                                productGST28)) /
                              1.18) /
                          0.18
                        ).toFixed(2)}
                      </label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flex: 1,
                          justifyContent: "space-between",
                        }}
                      >
                        <label>
                          CGST (9%) | <br />₹{" "}
                          {(
                            (totalAmount -
                              (productGST5 / 0.05 +
                                productGST12 / 0.12 +
                                productGST28 / 0.28 +
                                productGST5 +
                                productGST12 +
                                productGST28) -
                              (totalAmount -
                                (productGST5 / 0.05 +
                                  productGST12 / 0.12 +
                                  productGST28 / 0.28 +
                                  productGST5 +
                                  productGST12 +
                                  productGST28)) /
                                1.18) *
                            0.5
                          ).toFixed(2)}
                        </label>
                        <label>
                          SGST (9%) | <br />₹{" "}
                          {(
                            (totalAmount -
                              (productGST5 / 0.05 +
                                productGST12 / 0.12 +
                                productGST28 / 0.28 +
                                productGST5 +
                                productGST12 +
                                productGST28) -
                              (totalAmount -
                                (productGST5 / 0.05 +
                                  productGST12 / 0.12 +
                                  productGST28 / 0.28 +
                                  productGST5 +
                                  productGST12 +
                                  productGST28)) /
                                1.18) *
                            0.5
                          ).toFixed(2)}
                        </label>
                        <label>
                          <b>
                            GST (18%) | <br />₹{" "}
                            {(
                              totalAmount -
                              (productGST5 / 0.05 +
                                productGST12 / 0.12 +
                                productGST28 / 0.28 +
                                productGST5 +
                                productGST12 +
                                productGST28) -
                              (totalAmount -
                                (productGST5 / 0.05 +
                                  productGST12 / 0.12 +
                                  productGST28 / 0.28 +
                                  productGST5 +
                                  productGST12 +
                                  productGST28)) /
                                1.18
                            ).toFixed(2)}
                          </b>
                        </label>
                      </div>
                    </>
                  )}
                  {productGST28 > 0 ? (
                    <>
                      <label
                        style={{ display: "flex", flex: 1, width: "100%" }}
                      >
                        Base Amount (28%) : ₹ {(productGST28 / 0.28).toFixed(2)}
                      </label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flex: 1,
                          justifyContent: "space-between",
                        }}
                      >
                        <label>
                          CGST (14%) | <br />₹ {(productGST28 * 0.5).toFixed(2)}
                        </label>
                        <label>
                          SGST (14%) | <br />₹ {(productGST28 * 0.5).toFixed(2)}
                        </label>
                        <label>
                          <b>
                            GST (28%) | <br />₹ {productGST28.toFixed(2)}
                          </b>
                        </label>
                      </div>
                    </>
                  ) : null}
                  <div
                    style={{
                      display: "block",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <label>
                      <b>
                        Total Base Amount : ₹{" "}
                        {(
                          productGST5 / 2 +
                          productGST12 / 2 +
                          productGST28 / 2 +
                          (totalAmount -
                            (productGST5 / 0.05 +
                              productGST12 / 0.12 +
                              productGST28 / 0.28 +
                              productGST5 +
                              productGST12 +
                              productGST28) -
                            (totalAmount -
                              (productGST5 / 0.05 +
                                productGST12 / 0.12 +
                                productGST28 / 0.28 +
                                productGST5 +
                                productGST12 +
                                productGST28)) /
                              1.18) /
                            0.18 +
                          productGST5 / 0.05 +
                          productGST12 / 0.12 +
                          productGST28 / 0.28
                        ).toFixed(2)}
                      </b>
                    </label>
                    <br />
                    <label>
                      <b>
                        Total CGST : ₹{" "}
                        {(
                          (productGST5 +
                            productGST12 +
                            productGST28 +
                            (totalAmount -
                              (productGST5 / 0.05 +
                                productGST12 / 0.12 +
                                productGST28 / 0.28 +
                                productGST5 +
                                productGST12 +
                                productGST28) -
                              (totalAmount -
                                (productGST5 / 0.05 +
                                  productGST12 / 0.12 +
                                  productGST28 / 0.28 +
                                  productGST5 +
                                  productGST12 +
                                  productGST28)) /
                                1.18)) *
                          0.5
                        ).toFixed(2)}
                      </b>
                    </label>
                    <br />
                    <label>
                      <b>
                        Total SGST : ₹{" "}
                        {(
                          (productGST5 +
                            productGST12 +
                            productGST28 +
                            (totalAmount -
                              (productGST5 / 0.05 +
                                productGST12 / 0.12 +
                                productGST28 / 0.28 +
                                productGST5 +
                                productGST12 +
                                productGST28) -
                              (totalAmount -
                                (productGST5 / 0.05 +
                                  productGST12 / 0.12 +
                                  productGST28 / 0.28 +
                                  productGST5 +
                                  productGST12 +
                                  productGST28)) /
                                1.18)) *
                          0.5
                        ).toFixed(2)}
                      </b>
                    </label>
                    <br />
                    <label>
                      <b>
                        Total GST : ₹{" "}
                        {(
                          productGST5 +
                          productGST12 +
                          productGST28 +
                          (totalAmount -
                            (productGST5 / 0.05 +
                              productGST12 / 0.12 +
                              productGST28 / 0.28 +
                              productGST5 +
                              productGST12 +
                              productGST28) -
                            (totalAmount -
                              (productGST5 / 0.05 +
                                productGST12 / 0.12 +
                                productGST28 / 0.28 +
                                productGST5 +
                                productGST12 +
                                productGST28)) /
                              1.18)
                        ).toFixed(2)}
                      </b>
                    </label>
                  </div>
                </div>
              </div>
              <hr style={{ width: "100%", borderColor: "red" }} />

              <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    display: "flex",
                    padding: "1vw",
                    fontSize: "7vw",
                    fontFamily: "Inconsolata",
                    fontStyle: "monospace",
                    fontWeight: "900",
                    textAlign: "left",
                    paddingLeft: "3%",
                  }}
                >
                  SUBTOTAL
                  <br />
                  Discount
                  <br />
                  <label style={{ fontWeight: "900" }}>TOTAL PAYABLE</label>
                </div>
                <div
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    display: "flex",
                    padding: "1vw",
                    fontSize: "7vw",
                    fontFamily: "Inconsolata",
                    fontStyle: "monospace",
                    fontWeight: "900",
                    textAlign: "right",
                    paddingRight: "3%",
                  }}
                >
                  {parseFloat(amount).toFixed(2)} ₹
                  <br />- {parseFloat(discount).toFixed(2)} ₹
                  <br />
                  <label style={{ fontWeight: "900" }}>
                    {parseFloat(totalAmount).toFixed(2)} ₹
                  </label>
                  <hr style={{ width: "100%", borderColor: "red" }} />
                </div>
              </div>
              {/* <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Barcode value={invoice} />
                </div>
              </div> */}
              {printing === false ? (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    alt="Print"
                    style={{
                      width: "25vw",
                      cursor: "pointer",
                    }}
                    src={require("../assets/print.png")}
                    onClick={printInvoice}
                  />
                  <img
                    alt="Whatsapp"
                    style={{
                      width: "15vw",
                      cursor: "pointer",
                    }}
                    src={require("../assets/WhatsApp.svg")}
                    onClick={whatsapp}
                  />
                  <button
                    style={{ width: "8vw", height: "4vw", textAlign: "center" }}
                    onClick={checkAndBack}
                  >
                    Back
                  </button>
                </div>
              ) : null}
              <p
                style={{
                  fontSize: "4vw",
                  fontFamily: "Inconsolata",
                  fontStyle: "monospace",
                }}
              >
                <b>Terms And Conditions</b>
                <br />
                1. Vehicle parked, driven and any personal belonging left behind
                in the vehicle solely at the customer/ vehicle owner's risk and
                responsibility.
                <br />
                2. Innovative service center or its employees are will not be
                responsible for any damage prior to or after or during the
                possession of the vehicle for any treatment done at the
                Innovative service center.
                <br />
                3. The price and method//procedure and time was taken are only
                indicative of the job mentioned here in the above for any job
                done at Innovative service center and are subject to change with
                the prior notice as per the description of the technical and
                management of the Innovative Service Center.
                <br />
                4. The customer/vehicle owner affirms and undertakes that the
                vehicle is duly insured and will indemnify Innovative service
                center its employees for direct losses/damage/personal injury
                arising due to the vehicle in part or full to the extent of the
                damage caused.
                <br />
                5. Customer please note interest @ 24% p.a will be charged if
                payment is delayed beyond 15 days. 6. All subject to Sagar
                Jurisdiction
              </p>
              {/* {printing === true ? (
        <img
          style={{
            position: "absolute",
            bottom: "5%",
          }}
          alt=""
          src={require("../assets/haspr.svg")}
        />
      ) : null} */}
            </div>
          </div>
        ) : paperSize === "A4" ? (
          <div className="newInvoice-mainContainer">
            <div className="newInvoice-section1">
              <div className="newInvoice-section1-logo">
                <img
                  className="newInvoice-section1-logo"
                  src={require("../assets/logo.png")}
                  alt="Logo"
                />
              </div>
              <div className="newInvoice-section1-heading">
                <span className="newInvoice-section1-heading-spanInnvo">
                  Innovative Service Centre
                </span>
                <span className="newInvoice-section1-heading-spanMohan">
                {city==="sagar" ? "Mohan Nagar, Narsinghpur Road, Makronia,Sagar(MP)" : "Infront of Soni Petrol Pump, Sagar Road, Banda (M.P)"}
                </span>
                <span className="newInvoice-section1-heading-spanNumber">
                  9584245245,9111447245
                </span>
              </div>
            </div>
            <div className="newInvoice-section2">
              <div className="newInvoice-section2-billed">
                <span className="newInvoice-section2-billedSpan">
                  {" "}
                  Billed To
                </span>
                <span className="newInvoice-section2-detailsSpan">
                  {" "}
                  {iscForShow}
                </span>
                <span className="newInvoice-section2-detailsSpan">
                  {" "}
                  {customerName}
                </span>
                <span className="newInvoice-section2-detailsSpan">
                  {customerMobile}
                </span>
                <span className="newInvoice-section2-detailsSpan">
                  {vehicleCompany} - {vehicleModel}
                </span>
                <span className="newInvoice-section2-detailsSpan">
                  {vehicleNumber}
                </span>
                <span className="newInvoice-section2-detailsSpan">
                  {" "}
                  {kilmil} KM
                </span>
              </div>
              {packageDetails ? (
                <div className="newInvoice-section2-packageDetails">
                  <span className="newInvoice-section2-billedSpan">
                    Package Detail
                  </span>
                  <span className="newInvoice-section2-detailsSpan">
                    {packageDetails}
                  </span>
                  <span className="newInvoice-section2-detailsSpan">
                    Purchased Date : {purchase}
                  </span>
                  <span className="newInvoice-section2-detailsSpan">
                    Expiry Date : {expiry}
                  </span>
                  <span className="newInvoice-section2-detailsSpan">
                    Total Coupon : {totalServiceCount}
                  </span>
                  <span className="newInvoice-section2-detailsSpan">
                    Coupon Left : {count}
                  </span>
                </div>
              ) : null}
              <div className="newInvoice-section2-invoiceDetails">
                <span className="newInvoice-section2-billedSpan">
                  Invoice Detail
                </span>
                <span className="newInvoice-section2-detailsSpan">
                  Invoice No : {invoice}
                </span>
                <span className="newInvoice-section2-detailsSpan">
                  Date : {date}
                </span>
                <span className="newInvoice-section2-detailsSpan">
                  Mode : {modeOfTransaction}
                </span>
                <div className="barcode">
                  <Barcode value={invoice} />
                </div>
              </div>
              <div className="newInvoice-section2-total">
                <span className="newInvoice-section2-billedSpan">Amount</span>
                <span className="section2-billedSpan-total">
                  {" "}
                  &#8377;{totalAmount}
                </span>
              </div>
            </div>
            <hr className="kariya-line" />
            <div className="newInvoice-section3">
              <div className="newInvoice-section3-heading">
                <span className="newInvoice-section2-billedSpan">
                  Service Description
                </span>
                <span className="newInvoice-section2-billedSpan">Amount</span>
              </div>
              {billList.map(showData)}
              <div className="newInvoice-section3-GstDetails">
                <div className="section3-GstDetails-1stHalf">
                  <span className="newInvoice-section2-billedSpan">
                    GST Detail
                  </span>

                  {productGST5 > 0 ? (
                    <div className="GstDetails-1stHalf-details">
                      <span className="newInvoice-section2-detailsSpan">
                        Base Amt(5%) : {(productGST5 / 0.05).toFixed(2)}
                      </span>
                      <span className="newInvoice-section2-detailsSpan">
                        CGST(5%) : {(productGST5 * 0.5).toFixed(2)}
                      </span>
                      <span className="newInvoice-section2-detailsSpan">
                        SGST(5%) : {(productGST5 * 0.5).toFixed(2)}
                      </span>
                    </div>
                  ) : null}
                  {/* 5 GST */}

                  {productGST12 > 0 ? (
                    <div className="GstDetails-1stHalf-details">
                      <span className="newInvoice-section2-detailsSpan">
                        Base Amt(12%) : {(productGST12 / 0.12).toFixed(2)}
                      </span>
                      <span className="newInvoice-section2-detailsSpan">
                        CGST(12%) : {(productGST12 * 0.5).toFixed(2)}
                      </span>
                      <span className="newInvoice-section2-detailsSpan">
                        SGST(12%) : {(productGST12 * 0.5).toFixed(2)}
                      </span>
                    </div>
                  ) : null}

                  {/* 12 GST */}
                  <div className="GstDetails-1stHalf-details">
                    <span className="newInvoice-section2-detailsSpan">
                      Base Amt(18%) :{" "}
                      {(
                        (totalAmount -
                          (productGST5 / 0.05 +
                            productGST12 / 0.12 +
                            productGST28 / 0.28 +
                            productGST5 +
                            productGST12 +
                            productGST28) -
                          (totalAmount -
                            (productGST5 / 0.05 +
                              productGST12 / 0.12 +
                              productGST28 / 0.28 +
                              productGST5 +
                              productGST12 +
                              productGST28)) /
                            1.18) /
                        0.18
                      ).toFixed(2)}
                    </span>
                    <span className="newInvoice-section2-detailsSpan">
                      CGST(9%) :{" "}
                      {(
                        (totalAmount -
                          (productGST5 / 0.05 +
                            productGST12 / 0.12 +
                            productGST28 / 0.28 +
                            productGST5 +
                            productGST12 +
                            productGST28) -
                          (totalAmount -
                            (productGST5 / 0.05 +
                              productGST12 / 0.12 +
                              productGST28 / 0.28 +
                              productGST5 +
                              productGST12 +
                              productGST28)) /
                            1.18) *
                        0.5
                      ).toFixed(2)}
                    </span>
                    <span className="newInvoice-section2-detailsSpan">
                      SGST(9%) :{" "}
                      {(
                        (totalAmount -
                          (productGST5 / 0.05 +
                            productGST12 / 0.12 +
                            productGST28 / 0.28 +
                            productGST5 +
                            productGST12 +
                            productGST28) -
                          (totalAmount -
                            (productGST5 / 0.05 +
                              productGST12 / 0.12 +
                              productGST28 / 0.28 +
                              productGST5 +
                              productGST12 +
                              productGST28)) /
                            1.18) *
                        0.5
                      ).toFixed(2)}
                    </span>
                  </div>
                  {/* 18 GST */}
                  {productGST28 > 0 ? (
                    <div className="GstDetails-1stHalf-details">
                      <span className="newInvoice-section2-detailsSpan">
                        Base Amt(28%) : {(productGST28 / 0.28).toFixed(2)}
                      </span>
                      <span className="newInvoice-section2-detailsSpan">
                        CGST(28%) : {(productGST28 * 0.5).toFixed(2)}
                      </span>
                      <span className="newInvoice-section2-detailsSpan">
                        SGST(28%) : {(productGST28 * 0.5).toFixed(2)}
                      </span>
                    </div>
                  ) : null}
                  {/* 28 GST */}
                  <div className="GstDetails-1stHalf-details">
                    <span className="newInvoice-section2-detailsSpan">
                      Total Base:{" "}
                      {(
                        (totalAmount -
                          (productGST5 / 0.05 +
                            productGST12 / 0.12 +
                            productGST28 / 0.28 +
                            productGST5 +
                            productGST12 +
                            productGST28) -
                          (totalAmount -
                            (productGST5 / 0.05 +
                              productGST12 / 0.12 +
                              productGST28 / 0.28 +
                              productGST5 +
                              productGST12 +
                              productGST28)) /
                            1.18) /
                          0.18 +
                        productGST5 / 0.05 +
                        productGST12 / 0.12 +
                        productGST28 / 0.28
                      ).toFixed(2)}
                    </span>
                    <span className="newInvoice-section2-detailsSpan">
                      Total CGST(9%):{" "}
                      {(
                        (productGST5 +
                          productGST12 +
                          productGST28 +
                          (totalAmount -
                            (productGST5 / 0.05 +
                              productGST12 / 0.12 +
                              productGST28 / 0.28 +
                              productGST5 +
                              productGST12 +
                              productGST28) -
                            (totalAmount -
                              (productGST5 / 0.05 +
                                productGST12 / 0.12 +
                                productGST28 / 0.28 +
                                productGST5 +
                                productGST12 +
                                productGST28)) /
                              1.18)) *
                        0.5
                      ).toFixed(2)}
                    </span>
                    <span className="newInvoice-section2-detailsSpan">
                      Total SGST(9%):{" "}
                      {(
                        (productGST5 +
                          productGST12 +
                          productGST28 +
                          (totalAmount -
                            (productGST5 / 0.05 +
                              productGST12 / 0.12 +
                              productGST28 / 0.28 +
                              productGST5 +
                              productGST12 +
                              productGST28) -
                            (totalAmount -
                              (productGST5 / 0.05 +
                                productGST12 / 0.12 +
                                productGST28 / 0.28 +
                                productGST5 +
                                productGST12 +
                                productGST28)) /
                              1.18)) *
                        0.5
                      ).toFixed(2)}
                    </span>
                  </div>
                </div>
                <div className="section3-GstDetails-2ndHalf">
                  <div className="newInvoice-section3-subTotal-div">
                    <span className="newInvoice-section3-subTotal">
                      Sub Total
                    </span>
                    <span className="newInvoice-section3-subTotal">
                      ₹ {amount}
                    </span>
                  </div>
                  <div className="newInvoice-section3-subTotal-div">
                    <span className="newInvoice-section3-subTotal">
                      Discount
                    </span>
                    <span className="newInvoice-section3-subTotal">
                      ₹ {discount}
                    </span>
                  </div>
                  <div className="newInvoice-section3-subTotal-div">
                    <span
                      style={{ fontSize: "1.5vw", lineHeight: "3vw" }}
                      className="newInvoice-section2-billedSpan"
                    >
                      Amount Payable
                    </span>
                    <span
                      style={{ fontSize: "1.5vw", lineHeight: "3vw" }}
                      className="newInvoice-section2-billedSpan"
                    >
                      ₹ {totalAmount}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <hr className="kariya-line" />
            <div className="newInvoice-section4">
              <span className="term-n-condtion-heading">
                {" "}
                Terms & Conditions
              </span>
              <p className="term-n-condtion-kuch-lekha">
                1. Vehicle parked, driven and any personal belonging left behind
                in the vehicle solely at the customer/ vehicle owner's risk and
                responsibility.
                <br />
                2. Innovative service center or its employees are will not be
                responsible for any damage prior to or after or during the
                possession of the vehicle for any treatment done at the
                Innovative service center.
                <br />
                3. The price and method//procedure and time was taken are only
                indicative of the job mentioned here in the above for any job
                done at Innovative service center and are subject to change with
                the prior notice as per the description of the technical and
                management of the Innovative Service Center.
                <br />
                4. The customer/vehicle owner affirms and undertakes that the
                vehicle is duly insured and will indemnify Innovative service
                center its employees for direct losses/damage/personal injury
                arising due to the vehicle in part or full to the extent of the
                damage caused.
                <br />
                5. Customer please note interest @ 24% p.a will be charged if
                payment is delayed beyond 15 days. 6. All subject to Sagar
                Jurisdiction
              </p>
            </div>
            {printing === false ? (
              <div className="NewInvoice-section5">
                <div className="NewInvoice-section5-icons">
                  <div onClick={whatsapp} style={{ cursor: "pointer" }}>
                    <img
                      className="newInvoice-section5-logo"
                      src={require("../assets/WhatsApp.svg")}
                      alt="Whatsapp"
                    />
                  </div>
                  <div onClick={printInvoice} style={{ cursor: "pointer" }}>
                    <img
                      className="newInvoice-section5-logo"
                      src={require("../assets/print.png")}
                      alt="Print"
                    />
                  </div>
                  <div onClick={checkAndBack} style={{ cursor: "pointer" }}>
                    <img
                      className="newInvoice-section5-logo"
                      src={require("../assets/back.svg")}
                      alt="Back"
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span className="desgin">Design &amp; Engineered by </span>
                  <span className="haspr-span">H A S P R</span>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default NewInvoice;
