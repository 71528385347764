import React from "react";


import HomePage from "./Screens/HomeScreens/HomePage"
import { BrowserRouter, Switch, Route } from "react-router-dom";

export default function WebApp() {
  return (
    <div>

      <HomePage />

    </div>
  );
}
