import firebase from "firebase";
import "firebase/firestore";
import firebaseConfig from "./config";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const db=firebase.firestore();

firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});

firebase.firestore().enablePersistence().catch(function(err) {
  if (err.code == 'failed-precondition') {
    alert('Multiple tabs open')
  } else if (err.code == 'unimplemented') {
    alert('The current browser does not support Website')
  }
});

export default db; 
