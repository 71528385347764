import React, { useEffect, useState } from "react";
import "./PackageScreen.css";
import CardToAdd from "../Components/Admin Cards/CardToAdd";
import IcoInput from "../Components/IcoInput";
import IcoButton from "../Components/IcoButton";
import TabBar from "../Components/TabBar";
import Drop from "../Components/Drop";
import db from "../firebase/database";
import { FcPackage, FcPaid, FcPlanner, FcMoneyTransfer } from "react-icons/fc";
import { IoIosSend } from "react-icons/io";
import { HiSelector } from "react-icons/hi";
import { FcAutomotive } from "react-icons/fc";
import AdminPackageCard from "../Components/AdminPackageCard";

export default function PackageScreen({ list, adminType }) {
  const [wheelServices, setWheelServices] = useState([]);
  const [exteriorCleaning, setExteriorCleaning] = useState([]);
  const [interiorCleaning, setInteriorCleaning] = useState([]);
  const [engineServices, setEngineServices] = useState([]);
  const [otherServices, setOtherServices] = useState([]);
  const [packageName, setPackageName] = useState("");
  const [totalService, setTotalServices] = useState(0);
  const [duration, setDuration] = useState("");
  const [smallCost, setSmallCost] = useState("");
  const [mediumCost, setMediumCost] = useState("");
  const [largeCost, setLargeCost] = useState("");
  const [services, setServices] = useState([]);
  const [pack, setPack] = useState([]);
  const [selected, setSelected] = useState("");
  const [countSetter, setcountSetter] = useState();

  const [buttonShow, setbuttonShow] = useState(false);
  useEffect(() => {
    if (packageName && duration && smallCost && mediumCost && largeCost) {
      setbuttonShow(true);
    }
  }, [packageName, duration, smallCost, mediumCost, largeCost]);

  const [topTap1, setTopTap1] = useState(adminType === "banda" ? false : true);
  const [topTap2, setTopTap2] = useState(adminType === "banda" ? true : false);
  const tap1 = () => {
    setTopTap1(true);
    setTopTap2(false);
  };
  const tap2 = () => {
    setTopTap1(false);
    setTopTap2(true);
  };
  const [packages, setPackages] = useState("");
  const [packageList, setPackageList] = useState([]);
  const [numberPackage, setNumberPackage] = useState([]);
  const [packageCost, setPackageCost] = useState([]);

  useEffect(() => {
    if (packages !== "") {
      db.collection("packages")
        .doc(packages.value)
        .get()
        .then((item) => {
          console.log("here is data=>", item.data().cost);
          setNumberPackage(item.data().services);
          setPackageCost(item.data().cost);
        });
    }
  }, [packages]);
  useEffect(() => {
    db.collection("packages")
      .get()
      .then((item) => {
        let temp = [];
        item.forEach((item) => {
          temp.push(item.data().name);
          console.log(item.data().name);
        });
        setPackageList(temp);
      });
  }, []);

  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const b1 = () => {
    setShow1(true);
    setShow2(false);
    setShow3(false);
    setShow4(false);
    setShow5(false);
  };
  const b2 = () => {
    setShow1(false);
    setShow2(true);
    setShow3(false);
    setShow4(false);
    setShow5(false);
  };
  const b3 = () => {
    setShow1(false);
    setShow2(false);
    setShow3(true);
    setShow4(false);
    setShow5(false);
  };
  const b4 = () => {
    setShow1(false);
    setShow2(false);
    setShow3(false);
    setShow4(true);
    setShow5(false);
  };
  const b5 = () => {
    setShow1(false);
    setShow2(false);
    setShow3(false);
    setShow4(false);
    setShow5(true);
  };
  /// To Save
  useEffect(() => {
    const subscriber = db
      .collection("customers")

      .get()
      .then((querySnapshot) => {
        const tmp = [];

        querySnapshot.forEach((documentSnapshot) => {
          tmp.push({
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          });
        });
        const list = [];

        tmp.forEach((item, index) => {
          list.push({ value: item.customerName, label: item.customerName });
        });

        setPack(list);
        // Unsubscribe from events when no longer in use
        return () => subscriber();
      });
  }, []);

  useEffect(() => {
    const subscriber = db
      .collection("services")
      .where("category", "==", "Wheel Service")
      .get()
      .then((querySnapshot) => {
        const tmp = [];

        querySnapshot.forEach((documentSnapshot) => {
          tmp.push({
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          });
        });
        setWheelServices(tmp);
        // Unsubscribe from events when no longer in use
        return () => subscriber();
      });
  }, []);
  useEffect(() => {
    const subscriber = db
      .collection("services")
      .where("category", "==", "Exterior Cleaning")
      .get()
      .then((querySnapshot) => {
        const tmp = [];

        querySnapshot.forEach((documentSnapshot) => {
          tmp.push({
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          });
        });

        setExteriorCleaning(tmp);
        // Unsubscribe from events when no longer in use
        return () => subscriber();
      });
  }, []);
  useEffect(() => {
    const subscriber = db
      .collection("services")
      .where("category", "==", "Interior Cleaning")
      .get()
      .then((querySnapshot) => {
        const tmp = [];

        querySnapshot.forEach((documentSnapshot) => {
          tmp.push({
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          });
        });

        setInteriorCleaning(tmp);
        // Unsubscribe from events when no longer in use
        return () => subscriber();
      });
  }, []);
  useEffect(() => {
    const subscriber = db
      .collection("services")
      .where("category", "==", "Engine Service")
      .get()
      .then((querySnapshot) => {
        const tmp = [];

        querySnapshot.forEach((documentSnapshot) => {
          tmp.push({
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          });
        });

        setEngineServices(tmp);
        // Unsubscribe from events when no longer in use
        return () => subscriber();
      });
  }, []);
  useEffect(() => {
    const subscriber = db
      .collection("services")
      .where("category", "==", "Other Services")
      .get()
      .then((querySnapshot) => {
        const tmp = [];

        querySnapshot.forEach((documentSnapshot) => {
          tmp.push({
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          });
        });

        setOtherServices(tmp);
        // Unsubscribe from events when no longer in use
        return () => subscriber();
      });
  }, []);

  const buttonLabel = (
    <IoIosSend
      style={{
        color: "white",
        fontSize: "20px",
      }}
    />
  );
  const upload = () => {
    let newServices = [];
    services.forEach((item) => {
      if (item.count !== 0) {
        newServices.push(item);
      }
    });
    console.log(newServices);
    db.collection("packages")
      .doc(packageName)
      .set({
        name: packageName,
        duration: duration,
        services: newServices,
        count: countSetter,
        cost: {
          small: smallCost,
          medium: mediumCost,
          large: largeCost,
        },
      })
      .then(() => {
        alert(packageName + " Package Added");
      })
      .catch((error) => {
        alert(error);
      });
  };

  const cardHandler = (list, setList) => {
    return list.map((item) => {
      return (
        <CardToAdd
          data={services}
          setter={setServices}
          setcountSetter={setcountSetter}
          item={item}
          update={setTotalServices}
          toUpdate={totalService}
          setList={setList}
          list={list}
        />
      );
    });
  };

  return (
    <div className="upper-con">
      {/* <TabBar /> */}
      {adminType === "banda" ? null : (
        <div className="top-tap-bar">
          <div
            style={{ cursor: "pointer" }}
            className={
              topTap1 === true ? "ActiveCard-customer" : "InactiveCard"
            }
            onClick={tap1}
          >
            <p className="tile1cust">
              Create <b className="tile2cust">Package</b>
            </p>
          </div>
          <div
            style={{ cursor: "pointer" }}
            className={
              topTap2 === true ? "ActiveCard-customer" : "InactiveCard"
            }
            onClick={tap2}
          >
            <p className="tile1cust">
              View <b className="tile2cust">Package</b>
            </p>
          </div>
        </div>
      )}
      {adminType === "banda" ? null : (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            // marginLeft: "4.25%",
          }}
        >
          <hr
            style={{
              width: "50%",
              marginTop: "25px",
              backgroundColor: "red",
              opacity: 0.2,
            }}
          />
        </div>
      )}

      {topTap1 === true ? (
        <div>
          {" "}
          <label
            style={{
              paddingLeft: "4.5%",
              width: "100%",
              // paddingTop: buttonShow === true ? "2%" : "8%",
              transition: "0.6s",
              fontFamily: "Montserrat",
              fontWeight: "400",
              fontSize: "20px",
              color: "rgba(52, 50, 76, 0.8)",
            }}
          >
            Enter The Required Service Details Below
          </label>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              padding: "20px",
              marginTop: "-25px",
              paddingLeft: "3.8%",
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                marginTop: "10px",
                marginRight: "10px",
              }}
            >
              <IcoInput
                value={packageName}
                holder="Package Name"
                setter={setPackageName}
                icon={<FcPaid />}
                type="text"
              />
            </div>
            <div
              style={{
                marginLeft: "10px",
                marginTop: "10px",
                marginRight: "10px",
              }}
            >
              <IcoInput
                value={duration}
                holder="Duration in Months"
                setter={setDuration}
                icon={<FcPlanner />}
                type="number"
              />
            </div>
            <div
              style={{
                marginLeft: "10px",
                marginTop: "10px",
                marginRight: "10px",
              }}
            >
              <IcoInput
                value={smallCost}
                holder="Cost For Small (in ₹)"
                setter={setSmallCost}
                icon={<FcMoneyTransfer />}
                type="number"
              />
            </div>
            <div
              style={{
                marginLeft: "10px",
                marginTop: "10px",
                marginRight: "10px",
              }}
            >
              <IcoInput
                value={mediumCost}
                holder="Cost For Medium (in ₹)"
                setter={setMediumCost}
                icon={<FcMoneyTransfer />}
                type="number"
              />
            </div>
            <div
              style={{
                marginLeft: "10px",
                marginTop: "10px",
                marginRight: "10px",
              }}
            >
              <IcoInput
                value={largeCost}
                holder="Cost For Large (in ₹)"
                setter={setLargeCost}
                icon={<FcMoneyTransfer />}
                type="number"
              />
            </div>
          </div>
          {buttonShow === true ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <hr
                  style={{
                    width: "65vw",
                    backgroundColor: "#fe5236",
                    opacity: 0.2,
                  }}
                />
              </div>
              <label
                style={{
                  paddingLeft: "4.5%",
                  width: "100%",
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: window.innerWidth < 520 ? "2.5vw" : "1vw",
                  color: "rgba(52, 50, 76, 0.8)",
                }}
              >
                Select Categoy To Add Services
              </label>

              <div
                className="Top-tap-package"
                style={{ paddingLeft: window.innerWidth < 520 ? null : "4.1%" }}
              >
                <div className="drawerBackThing">
                  <div
                    style={{ cursor: "pointer" }}
                    className={
                      show1 === true ? "ActiveCard-package" : "InactiveCardPack"
                    }
                    onClick={b1}
                  >
                    <p className="tile1Pack">
                      Wheel <b className="tile2Pack">Services</b>
                    </p>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className={
                      show2 === true ? "ActiveCard-package" : "InactiveCardPack"
                    }
                    onClick={b2}
                  >
                    <p className="tile1Pack">
                      Exterior <b className="tile2Pack">Cleaning</b>
                    </p>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className={
                      show3 === true ? "ActiveCard-package" : "InactiveCardPack"
                    }
                    onClick={b3}
                  >
                    <p className="tile1Pack">
                      Interior <b className="tile2Pack">Cleaning</b>
                    </p>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className={
                      show4 === true ? "ActiveCard-package" : "InactiveCardPack"
                    }
                    onClick={b4}
                  >
                    <p className="tile1Pack">
                      Engine <b className="tile2Pack">Services</b>
                    </p>
                  </div>
                </div>

                {window.innerWidth < 520 ? null : (
                  <div style={{ marginLeft: "18vw" }} onClick={() => upload()}>
                    <IcoButton
                      icon={buttonLabel}
                      start="Create"
                      end="Package"
                    />
                  </div>
                )}
              </div>
              {window.innerWidth > 520 ? null : (
                <div
                  style={{ marginLeft: "5.8%", marginTop: "1vw" }}
                  onClick={() => upload()}
                >
                  <IcoButton icon={buttonLabel} start="Create" end="Package" />
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <hr
                  style={{
                    width: "55vw",
                    backgroundColor: "#fe5236",
                    opacity: 0.2,
                  }}
                />
              </div>
              {show1 === true ? (
                <div
                  style={{
                    width: "95%",
                    display: "flex",
                    flexWrap: "wrap",
                    padding: "30px",
                  }}
                  className="package-screen-container"
                >
                  {cardHandler(wheelServices, setWheelServices)}
                </div>
              ) : null}
              {show2 === true ? (
                <div
                  style={{
                    width: "95%",
                    display: "flex",
                    flexWrap: "wrap",
                    padding: "30px",
                  }}
                  className="package-screen-container"
                >
                  {cardHandler(exteriorCleaning, setExteriorCleaning)}
                </div>
              ) : null}
              {show3 === true ? (
                <div
                  style={{
                    width: "95%",
                    display: "flex",
                    flexWrap: "wrap",
                    padding: "30px",
                  }}
                  className="package-screen-container"
                >
                  {cardHandler(interiorCleaning, setInteriorCleaning)}
                </div>
              ) : null}
              {show4 === true ? (
                <div
                  style={{
                    width: "95%",
                    display: "flex",
                    flexWrap: "wrap",
                    padding: "30px",
                  }}
                  className="package-screen-container"
                >
                  {cardHandler(engineServices, setEngineServices)}
                </div>
              ) : null}
              {show5 === true ? (
                <div
                  style={{
                    width: "95%",
                    display: "flex",
                    flexWrap: "wrap",
                    padding: "30px",
                  }}
                  className="package-screen-container"
                >
                  {cardHandler(otherServices, setOtherServices)}
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      ) : null}
      {topTap2 === true ? (
        <div>
          <label
            style={{
              paddingLeft: "4.5%",
              width: "100%",
              // paddingTop: buttonShow === true ? "2%" : "8%",
              transition: "0.6s",
              fontFamily: "Montserrat",
              fontWeight: "400",
              fontSize: "20px",
              color: "rgba(52, 50, 76, 0.8)",
            }}
          >
            Enter The Required Details
          </label>
          <div className="select-package-drop">
            <div className="drop-div">
              <Drop
                val={packages}
                holder={"Select Package Name"}
                list={packageList}
                icon={<HiSelector />}
                type={"drop"}
                setter={setPackages}
              />
            </div>
            <div className="label-price">
              Small Price : ₹&nbsp;{packageCost.small}
            </div>
            <div className="label-price">
              Medium Price : ₹&nbsp;{packageCost.medium}
            </div>
            <div className="label-price">
              Large Price : ₹&nbsp;{packageCost.large}
            </div>
          </div>
          <div className="view-package-card">
            {numberPackage.map((val) => {
              return (
                <AdminPackageCard
                  name={val.name}
                  count={val.count}
                  small={val.small}
                  medium={val.medium}
                  large={val.large}
                />
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
}
