import React, { useState, useEffect } from "react";
import "./Inventory.css";
import IcoInput from "../IcoInput";
import IcoButton from "../IcoButton";
import Drop from "../Drop";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import firebase from "../../firebase/firebase";
import storage from "../../firebase/storage";
import TextField from "@material-ui/core/TextField";
import Loading from "../Loading/Loading";
import {
  FcPackage,
  FcDataRecovery,
  FcDataBackup,
  FcDataConfiguration,
  FcNext,
  FcAutomatic,
  FcBusinessman,
} from "react-icons/fc";

import {
  FcDisplay,
  FcFactory,
  FcTrademark,
  FcMinus,
  FcPlus,
} from "react-icons/fc";
import { ImUpload, ImCheckmark } from "react-icons/im";
import { RiNumbersFill } from "react-icons/ri";

import { AiOutlineNumber, AiFillMobile, AiFillCar } from "react-icons/ai";
import db from "../../firebase/database";

import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: "100%",
  },
});
export default function AddProduct() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [loading, setloading] = useState(0);

  //Product Photo Starts
  const [photo, setphoto] = useState("");
  const [display, setdisplay] = useState({});
  const allInputs = { imgUrl: "" };
  const [imageAsFile, setImageAsFile] = useState("");
  const [imageAsUrl, setImageAsUrl] = useState(allInputs);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleImageAsFile = (e) => {
    const image = e.target.files[0];
    setImageAsFile((imageFile) => image);
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      let file = e.target.files[0];
      reader.onloadend = () => {
        setdisplay({
          ...display,
          imagePreview: reader.result,
          file: file,
        });
      };
      reader.readAsDataURL(file);
    }
  };
  //Product Photo Ends

  const [itemAmount, setitemAmount] = useState(0);

  const [vechileCompanyDropList, setVechileCompanyDropList] = useState([]);
  const [updateVehicleCompany, setUpdateVechicleCompany] = useState("");
  const [vehicleNameListDrop, setVehicleNameListDrop] = useState([]);
  const [updateVehicleName, setUpdateVehicleName] = useState("");
  const [carList, setcarList] = useState([]);

  const carAddToArray = (item) => {
    if (carList.indexOf(item) === -1) {
      setcarList((prevArray) => [...prevArray, item]);
    }
  };
  const carRemoveFromArray = (name) => {
    setcarList(carList.filter((e) => e !== name));
  };

  useEffect(() => {
    db.collection("VehicleCompany")
      .get()
      .then((item) => {
        let temp = [];
        item.forEach((item) => {
          temp.push(item.data().name);
        });
        setVechileCompanyDropList(temp);
      });
    if (updateVehicleCompany !== "") {
      db.collection("VehicleCompany")
        .doc(updateVehicleCompany.value)
        .get()
        .then((item) => {
          setVehicleNameListDrop(item.data().ModelName);
        });
    }
    // Unsubscribe from events when no longer in use
  }, [updateVehicleCompany]);

  const carAddShow = (item) => {
    return (
      <div style={{ fontSize: "1vw", fontWeight: "700" }}>
        <label onClick={() => carRemoveFromArray(item)}>
          {"  "}
          {item}
          <FcMinus />
        </label>
        <br />
      </div>
    );
  };

  const [vendor, setVendor] = useState("");
  const [vendorGSTN, setVendorGSTN] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [vendorListName, setVendorListName] = useState([]);
  const [vendorListNameInit, setVendorListNameInit] = useState([]);
  const [partList, setPartList] = useState([]);
  const [productID, setProductID] = useState();
  const [productDet, setProductDet] = useState();

  const [imagePresent, setImagePresent] = useState(false);

  const [gst, setGst] = useState("");
  const [gstChange, setGstChange] = useState("");
  const [HSNnumbers, setHSNnumbers] = useState("");
  const [mrp, setMrp] = useState("");
  const [count, setCount] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [vendorPrice, setVendorPrice] = useState("");

  useEffect(() => {
    setGst(gstChange.value);
  }, [gstChange]);
  useEffect(() => {
    try {
      db.collection("products")
        .doc(productID.value)
        .get()
        .then((item) => {
          setGst(item.data().GST);
          setHSNnumbers(item.data().HSNnumber);
          setMrp(item.data().MRP);
          setCount(item.data().count);
          setName(item.data().name);
          setPrice(item.data().price);
          setVendorPrice(item.data().vendorPrice);
          if (item.data().cars) {
            setcarList(item.data().cars);
          }
          if (item.data().image) {
            setImagePresent(true);
            setdisplay({
              imagePreview: item.data().image,
            });
          }
        });
    } catch (error) {}

    return () => {};
  }, [productID]);

  useEffect(() => {
    db.collection("products")
      .get()
      .then((item) => {
        const list = [];
        item.forEach((item) => {
          list.push(item.data().partNumber);
        });
        setPartList(list);
      });

    return () => {};
  }, []);

  const updateProduct = async() => {
    setloading(20);

    if (imageAsFile !== "") {
      const uploadTask = storage
        .ref(`/ProductImages/${imageAsFile.name}`)
        .put(imageAsFile);
      uploadTask.on(
        "state_changed",
        (snapShot) => {
          console.log(snapShot);
        },
        (err) => {
          console.log(err);
        },
        () => {
          storage
            .ref("ProductImages")
            .child(imageAsFile.name)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              setImageAsUrl((prevObject) => ({
                ...prevObject,
                imgUrl: fireBaseUrl,
              }));
              setphoto(fireBaseUrl);
              setloading(50);
              db.collection("products")
                .doc(productID.value)
                .update({
                  GST: gst,
                  HSNnumber: HSNnumbers,
                  MRP: mrp,
                  count: count,
                  name: name,
                  price: price,
                  vendorPrice: vendorPrice,
                  image: fireBaseUrl,
                  cars: carList,
                })
                .then(() => {
                  alert("Successfully Updated Product Details");
                  setloading(100);
                });
            });
        }
      );
    } else if (imagePresent === true) {
      console.log("aacha vala chala")
      db.collection("products")
        .doc(productID.value)
        .update({
          GST: gst,
          HSNnumber: HSNnumbers,
          MRP: mrp,
          count: count,
          name: name,
          price: price,
          vendorPrice: vendorPrice,
          cars: carList,
        })
        .then(() => {
          alert("Successfully Updated Product Details");
          setloading(100);
        });
    }
  };

  return (
    <div>
      <Loading load={loading} />
      <Grid
        style={{
          padding: "1rem",
        }}
        container
        className={classes.root}
        spacing={2}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Drop
            list={partList}
            val={productID}
            setterList={setPartList}
            setter={setProductID}
            icon={<FcDisplay />}
            holder="Enter Product ID"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Drop
            list={["5%", "12%", "18%", "28%"]}
            val={gstChange}
            setter={setGstChange}
            icon={<FcPackage />}
            holder={gst}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <IcoInput
            value={HSNnumbers}
            holder="HSN number"
            setter={setHSNnumbers}
            icon={<FcDisplay />}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <IcoInput
            value={mrp}
            holder="MRP"
            setter={setMrp}
            icon={<FcDisplay />}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <IcoInput
            value={count}
            holder="Count"
            setter={setCount}
            icon={<FcDisplay />}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <IcoInput
            value={name}
            holder="Name"
            setter={setName}
            icon={<FcDisplay />}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <IcoInput
            value={price}
            holder="Price"
            setter={setPrice}
            icon={<FcDisplay />}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <IcoInput
            value={vendorPrice}
            holder="Vendor Price"
            setter={setVendorPrice}
            icon={<FcDisplay />}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}></Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Drop
            list={vechileCompanyDropList}
            val={updateVehicleCompany}
            setter={setUpdateVechicleCompany}
            icon={<AiFillCar />}
            holder={"Vehicle Company"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Drop
              list={vehicleNameListDrop}
              val={updateVehicleName}
              setter={setUpdateVehicleName}
              icon={<AiFillCar />}
              holder={"Vehicle Model"}
            />{" "}
            <FcPlus
              style={{ fontSize: "2.5vw", marginLeft: "1.5vw" }}
              onClick={() => carAddToArray(updateVehicleName.value)}
            ></FcPlus>
          </div>
        </Grid>
      </Grid>

      <div
        style={{
          padding: "1vw",
          display: "block",
          flex: 1,
          width: "30%",
          marginLeft: "1rem",
          backgroundColor: "white",
          borderRadius: "1vw",
        }}
      >
        <label>Serviceable Cars</label>
        <br />
        {carList.length > 0 ? carList.map(carAddShow) : <div>No Car</div>}
      </div>

      <div
        style={{
          marginTop: "1vw",
          padding: "1vw",
          display: "block",
          flex: 1,
          width: "50%",
          marginLeft: "1rem",
          backgroundColor: "white",
          borderRadius: "1vw",
        }}
      >
        <label>Product Display Photo (White Background only)</label>
        <br />
        <div>
          <input type="file" onChange={handleImageAsFile} accept="image/*" />
        </div>

        {display.imagePreview ? (
          <img
            alt="Product"
            style={{ height: "10vw", aspectRatio: "contain" }}
            src={display.imagePreview}
          />
        ) : null}
      </div>

      <div
        style={{ position: "absolute", right: "5vw", bottom: "5vh" }}
        onClick={updateProduct}
      >
        <IcoButton end="Details" start="Update" icon={<FcDataBackup />} />
      </div>
    </div>
  );
}
