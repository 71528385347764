import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Drop from "../Components/Drop";
import IcoButton from "../Components/IcoButton";
import { FcPackage, FcApproval, FcMoneyTransfer } from "react-icons/fc";
import IcoInput from "../Components/IcoInput";
import db from "../firebase/database";
import firebase from "../firebase/firebase";
import ProductDisplay from "./ProductDisplay.jsx";
export default function ProductCard({
  item,
  bill,
  setter,
  vehicleTypeDecider,
}) {
  const [productDikhao, setproductDikhao] = useState(false);
  const [labourDikhao, setlabourDikhao] = useState(false);
  const [dentingDikhao, setdentingDikhao] = useState(false);
  const [paintingDikhao, setpaintingDikhao] = useState(false);
  //Products Vala Pura Code Starts
  const [product, setProduct] = useState("");
  const [productList, setProductList] = useState([]);
  const [productDet, setProductDet] = useState();
  const [toSend, setToSend] = useState({});
  const [tem, setTemp] = useState(false);

  
  useEffect(() => {
  try {
if(productDikhao===true){
  db.collection("products")
  .doc(product.value)
  .get()
  .then((item) => {
    if (item.data().count > 0) {
      setProductDet(item.data());
    }
    else{
      alert("Running out of Stock")
    }
  });
}
} catch (error) {}
  }, [product,productDikhao]);

  useEffect(() => {
    if (productDet) {
      setToSend({
        name: productDet.name + "(P-" + productDet.GST + ")",
        large: productDet.MRP,
        medium: productDet.MRP,
        small: productDet.MRP,
        time: 30,
        category: "Products",
        MRP: productDet.MRP,
        price: productDet.price,
        GST: productDet.GST,
        PartNumber: productDet.partNumber,
      });
    }
  }, [productDet]);

  useEffect(() => {
    if (productDikhao === true) {
      db.collection("extras")
        .doc("products")
        .get()
        .then((item) => {
          const list = [];
          item.data().products.forEach((element) => {
            list.push(element.partNumber);
          });
          setProductList(list);
        });
    }
  }, [productDikhao]);
  console.log("product not come till clik : " + productList);
  //Products Vala Pura Code Ends

  //Labour Vala Pura Code Startsv
  const [labourCost, setLabourCost] = useState();
  const [labourCostList, setLabourCostList] = useState([]);
  const [labourCostDet, setLabourCostDet] = useState();
  const [toSendLabourCost, setToSendLabourCost] = useState({});
  const [tem2, setTemp2] = useState(false);

  useEffect(() => {
    if (labourDikhao === true) {
      try {
        db.collection("labourCost")
          .doc(labourCost.value)
          .get()
          .then((item) => {
            setLabourCostDet(item.data());
          });
      } catch (error) {}
    }
  }, [labourCost, labourDikhao]);

  useEffect(() => {
    if (labourCostDet) {
      setToSendLabourCost({
        name: labourCostDet.name + "(Labour)",
        price:
          vehicleTypeDecider === "small"
            ? labourCostDet.small
            : vehicleTypeDecider === "medium"
            ? labourCostDet.medium
            : vehicleTypeDecider === "large"
            ? labourCostDet.large
            : 0,
        time: labourCostDet.time,
        category: "Labour Cost",
        small: labourCostDet.small,
        medium: labourCostDet.medium,
        large: labourCostDet.large,
      });
    }
  }, [labourCostDet, vehicleTypeDecider]);

  useEffect(() => {
    if (labourDikhao === true) {
      db.collection("extras")
        .doc("services")
        .get()
        .then((item) => {
          const list = [];
          item.data().labourCost.forEach((element) => {
            list.push(element.name);
          });

          setLabourCostList(list);
        });
    }
  }, [labourDikhao]);
  //Labour Vala Pura Code Ends

  //Painting Vala Pura Code Starts
  const [paintCost, setPaintCost] = useState();
  const [paintCostList, setPaintCostList] = useState([]);
  const [paintCostDet, setPaintCostDet] = useState();
  const [toSendPaintCost, setToSendPaintCost] = useState({});
  const [tem3, setTem3] = useState(false);

  useEffect(() => {
    if (paintingDikhao === true) {
      try {
        db.collection("paintCost")
          .doc(paintCost.value)
          .get()
          .then((item) => {
            setPaintCostDet(item.data());
          });
      } catch (error) {}
    }
  }, [paintCost, paintingDikhao]);

  useEffect(() => {
    if (paintCostDet) {
      setToSendPaintCost({
        name: paintCostDet.name + "(Painting)",
        price:
          vehicleTypeDecider === "small"
            ? paintCostDet.small
            : vehicleTypeDecider === "medium"
            ? paintCostDet.medium
            : vehicleTypeDecider === "large"
            ? paintCostDet.large
            : 0,
        time: paintCostDet.time,
        category: "Painting Service",
        small: paintCostDet.small,
        medium: paintCostDet.medium,
        large: paintCostDet.large,
      });
    }
  }, [paintCostDet, vehicleTypeDecider]);

  useEffect(() => {
    if (paintingDikhao === true) {
      db.collection("extras")
        .doc("services")
        .get()
        .then((item) => {
          const list = [];
          item.data().paintCost.forEach((element) => {
            list.push(element.name);
          });

          setPaintCostList(list);
        });
    }
  }, [paintingDikhao]);
  //Painting Vala Pura Code Ends

  //Denting Vala Pura Code Starts
  const [dentEnterCost, setDentEnterCost] = useState();
  const [dentCost, setDentCost] = useState();
  const [dentCostList, setDentCostList] = useState([]);
  const [dentCostDet, setDentCostDet] = useState();
  const [toSendDentCost, setToSendDentCost] = useState({});
  const [tem4, setTem4] = useState(false);

  useEffect(() => {
    if (dentingDikhao === true) {
      try {
        db.collection("paintCost")
          .doc(dentCost.value)
          .get()
          .then((item) => {
            setDentCostDet(item.data());
          });
      } catch (error) {}
    }
  }, [dentCost, dentingDikhao]);

  useEffect(() => {
    if (dentCostDet) {
      setToSendDentCost({
        name: dentCostDet.name + "(Denting)",
        price: dentEnterCost,
        time: 30,
        category: "Denting Service",
        small: dentEnterCost,
        medium: dentEnterCost,
        large: dentEnterCost,
      });
    }
  }, [dentCostDet, dentEnterCost]);

  useEffect(() => {
    if (dentingDikhao === true) {
      db.collection("paintCost")
        .get()
        .then((item) => {
          const list = [];
          item.forEach((element) => {
            list.push(element.data().name);
          });

          setDentCostList(list);
        });
    }
  }, [dentingDikhao]);
  //Denting Vala Pura Code Ends

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: "100%",
      }}
    >
      {/* First Column */}
      <div style={{ display: "flex", flex: 1 }}>
        <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
          <div
            style={{
              display: "inline-block",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: window.innerWidth < 520 ? "2.5vw" : "1vw",
                padding: "1.25vw",
              }}
            >
              Add <b>Products</b> from Product List Below
            </label>
            <div
              style={{ padding: "1.5vw" }}
              onClick={() => setproductDikhao(true)}
            >
              <Drop
                list={productList}
                val={product}
                setter={setProduct}
                icon={<FcPackage />}
                holder="Product Number"
              />
            </div>
            <div
              style={{
                margin: "1.5vw",
              }}
              onClick={() => {
                setTemp(!tem);
                if (!bill.includes(toSend)) {
                  setter([...bill, toSend]);
                } else {
                  let tempArr = bill;
                  const index = tempArr.indexOf(toSend);
                  if (index > -1) {
                    tempArr.splice(index, 1);
                  }
                  setter([...tempArr]);
                }
              }}
            >
              {productDet ? (
                <>
                  <ProductDisplay item={toSend} />
                  <IcoButton
                    end="Product"
                    start={!bill.includes(toSend) ? "Add" : "Remove "}
                    icon={<FcApproval />}
                  />
                </>
              ) : null}
            </div>
          </div>
          <div
            style={{
              display: "inline-block",
              flex: 1,
              backgroundColor: "#CDCDCD",
              paddingLeft: "0.75vw",
            }}
          >
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: window.innerWidth < 520 ? "2.5vw" : "1vw",
                padding: "1.25vw",
              }}
            >
              Add <b>Labour</b> Cost For Required Services
            </label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <div
                style={{ padding: "1.5vw" }}
                onClick={() => setlabourDikhao(true)}
              >
                <Drop
                  list={labourCostList}
                  val={labourCost}
                  setter={setLabourCost}
                  icon={<FcPackage />}
                  holder="Service Name"
                />
              </div>
              <div
                style={{
                  margin: "1.25vw",
                }}
                onClick={() => {
                  setTemp2(!tem2);
                  if (!bill.includes(toSendLabourCost)) {
                    setter([...bill, toSendLabourCost]);
                  } else {
                    let tempArr = bill;
                    const index = tempArr.indexOf(toSendLabourCost);
                    if (index > -1) {
                      tempArr.splice(index, 1);
                    }
                    setter([...tempArr]);
                  }
                }}
              >
                {labourCostDet ? (
                  <>
                    <ProductDisplay item={toSendLabourCost} />
                    <IcoButton
                      end="Labour"
                      start={
                        !bill.includes(toSendLabourCost) ? "Add" : "Remove "
                      }
                      icon={<FcApproval />}
                    />
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Second Column */}
      <div style={{ display: "flex", flex: 1 }}>
        <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
          <div
            style={{
              display: "inline-block",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: window.innerWidth < 520 ? "2.5vw" : "1vw",
                padding: "1.25vw",
              }}
            >
              Add <b>Denting</b> Service from List Below
            </label>
            <div
              style={{ padding: "1.5vw" }}
              onClick={() => setdentingDikhao(true)}
            >
              <Drop
                list={dentCostList}
                val={dentCost}
                setter={setDentCost}
                icon={<FcPackage />}
                holder="Denting Part"
              />
            </div>
            <div style={{ marginLeft: "1.5vw" }}>
              {dentCostDet ? (
                <div style={{ marginBottom: "1vw" }}>
                  <label
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: window.innerWidth < 520 ? "1.75vw" : "1vw",
                    }}
                  >
                    Enter Denting Cost Below (₹)
                  </label>
                  <IcoInput
                    value={dentEnterCost}
                    holder={"Denting Cost"}
                    icon={<FcMoneyTransfer />}
                    type={"number"}
                    setter={setDentEnterCost}
                    width="50"
                    fontsize={"1vw"}
                  />
                  <div
                    style={{ marginTop: "1.5vw" }}
                    onClick={() => {
                      setTem4(!tem4);
                      if (!bill.includes(toSendDentCost)) {
                        setter([...bill, toSendDentCost]);
                      } else {
                        let tempArr = bill;
                        const index = tempArr.indexOf(toSendDentCost);
                        if (index > -1) {
                          tempArr.splice(index, 1);
                        }
                        setter([...tempArr]);
                      }
                    }}
                  >
                    <IcoButton
                      end="Denting"
                      start={!bill.includes(toSendDentCost) ? "Add" : "Remove "}
                      icon={<FcApproval />}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div
            style={{
              display: "inline-block",
              flex: 1,
              backgroundColor: "#CDCDCD",
              paddingLeft: "0.75vw",
            }}
          >
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: window.innerWidth < 520 ? "2.5vw" : "1vw",
                padding: "1.25vw",
              }}
            >
              Add <b>Painting</b> Service from List Below
            </label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <div
                style={{ padding: "1.5vw" }}
                onClick={() => setpaintingDikhao(true)}
              >
                <Drop
                  list={paintCostList}
                  val={paintCost}
                  setter={setPaintCost}
                  icon={<FcPackage />}
                  holder="Painting Service"
                />
              </div>
              <div
                style={{
                  margin: "1.25vw",
                }}
                onClick={() => {
                  setTem3(!tem3);
                  if (!bill.includes(toSendPaintCost)) {
                    setter([...bill, toSendPaintCost]);
                  } else {
                    let tempArr = bill;
                    const index = tempArr.indexOf(toSendPaintCost);
                    if (index > -1) {
                      tempArr.splice(index, 1);
                    }
                    setter([...tempArr]);
                  }
                }}
              >
                {paintCostDet ? (
                  <>
                    <ProductDisplay item={toSendPaintCost} />
                    <IcoButton
                      end="Painting"
                      start={
                        !bill.includes(toSendPaintCost) ? "Add" : "Remove "
                      }
                      icon={<FcApproval />}
                    />
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
