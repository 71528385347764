import React,{useState,useEffect} from "react";
import LoadingBar from "react-top-loading-bar";

export default function Loading({load,rang,uchai}){
    const [progress, setProgress] = useState(0);
    useEffect(() => {
        setProgress(load);
    }, [load])
    return(
        <LoadingBar
          color={rang||"#FE5236"}
          progress={progress}
          height={uchai||5}
          loaderSpeed={500}
          onLoaderFinished={() => {
            setProgress(0);
          }}
        />
    );
}
