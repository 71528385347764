import React, { useEffect, useState } from "react";
import IcoInput from "../Components/IcoInput";
import IcoButton from "../Components/IcoButton";
import Drop from "../Components/Drop";
import moment from "moment";
import "./CreateCustomer.css";
import { FcPackage, FcPrint,FcMoneyTransfer,  FcCalendar,FcDisplay } from "react-icons/fc";
import { BiCreditCardFront } from "react-icons/bi";
import { AiOutlineNumber, AiFillMobile, AiFillCar } from "react-icons/ai";
import db from "../firebase/database";
import firebase from "../firebase/firebase";
import { useHistory } from "react-router-dom";
import Loading from "../Components/Loading/Loading";

export default function CreateCustomerEmployee() {
  const [loading, setloading] = useState(0);
  const history = useHistory();

  const [selected, setSelected] = React.useState("");
  const [selected2, setSelected2] = React.useState("");

  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  const [empPin, setEmpPin] = useState();
  const [empName, setEmpName] = useState();
  const [showEmpName, setshowEmpName] = useState(false);
  useEffect(() => {
    if (empPin) {
      db.collection("employee")
        .doc(empPin)
        .get()
        .then((doc) => {
          if (doc.exists) {
            if (doc.data().employeePin !== "deactivated") {
              setEmpName(doc.data().employeeName);
              setshowEmpName(true);
            } else if (doc.data().employeePin === "deactivated") {
              setEmpName("Deactivated Employee, Contact Owner");
              setshowEmpName(true);
            } else {
              setEmpName("No Employee With : " + empPin);
            }
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  }, [empPin]);

  // const [selected, setSelected] = React.useState("");
  // const [selected2, setSelected2] = React.useState("");

  const [customerName, setcustomerName] = useState("");
  const [customerMobile, setcustomerMobile] = useState("");
  const [customerEmail, setcustomerEmail] = useState("");
  const [customerAddress, setcustomerAddress] = useState("");
  const [vehicleCompany, setvehicleCompany] = useState("");
  const [vehicleCompany1, setvehicleCompany1] = useState("");
  const [vehicalList, setVehivalList] = useState([]);
  const [vehicalList1, setVehivalList1] = useState([]);
  const [vehicleName, setvehicleName] = useState("");
  const [vehicleName1, setvehicleName1] = useState("");
  const [vehicleNameList, setVehicleNameList] = useState([]);
  const [vehicleNameList1, setVehicleNameList1] = useState([]);
  const [vehicleType, setvehicleType] = useState("");
  const [vehicleType1, setvehicleType1] = useState("");
  const [vehicleNumber, setvehicleNumber] = useState("");
  const [vehicleKM, setvehicleKM] = useState("");
  const [vehicleNumber1, setvehicleNumber1] = useState("");
  const [vehicleKM1, setvehicleKM1] = useState("");
  const [iscNumber, setiscNumber] = useState();
  const [servicePackage, setservicePackage] = useState({});
  const [packageList, setPackageList] = useState([]);
  const [iscNum, setiscNum] = useState([]);
  const [data, setData] = useState([]);
  const [gotData, setGotData] = useState(false);
  const priceList = ["small", "medium", "large"];
  const [customerBackDate, setCustomerBackDate] = useState('');

  const [updateName, setUpdateName] = useState("");
  const [updateNumber, setUpdateNumber] = useState("");
  const [updateEmail, setUpdateEmail] = useState("");
  const [updateAddress, setUpdateAddress] = useState("");
  const [updateVehicleCompany, setUpdateVechicleCompany] = useState("");
  const [updateVehicleName, setUpdateVehicleName] = useState("");
  const [updateVehicleType, setUpdateVehicleType] = useState("");
  const [updateVehicleNumber, setUpdateVehicleNumber] = useState("");
  const [updateVehicleKM, setUpdateVehicleKM] = useState("");
  const [vechileCompanyDropList, setVechileCompanyDropList] = useState([]);
  const [vehicleNameListDrop, setVehicleNameListDrop] = useState([]);
  const [packPriceForShow, setpackPriceForShow] = useState();

  const [viewallReceipts, setviewallReceipts] = useState([]);

  const [totalCustomerServiceCount, setTotalCustomerServiceCount] = useState();

  const [modeOfTransaction, setmodeOfTransaction] = useState("Cash")
  const [dateFrom, setdateFrom] = useState("");
  const [dateTo, setdateTo] = useState("");

  let formatDateFrom = moment(dateFrom).format("MM/DD/YYYY");
  let formatDateTo = moment(dateTo).add(1, 'days').format("MM/DD/YYYY");

  let ShowformatDateFrom = moment(dateFrom).format("DD/MM/YYYY");
  let ShowformatDateTo = moment(dateTo).format("DD/MM/YYYY");

  const [showCustomerButton, setshowCustomerButton] = useState(false);
  useEffect(() => {
    try {
      db.collection("VehicleCompany")
        .doc(vehicleCompany.value)
        .get()
        .then((item) => {
          item.data().VehicleName.forEach((det) => {
            if (det.name === vehicleName.value) {
              setvehicleType(det.type);
            }
          });
        });
        if (selected.value === "SILVER 2 CAR PACKAGE") {
          db.collection("VehicleCompany")
            .doc(vehicleCompany1.value)
            .get()
            .then((item) => {
              item.data().VehicleName.forEach((det) => {
                if (det.name === vehicleName1.value) {
                  setvehicleType1(det.type);
                }
              });
            });
        }
    } catch (error) {
      console.log(error);
    }
  }, [vehicleName, vehicleCompany, vehicleType,selected,vehicleCompany1,vehicleName1]);

  useEffect(() => {
    if (
      customerName &&
      customerMobile &&
      vehicleCompany &&
      vehicleKM &&
      vehicleName &&
      vehicleNumber &&
      showEmpName
    ) {
      setshowCustomerButton(true);
    }
    if (empName === "Deactivated Employee, Contact Owner") {
      setshowCustomerButton(false);
    }
  }, [
    customerName,
    customerMobile,
    vehicleCompany,
    vehicleKM,
    vehicleName,
    vehicleNumber,
    showEmpName,
    empName,
  ]);

  //Invoice Fetch and Format Code Starts Here
  const [invoiceNum, setInvoiceNum] = useState();
  const [invoiceFormatted, setInvoiceFormatted] = useState("");
  useEffect(() => {
    db.collection("extras")
      .doc("invoice")
      .get()
      .then(function (doc) {
        setInvoiceNum(doc.data().base);
        setInvoiceFormatted("IN" + invoiceNum);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }, [invoiceNum]);
  //Invoice Fetch and Format Code Ends Here

  useEffect(() => {
    db.collection("VehicleCompany")
      .get()
      .then((item) => {
        let temp = [];
        item.forEach((item) => {
          temp.push(item.data().name);
        });
        setVechileCompanyDropList(temp);
      });

    if (updateVehicleCompany !== "") {
      db.collection("VehicleCompany")
        .doc(updateVehicleCompany.value)
        .get()
        .then((item) => {
          setVehicleNameListDrop(item.data().ModelName);
        });
    }
    // Unsubscribe from events when no longer in use
  }, [updateVehicleCompany]);

  const label = <FcPackage />;

  useEffect(() => {
    db.collection("packages")
      .get()
      .then((item) => {
        let temp = [];
        item.forEach((item) => {
          temp.push(item.data().name);
        });
        setPackageList(temp);
      });
  }, []);

  useEffect(() => {
    db.collection("VehicleCompany")
      .get()
      .then((item) => {
        let temp = [];
        item.forEach((item) => {
          temp.push(item.data().name);
        });
        setVehivalList(temp);
        setVehivalList1(temp);
      });

    if (vehicleCompany !== "") {
      db.collection("VehicleCompany")
        .doc(vehicleCompany.value)
        .get()
        .then((item) => {
          setVehicleNameList(item.data().ModelName);
        });
    }
    if (vehicleCompany1 !== "") {
      db.collection("VehicleCompany")
        .doc(vehicleCompany1.value)
        .get()
        .then((item) => {
          setVehicleNameList1(item.data().ModelName);
        });
    }
    const subscriber = db.collection("packages").onSnapshot((querySnapshot) => {
      const tmp = [];

      querySnapshot.forEach((documentSnapshot) => {
        tmp.push({
          ...documentSnapshot.data(),
          key: documentSnapshot.id,
        });
      });

      setservicePackage(tmp);
    });

    // Unsubscribe from events when no longer in use
    return () => subscriber();
  }, [vehicleCompany,vehicleCompany1]);
  db.collection("extras")
    .doc("customerISC")
    .get()
    .then(function (doc) {
      setiscNumber(doc.data().baseISC);
    })
    .catch(function (error) {});

  const [ser, setSer] = useState([]);

  function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  // const uploadCustomer = async () => {
  //   const index = findWithAttr(servicePackage, "name", selected.value);
  //   // evt.preventDefault();
  //   try {
  //     await db
  //       .collection("customers")
  //       .doc("ISC-" + iscNumber)
  //       .set({
  //         NAME: customerName,
  //         MOBILE: customerMobile,
  //         EMAIL: customerEmail,
  //         ADDRESS: customerAddress,
  //         COMPANY: vehicleCompany.value,
  //         MODEL: vehicleName.value,
  //         SIZE: vehicleType,
  //         VEHICLENUMBER: vehicleNumber,
  //         KILOMETERDRIVEN: vehicleKM,
  //         PACKAGE: selected.value,
  //         SERVICES: totalCustomerServiceCount,
  //         EXPIRY: packageExpiry,
  //         ISCNO: "ISC-" + iscNumber,
  //         PURCHASED: date + "/" + month + "/" + year,
  //         LASTVISIT: date + "/" + month + "/" + year,
  //         timestamp: firebase.firestore.FieldValue.serverTimestamp(),
  //       })
  //       .then(() => {
  //         console.log("Customer Created");
  //         db.collection("sales")
  //           .doc(invoiceFormatted)
  //           .set({
  //             invoice: invoiceFormatted,
  //             vehicleNumber: vehicleNumber,
  //             customerName: customerName,
  //             customerMobile: customerMobile,
  //             package: selected.value,
  //             mode: "Cash",
  //             bill: [
  //               {
  //                 name: selected.value,
  //                 small: packPriceForShow,
  //                 medium: packPriceForShow,
  //                 large: packPriceForShow,
  //                 key: selected.value,
  //               },
  //             ],
  //             employeeName: empName,
  //             employeePin: empPin,
  //             approve: false,
  //             date: date + "/" + month + "/" + year,
  //             amount: packPriceForShow,
  //             timestamp: firebase.firestore.FieldValue.serverTimestamp(),
  //           });
  //         console.log("Sales Added");
  //       })
  //       .then(() => {
  //         db.collection("employee")
  //           .doc(empPin)
  //           .collection("transactions")
  //           .doc()
  //           .set({
  //             invoice: invoiceFormatted,
  //             vehicleNumber: vehicleNumber,
  //             customerName: customerName,
  //             customerMobile: customerMobile,
  //             date: date + "/" + month + "/" + year,
  //             amount: packPriceForShow,
  //             timestamp: firebase.firestore.FieldValue.serverTimestamp(),
  //             employeeName: empName,
  //             approve: false,
  //             employeePin: empPin,
  //           });
  //         console.log("Employee Paisa Cuted");
  //       })
  //       .then(() => {
  //         const temp = servicePackage[index].services || [];
  //         temp.forEach(async (item) => {
  //           await dbmode: modeOfTransaction.value,
  //             .collection("services")
  //             .doc(item.name)
  //             .set(item);
  //         });
  //         console.log("Service Subcollection Made");
  //         db.collection("extras")
  //           .doc("customerISC")
  //           .update({
  //             baseISC: firebase.firestore.FieldValue.increment(+1),
  //           });
  //         console.log("ISC Series Up");
  //         db.collection("extras")
  //           .doc("invoice")
  //           .update({
  //             base: firebase.firestore.FieldValue.increment(+1),
  //           });
  //         console.log("Invoice Series Up");
  //       })
  //       .then(() => {
  //         console.log('Next Page')
  //         history.push({
  //           pathname: "/receipt",
  //           billList: [
  //             {
  //               name: selected.value,
  //               small: packPriceForShow,
  //               medium: packPriceForShow,
  //               large: packPriceForShow,
  //             },
  //           ],
  //           invoice: invoiceFormatted,
  //           vehicleNumber: vehicleNumber,
  //           customerName: customerName,
  //           customerMobile: customerMobile,
  //           vehicleCompany: vehicleCompany.value,
  //           vehicleModel: vehicleName.value,
  //           packageDetails: selected.value,
  //           date: date + "/" + month + "/" + year,
  //           amount: packPriceForShow,
  //           gst: 0,
  //           isc: "ISC-" + iscNumber,
  //           totalAmount: packPriceForShow,
  //           vehicleSize: vehicleType,
  //         });
  //       });
  //   } catch (error) {}
  // };

  const uploadCustomer = () => {
    const index = findWithAttr(servicePackage, "name", selected.value);
    // evt.preventDefault();
    try {
      setloading(20);

      db.collection("customers")
        .doc("ISC-" + iscNumber)
        .set({
          NAME: customerName,
          MOBILE: customerMobile,
          EMAIL: customerEmail,
          ADDRESS: customerAddress,
          COMPANY: vehicleCompany.value,
          MODEL: vehicleName.value,
          SIZE: vehicleType,
          VEHICLENUMBER: vehicleNumber,
          KILOMETERDRIVEN: vehicleKM,
          PACKAGE: selected.value,
          LASTPRICE: selected.value==="SILVER 2 CAR PACKAGE" ? packPriceForShow*2 : packPriceForShow,
          SERVICES: totalCustomerServiceCount,
          EXPIRY: packageExpiry,
          ISCNO: "ISC-" + iscNumber,
          PURCHASED: moment(customerBackDate).format("DD/MM/YYYY"),
          LASTVISIT: date + "/" + month + "/" + year,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          const dateee = new Date();

          // db.collection("extras")
          //   .doc("sales")
          //   .update({
          //     total: firebase.firestore.FieldValue.increment(
          //       parseInt(parseInt(packPriceForShow))
          //     ),
          //     sales: firebase.firestore.FieldValue.arrayUnion({
          //       approve: false,
          //       invoice: invoiceFormatted,
          //       vehicleNumber: vehicleNumber,
          //       vehicleCompany: vehicleCompany.value, //need
          //       vehicleModel: vehicleName.value, //need
          //       vehicleKilometer: vehicleKM,
          //       gst: 0, //need
          //       discount: 0, //need
          //       totalAmount: selected.value==="SILVER 2 CAR PACKAGE" ? packPriceForShow*2 : packPriceForShow, //need
          //       count: totalCustomerServiceCount, //need
          //       totalServiceCount: totalCustomerServiceCount, //need
          //       customerName: customerName,
          //       customerMobile: customerMobile,
          //       vehicleSize: vehicleType,
          //       package: selected.value,
          //       mode: modeOfTransaction.value||"Cash",
          //       bill: [
          //         {
          //           name: selected.value,
          //           small: selected.value==="SILVER 2 CAR PACKAGE" ? packPriceForShow*2 : packPriceForShow,
          //           medium: selected.value==="SILVER 2 CAR PACKAGE" ? packPriceForShow*2 : packPriceForShow,
          //           large: selected.value==="SILVER 2 CAR PACKAGE" ? packPriceForShow*2 : packPriceForShow,
          //           amount: selected.value==="SILVER 2 CAR PACKAGE" ? packPriceForShow*2 : packPriceForShow,
          //           key: selected.value,
          //           category: "Create Customer",
          //         },
          //       ],
          //       employeeName: empName,
          //       employeePin: empPin,
          //       date: date + "/" + month + "/" + year,
          //       amount: selected.value==="SILVER 2 CAR PACKAGE" ? packPriceForShow*2 : packPriceForShow,
          //       // timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          //       purchase: moment(customerBackDate).format("DD/MM/YYYY"),
          //       expiry: packageExpiry,
          //     }),
          //   });
          setloading(40);
          console.warn("Customer create");
          db.collection("sales")
            .doc(invoiceFormatted)
            .set({
              approve: false,
              invoice: invoiceFormatted,
              vehicleNumber: vehicleNumber,
              vehicleCompany: vehicleCompany.value, //need
              vehicleModel: vehicleName.value, //need
              vehicleKilometer: vehicleKM,
              gst: 0, //need
              discount: 0, //need
              totalAmount: selected.value==="SILVER 2 CAR PACKAGE" ? packPriceForShow*2 : packPriceForShow, //need
              count: totalCustomerServiceCount, //need
              totalServiceCount: totalCustomerServiceCount, //need
              customerName: customerName,
              customerMobile: customerMobile,
              vehicleSize: vehicleType,
              package: selected.value,
              mode: modeOfTransaction.value||"Cash",
              bill: [
                {
                  name: selected.value,
                  small: selected.value==="SILVER 2 CAR PACKAGE" ? packPriceForShow*2 : packPriceForShow,
                  medium: selected.value==="SILVER 2 CAR PACKAGE" ? packPriceForShow*2 : packPriceForShow,
                  large: selected.value==="SILVER 2 CAR PACKAGE" ? packPriceForShow*2 : packPriceForShow,
                  amount: selected.value==="SILVER 2 CAR PACKAGE" ? packPriceForShow*2 : packPriceForShow,
                  key: selected.value,
                  category: "Create Customer",
                },
              ],
              employeeName: empName,
              employeePin: empPin,
              date: date + "/" + month + "/" + year,
              amount: selected.value==="SILVER 2 CAR PACKAGE" ? packPriceForShow*2 : packPriceForShow,
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              purchase: moment(customerBackDate).format("DD/MM/YYYY"),
              expiry: packageExpiry,
            });
          console.warn("Sale create");
        })
        .then(() => {
          setloading(60);
          const temp = servicePackage[index].services || [];
          temp.forEach(async (item) => {
            await db
              .collection("customers")
              .doc("ISC-" + iscNumber)
              .collection("services")
              .doc(item.name)
              .set(item);
          });
          console.warn("Service Sub create");
          db.collection("extras")
            .doc("customerISC")
            .update({
              baseISC: firebase.firestore.FieldValue.increment(+1),
            }).then(()=>{
              if(selected.value==="SILVER 2 CAR PACKAGE"){
                //Second ISC Starts Here
               db
        .collection("customers")
        .doc("ISC-" + tempISC2) //change to base isc
        .set({
          NAME: customerName,
          MOBILE: customerMobile,
          EMAIL: customerEmail,
          ADDRESS: customerAddress,
          COMPANY: vehicleCompany1.value,
          MODEL: vehicleName1.value,
          SIZE: vehicleType1,
          VEHICLENUMBER: vehicleNumber1,
          KILOMETERDRIVEN: vehicleKM1,
          PACKAGE: selected.value,
          SERVICES: totalCustomerServiceCount,
          EXPIRY: packageExpiry,
          ISCNO: "ISC-" + tempISC2,
          PURCHASED: moment(customerBackDate).format("DD/MM/YYYY"),
          LASTVISIT: date + "/" + month + "/" + year,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          const dateee = new Date();
          console.warn("Customer create");
          // db.collection("extras")
          //   .doc("sales")
          //   .update({
          //     customer: firebase.firestore.FieldValue.arrayUnion({
          //       invoice: invoiceFormatted,
          //       vehicleNumber: vehicleNumber1,
          //       vehicleCompany: vehicleCompany1.value, //need
          //       vehicleModel: vehicleName1.value, //need
          //       gst: 0, //need
          //       discount: 0, //need
          //       totalAmount: packPriceForShow, //need
          //       count: totalCustomerServiceCount/2, //need
          //       totalServiceCount: totalCustomerServiceCount/2, //need
          //       customerName: customerName,
          //       customerMobile: customerMobile,
          //       vehicleSize: vehicleType1,
          //       package: selected.value,
          //       mode: modeOfTransaction.value || "Cash",
          //       bill: [
          //         {
          //           name: selected.value,
          //           small: packPriceForShow,
          //           medium: packPriceForShow,
          //           large: packPriceForShow,
          //           amount: packPriceForShow,
          //           key: selected.value,
          //         },
          //       ],
          //       employeeName: "Admin",
          //       employeePin: "0",
          //       approve: true,
          //       date: date + "/" + month + "/" + year,
          //       amount: packPriceForShow,
          //       // timestamp: dateee,
          //       purchase: moment(customerBackDate).format("DD/MM/YYYY"),
          //       expiry: packageExpiry,
          //     }),
          //   });
          // db.collection("sales")
          //   .doc(invoiceFormatted)
          //   .set({
          //     invoice: invoiceFormatted,
          //     vehicleNumber: vehicleNumber+"/"+vehicleNumber1,
          //     vehicleCompany: vehicleCompany.value+"/"+vehicleCompany1.value, //need
          //     vehicleModel: vehicleName.value+"/"+vehicleName1.value, //need
          //     gst: 0, //need
          //     discount: 0, //need
          //     totalAmount: packPriceForShow, //need
          //     count: totalCustomerServiceCount, //need
          //     totalServiceCount: totalCustomerServiceCount, //need
          //     customerName: customerName,
          //     customerMobile: customerMobile,
          //     vehicleSize: vehicleType+"/"+vehicleType1,
          //     package: selected.value,
          //     mode: modeOfTransaction.value || "Cash",
          //     bill: [
          //       {
          //         name: selected.value,
          //         small: packPriceForShow,
          //         medium: packPriceForShow,
          //         large: packPriceForShow,
          //         amount: packPriceForShow,
          //         key: selected.value,
          //       },
          //     ],
          //     employeeName: "Admin",
          //     employeePin: "0",
          //     approve: true,
          //     date: date + "/" + month + "/" + year,
          //     amount: packPriceForShow,
          //     timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          //     purchase: moment(customerBackDate).format("DD/MM/YYYY"),
          //     expiry: packageExpiry,
          //   });
          console.warn("Sale create");
        })
        .then(() => {
          const temp = servicePackage[index].services || [];
          temp.forEach(async (item) => {
            await db
              .collection("customers")
              .doc("ISC-" + tempISC2)
              .collection("services")
              .doc(item.name)
              .set(item);
          });
          console.warn("Service Sub create");
          db.collection("extras")
            .doc("customerISC")
            .update({
              baseISC: firebase.firestore.FieldValue.increment(+1),
            })
        })
                //Second ISC End Here
              }
            })
          console.warn("ISC up create");
          setloading(80);
          db.collection("extras")
            .doc("invoice")
            .update({
              base: firebase.firestore.FieldValue.increment(+1),
            });
          console.warn("Invoice up create");
          setloading(90);
        })
        .then(() => {
          setloading(100);
          console.warn("Bye");
          db.collection('packages').doc(selected.value).collection('history').doc().set({
            name: selected.value,
            price: selected.value === "SILVER 2 CAR PACKAGE"
            ? parseInt(packPriceForShow * 2,10)
            : parseInt(packPriceForShow,10),
            type: 'Paid',
            category: 'Package',
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
          })
          if(selected.value==="SILVER 2 CAR PACKAGE"){
            history.push({
              pathname: "/receipt",
              mode: modeOfTransaction.value || "Cash",
              billList: [
                {
                  name: selected.value,
                  small: packPriceForShow*2,
                  medium: packPriceForShow*2,
                  large: packPriceForShow*2,
                  key: selected.value
                },
              ],
              invoice: invoiceFormatted,
              vehicleCompany: vehicleCompany.value+"-"+vehicleName.value, //need
              vehicleModel: vehicleCompany1.value+"-"+vehicleName1.value, //need
              gst: 0, //need
              discount: 0, //need
              totalAmount: packPriceForShow*2, //need
              count: totalCustomerServiceCount*2, //need
              totalServiceCount: totalCustomerServiceCount*2, //need
              vehicleNumber: vehicleNumber+"/"+vehicleNumber1,
              customerName: customerName,
              customerMobile: customerMobile,
              packageDetails: selected.value,
              date: date + "/" + month + "/" + year,
              amount: packPriceForShow*2,
              isc: "ISC-" + iscNumber+" , ISC-"+tempISC2,
              vehicleSize: vehicleType,
              purchase: moment(customerBackDate).format("DD/MM/YYYY"),
              expiry: packageExpiry,
              checkPage: "cust",
              kilmil: vehicleKM
            });
          }else{
            history.push({
              pathname: "/receipt",
              mode: modeOfTransaction.value || "Cash",
              billList: [
                {
                  name: selected.value,
                  small: packPriceForShow,
                  medium: packPriceForShow,
                  large: packPriceForShow,
                  key: selected.value
                },
              ],
              invoice: invoiceFormatted,
              vehicleCompany: vehicleCompany.value, //need
              vehicleModel: vehicleName.value, //need
              gst: 0, //need
              discount: 0, //need
              totalAmount: packPriceForShow, //need
              count: totalCustomerServiceCount, //need
              totalServiceCount: totalCustomerServiceCount, //need
              vehicleNumber: vehicleNumber,
              customerName: customerName,
              customerMobile: customerMobile,
              packageDetails: selected.value,
              date: date + "/" + month + "/" + year,
              amount: packPriceForShow,
              isc: "ISC-" + iscNumber,
              vehicleSize: vehicleType,
              purchase: moment(customerBackDate).format("DD/MM/YYYY"),
              expiry: packageExpiry,
              checkPage: "cust",
              kilmil: vehicleKM
            });
          }
        });
    } catch (error) {
      alert(" Failed Try again");
    }
  };

  //Packages Dropdown
  const [pack, setPack] = React.useState([]);
  useEffect(() => {
    const subscriber = db
      .collection("packages")

      .onSnapshot((querySnapshot) => {
        const tmp = [];

        querySnapshot.forEach((documentSnapshot) => {
          tmp.push({
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          });
        });
        const list = [];

        tmp.forEach((item, index) => {
          list.push({ value: item.name, label: item.name });
        });

        setPack(list);
      });

    // Unsubscribe from events when no longer in use
    return () => subscriber();
  }, []);

  const [tempISC2, settempISC2] = useState("");
  useEffect(()=>{
    let farjiISC=parseInt(iscNumber,'10')+1;
    settempISC2(farjiISC)
  },[iscNumber])

  const getDetArr = [
    {
      value: customerName,
      holder: "Customer Name",
      setter: setcustomerName,
      icon: <FcPackage />,
      type: "text",
      do: "input",
    },
    {
      value: customerMobile,
      holder: "Mobile Number",
      setter: setcustomerMobile,
      icon: <FcPackage />,
      type: "number",
      do: "input",
    },
    {
      value: customerEmail,
      holder: "Email",
      setter: setcustomerEmail,
      icon: <FcPackage />,
      type: "email",
      do: "input",
    },
    {
      value: customerAddress,
      holder: "Address",
      setter: setcustomerAddress,
      icon: <FcPackage />,
      type: "text",
      do: "input",
    },

    {
      icon: <FcPackage />,
      list: vehicalList,
      val: vehicleCompany,
      setter: setvehicleCompany,
      holder: "Vehical Company",
      do: "drop",
    },
    {
      icon: <FcPackage />,
      list: vehicleNameList,
      val: vehicleName,
      setter: setvehicleName,
      holder: "Vehical Model",
      do: "drop",
    },
    // {
    //   icon: <FcPackage />,
    //   list: priceList,
    //   val: vehicleType,
    //   setter: setvehicleType,
    //   holder: "Vehicle Type",
    //   do: "drop",
    // },
    {
      value: vehicleNumber,
      holder: "Vehicle Number",
      setter: setvehicleNumber,
      icon: <FcPackage />,
      type: "text",
      do: "input",
    },
    {
      value: vehicleKM,
      holder: "Kilo Meter",
      setter: setvehicleKM,
      icon: <FcPackage />,
      type: "number",
      do: "input",
    },
    {
      icon: <FcPackage />,
      list: packageList,
      val: selected,
      setter: setSelected,
      holder: "Select Package",
      do: "drop",
    },
    {
      value: customerBackDate,
      holder: 'Purchase Date',
      setter: setCustomerBackDate,
      icon: <FcCalendar />,
      type: 'date',
      do: 'input'
    },
    {
      icon: <FcMoneyTransfer />,
      list: ["Cash", "Card", "UPI", "Other"],
      val: modeOfTransaction,
      setter: setmodeOfTransaction,
      holder: "Cash",
      do: "drop",
    },
    {
      value: empPin,
      holder: "Employee PIN",
      setter: setEmpPin,
      icon: <FcPackage />,
      type: "password",
      do: "input",
    },
  ];

  const inputHandler = (item) => {
    return item.map((item) => {
      if (item.do === "input") {
        return (
          <IcoInput
            value={item.value}
            holder={item.holder}
            setter={item.setter}
            icon={item.icon}
            type={item.type}
          />
        );
      } else if (item.do === "drop") {
        return (
          <Drop
            list={item.list}
            val={item.val}
            setter={item.setter}
            icon={item.icon}
            holder={item.holder}
          />
        );
      }
    });
  };

  const [packageSelectedDuration, setpackageSelectedDuration] = useState("");

  useEffect(() => {
    if (selected.value === undefined) {
      return;
    } else {
      db.collection("packages")
        .doc(selected.value)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            setpackageSelectedDuration(doc.data().duration);
          } else {
            alert("No such document!");
          }
        })
        .catch(function (error) {
          alert("Error getting document:", error);
        });
    }
  }, [selected.value]);

  let expiry = moment(customerBackDate, "YYYY-MM-DD");
  expiry.month();
  expiry.add(packageSelectedDuration, "month");
  let packageExpiry = expiry.subtract(1, "days").format("DD/MM/YYYY");

  const [mobileForReceipt, setmobileForReceipt] = useState();

  const [show1, setShow1] = useState(true);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const tab1 = () => {
    setShow1(true);
    setShow2(false);
    setShow3(false);
  };
  const tab2 = () => {
    setShow1(false);
    setShow2(true);
    setShow3(false);
  };
  const tab3 = () => {
    setShow1(false);
    setShow2(false);
    setShow3(true);
  };

  const searchCustomers = async () => {
    await db
      .collection("customers")
      .doc("ISC-" + iscNum)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setData(doc.data());
          setGotData(true);
        } else {
          alert("No Customer with this ISC Number!");
        }
      });
  };

  const reprintReceipt = (item) => {
    history.push({
      pathname: "/receipt",
      invoice: item.invoice,
      vehicleNumber: item.vehicleNumber,
      customerName: item.customerName,
      customerMobile: item.customerMobile,
      vehicleCompany: item.vehicleCompany, //need
      vehicleModel: item.vehicleModel, //need
      gst: item.gst, //need
      discount: item.discount, //need
      totalAmount: item.amount, //need
      count: item.count, //need
      totalServiceCount: item.totalServiceCount, //need
      packageDetails: item.package,
      date: item.date,
      amount: item.amount,
      mode: item.mode,
      billList: item.bill,
      isc: item.isc,
      vehicleSize: item.vehicleSize,
      purchase: date + "/" + month + "/" + year,
      expiry: packageExpiry,
      checkPage: "cust"
    });
  };

  const viewReceipts = async () => {
    if (mobileForReceipt) {
      await db
        .collection("sales")
        .where("vehicleNumber", "==", mobileForReceipt.toUpperCase())
        .where(
          "timestamp",
          ">=",
          firebase.firestore.Timestamp.fromDate(new Date(formatDateFrom))
        )
        .where(
          "timestamp",
          "<=",
          firebase.firestore.Timestamp.fromDate(new Date(formatDateTo))
        )
        .get()
        .then((querySnapshot)=>{
          const tmp = [];
          querySnapshot.forEach((documentSnapshot) => {
            tmp.push({
              ...documentSnapshot.data(),
              key: documentSnapshot.id,
            });
          });
          setviewallReceipts(tmp)
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    }
  };

  const showReceiptsForPrint = (item) => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          marginTop: "1vw",
          padding: "1vw",
          paddingLeft: "2vw",
          paddingRight: "2vw",
          borderRadius: "0.5vw",
          backgroundColor: "white",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <label
          style={{
            width: "25%",
            fontFamily: "Montserrat",
            fontSize: "1.25vw",
            fontWeight: "400",
          }}
        >
          {item.date}
        </label>
        <label
          style={{
            width: "25%",
            fontFamily: "Montserrat",
            fontSize: "1.25vw",
            fontWeight: "400",
          }}
        >
          {item.customerName}
        </label>
        <label
          style={{
            width: "25%",
            fontFamily: "Montserrat",
            fontSize: "1.25vw",
            fontWeight: "400",
          }}
        >
          {item.invoice}
        </label>
        <label
          style={{
            width: "25%",
            fontFamily: "Montserrat",
            fontSize: "1.25vw",
            fontWeight: "400",
          }}
        >
          {item.amount}
        </label>
        <FcPrint
          className="printerButton"
          onClick={() => reprintReceipt(item)}
        />
      </div>
    );
  };

  const updateCustomerDetails = () => {
    if (updateName !== "") {
      db.collection("customers")
        .doc("ISC-" + iscNum)
        .update({ NAME: updateName })
        .then(() => {
          alert("Customer Details are updated : " + updateName);
        });
    }
    if (updateNumber !== "") {
      db.collection("customers")
        .doc("ISC-" + iscNum)
        .update({ MOBILE: updateNumber })
        .then(() => {
          alert("Customer Details are updated : " + updateNumber);
        });
    }
    if (updateEmail !== "") {
      db.collection("customers")
        .doc("ISC-" + iscNum)
        .update({ EMAIL: updateEmail })
        .then(() => {
          alert("Customer Details are updated : " + updateEmail);
        });
    }
    if (updateAddress !== "") {
      db.collection("customers")
        .doc("ISC-" + iscNum)
        .update({ ADDRESS: updateAddress })
        .then(() => {
          alert("Customer Details are updated : " + updateAddress);
        });
    }
    if (updateVehicleCompany !== "") {
      db.collection("customers")
        .doc("ISC-" + iscNum)
        .update({ COMPANY: updateVehicleCompany.value })
        .then(() => {
          alert("Customer Details are updated : " + updateVehicleCompany);
        });
    }
    if (updateVehicleName !== "") {
      db.collection("customers")
        .doc("ISC-" + iscNum)
        .update({ MODEL: updateVehicleName.value })
        .then(() => {
          alert("Customer Details are updated : " + updateVehicleName);
        });
    }
    if (updateVehicleType !== "") {
      db.collection("customers")
        .doc("ISC-" + iscNum)
        .update({ SIZE: updateVehicleType.value })
        .then(() => {
          alert("Customer Details are updated : " + updateVehicleType.value);
        });
    }
    if (updateVehicleNumber !== "") {
      db.collection("customers")
        .doc("ISC-" + iscNum)
        .update({ VEHICLENUMBER: updateVehicleNumber })
        .then(() => {
          alert("Customer Details are updated : " + updateVehicleNumber);
        });
    }

    if (updateVehicleKM !== "") {
      db.collection("customers")
        .doc("ISC-" + iscNum)
        .update({ KILOMETERDRIVEN: updateVehicleKM })
        .then(() => {
          alert("Customer Details are updated : " + updateVehicleKM);
        });
    }
    setGotData(false);
  };

  useEffect(() => {
    console.log(vehicleType);
    console.log(packPriceForShow);
    if (selected) {
      db.collection("packages")
        .doc(selected.value)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            if (vehicleType === "small")
              setpackPriceForShow(doc.data().cost.small);
            if (vehicleType === "medium")
              setpackPriceForShow(doc.data().cost.medium);
            if (vehicleType === "large")
              setpackPriceForShow(doc.data().cost.large);
          } else {
            alert("No such Package!");
          }
        })
        .catch(function (error) {
          alert("Error getting document:", error);
        });
    }
  }, [selected, vehicleType, packPriceForShow]);

  useEffect(() => {
    if (selected.value) {
      db.collection("packages")
        .doc(selected.value)
        .get()
        .then((item) => {
          setTotalCustomerServiceCount(item.data().count);
        });
    }
  }, [selected]);

  return (
    <div>
      <Loading load={loading} />
      <div className="top-tap-bar">
        <div
          style={{ cursor: "pointer" }}
          className={show1 === true ? "ActiveCard-customer" : "InactiveCard"}
          onClick={tab1}
        >
          <p className="tile1cust">
            Create <b className="tile2cust">Customer</b>
          </p>
        </div>
        <div
          style={{ cursor: "pointer" }}
          className={show2 === true ? "ActiveCard-customer" : "InactiveCard"}
          onClick={tab2}
        >
          <p className="tile1cust">
            Edit <b className="tile2cust">Customer</b>
          </p>
        </div>
        <div
          style={{ cursor: "pointer" }}
          className={show3 === true ? "ActiveCard-customer" : "InactiveCard"}
          onClick={tab3}
        >
          <p className="tile1cust">
            View <b className="tile2cust">Receipts</b>
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          marginLeft: "4.25%",
        }}
      >
        <hr
          style={{
            width: "50%",
            marginTop: "25px",
            backgroundColor: "red",
            opacity: 0.2,
          }}
        />
      </div>
      <label
        style={{
          marginTop: "10px",
          fontFamily: "Montserrat",
          fontWeight: "400",
          marginLeft: "4.25%",
          fontSize: window.innerWidth < 520 ? "2.5vw" : "1vw",
        }}
      >
        Enter The Required Customer Details Below
      </label>
      {show1 === true ? (
        <div>
          <div className="cust-box-customer">{inputHandler(getDetArr)}</div>
          {selected.value === "SILVER 2 CAR PACKAGE" ? (
            <>
              <label
                style={{
                  marginTop: "10px",
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  marginLeft: "4.5%",
                  fontSize: window.innerWidth < 520 ? "2.5vw" : "1vw",
                }}
              >
                Enter 2nd Vehicle Details
              </label>
              <div className="cust-box-customer">
                {/* <Drop
                  icon={<FcPackage />}
                  list={["DP-2nd Vehicle"]}
                  val={"2nd Vehicle Pack"}
                  setter={setSelected2}
                  holder={"Select 2nd Package"}
                /> */}
                <Drop
                  icon={<AiFillCar />}
                  list={vehicalList1}
                  val={vehicleCompany1}
                  setter={setvehicleCompany1}
                  holder={"2nd Vehical Company"}
                />
                <Drop
                  icon={<AiFillCar />}
                  list={vehicleNameList1}
                  val={vehicleName1}
                  setter={setvehicleName1}
                  holder={"2nd Vehical Model"}
                />
                <IcoInput
                  value={vehicleNumber1}
                  holder={"2nd Vehicle Number"}
                  setter={setvehicleNumber1}
                  icon={<BiCreditCardFront />}
                  type={"text"}
                />
                <IcoInput
                  value={vehicleKM1}
                  holder={"2nd Vehicle KM"}
                  setter={setvehicleKM1}
                  icon={<FcDisplay />}
                  type={"number"}
                />
              </div>
            </>
          ) : null}
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <hr
              style={{
                width: "75%",
                marginTop: "25px",
                backgroundColor: "red",
                opacity: 0.2,
              }}
            />
          </div>
          {showEmpName === true ? (
            <div style={{ marginLeft: "4.1%" }}>
              <IcoInput
                value={empName}
                holder={"Employee Name"}
                setter={setEmpName}
                icon={<FcPackage />}
                type={"text"}
                editable={false}
              />
            </div>
          ) : null}
          {showCustomerButton === true ? (
            <div onClick={() => uploadCustomer()} className="button-Customer">
              <IcoButton icon={label} start="Create" end="Customer" />
            </div>
          ) : null}
        </div>
      ) : null}
      {show2 === true ? (
        gotData === false ? (
          <div className="check-details">
            <div>
              <IcoInput
                value={iscNum}
                holder="ISC-"
                setter={setiscNum}
                icon={<FcPackage />}
                type="text"
              />
            </div>
            <div onClick={searchCustomers} style={{ marginLeft: "7.5vw" }}>
              <IcoButton icon={label} start="Search" end="Customer" />
            </div>
          </div>
        ) : (
          <div>
            <div className="All-edit-field-div">
              <div>
                <IcoInput
                  value={updateName}
                  holder={data.NAME}
                  setter={setUpdateName}
                  icon={<FcPackage />}
                />
              </div>
              <div>
                <IcoInput
                  value={updateNumber}
                  holder={data.MOBILE}
                  setter={setUpdateNumber}
                  icon={<FcPackage />}
                />
              </div>

              <div>
                <IcoInput
                  value={updateEmail}
                  holder={data.EMAIL}
                  setter={setUpdateEmail}
                  icon={<FcPackage />}
                />
              </div>
              <div>
                <IcoInput
                  value={updateAddress}
                  holder={data.ADDRESS}
                  setter={setUpdateAddress}
                  icon={<FcPackage />}
                />
              </div>

              <div>
                <IcoInput
                  value={updateVehicleNumber}
                  holder={data.VEHICLENUMBER}
                  setter={setUpdateVehicleNumber}
                  icon={<FcPackage />}
                />
              </div>
              <div>
                <IcoInput
                  value={updateVehicleKM}
                  holder={data.KILOMETERDRIVEN}
                  setter={setUpdateVehicleKM}
                  icon={<FcPackage />}
                  type="number"
                />
              </div>
              <div>
                <Drop
                  list={vechileCompanyDropList}
                  val={updateVehicleCompany}
                  setter={setUpdateVechicleCompany}
                  icon={<FcPackage />}
                  holder={data.COMPANY}
                />
              </div>
              <div>
                <Drop
                  list={vehicleNameListDrop}
                  val={updateVehicleName}
                  setter={setUpdateVehicleName}
                  icon={<FcPackage />}
                  holder={data.MODEL}
                />
              </div>
              <div>
                <Drop
                  list={priceList}
                  val={updateVehicleType}
                  setter={setUpdateVehicleType}
                  icon={<FcPackage />}
                  holder={data.SIZE}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <hr
                style={{
                  width: "75%",
                  marginTop: "25px",
                  backgroundColor: "red",
                  opacity: 0.2,
                }}
              />
            </div>
            <div className="button-Customer" onClick={updateCustomerDetails}>
              <IcoButton icon={label} start="Update" end="Customer" />
            </div>
          </div>
        )
      ) : null}
      {show3 === true ? (
        <div>
          <div className="check-details">
            <div style={{ marginTop: "1vh", marginLeft: "1vw" }}>
              <IcoInput
                value={mobileForReceipt}
                holder="Enter Vehicle No "
                setter={setmobileForReceipt}
                icon={<AiFillMobile />}
                type="text"
              />
            </div>
            {/* <div style={{marginTop: "1vh" , marginLeft :"1vw"}}>
            <Drop
                  list={["2020","2021","2022","2023","2024","2025","2026","2027","2028","2029","2030",]}
                  val={fromDate}
                  setter={setFromDate}
                  icon={<AiFillCar />}
                  holder="Select Year"
                />
            </div>
            <div style={{marginTop: "1vh", marginLeft :"1vw" }}>
            <Drop
                  list={["01","02","03","04","05","06","07","08","09","10","11","12"]}
                  val={monthSelect}
                  setter={setMonthSelect}
                  icon={<AiFillCar />}
                  holder="Select Month"
                />
            </div> */}
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                marginLeft: "1vw",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "1vw",
                    alignItems: "center",
                    marginRight: "1vw",
                  }}
                >
                  Date From
                </label>
                <IcoInput
                  value={dateFrom}
                  holder={"Date From"}
                  type={"date"}
                  icon={<FcCalendar />}
                  setter={setdateFrom}
                  fontsize={window.innerWidth < 520 ? " 2vw" : "0.75vw"}
                  width={window.innerWidth < 520 ? 100 : null}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "1vw",
                }}
              >
                <label
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "1vw",
                    alignItems: "center",
                    marginRight: "1vw",
                  }}
                >
                  Date To
                </label>
                <IcoInput
                  value={dateTo}
                  holder={"Date To"}
                  type={"date"}
                  icon={<FcCalendar />}
                  setter={setdateTo}
                  fontsize={window.innerWidth < 520 ? " 2vw" : "0.75vw"}
                  width={window.innerWidth < 520 ? 100 : null}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: "5.1%",
              marginTop: window.innerWidth < 520 ? "1vw" : null,
            }}
            onClick={viewReceipts}
          >
            <IcoButton icon={label} start="View" end="Receipts" />
          </div>
          <hr
            style={{
              width: "90%",
              backgroundColor: "red",
              opacity: 0.2,
              marginLeft: "4%",
            }}
          />
          <div
            style={{
              display: "flex",
              flex: 1,
              width: "90%",
              flexDirection: "column",
              marginLeft: "4%",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "1vw",
                paddingLeft: "2vw",
                paddingRight: "2vw",
                backgroundColor: "#fe5236",
                borderRadius: "0.5vw",
                marginTop: "1vh",
              }}
            >
              <label
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "1vw",
                  color: "white",
                }}
              >
                Service Date
              </label>
              <label
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "1vw",
                  color: "white",
                }}
              >
                Customer Name
              </label>
              <label
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "1vw",
                  color: "white",
                }}
              >
                Invoice No
              </label>
              <label
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "1vw",
                  color: "white",
                }}
              >
                Amount
              </label>
              <label
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "1vw",
                  color: "white",
                }}
              >
                Reprint Receipt
              </label>
            </div>
            {viewallReceipts.map(showReceiptsForPrint)}
          </div>
        </div>
      ) : null}
    </div>
  );
}
