const firebaseConfig = {
  apiKey: "AIzaSyDAwLXz1Z6ZPSWVymfOSYpYHwXyeZ1bDbE",
  authDomain: "innovative-service.firebaseapp.com",
  databaseURL: "https://innovative-service.firebaseio.com",
  projectId: "innovative-service",
  storageBucket: "innovative-service.appspot.com",
  messagingSenderId: "1051723006139",
  appId: "1:1051723006139:web:5efa5ec926f24faedfe99e",
  measurementId: "G-X722J2R2SY",
};

export default firebaseConfig;
