import React, { useEffect } from "react";
import "./CardToAdd.css";

export default function CardToAdd({
  item,
  setter,
  data,
  update,
  toUpdate,
  list,
  setList,
  setcountSetter,
}) {
  const [number, setnumber] = React.useState(item.count ? item.count : 0);
  // console.log("list", list);
  // console.log("data", data);
  // console.log("item", item);

  function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }
  useEffect(() => {
    // if (item.count) {
    //   setnumber(item.count);
    // }
    return () => {
      const temp = findWithAttr(list, "name", item.name);

      const objIndex = list.findIndex((obj) => obj.name === item.name);
      const templist = list;
      templist[objIndex].count = number;

      console.log("LList TO Uodat", number);
      setList([...templist]);
    };
  }, [number]);

  const downCount = () => {
    const objIndex = data.findIndex((obj) => obj.name === item.name);

    const temp = data;
    const tempCount = temp[objIndex].count;
    temp[objIndex] = { ...item, count: tempCount - 1 };
    setter(temp);
    setnumber(number - 1);
  };

  function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }
  const updateCount = () => {
    setnumber(number + 1);
    const hello = findWithAttr(data, "name", item.name);

    if (hello === -1) setter([...data, { ...item, count: number + 1 }]);
    else {
      const temp = data;
      temp[hello] = { ...item, count: number + 1 };
      setter(temp);
    }

    update(toUpdate + 1);
  };
  let countSet = 0;
  data.forEach((item) => {
    countSet = countSet + item.count;
  });
  setcountSetter(countSet);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
      className="bgCardAdder"
    >
      <div style={{ display: "block", flex: 0.4 }} className="topCardStuff">
        <label
          style={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "900",
            fontSize: 28,
            color: "white",
            paddingLeft: "10%",
            marginTop: "6%",
            lineHeight: "34px",
            marginBottom: "-50px",
          }}
        >
          {item.name}
        </label>
        <label
          style={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "300",
            fontSize: 22,
            color: "white",
            paddingLeft: "10%",
            lineHeight: "27px",
          }}
        >
          {item.category}
        </label>
      </div>
      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          flex: 0.6,
          flexDirection: "row",
        }}
        className="bottomCardStuff"
      >
        <div style={{ display: "block", flex: 1 }}>
          <div
            style={{
              width: 145,
              height: 45,
              backgroundColor: "#EEEEEE",
              borderRadius: 6,
              marginTop: "25%",
              marginLeft: "20%",
              flexDirection: "row",
              display: "flex",
            }}
          >
            <div
              style={{
                flex: 1,
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "900",
                fontSize: "36px",
                lineHeight: "44px",
                paddingLeft: "10%",
                cursor: "pointer",
              }}
              className="minusClick"
              onClick={() =>
                number !== 0 ? downCount() : console.log("Nothing")
              }
            >
              -
            </div>
            <div
              style={{
                flex: 1,
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "900",
                fontSize: "32px",
                lineHeight: "45px",
                color: "#595959",
                paddingLeft: "10%",
              }}
            >
              {number}
            </div>
            <div
              style={{
                flex: 1,
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "900",
                fontSize: "36px",
                lineHeight: "44px",
                paddingLeft: "10%",
                cursor: "pointer",
              }}
              className="plusClick"
              onClick={() => {
                updateCount();
              }}
            >
              +
            </div>
          </div>
          <div
            style={{
              width: 165,
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "22px",
              color: "#595959",
              paddingLeft: "20%",
              marginTop: "8%",
            }}
          >
            Free Services
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "block",
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "900",
            fontSize: "144px",
            lineHeight: "176px",
            color: "#EEEEEE",
            paddingLeft: number > 9 ? "6%" : "12%",
          }}
        >
          {number}
        </div>
      </div>
    </div>
  );
}
