import React from "react";
import "./ServiceLeft.css";

const ServiceLeft = props => {
  const serviceTotal=props.totalServiceCount;
  const halfsize = props.size * 0.5;
  const radius = halfsize - props.strokewidth * 0.5;
  const circumference = 2 * Math.PI * radius;
  const strokeval = (props.value * circumference) / serviceTotal;
  const dashval = strokeval + " " + circumference;

  const trackstyle = { strokeWidth: props.strokewidth };
  const indicatorstyle = {
    strokeWidth: props.strokewidth,
    strokeDasharray: dashval
  };
  const rotateval = "rotate(-90 " + halfsize + "," + halfsize + ")";

  return (
    <svg width={props.size} height={props.size} className="donutchart">
      <circle
        r={radius}
        cx={halfsize}
        cy={halfsize}
        transform={rotateval}
        style={trackstyle}
        className="donutchart-track"
      />
      <circle
        r={radius}
        cx={halfsize}
        cy={halfsize}
        transform={rotateval}
        style={indicatorstyle}
        className="donutchart-indicator"
      />
      <text
        className="donutchart-text"
        x={halfsize}
        y={halfsize}
        style={{ textAnchor: "middle" }}
      >
        <tspan className="donutchart-text-val" style={{fontSize: window.innerWidth<520 ? '13vw' : '4vw'}}>{props.value}</tspan>

        <tspan className="donutchart-text-label" style={{fontSize: window.innerWidth<520 ? '4vw' : '1.25vw'}} x={halfsize} y={halfsize*1.3} width="20px">
          {props.valuelabel}
        </tspan>
      </text>
    </svg>
  );
};

export default ServiceLeft;
