import React from "react";
import "./Date.css";

const Date = (props) => {
  return (
    <div
      class="calContainer"
      style={{ width: props.width, height: props.height,lineHeight: window.innerWidth<520 ? '9vw' : '2.5vw' }}
    >
      <label
        className="month"
        style={{ fontSize: props.fontSize,width: '100%' }}
      >
        {props.month}
      </label>
      <label style={{ fontSize: props.fontSize * 3, fontWeight: "900",width: '100%' }}>
        . {props.date} .
      </label>
      <label className="year" style={{ fontSize: props.fontSize,width: '100%' }}>
        {props.year}
      </label>
    </div>
  );
};
export default Date;
