import React from "react";
import "./IcoButton.css";

export default function IcoButton(props) {
  return (
    <div className="button-container" style={{width: window.innerWidth<520 ? '63vw' : '15vw',height: window.innerWidth<520 ? '10vw' : '3vw'}}>
               <span className="button-icon" style={{fontSize: window.innerWidth<520 ? '5vw' : '1.75vw'}}>{props.icon}</span>
      <div class="titleForSubmit" style={{fontSize: window.innerWidth<520 ? '2vw' : '0.8vw'}}>
        <span>
          <text>{props.start}</text>
          <text style={{fontWeight: '900'}}>{props.end}</text>
        </span>
      </div>
      <img
        alt=""
        className="chotaArrow"
        src={require("../assets/smallArrow.svg")}
      />
    </div>
  );
}
