import React, { useEffect, useState } from "react";
import "./AdminApp.css";
import "./Login.css";
import "./PackageScreen.css";

export default function AdminLogin({
  windowDimensions,
  email,
  emailSetter,
  password,
  passwordSetter,
  doWithKeyPress,
  verifyAdmin,
}) {
    const [heightt, setheightt] = useState();
    const [finalHeight, setFinalHeight] = useState();
    useEffect(() => {
      sessionStorage.setItem("heightt", windowDimensions.height);
      if (heightt) {
        setFinalHeight(heightt);
      } else if (!heightt) {
        let tmp = sessionStorage.getItem("heightt");
        setheightt(tmp);
      }
    }, [heightt, windowDimensions]);

  if (window.innerWidth < 520) {
    return (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "#EFEFEF",
            width: windowDimensions.width,
            height: finalHeight,
            overflow: "auto",
          }}
        >
        <div
          className="shadowBGAdmin"
          style={{
            position: "absolute",
            width: windowDimensions.width / 1.1,
            height: finalHeight / 1.1,
            backgroundColor: "white",
            borderRadius: "25px",
            left: "4.5%",
            top: "4.5%",
          }}
        />
        <img
          alt="Banner"
          style={{
            position: "absolute",
            top: "4.5%",
            left: "4.5%",
            borderTopLeftRadius: "25px",
            height: finalHeight / 3,
          }}
          src={require("../assets/LeftAdminSvg.svg")}
        />
        <div
          style={{
            position: "relative",
            marginTop: "80%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            width: "100vw",
            height: "100vw",
          }}
        >
          <img
            alt="Banner"
            style={{
              position: "absolute",
              height: windowDimensions.height / 1.2,
              width: windowDimensions.width / 1.2,
            }}
            src={require("../assets/circle.svg")}
          />
          <label
            style={{
              position: "absolute",
              //   left: "0%",
              top: "20%",
              width: "100%",
              textAlign: "center",
              fontSize: windowDimensions.width / 20,
              color: "white",
              fontFamily: "Montserrat",
              fontWeight: "400",
            }}
          >
            EMAIL
          </label>
          <input
            placeholder=""
            value={email}
            onChange={emailSetter}
            className="field textInputEmail"
            style={{
              fontFamily: "Montserrat",
              position: "absolute",
              //   left: "25%",
              top: "30%",
              height: windowDimensions.width / 10,
              width: windowDimensions.width / 1.5,
              fontSize: windowDimensions.width / 25,
            }}
          />
          <label
            style={{
              position: "absolute",
              width: "100%",
              //   left: "0%",
              top: "45%",
              textAlign: "center",
              fontSize: windowDimensions.width / 20,
              color: "white",
              fontFamily: "Montserrat",
              fontWeight: "400",
            }}
          >
            PASSWORD
          </label>
          <input
            placeholder=""
            value={password}
            type="password"
            onChange={passwordSetter}
            onKeyDown={(e) => doWithKeyPress(e)}
            className="field textInputPassword"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "900",
              position: "absolute",
              //   left: "25%",
              top: "55%",
              height: windowDimensions.width / 10,
              width: windowDimensions.width / 1.5,
              fontSize: windowDimensions.width / 25,
            }}
          />
          <img
            alt=""
            style={{
              position: "absolute",
              top: "70%",
              //   left: "43.5%",
              height: windowDimensions.width / 7,
            }}
            className="buttonForward"
            src={require("../assets/forward.svg")}
            onClick={verifyAdmin}
          />
        </div>
      </div>
    );
  } else if (window.innerWidth > 520) {
    return (
      <div>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "#EFEFEF",
            width: windowDimensions.width,
            height: windowDimensions.height,
            overflow: "hidden",
          }}
        />
        <div
          className="shadowBGAdmin"
          style={{
            position: "absolute",
            width: windowDimensions.width / 1.1,
            height: windowDimensions.height / 1.1,
            backgroundColor: "white",
            borderRadius: 48,
            left: "4.5%",
            top: "4.5%",
          }}
        />
        <img
          alt="Banner"
          style={{
            position: "absolute",
            top: "4.5%",
            left: "4.5%",
            borderTopLeftRadius: 48,
            height: windowDimensions.height / 1.275,
          }}
          src={require("../assets/LeftAdminSvg.svg")}
        />
        <div style={{ position: "absolute", top: "18%", right: "12%" }}>
          <img
            alt="Banner"
            style={{
              height: windowDimensions.height / 1.5,
              width: windowDimensions.width / 3,
            }}
            src={require("../assets/circle.svg")}
          />
          <label
            style={{
              position: "absolute",
              left: "0%",
              top: "20%",
              width: "100%",
              textAlign: "center",
              fontSize: windowDimensions.width / 50,
              color: "white",
              fontFamily: "Montserrat",
              fontWeight: "400",
            }}
          >
            EMAIL
          </label>
          <input
            placeholder=""
            value={email}
            onChange={emailSetter}
            className="field textInputEmail"
            style={{
              fontFamily: "Montserrat",
              position: "absolute",
              left: "25%",
              top: "30%",
              height: windowDimensions.height / 16,
              width: windowDimensions.width / 6,
              fontSize: windowDimensions.width / 50,
            }}
          />
          <label
            style={{
              position: "absolute",
              width: "100%",
              left: "0%",
              top: "45%",
              textAlign: "center",
              fontSize: windowDimensions.width / 50,
              color: "white",
              fontFamily: "Montserrat",
              fontWeight: "400",
            }}
          >
            PASSWORD
          </label>
          <input
            placeholder=""
            value={password}
            type="password"
            onChange={passwordSetter}
            onKeyDown={(e) => doWithKeyPress(e)}
            className="field textInputPassword"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "900",
              position: "absolute",
              left: "25%",
              top: "55%",
              height: windowDimensions.height / 16,
              width: windowDimensions.width / 6,
              fontSize: windowDimensions.width / 50,
            }}
          />
          <img
            alt=""
            style={{
              position: "absolute",
              top: "70%",
              left: "43.5%",
              height: windowDimensions.width / 20,
            }}
            className="buttonForward"
            src={require("../assets/forward.svg")}
            onClick={verifyAdmin}
          />
        </div>
      </div>
    );
  }
}
