import React  from "react";
import "./AdminPackageCard.css";

export default function AdminPackageCard(props) {
  return (
    <div className="mother-card-admin-package">
      <div className="card-for-package-container-admin-package">
        <div className="card-upper-part-green-admin-package">
          <div className="card-top-text-admin-package">{props.name}</div>

          {props.count > 0 ? (
            <div
              // style={{
              //   position: "absolute",
              //   top: "0.2vw",
              //   right: "0.2vw",
              //   background: "white",
              //   color: "#459258",
              //   borderRadius: "50%",
              //   padding: "1vw",
              //   fontSize: "1vw",
              //   width: "1vw",
              //   height: "1vw",
              //   display: "flex",
              //   justifyContent: "center",
              //   alignItems: "center",
              // }}
              className="card-top-count-admin-package"
            >
              {props.count}
            </div>
          ) : null}

        
        </div>
        <div className="card-lower-part-admin-package">
          <div className="card-mini-div-admin-package">
          {/* <FcMoneyTransfer
              style={{
                fontSize: "1.5vw",
              }}
            /> */}
            <text className="card-mini-text-admin-package" >
            <span style={{ fontWeight: "500" }}>Small : ₹&nbsp;</span>
            {props.small}
            </text>
          </div>
          <div className="card-mini-div-admin-package">
          {/* <FcMoneyTransfer
              style={{
                fontSize: "1.5vw",
              }}
            /> */}

            <text className="card-mini-text-admin-package" >
              <span style={{ fontWeight: "500" }}>Medium : ₹&nbsp;</span>
              {props.medium}
            </text>
          </div>
          <div className="card-mini-div-admin-package">
            {/* <FcMoneyTransfer
              style={{
                fontSize: "1.5vw",
              }}
            /> */}

            <text className="card-mini-text-admin-package" >
            <span style={{ fontWeight: "500" }}>Large : ₹&nbsp;</span>
             {props.large}
            </text>
          </div>
        </div>
      </div>
    </div>
  );
}
