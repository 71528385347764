import React from "react";
import "./IcoInput.css";

function IcoInput({
  setter,
  icon,
  value,
  holder,
  type,
  editable,
  width,
  height,
  fontsize,
}) {
  const handleChange = (event) => {
    if (editable === false) {
      return;
    } else {
      setter(event.target.value);
    }
  };
  return (
    <div className="field-container-bg" style={{ height: height }}>
      <span
        className="field-icon-Left"
        style={{
          height: height,
          fontSize: fontsize,
        }}
      >
        {icon}
      </span>

      <input
      className="inputPlzNoCSS"
        type={type}
        placeholder={holder}
        value={value}
        onChange={handleChange}
        style={{ width: width + "%", fontSize: fontsize, paddingLeft: "20px" }}
      />
    </div>
  );
}

export default IcoInput;
