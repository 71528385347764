import React, { useState, useEffect } from "react";
import "./Styling/Main.css";
import Kilometer from "../../Components/KilometerBar/Kilometer";
import db from "../../firebase/database";
import { useLocation } from "react-router-dom";
import Services from "../../Components/Services/Services";
import CustomersTable from "../../Components/Customers/CustomersTable";
import Date from "../../Components/Date/Date";
import {
  FcPackage,
  FcBusinessman,
  FcPhoneAndroid,
  FcVoicemail,
  FcSupport,
  FcAutomotive,
  FcInTransit,
  FcNoIdea,
} from "react-icons/fc";
import { AiOutlineFieldNumber } from "react-icons/ai";
import IcoInput from "../../Components/IcoInput";
import Drop from "../../Components/Drop";

export default function DetailsServices({
  setcustomerName,
  setcustomerMobile,
  setcustomerEmail,
  setcustomerAddress,
  setvehicleCompany,
  setvehicleName,
  setvehicleType,
  setvehicleNumber,
  setvehicleKM,
  customerName,
  customerMobile,
  customerAddress,
  customerEmail,
  vehicleCompany,
  vehicleName,
  vehicleType,
  vehicleNumber,
  vehicleKM,
}) {
  const [vehicalList, setVehicleList] = useState([]);
  const [vehicleNameList, setVehicleNameList] = useState([]);
  console.log("At Screen", vehicleType);
  useEffect(() => {
    try {
      db.collection("VehicleCompany")
        .doc(vehicleCompany.value)
        .get()
        .then((item) => {
          item.data().VehicleName.forEach((det) => {
            if (det.name === vehicleName.value) {
              setvehicleType(det.type);
            }
          });
        });
    } catch (error) {
      console.log(error);
    }
  }, [vehicleName, vehicleCompany]);
  useEffect(() => {
    db.collection("VehicleCompany")
      .get()
      .then((item) => {
        let temp = [];
        item.forEach((item) => {
          temp.push(item.data().name);
        });
        setVehicleList(temp);
      });

    if (vehicleCompany !== "") {
      db.collection("VehicleCompany")
        .doc(vehicleCompany.value)
        .get()
        .then((item) => {
          if(vehicleNameList.length!==1){
            setVehicleNameList(item.data().ModelName);
          }
          
        });
    }
  }, [vehicleCompany]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [companyIcon, setcompanyIcon] = useState("");
  useEffect(() => {
    if (vehicleCompany.value === "Chevrolet")
      setcompanyIcon(require("../../assets/company/chevrolet.png"));
    if (vehicleCompany.value === "Ford")
      setcompanyIcon(require("../../assets/company/ford.png"));
    if (vehicleCompany.value === "Honda")
      setcompanyIcon(require("../../assets/company/honda.png"));
    if (vehicleCompany.value === "Hyundai")
      setcompanyIcon(require("../../assets/company/hyundai.png"));
    if (vehicleCompany.value === "Kia")
      setcompanyIcon(require("../../assets/company/kia.png"));
    if (vehicleCompany.value === "MG Motor")
      setcompanyIcon(require("../../assets/company/mg.png"));
    if (vehicleCompany.value === "Mahindra")
      setcompanyIcon(require("../../assets/company/mahindra.png"));
    if (vehicleCompany.value === "Maruti Suzuki")
      setcompanyIcon(require("../../assets/company/maruti.png"));
    if (vehicleCompany.value === "Mitsubishi")
      setcompanyIcon(require("../../assets/company/mitsubishi.png"));
    if (vehicleCompany.value === "Renault")
      setcompanyIcon(require("../../assets/company/renault.png"));
    if (vehicleCompany.value === "Skoda")
      setcompanyIcon(require("../../assets/company/skoda.png"));
    if (vehicleCompany.value === "Tata Motors")
      setcompanyIcon(require("../../assets/company/tata.png"));
    if (vehicleCompany.value === "Toyota")
      setcompanyIcon(require("../../assets/company/toyota.png"));
    if (vehicleCompany.value === "Volkswagen")
      setcompanyIcon(require("../../assets/company/volkswagen.png"));
    if (vehicleCompany.value === "Datsun")
      setcompanyIcon(require("../../assets/company/datsun.png"));
    if (vehicleCompany.value === "Fiat")
      setcompanyIcon(require("../../assets/company/fiat.png"));
  }, [vehicleCompany, companyIcon]);

  let carSize;
  if (vehicleType === "small") carSize = "Small";
  if (vehicleType === "medium") carSize = "Medium";
  if (vehicleType === "large") carSize = "Large";

  const checkTempCustomer = () => {
    if (vehicleNumber) {
      db.collection("sales")
        .where("vehicleNumber", "==", vehicleNumber)
        .limit(1)
        .get()
        .then((item) => {
          if(item.size>=1){
            item.forEach((doc)=>{
              setcustomerName(doc.data().customerName);
              setcustomerMobile(doc.data().customerMobile);
              setVehicleList([doc.data().vehicleCompany]);
              setVehicleNameList([doc.data().vehicleModel]);
              // setvehicleCompany(doc.data().vehicleCompany);
              // setvehicleName(doc.data().vehicleModel);
              setvehicleType(doc.data().vehicleSize);
              setvehicleKM(doc.data().vehicleKilometer);
            })
          }else{
            alert("No Customer with this Vehicle Number")
          }
        })
        .catch((error) => {
          alert("Error is " + error);
        });
    } else {
      alert("Enter Vehicle Number First");
    }
  };

  if (window.innerWidth < 520) {
    return (
      <div
        style={{
          display: "block",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            flex: 1,
            backgroundColor: "#EEEEEE",
            display: "block",
            borderRadius: 24,
            marginTop: "14%",
            padding: "5vw",
            marginLeft: "5vw",
            marginRight: "5vw",
          }}
        >
          <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
            <img
              alt=""
              style={{
                height: windowDimensions.width / 15,
              }}
              src={require("../../assets/customerDetails.svg")}
            />
          </div>
          <div>
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Vehicle Number
            </label>
            <IcoInput
              value={vehicleNumber.toUpperCase()}
              holder={"Vehicle Number"}
              icon={
                <AiOutlineFieldNumber
                  style={{ width: windowDimensions.width / 15 }}
                />
              }
              type={"text"}
              setter={setvehicleNumber}
              width="100"
              height={windowDimensions.height / 18}
              fontsize={windowDimensions.width / 40}
            />
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Customer Name
            </label>
            <IcoInput
              value={customerName}
              holder={"Customer Name"}
              icon={
                <FcBusinessman style={{ width: windowDimensions.width / 15 }} />
              }
              type={"text"}
              setter={setcustomerName}
              width="100"
              height={windowDimensions.height / 18}
              fontsize={windowDimensions.width / 40}
            />
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                marginTop: "10px",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Mobile Number
            </label>
            <IcoInput
              value={customerMobile}
              holder={"Mobile Number"}
              icon={
                <FcPhoneAndroid
                  style={{ width: windowDimensions.width / 15 }}
                />
              }
              type={"text"}
              setter={setcustomerMobile}
              width="100"
              height={windowDimensions.height / 18}
              fontsize={windowDimensions.width / 40}
            />
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                marginTop: "10px",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Email Address
            </label>
            <IcoInput
              value={customerEmail}
              holder={"Email Address"}
              icon={
                <FcVoicemail style={{ width: windowDimensions.width / 15 }} />
              }
              type={"text"}
              setter={setcustomerEmail}
              width="100"
              height={windowDimensions.height / 18}
              fontsize={windowDimensions.width / 40}
            />
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                marginTop: "10px",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Full Address
            </label>
            <textarea
              style={{
                width: "100%",
                height: windowDimensions.height / 7,
                backgroundColor: "white",
                borderRadius: 10,
                padding: 20,
                borderColor: "white",
                fontSize: windowDimensions.width / 40,
              }}
              placeholder="Enter Full Address"
              onChange={(e) => setcustomerAddress(e.target.value)}
            />
          </div>
        </div>
        {/* Vehicle Details Starts */}

        <div
          style={{
            flex: 1,
            backgroundColor: "#EEEEEE",
            display: "block",
            borderRadius: 24,
            marginTop: "5vw",
            padding: "5vw",
            marginLeft: "5vw",
            marginRight: "5vw",
          }}
        >
          <div>
            <div
              style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <img
                alt=""
                style={{
                  height: windowDimensions.width / 15,
                }}
                src={require("../../assets/vehicleDetails.svg")}
              />
            </div>
            <div>
              <label
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: windowDimensions.height / 45,
                }}
              >
                Vehicle Company
              </label>
              <Drop
                val={vehicleCompany}
                holder={"Vehicle Company"}
                list={vehicalList}
                icon={
                  companyIcon ? (
                    <img
                      alt=""
                      src={companyIcon}
                      style={{ width: windowDimensions.width / 15 }}
                    />
                  ) : (
                    <FcNoIdea style={{ width: windowDimensions.width / 15 }} />
                  )
                }
                type={"drop"}
                setter={setvehicleCompany}
              />
              <label
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: windowDimensions.height / 45,
                  marginTop: 20,
                }}
              >
                Vehicle Model
              </label>
              <Drop
                val={vehicleName}
                holder={"Vehicle Model Name"}
                list={vehicleNameList}
                icon={
                  <FcAutomotive
                    style={{ width: windowDimensions.width / 15 }}
                  />
                }
                type={"drop"}
                setter={setvehicleName}
              />
              <label
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  marginTop: "10px",
                  fontSize: windowDimensions.height / 45,
                }}
              >
                Kilometer Completed
              </label>
              <IcoInput
                value={vehicleKM}
                holder={"Distance Covered KM"}
                icon={
                  <div style={{ width: windowDimensions.width / 15 }}>KM</div>
                }
                type={"text"}
                setter={setvehicleKM}
                width="100"
                height={windowDimensions.height / 18}
                fontsize={windowDimensions.width / 40}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else
    return (
      // Main Division
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "white",
          width: "100%",
          height: "100vh",
          paddingTop: "15%",
        }}
      >
        {/* ISC Number */}
        <img
          alt=""
          src={require("../../assets/regNumber.svg")}
          onClick={checkTempCustomer}
          style={{
            position: "absolute",
            width: windowDimensions.width / 4,
            top: "9%",
            left: "4%",
            cursor: "pointer",
          }}
        />
        <div style={{ position: "absolute", top: "14%", left: "4%" }}>
          <div
            style={{
              width: windowDimensions.width / 5.5,
              height: windowDimensions.width / 35,
              backgroundColor: "#EEEEEE",
              borderRadius: 6,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input
              style={{
                width: windowDimensions.width / 4,
                fontFamily: "Montserrat",
                fontWeight: "700",
                borderRadius: 6,
                marginLeft: 20,
                fontSize: windowDimensions.width / 90,
                backgroundColor: "transparent",
              }}
              placeholder="Enter Vehicle No"
              onChange={(e) => setvehicleNumber(e.target.value)}
            />
          </div>
        </div>
        {/* ISC Number */}
        {/* 3 Columns */}
        {/* Customer Details */}
        <div
          style={{
            flex: 2,
            backgroundColor: "#EEEEEE",
            display: "block",
            borderRadius: 24,
            marginLeft: "4%",
            marginRight: 20,
            marginBottom: 40,
            height: "110%",
            width: "100%",
          }}
        >
          <img
            alt=""
            style={{
              margin: 20,
              display: "flex",
              justifyContent: "flex-end",
              height: windowDimensions.height / 15,
              width: "100%",
              marginLeft: windowDimensions.width / 17,
            }}
            src={require("../../assets/customerDetails.svg")}
          />
          <div style={{ paddingLeft: "8%" }}>
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Customer Name
            </label>
            <IcoInput
              value={customerName}
              holder={"Customer Name"}
              icon={
                <FcBusinessman style={{ width: windowDimensions.width / 60 }} />
              }
              type={"text"}
              setter={setcustomerName}
              width="78"
              height={windowDimensions.height / 18}
              fontsize={windowDimensions.width / 100}
            />
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                marginTop: "10px",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Mobile Number
            </label>
            <IcoInput
              value={customerMobile}
              holder={"Mobile Number"}
              icon={
                <FcPhoneAndroid
                  style={{ width: windowDimensions.width / 60 }}
                />
              }
              type={"text"}
              setter={setcustomerMobile}
              width="78"
              height={windowDimensions.height / 18}
              fontsize={windowDimensions.width / 100}
            />
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                marginTop: "10px",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Email Address
            </label>
            <IcoInput
              value={customerEmail}
              holder={"Email Address"}
              icon={
                <FcVoicemail style={{ width: windowDimensions.width / 60 }} />
              }
              type={"text"}
              setter={setcustomerEmail}
              width="78"
              height={windowDimensions.height / 18}
              fontsize={windowDimensions.width / 100}
            />
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                marginTop: "10px",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Full Address
            </label>
            <textarea
              style={{
                width: "87%",
                height: windowDimensions.height / 7,
                backgroundColor: "white",
                borderRadius: 10,
                padding: 20,
                borderColor: "white",
                fontSize: windowDimensions.width / 100,
              }}
              placeholder="Enter Full Address"
              onChange={(e) => setcustomerAddress(e.target.value)}
            />
          </div>
        </div>
        {/* Vehicle Details */}
        <div
          style={{
            flex: 2,
            backgroundColor: "#EEEEEE",
            display: "flex",
            borderRadius: 24,
            marginRight: 20,
            marginBottom: 40,
            height: "110%",
            width: "100%",
            flexDirection: "row",
          }}
        >
          <div style={{ flex: 1.15 }}>
            <img
              alt=""
              style={{
                margin: 20,
                display: "flex",
                justifyContent: "flex-end",
                height: windowDimensions.height / 15,
                width: "100%",
                marginLeft: windowDimensions.width / 20,
              }}
              src={require("../../assets/vehicleDetails.svg")}
            />
            <div style={{ paddingLeft: "10%" }}>
              <label
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: windowDimensions.height / 45,
                }}
              >
                Vehicle Company
              </label>
              <Drop
                val={vehicleCompany}
                holder={"Vehicle Company"}
                list={vehicalList}
                icon={
                  companyIcon ? (
                    <img
                      alt=""
                      src={companyIcon}
                      style={{ width: windowDimensions.width / 65 }}
                    />
                  ) : (
                    <FcNoIdea style={{ width: windowDimensions.width / 60 }} />
                  )
                }
                type={"drop"}
                setter={setvehicleCompany}
              />
              <label
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: windowDimensions.height / 45,
                  marginTop: 20,
                }}
              >
                Vehicle Model
              </label>
              <Drop
                val={vehicleName}
                holder={"Vehicle Model Name"}
                list={vehicleNameList}
                icon={
                  <FcAutomotive
                    style={{ width: windowDimensions.width / 60 }}
                  />
                }
                type={"drop"}
                setter={setvehicleName}
              />
              <label
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  marginTop: "20px",
                  fontSize: windowDimensions.height / 45,
                }}
              >
                Kilometer Completed
              </label>
              <IcoInput
                value={vehicleKM}
                holder={"Distance Covered KM"}
                icon={
                  <label
                    style={{
                      textAlign: "center",
                      fontFamily: "Montserrat",
                      fontSize: "1vw",
                      width: windowDimensions.width / 60,
                    }}
                  >
                    KM
                  </label>
                }
                type={"text"}
                setter={setvehicleKM}
              />
            </div>
          </div>
        </div>

        {/* Car SVG Right */}
        <div
          style={{
            flex: 0.75,
            display: "flex",
            height: "110%",
            width: "100%",
            marginBottom: 40,
          }}
        >
          <img
            alt=""
            style={{
              position: "absolute",
              right: 0,
              top: "24%",
              width: windowDimensions.width / 7.5,
              height: windowDimensions.height / 1.75,
            }}
            src={require("../../assets/CarSVGRight.svg")}
          />
        </div>
        {/* Main divison End */}
      </div>
    );
}
