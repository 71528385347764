import React, { useState, useEffect, createRef } from "react";
import Details from "./Screens/Details";
import db from "../firebase/database";
import { useLocation, useHistory } from "react-router-dom";
import "./Screens/Styling/Main.css";
import Loading from "../Components/Loading/Loading";
import ServicesScreen from "../AppScreens/Screens/ServicesScreen";
import moment from "moment";

export default function Packages() {
  const [loading, setloading] = useState(0);
  const [loading2, setloading2] = useState(0);
  const [loading3, setloading3] = useState(0);
  const servicesScroll = createRef();
  

  const scrollToServices = (event) => {
    //.current is verification that your element has rendered
    if (servicesScroll.current) {
      servicesScroll.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    } else {
      alert("Please Enter Valid Current Kilometer ");
    }
  };

  const history = useHistory();
  const location = useLocation();
  const [customerDetails, setCustomerDetails] = useState([]);
  const [customerServicesInfo, setcustomerServicesInfo] = useState([]);
  const [servicesInfo, setServicesInfo] = useState([]);
  const [totalServiceCount, settotalServiceCount] = useState(0);

  let beta = [];

  const [isc, setisc] = useState(location.isc);
  const [inBill, setInBill] = useState([]);
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [packPriceRef, setpackPriceRef] = useState("");
  const [currentKM, setcurrentKM] = useState("");
  // const [isclocal, setisclocal] = useState(localStorage.getItem('ISCNumber'));
  const arrayUnique = (array) => {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i].name === a[j].name) a.splice(j++, 1);
      }
    }

    beta = a;
  };

  arrayUnique(customerServicesInfo.concat(servicesInfo));

  useEffect(() => {
    if (isc) {
      db.collection("customers")
        .doc(isc)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            setCustomerDetails(doc.data());
            db.collection("packages")
              .doc(doc.data().PACKAGE)
              .get()
              .then((snap) => {
                if (doc.data().SIZE === "small")
                  setpackPriceRef(snap.data().cost.small);
                else if (doc.data().SIZE === "medium")
                  setpackPriceRef(snap.data().cost.medium);
                else if (doc.data().SIZE === "large")
                  setpackPriceRef(snap.data().cost.large);
                settotalServiceCount(snap.data().count);
                setloading(34);
                console.warn("loading:", loading);
              });
          } else {
            alert("No such Customer!");
          }
        })
        .catch(function (error) {
          alert("Error getting document:", error);
        });
      db.collection("extras")
        .doc("services")
        .get()
        .then((querySnapshot) => {
          const tmp = querySnapshot.data().services;

          // querySnapshot.forEach((documentSnapshot) => {
          //   tmp.push({
          //     ...documentSnapshot.data(),
          //     key: documentSnapshot.id,
          //   });
          // });

          setServicesInfo(tmp);
          setloading2(34);
          console.warn("loading2:", loading2);
        });
    }
  }, [isc, loading, loading2]);

  useEffect(() => {
    if (isc) {
      db.collection("customers")
        .doc(isc)
        .collection("services")
        .get()
        .then((querySnapshot) => {
          const tmp = [];

          querySnapshot.forEach((documentSnapshot) => {
            tmp.push({
              ...documentSnapshot.data(),
              key: documentSnapshot.id,
            });
          });

          setcustomerServicesInfo(tmp);
          setloading3(34);
          console.warn("loading3:", loading3);
        });

      // Unsubscribe from events when no longer in use
    }
  }, [isc, loading3]);
  let count = 0;
  customerServicesInfo.forEach((item) => {
    count = count + item.count;
  });

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let purchasedTimestamp = moment(customerDetails.PURCHASED, "DD/MM/YYYY");
  let dayPur = purchasedTimestamp.format("DD");
  let monthPur = purchasedTimestamp.format("MMMM");
  let yearPur = purchasedTimestamp.format("YYYY");

  let expiryTimestamp = moment(customerDetails.EXPIRY, "DD/MM/YYYY");
  let dayExp = expiryTimestamp.format("DD");
  let monthExp = expiryTimestamp.format("MMMM");
  let yearExp = expiryTimestamp.format("YYYY");
  const km = parseInt(customerDetails.KILOMETERDRIVEN);

  return (
    <div className="main-container-div">
      <Loading load={loading3 + loading2 + loading} />
      {window.innerWidth < 520 ? null : (
        <img
          alt=""
          className="scrollDownPackages"
          style={{ width: windowDimensions.width / 8 }}
          src={require("../assets/scrollDown.svg")}
          onClick={scrollToServices}
        />
      )}
      <img
        alt=""
        src={require("../assets/header.svg")}
        width="100%"
        style={{ top: 0, position: "absolute" }}
      />
      <div>
        <Details
          customerDetails={customerDetails}
          packPriceRef={packPriceRef}
          setcurrentKM={setcurrentKM}
          currentKM={currentKM}
          dayPur={dayPur}
          monthPur={monthPur}
          yearPur={yearPur}
          dayExp={dayExp}
          monthExp={monthExp}
          yearExp={yearExp}
          count={count}
          totalServiceCount={totalServiceCount}
        />
      </div>

      {currentKM > km ? (
        <div ref={servicesScroll}>
          <ServicesScreen
            iscNumber={isc}
            servicesInfo={servicesInfo}
            customerServicesInfo={customerServicesInfo}
            beta={beta}
            customerDetails={customerDetails}
            setter={setInBill}
            currentKM={currentKM}
            count={count}
            totalServiceCount={totalServiceCount}
          />
        </div>
      ) : null}
    </div>
  );
}
