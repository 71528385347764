import React, { useEffect, useState, createRef } from "react";
import IcoInput from "../Components/IcoInput";
import IcoButton from "../Components/IcoButton";
import Drop from "../Components/Drop";
import "./NonPackage.css";
import { FcPackage } from "react-icons/fc";
import db from "../firebase/database";
import firebase from "../firebase/firebase";
import InvoiceNonPackage from "../Components/InvoiceNonPackage/InvoiceNonPackage";
import "./Screens/Styling/Main.css";
import CardForServices from "../Components/CardForServices";
import DetailsServices from "./Screens/DetailsServices";
import ProductCard from "./ProductCard";
import { FcMenu } from "react-icons/fc";

export default function NonPackages() {
  const servicesScroll = createRef();

  const scrollToServices = (event) => {
    //.current is verification that your element has rendered
    if (servicesScroll.current) {
      servicesScroll.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    } else {
      alert("Please Fill All Above Details");
    }
  };

  const [checkMini, setcheckMini] = useState(false);

  const minimizer = () => {
    setcheckMini(!checkMini);
  };

  //Cusrtomer Details
  const [customerName, setcustomerName] = useState("");
  const [customerMobile, setcustomerMobile] = useState("");
  const [customerEmail, setcustomerEmail] = useState("");
  const [customerAddress, setcustomerAddress] = useState("");
  const [vehicleCompany, setvehicleCompany] = useState("");
  const [vehicleName, setvehicleName] = useState("");
  const [vehicleType, setvehicleType] = useState("");
  const [vehicleNumber, setvehicleNumber] = useState("");
  const [vehicleKM, setvehicleKM] = useState("");

  useEffect(() => {
    try {
      db.collection("VehicleCompany")
        .doc(vehicleCompany.value)
        .get()
        .then((item) => {
          item.data().VehicleName.forEach((det) => {
            if (det.name === vehicleName.value) {
              setvehicleType(det.type);
            }
          });
        });
    } catch (error) {
      console.log(error);
    }
  }, [vehicleName, vehicleCompany]);

  const customerDetails = {
    customerName: customerName,
    customerMobile: customerMobile,
    customerEmail: customerEmail,
    customerAddress: customerAddress,
    vehicleCompany: vehicleCompany.value,
    vehicleName: vehicleName.value,
    vehicleType: vehicleType,
    vehicleNumber: vehicleNumber,
    vehicleKM: vehicleKM,
  };

  function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  //Show Side DRawer
  const [show1, set1] = useState(true);
  const b1 = () => {
    set1(true);
    set2(false);
    set3(false);
    set4(false);
    set5(false);
  };
  const [show2, set2] = useState(false);
  const b2 = () => {
    set1(false);
    set2(true);
    set3(false);
    set4(false);
    set5(false);
  };
  const [show3, set3] = useState(false);
  const b3 = () => {
    set1(false);
    set2(false);
    set3(true);
    set4(false);
    set5(false);
  };
  const [show4, set4] = useState(false);
  const b4 = () => {
    set1(false);
    set2(false);
    set3(false);
    set4(true);
    set5(false);
  };
  const [show5, set5] = useState(false);
  const b5 = () => {
    set1(false);
    set2(false);
    set3(false);
    set4(false);
    set5(true);
  };
  //SUde Drawer

  //Services
  const [data, setData] = useState([]);
  const [bill, setBill] = useState([]);

  useEffect(() => {
    db.collection("extras")
      .doc("services")
      .get()
      .then(function (querySnapshot) {
        const temp = querySnapshot.data().services;
        // querySnapshot.forEach(function (doc) {
        //   temp.push(doc.data());
        // });
        setData(temp);
      });
  }, []);

  const showWheelServices = (item) => {
    if (item.category === "Wheel Service") {
      return (
        // <Card
        //   key={item.name}
        //   header={item.name}
        //   time={item.time}
        //   money={
        //     vehicleType.value === "small"
        //       ? item.small
        //       : vehicleType.value === "medium"
        //       ? item.medium
        //       : vehicleType.value === "large"
        //       ? item.large
        //       : 0
        //   }
        //   type={vehicleType.value}
        //   onClick={() => {
        //     let index = null;
        //     index = bill.findIndex((x) => x.name === item.name);
        //     if (index === -1) {
        //       setBill([...bill, item]);
        //     } else {
        //       let temp = bill;

        //       temp = temp.slice(index, 1);
        //     }
        //   }}
        // />

        <CardForServices
          val={bill}
          button={bill.includes(item) ? false : true}
          det={item}
          dat={customerDetails}
          add={() => {
            let tempArr = bill;
            const index = tempArr.indexOf(item);
            if (index > -1) {
              tempArr.splice(index, 1);
              tempArr.push({ ...item, time: 0 });
              setBill([...tempArr]);
            }

            setBill([...bill, item]);
            console.log(bill);
          }}
          remove={() => {
            let tempArr = bill;
            let tempDet = { ...item, time: 0 };
            const index = tempArr.indexOf(item);
            // const indexTemp = tempArr.indexOf(tempDet);

            const indexTemp = tempArr.findIndex((x) => x.name === item.name);
            if (indexTemp > -1) {
              console.log(indexTemp);
              tempArr.splice(indexTemp, 1);
            }
            if (index > -1) {
              tempArr.splice(index, 1);
            }
            setBill([...tempArr]);
            console.log(bill);
          }}
          onPress={() => {
            if (!bill.includes(item)) {
              setBill([...bill, item]);
              console.log(bill);
            } else {
              let tempArr = bill;
              const index = tempArr.indexOf(item);
              if (index > -1) {
                tempArr.splice(index, 1);
              }
              setBill([...tempArr]);
            }
          }}
        />
      );
    }
  };

  const showExteriorCleaning = (item) => {
    if (vehicleName.value === "Bike") {
      if (
        item.category === "Exterior Cleaning" &&
        (item.name.includes("Bike") || item.name.includes("bike"))
      ) {
        return (
          <CardForServices
            val={bill}
            button={bill.includes(item) ? false : true}
            det={item}
            dat={customerDetails}
            add={() => {
              setBill([...bill, item]);
            }}
            remove={() => {
              let tempArr = bill;
              const index = tempArr.indexOf(item);
              if (index > -1) {
                tempArr.splice(index, 1);
              }
              setBill([...tempArr]);
            }}
            onPress={() => {
              if (!bill.includes(item)) {
                setBill([...bill, item]);
                console.log(bill);
              } else {
                let tempArr = bill;
                const index = tempArr.indexOf(item);
                if (index > -1) {
                  tempArr.splice(index, 1);
                }
                setBill([...tempArr]);
              }
            }}
          />
        );
      }
    } else if (
      item.category === "Exterior Cleaning" &&
      !item.name.includes("Bike") &&
      !item.name.includes("bike")
    ) {
      return (
        <CardForServices
          val={bill}
          button={bill.includes(item) ? false : true}
          det={item}
          dat={customerDetails}
          add={() => {
            setBill([...bill, item]);
          }}
          remove={() => {
            let tempArr = bill;
            const index = tempArr.indexOf(item);
            if (index > -1) {
              tempArr.splice(index, 1);
            }
            setBill([...tempArr]);
          }}
          onPress={() => {
            if (!bill.includes(item)) {
              setBill([...bill, item]);
              console.log(bill);
            } else {
              let tempArr = bill;
              const index = tempArr.indexOf(item);
              if (index > -1) {
                tempArr.splice(index, 1);
              }
              setBill([...tempArr]);
            }
          }}
        />
      );
    }
  };

  const showInteriorCleaning = (item) => {
    if (item.category === "Interior Cleaning") {
      return (
        // <Card
        //   key={item.name}
        //   header={item.name}
        //   time={item.time}
        //   money={
        //     vehicleType.value === "small"
        //       ? item.small
        //       : vehicleType.value === "medium"
        //       ? item.medium
        //       : vehicleType.value === "large"
        //       ? item.large
        //       : 0
        //   }
        //   type={vehicleType.value}
        //   onClick={() => {
        //     let index = null;
        //     index = bill.findIndex((x) => x.name === item.name);
        //     if (index === -1) {
        //       setBill([...bill, item]);
        //     } else {
        //       let temp = bill;

        //       temp = temp.slice(index, 1);
        //     }
        //   }}
        // />
        <CardForServices
          val={bill}
          button={bill.includes(item) ? false : true}
          det={item}
          dat={customerDetails}
          add={() => {
            setBill([...bill, item]);
          }}
          remove={() => {
            let tempArr = bill;
            const index = tempArr.indexOf(item);
            if (index > -1) {
              tempArr.splice(index, 1);
            }
            setBill([...tempArr]);
          }}
          onPress={() => {
            if (!bill.includes(item)) {
              setBill([...bill, item]);
              console.log(bill);
            } else {
              let tempArr = bill;
              const index = tempArr.indexOf(item);
              if (index > -1) {
                tempArr.splice(index, 1);
              }
              setBill([...tempArr]);
            }
          }}
        />
      );
    }
  };

  const showEngineServices = (item) => {
    if (item.category === "Engine Service") {
      return (
        // <Card
        //   key={item.name}
        //   header={item.name}
        //   time={item.time}
        //   money={
        //     vehicleType.value === "small"
        //       ? item.small
        //       : vehicleType.value === "medium"
        //       ? item.medium
        //       : vehicleType.value === "large"
        //       ? item.large
        //       : 0
        //   }
        //   type={vehicleType.value}
        //   onClick={() => {
        //     let index = null;
        //     index = bill.findIndex((x) => x.name === item.name);
        //     if (index === -1) {
        //       setBill([...bill, item]);
        //     } else {
        //       let temp = bill;

        //       temp = temp.slice(index, 1);
        //     }
        //   }}
        // />
        <CardForServices
          val={bill}
          button={bill.includes(item) ? false : true}
          det={item}
          dat={customerDetails}
          add={() => {
            setBill([...bill, item]);
          }}
          remove={() => {
            let tempArr = bill;
            const index = tempArr.indexOf(item);
            if (index > -1) {
              tempArr.splice(index, 1);
            }
            setBill([...tempArr]);
          }}
          onPress={() => {
            if (!bill.includes(item)) {
              setBill([...bill, item]);
              console.log(bill);
            } else {
              let tempArr = bill;
              const index = tempArr.indexOf(item);
              if (index > -1) {
                tempArr.splice(index, 1);
              }
              setBill([...tempArr]);
            }
          }}
        />
      );
    }
  };

  const showOtherServices = (item) => {
    if (item.category === "Other Services") {
      return (
        // <Card
        //   key={item.name}
        //   header={item.name}
        //   time={item.time}
        //   money={
        //     vehicleType.value === "small"
        //       ? item.small
        //       : vehicleType.value === "medium"
        //       ? item.medium
        //       : vehicleType.value === "large"
        //       ? item.large
        //       : 0
        //   }
        //   type={vehicleType.value}
        //   onClick={() => {
        //     let index = null;
        //     index = bill.findIndex((x) => x.name === item.name);
        //     if (index === -1) {
        //       setBill([...bill, item]);
        //     } else {
        //       let temp = bill;

        //       temp = temp.slice(index, 1);
        //     }
        //   }}
        // />
        <CardForServices
          val={bill}
          button={bill.includes(item) ? false : true}
          det={item}
          dat={customerDetails}
          add={() => {
            setBill([...bill, item]);
          }}
          remove={() => {
            let tempArr = bill;
            const index = tempArr.indexOf(item);
            if (index > -1) {
              tempArr.splice(index, 1);
            }
            setBill([...tempArr]);
          }}
          onPress={() => {
            if (!bill.includes(item)) {
              setBill([...bill, item]);
              console.log(bill);
            } else {
              let tempArr = bill;
              const index = tempArr.indexOf(item);
              if (index > -1) {
                tempArr.splice(index, 1);
              }
              setBill([...tempArr]);
            }
          }}
        />
      );
    }
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const showInvoice = ({ customerDetails, bill }) => {
    if (customerName === "" || vehicleNumber === "" || vehicleType === "")
      return;
    else {
      console.log("hello", bill);
      return (
        <InvoiceNonPackage
          setter={setBill}
          customerDetails={customerDetails}
          bill={bill}
        />
      );
    }
  };
  return (
    <div>
      <img
        alt=""
        src={require("../assets/header.svg")}
        width="100%"
        style={{ top: 0, position: "absolute" }}
      />
      {window.innerWidth < 520 ? null : (
        <img
          alt=""
          style={{ width: windowDimensions.width / 8 }}
          className="scrollDown"
          src={require("../assets/scrollDown.svg")}
          onClick={scrollToServices}
        />
      )}
      {/* Customer Details */}
      {/* <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            display: "grid",
            borderRadius: 24,
            flex: 2,
            marginLeft: 20,
            marginRight: 20,
            paddingInline: 20,
          }}
          className="cust-box"
        >
          <label>Customer Details</label>
          {inputHandler(getDetArr)}
        </div>
        <div
          style={{
            display: "grid",
            borderRadius: 24,
            flex: 3,
            marginLeft: 20,
            marginRight: 20,
            paddingInline: 20,
          }}
          className="veh-box"
        >
          <label>Vehicle Details</label>
          <br />
          {inputHandler(getDetArrVehicle)}
        </div>
      </div>
      Services Details */}
      <DetailsServices
        setcustomerName={setcustomerName}
        setcustomerMobile={setcustomerMobile}
        setcustomerEmail={setcustomerEmail}
        setcustomerAddress={setcustomerAddress}
        setvehicleCompany={setvehicleCompany}
        setvehicleName={setvehicleName}
        setvehicleType={setvehicleType}
        setvehicleNumber={setvehicleNumber}
        setvehicleKM={setvehicleKM}
        customerName={customerName}
        customerMobile={customerMobile}
        customerAddress={customerAddress}
        customerEmail={customerEmail}
        vehicleCompany={vehicleCompany}
        vehicleName={vehicleName}
        vehicleType={vehicleType}
        vehicleNumber={vehicleNumber}
        vehicleKM={vehicleKM}
      />
      {vehicleKM === "" ? (
        <label
          style={{
            width: windowDimensions.width,
            textAlign: "center",
            fontSize: windowDimensions.width / 80,
            fontWeight: "700",
            color: "black",
            opacity: 0.5,
            paddingTop: "7vw",
          }}
        >
          Enter All Necessary Details Above to Proceed
        </label>
      ) : (
        <div ref={servicesScroll}>
          <div class="service-header">
            <span
              style={{
                fontWeight: "300",
              }}
            >
              Choose Your&nbsp;
            </span>
            Services
          </div>
          <div
            style={{
              width: windowDimensions.width / 1.05,
              height: windowDimensions.height / 1.05,
              backgroundColor: "#EEEEEE",
              marginTop: 25,
            }}
            class="mainSabsePeeche"
          >
            {window.innerWidth < 520 ? (
              <label
                style={{
                  position: "absolute",
                  fontSize: "7vw",
                  marginLeft: "2vw",
                  cursor: "pointer",
                }}
                onClick={minimizer}
              >
                <FcMenu />
              </label>
            ) : null}
            {window.innerWidth < 520 && checkMini === true ? null : (
              <>
                <div
                  className="aniDraw"
                  style={{
                    backgroundColor: "#DBDBDB",
                    display: "flex",
                    flex: window.innerWidth < 520 ? 4 : 1,
                    borderTopLeftRadius: 24,
                    borderBottomLeftRadius: 24,
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      height: "20%",
                      borderTopLeftRadius: 24,
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    class={
                      show1 === true ? "drawerActiveColor" : "drawerItemLeft"
                    }
                    onClick={b1}
                  >
                    <text
                      style={{
                        fontSize:
                          window.innerWidth < 520
                            ? windowDimensions.width / 30
                            : windowDimensions.width / 60,
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                      }}
                    >
                      Wheel<text style={{ fontWeight: "400" }}> Services</text>
                    </text>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      height: "20%",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    class={
                      show2 === true ? "drawerActiveColor" : "drawerItemLeft"
                    }
                    onClick={b2}
                  >
                    <text
                      style={{
                        fontSize:
                          window.innerWidth < 520
                            ? windowDimensions.width / 30
                            : windowDimensions.width / 60,
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                      }}
                    >
                      Exerior
                      <text style={{ fontWeight: "400" }}> Cleaning</text>
                    </text>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      height: "20%",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    class={
                      show3 === true ? "drawerActiveColor" : "drawerItemLeft"
                    }
                    onClick={b3}
                  >
                    <text
                      style={{
                        fontSize:
                          window.innerWidth < 520
                            ? windowDimensions.width / 30
                            : windowDimensions.width / 60,
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                      }}
                    >
                      Interior
                      <text style={{ fontWeight: "400" }}> Cleaning</text>
                    </text>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      height: "20%",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    class={
                      show4 === true ? "drawerActiveColor" : "drawerItemLeft"
                    }
                    onClick={b4}
                  >
                    <text
                      style={{
                        fontSize:
                          window.innerWidth < 520
                            ? windowDimensions.width / 30
                            : windowDimensions.width / 60,
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                      }}
                    >
                      Engine<text style={{ fontWeight: "400" }}> Services</text>
                    </text>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      borderBottomLeftRadius: 24,
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    class={
                      show5 === true ? "drawerActiveColor" : "drawerItemLeft"
                    }
                    onClick={b5}
                  >
                    <text
                      style={{
                        fontSize:
                          window.innerWidth < 520
                            ? windowDimensions.width / 30
                            : windowDimensions.width / 60,
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                      }}
                    >
                      Other<text style={{ fontWeight: "400" }}> Services</text>
                    </text>
                  </div>
                </div>
              </>
            )}
            <div
              style={{
                display: "flex",
                flex: window.innerWidth < 520 ? 1 : 4,
                borderTopRightRadius: 24,
                borderBottomRightRadius: 24,
                marginLeft:
                  window.innerWidth < 520 && checkMini === true ? "8vw" : 0,
              }}
              class="servicesSection"
            >
              {show1 === true ? data.map(showWheelServices) : null}
              {show2 === true ? data.map(showExteriorCleaning) : null}
              {show3 === true ? data.map(showInteriorCleaning) : null}
              {show4 === true ? data.map(showEngineServices) : null}
              {show5 === true ? (
                <ProductCard
                  bill={bill}
                  setter={setBill}
                  vehicleTypeDecider={customerDetails.vehicleType}
                />
              ) : null}
            </div>
          </div>
          <div>{showInvoice({ customerDetails, bill })}</div>
        </div>
      )}
    </div>
  );
}
