import React, { useEffect, useState } from "react";
import "./AdminApp.css";
import CreateCustomerEmployee from "./CreateCustomerEmployee";
import Inventory from "../Components/Inventory/Inventory";
import { useHistory } from "react-router-dom";

export default function EmployeePage() {
    const history = useHistory();

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height,
        };
    }
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    //Side drawer
    const [show4, set4] = useState(false);
    const b4 = () => {
        set4(true);
        set5(false);
    };
    const [show5, set5] = useState(true);
    const b5 = () => {
        set4(false);
        set5(true);
    };

    return (
        <div className="main-div">
            <div className="contain">
                <div class="drawerNewButtons">
                    <div
                        class={show5 === true ? "DrawerItemActiveEmp" : "DrawerItemEmp"}
                        onClick={b5}
                    >
                        <p
                            class="title1"
                            style={{ fontSize: windowDimensions.width / 70 }}
                        >
                            Inven<b class="title2">tory</b>
                        </p>
                    </div>
                    <div
                        class={show4 === true ? "DrawerItemActiveEmp" : "DrawerItemEmp"}
                        onClick={b4}
                    >
                        <p
                            class="title1"
                            style={{ fontSize: windowDimensions.width / 70 }}
                        >
                            Add <b class="title2">Customer</b>
                        </p>
                    </div>
                </div>
                <div class="servicesNewSetion">
                    {show4 === true ? <CreateCustomerEmployee /> : null}
                    {show5 === true ? <Inventory /> : null}
                </div>
            </div>
        </div>
    );
}
