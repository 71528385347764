import React, { useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import AddIcon from "@material-ui/icons/Add";
import { IconButton } from "@material-ui/core";
import "./AdminApp.css";
import AdminServiceCard from "../Components/Admin Cards/AdminServiceCard.js";
import MenuIcon from "@material-ui/icons/Menu";
import PackageScreen from "./PackageScreen";
import SeePackage from "./SeePackage";
import SalesTable from "../Components/SalesTable/SalesTable.js";
import DashBoard from "./DashBoard";
import CreateEmp from "./CreateEmp";
import CustomersTable from "../Components/Customers/CustomersTable.js";
import db from "../firebase/database";
import firebase from "../firebase/firebase";
import Employee from "../Components/Employees/Employee";
import Drop from "../Components/Drop";
import { FcAssistant, FcPrivacy, FcInspection } from "react-icons/fc"; //Import Icon
import CreateCustomer from "./CreateCustomer";
import Products from "./Products";
import Inventory from "../Components/Inventory/Inventory";
import CreateService from "./CreateService";
import "./Login.css";
import "./PackageScreen.css";
import IcoInput from "../Components/IcoInput";
import IcoButton from "../Components/IcoButton";
import AdminLogin from "./AdminLogin";
import { useHistory } from "react-router-dom";

export default function AdminApp() {
  const history = useHistory();

  const [adminVerified, setAdminVerified] = useState(false); //set False Later
  const [adminType, setAdminType] = useState(""); //set False Later
  const [wheelServices, setWheelServices] = useState([]);
  const [exteriorCleaning, setExteriorCleaning] = useState([]);
  const [interiorCleaning, setInteriorCleaning] = useState([]);
  const [engineServices, setEngineServices] = useState([]);
  const [otherServices, setOtherServices] = useState([]);
  const [paintServices, setPaintServices] = useState([]);
  const [labourCharges, setLabourCharges] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [iscNumber, setiscNumber] = useState();
  const [servicePackage, setservicePackage] = useState({});

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const emailSetter = (event) => {
    setEmail(event.target.value);
  };
  const passwordSetter = (event) => {
    setPassword(event.target.value);
  };
  const verifyAdmin = () => {
    if (email === "ankit@isc" && password === "ankit@isc") {
      setAdminVerified(true);
      setAdminType("sagar");
      window.sessionStorage.setItem("adminLogin", "true");
      window.sessionStorage.setItem("adminType", "sagar");
    } else if (email === "banda@isc" && password === "banda@isc") {
      setAdminVerified(true);
      setAdminType("banda");

      window.sessionStorage.setItem("adminLoginBanda", "true");
      window.sessionStorage.setItem("adminType", "banda");
    } else if (email === "emp" && password === "emp") history.push("/employee");
    else alert("Wrong Email or Password");
  };

  let loginChecker = window.sessionStorage.getItem("adminLogin");

  const [serviceList, setServiceList] = useState();
  useEffect(() => {
    const subscriber = db.collection("services").onSnapshot((querySnapshot) => {
      const tmp = [];

      querySnapshot.forEach((documentSnapshot) => {
        tmp.push({
          ...documentSnapshot.data(),
          key: documentSnapshot.id,
        });
      });

      setServiceList(tmp);
    });

    // Unsubscribe from events when no longer in use
    return () => subscriber();
  }, []);

  useEffect(() => {
    const subscriber = db
      .collection("services")
      .where("category", "==", "Wheel Service")
      .onSnapshot((querySnapshot) => {
        const tmp = [];

        querySnapshot.forEach((documentSnapshot) => {
          tmp.push({
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          });
        });
        console.log(tmp);
        setWheelServices(tmp);
      });

    // Unsubscribe from events when no longer in use
    return () => subscriber();
  }, []);
  const [packList, setPackList] = useState([]);
  useEffect(() => {
    db.collection("packages")
      .get()
      .then((item) => {
        let list = [];
        item.forEach((item) => {
          list.push(item.data());
        });
        setPackList(list);
      });
    const subscriber = db
      .collection("services")
      .where("category", "==", "Exterior Cleaning")
      .onSnapshot((querySnapshot) => {
        const tmp = [];

        querySnapshot.forEach((documentSnapshot) => {
          tmp.push({
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          });
        });

        setExteriorCleaning(tmp);
      });

    // Unsubscribe from events when no longer in use
    return () => subscriber();
  }, []);
  useEffect(() => {
    const subscriber = db
      .collection("services")
      .where("category", "==", "Interior Cleaning")
      .onSnapshot((querySnapshot) => {
        const tmp = [];

        querySnapshot.forEach((documentSnapshot) => {
          tmp.push({
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          });
        });

        setInteriorCleaning(tmp);
      });

    // Unsubscribe from events when no longer in use
    return () => subscriber();
  }, []);
  const [servicedrawer, setSerivceDrawer] = useState(false);
  useEffect(() => {
    const subscriber = db
      .collection("services")
      .where("category", "==", "Engine Service")
      .onSnapshot((querySnapshot) => {
        const tmp = [];

        querySnapshot.forEach((documentSnapshot) => {
          tmp.push({
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          });
        });

        setEngineServices(tmp);
      });

    // Unsubscribe from events when no longer in use
    return () => subscriber();
  }, []);
  // useEffect(() => {
  //   const subscriber = db
  //     .collection("services")
  //     .where("category", "==", "Other Services")
  //     .onSnapshot((querySnapshot) => {
  //       const tmp = [];

  //       querySnapshot.forEach((documentSnapshot) => {
  //         tmp.push({
  //           ...documentSnapshot.data(),
  //           key: documentSnapshot.id,
  //         });
  //       });

  //       setOtherServices(tmp);
  //     });

  //   // Unsubscribe from events when no longer in use
  //   return () => subscriber();
  // }, []);

  useEffect(() => {
    const subscriber = db
      .collection("paintCost")
      .where("category", "==", "Paint Service")
      .onSnapshot((querySnapshot) => {
        const tmp = [];

        querySnapshot.forEach((documentSnapshot) => {
          tmp.push({
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          });
        });

        setPaintServices(tmp);
      });

    // Unsubscribe from events when no longer in use
    return () => subscriber();
  }, []);

  useEffect(() => {
    const subscriber = db
      .collection("labourCost")
      .where("category", "==", "Labour Cost")
      .onSnapshot((querySnapshot) => {
        const tmp = [];

        querySnapshot.forEach((documentSnapshot) => {
          tmp.push({
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          });
        });

        setLabourCharges(tmp);
      });

    // Unsubscribe from events when no longer in use
    return () => subscriber();
  }, []);

  //Services
  const [name, setName] = useState("");
  const [category, setCategory] = useState("Wheel Service");
  const [time, setTime] = useState("");
  const [small, setsmall] = useState(0);
  const [medium, setmedium] = useState(0);
  const [large, setlarge] = useState(0);

  const uploadService = (evt) => {
    evt.preventDefault();
    db.collection("services")
      .doc(name)
      .set({
        name: name,
        category: category,
        time: time,
        small: small,
        medium: medium,
        large: large,
        count: 0,
      })
      .then(function () {
        alert("Service Added");
      })
      .catch(function (error) {
        alert(error);
      });
  };

  //Display Service Cards
  const showCard = (item) => {
    return (
      <AdminServiceCard
        name={item.name}
        time={item.time}
        small={item.small}
        medium={item.medium}
        large={item.large}
        type={item.category}
        adminType={adminType}
      />
    );
  };
  const doWithKeyPress = (event) => {
    if (event.keyCode === 13) {
      console.log("enter");
      verifyAdmin();
    }
  };

  //Employee
  const [employeeName, setEmployeeName] = useState("");
  const [employeePin, setEmployeePin] = useState("1000");

  const uploadEmployee = (evt) => {
    evt.preventDefault();
    db.collection("employee")
      .doc(employeePin)
      .set({
        employeeName: employeeName,
        employeePin: employeePin,
      })
      .then(function () {
        alert("Employee Added");
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const employeeShow = (item) => {
    return (
      <Employee
        employeeName={item.employeeName}
        employeePin={item.employeePin}
      />
    );
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  //Top taps bat

  const [shows1, setShows1] = useState(false);
  const [shows2, setShows2] = useState(false);
  const [shows3, setShows3] = useState(false);
  const [shows4, setShows4] = useState(false);
  const [shows5, setShows5] = useState(false);
  const [shows6, setShows6] = useState(false);
  const bt1 = () => {
    setShows1(true);
    setShows2(false);
    setShows3(false);
    setShows4(false);
    setShows5(false);
    setShows6(false);
  };
  const bt2 = () => {
    setShows1(false);
    setShows2(true);
    setShows3(false);
    setShows4(false);
    setShows5(false);
    setShows6(false);
  };
  const bt3 = () => {
    setShows1(false);
    setShows2(false);
    setShows3(true);
    setShows4(false);
    setShows5(false);
    setShows6(false);
  };
  const bt4 = () => {
    setShows1(false);
    setShows2(false);
    setShows3(false);
    setShows4(true);
    setShows5(false);
    setShows6(false);
  };
  const bt5 = () => {
    setShows1(false);
    setShows2(false);
    setShows3(false);
    setShows4(false);
    setShows5(true);
    setShows6(false);
  };
  const bt6 = () => {
    setShows1(false);
    setShows2(false);
    setShows3(false);
    setShows4(false);
    setShows5(false);
    setShows6(true);
  };

  //Side drawer
  const [show1, set1] = useState(false);
  const b1 = () => {
    set1(true);
    set2(false);
    set3(false);
    set4(false);
    set5(false);
    set6(false);
  };
  const [show2, set2] = useState(false);
  const b2 = () => {
    set1(false);
    set2(true);
    set3(false);
    set4(false);
    set5(false);
    set6(false);
  };
  const [show3, set3] = useState(false);
  const b3 = () => {
    set1(false);
    set2(false);
    set3(true);
    set4(false);
    set5(false);
    set6(false);
  };
  console.log(adminType);
  const [show4, set4] = useState(false);
  const b4 = () => {
    set1(false);
    set2(false);
    set3(false);
    set4(true);
    set5(false);
    set6(false);
  };
  // const [show5, set5] = useState(true);
  const [show5, set5] = useState(false);
  const b5 = () => {
    set1(false);
    set2(false);
    set3(false);
    set4(false);
    set5(true);
    set6(false);
  };
  const [show6, set6] = useState(true);
  const b6 = () => {
    set1(false);
    set2(false);
    set3(false);
    set4(false);
    set5(false);
    set6(true);
  };

  if (adminVerified === false && loginChecker === null) {
    return (
      <AdminLogin
        windowDimensions={windowDimensions}
        email={email}
        emailSetter={emailSetter}
        password={password}
        passwordSetter={passwordSetter}
        doWithKeyPress={doWithKeyPress}
        verifyAdmin={verifyAdmin}
      />
    );
  }
  if (
    (adminVerified === true || loginChecker === "true") &&
    adminType === "banda"
  ) {
    return (
      <div className="main-div">
        <div className="flo" onClick={() => setSerivceDrawer(!servicedrawer)}>
          <IconButton
            style={{
              backgroundColor: "#060930",
              color: "#ebebeb",
            }}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <div className="draverMobile">
          <Drawer
            anchor="right"
            open={servicedrawer}
            onClose={() => setSerivceDrawer(!servicedrawer)}
          >
            {/* <div
          class={show5 === true ? "DrawerItemActiveMini" : "DrawerItemMini"}
          onClick={b5}
        >
          <p style={{ fontSize: "20px" }}>
            Dash<b class="title2new">Board</b>
          </p>
        </div> */}
            <div
              class={show6 === true ? "DrawerItemActiveMini" : "DrawerItemMini"}
              onClick={b6}
            >
              <p style={{ fontSize: "20px" }}>
                View <b class="title2new">Reports</b>
              </p>
            </div>

            <div
              class={show3 === true ? "DrawerItemActiveMini" : "DrawerItemMini"}
              onClick={b3}
            >
              <p style={{ fontSize: "20px" }}>
                Inven<b class="title2new">tory</b>
              </p>
            </div>
            <div
              class={show4 === true ? "DrawerItemActiveMini" : "DrawerItemMini"}
              onClick={b4}
            >
              <p style={{ fontSize: "20px" }}>
                Manage <b class="title2new">Customer</b>
              </p>
            </div>
            <div
              class={show1 === true ? "DrawerItemActiveMini" : "DrawerItemMini"}
              onClick={b1}
            >
              <p style={{ fontSize: "20px" }}>
                Create <b class="title2new">Service</b>
              </p>
            </div>
            <div
              class={show2 === true ? "DrawerItemActiveMini" : "DrawerItemMini"}
              onClick={b2}
            >
              <p style={{ fontSize: "20px" }}>
                Create <b class="title2new">Package</b>
              </p>
            </div>
          </Drawer>
        </div>
        <div class="drawerNewButtons">
          <div
            class={show6 === true ? "DrawerItemActive" : "DrawerItem"}
            onClick={b6}
          >
            <p
              class="title1new"
              style={{ fontSize: windowDimensions.width / 70 }}
            >
              View <b class="title2new">Reports</b>
            </p>
          </div>
          <div
            class={show3 === true ? "DrawerItemActive" : "DrawerItem"}
            onClick={b3}
          >
            <p
              class="title1new"
              style={{ fontSize: windowDimensions.width / 70 }}
            >
              Inven<b class="title2new">tory</b>
            </p>
          </div>

          <div
            class={show4 === true ? "DrawerItemActive" : "DrawerItem"}
            onClick={b4}
          >
            <p
              class="title1new"
              style={{ fontSize: windowDimensions.width / 70 }}
            >
              Manage <b class="title2new">Customer</b>
            </p>
          </div>
          <div
            class={show1 === true ? "DrawerItemActive" : "DrawerItem"}
            onClick={b1}
          >
            <p
              class="title1new"
              style={{ fontSize: windowDimensions.width / 70 }}
            >
              View <b class="title2new">Service</b>
            </p>
          </div>
          <div
            class={show2 === true ? "DrawerItemActive" : "DrawerItem"}
            onClick={b2}
          >
            <p
              class="title1new"
              style={{ fontSize: windowDimensions.width / 70 }}
            >
              View <b class="title2new">Package</b>
            </p>
          </div>
        </div>
        <div class="servicesNewSetion">
          {show1 === true ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <CreateService />
              </div>
              <div
                className="Top-tap-package"
                style={{ paddingLeft: window.innerWidth < 520 ? null : "5.8%" }}
              >
                <div
                  className="drawerBackThing"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "white",
                    // width: '90%',
                    borderRadius: "1vw",
                    padding: window.innerWidth < 520 ? "2vw" : null,
                  }}
                >
                  <div
                    style={{ cursor: "pointer" }}
                    className={
                      shows1 === true
                        ? "ActiveCard-package"
                        : "InactiveCardPack"
                    }
                    onClick={bt1}
                  >
                    <p className="tile1Pack">
                      Wheel <b className="tile2Pack">Services</b>
                    </p>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className={
                      shows2 === true
                        ? "ActiveCard-package"
                        : "InactiveCardPack"
                    }
                    onClick={bt2}
                  >
                    <p className="tile1Pack">
                      Exterior <b className="tile2Pack">Cleaning</b>
                    </p>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className={
                      shows3 === true
                        ? "ActiveCard-package"
                        : "InactiveCardPack"
                    }
                    onClick={bt3}
                  >
                    <p className="tile1Pack">
                      Interior <b className="tile2Pack">Cleaning</b>
                    </p>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className={
                      shows4 === true
                        ? "ActiveCard-package"
                        : "InactiveCardPack"
                    }
                    onClick={bt4}
                  >
                    <p className="tile1Pack">
                      Engine <b className="tile2Pack">Services</b>
                    </p>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className={
                      shows5 === true
                        ? "ActiveCard-package"
                        : "InactiveCardPack"
                    }
                    onClick={bt5}
                  >
                    <p className="tile1Pack">
                      Paint <b className="tile2Pack">Services</b>
                    </p>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className={
                      shows6 === true
                        ? "ActiveCard-package"
                        : "InactiveCardPack"
                    }
                    onClick={bt6}
                  >
                    <p className="tile1Pack">
                      Labour <b className="tile2Pack">Charges</b>
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <hr
                  style={{
                    width: "55vw",
                    backgroundColor: "#fe5236",
                    opacity: 0.2,
                  }}
                />
              </div>
              {shows1 === true ? (
                <div
                  style={{
                    width: "95%",
                    display: "flex",
                    flexWrap: "wrap",
                    marginLeft: "-1.5%",
                  }}
                  className="package-screen-container"
                >
                  {wheelServices.map(showCard)}
                </div>
              ) : null}
              {shows2 === true ? (
                <div
                  style={{
                    width: "95%",
                    display: "flex",
                    flexWrap: "wrap",
                    marginLeft: "-1.5%",
                  }}
                  className="package-screen-container"
                >
                  {exteriorCleaning.map(showCard)}
                </div>
              ) : null}
              {shows3 === true ? (
                <div
                  style={{
                    width: "95%",
                    display: "flex",
                    flexWrap: "wrap",
                    marginLeft: "-1.5%",
                  }}
                  className="package-screen-container"
                >
                  {interiorCleaning.map(showCard)}
                </div>
              ) : null}
              {shows4 === true ? (
                <div
                  style={{
                    width: "95%",
                    display: "flex",
                    flexWrap: "wrap",
                    marginLeft: "-1.5%",
                  }}
                  className="package-screen-container"
                >
                  {engineServices.map(showCard)}
                </div>
              ) : null}
              {shows5 === true ? (
                <div
                  style={{
                    width: "95%",
                    display: "flex",
                    flexWrap: "wrap",
                    marginLeft: "4.5%",
                  }}
                  className="package-screen-container"
                >
                  {paintServices.map(showCard)}
                </div>
              ) : null}
              {shows6 === true ? (
                <div
                  style={{
                    width: "95%",
                    display: "flex",
                    flexWrap: "wrap",
                    marginLeft: "4.5%",
                  }}
                  className="package-screen-container"
                >
                  {labourCharges.map(showCard)}
                </div>
              ) : null}
            </div>
          ) : null}

          {show3 === true ? <Inventory /> : null}
          {show4 === true ? <CreateCustomer /> : null}
          {show2 === true ? (
            <PackageScreen list={serviceList} adminType={adminType} />
          ) : null}
          {show6 === true ? <SalesTable /> : null}
        </div>
      </div>
    );
  }
  if (
    (adminVerified === true || loginChecker === "true") &&
    adminType === "sagar"
  ) {
    return (
      <div className="main-div">
        <div className="flo" onClick={() => setSerivceDrawer(!servicedrawer)}>
          <IconButton
            style={{
              backgroundColor: "#060930",
              color: "#ebebeb",
            }}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <div className="draverMobile">
          <Drawer
            anchor="right"
            open={servicedrawer}
            onClose={() => setSerivceDrawer(!servicedrawer)}
          >
            <div
              class={show6 === true ? "DrawerItemActiveMini" : "DrawerItemMini"}
              onClick={b6}
            >
              <p style={{ fontSize: "20px" }}>
                View <b class="title2new">Reports</b>
              </p>
            </div>

            <div
              class={show3 === true ? "DrawerItemActiveMini" : "DrawerItemMini"}
              onClick={b3}
            >
              <p style={{ fontSize: "20px" }}>
                Inven<b class="title2new">tory</b>
              </p>
            </div>
            <div
              class={show4 === true ? "DrawerItemActiveMini" : "DrawerItemMini"}
              onClick={b4}
            >
              <p style={{ fontSize: "20px" }}>
                Manage <b class="title2new">Customer</b>
              </p>
            </div>
            <div
              class={show1 === true ? "DrawerItemActiveMini" : "DrawerItemMini"}
              onClick={b1}
            >
              <p style={{ fontSize: "20px" }}>
                Create <b class="title2new">Service</b>
              </p>
            </div>
            <div
              class={show2 === true ? "DrawerItemActiveMini" : "DrawerItemMini"}
              onClick={b2}
            >
              <p style={{ fontSize: "20px" }}>
                Create <b class="title2new">Package</b>
              </p>
            </div>
            <div
              class={show5 === true ? "DrawerItemActiveMini" : "DrawerItemMini"}
              onClick={b5}
            >
              <p style={{ fontSize: "20px" }}>
                Create <b class="title2new">Employee</b>
              </p>
            </div>
          </Drawer>
        </div>
        <div class="drawerNewButtons">
          <div
            class={show6 === true ? "DrawerItemActive" : "DrawerItem"}
            onClick={b6}
          >
            <p
              class="title1new"
              style={{ fontSize: windowDimensions.width / 70 }}
            >
              View <b class="title2new">Reports</b>
            </p>
          </div>
          <div
            class={show3 === true ? "DrawerItemActive" : "DrawerItem"}
            onClick={b3}
          >
            <p
              class="title1new"
              style={{ fontSize: windowDimensions.width / 70 }}
            >
              Inven<b class="title2new">tory</b>
            </p>
          </div>

          <div
            class={show4 === true ? "DrawerItemActive" : "DrawerItem"}
            onClick={b4}
          >
            <p
              class="title1new"
              style={{ fontSize: windowDimensions.width / 70 }}
            >
              Manage <b class="title2new">Customer</b>
            </p>
          </div>
          <div
            class={show1 === true ? "DrawerItemActive" : "DrawerItem"}
            onClick={b1}
          >
            <p
              class="title1new"
              style={{ fontSize: windowDimensions.width / 70 }}
            >
              Create <b class="title2new">Service</b>
            </p>
          </div>
          <div
            class={show2 === true ? "DrawerItemActive" : "DrawerItem"}
            onClick={b2}
          >
            <p
              class="title1new"
              style={{ fontSize: windowDimensions.width / 70 }}
            >
              Create <b class="title2new">Package</b>
            </p>
          </div>
          <div
            class={show5 === true ? "DrawerItemActive" : "DrawerItem"}
            onClick={b5}
          >
            <p
              class="title1new"
              style={{ fontSize: windowDimensions.width / 70 }}
            >
              Create <b class="title2new">Employee</b>
            </p>
          </div>
        </div>
        <div class="servicesNewSetion">
          {show1 === true ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <CreateService />
              </div>
              <div
                className="Top-tap-package"
                style={{ paddingLeft: window.innerWidth < 520 ? null : "5.8%" }}
              >
                <div
                  className="drawerBackThing"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "white",
                    // width: '90%',
                    borderRadius: "1vw",
                    padding: window.innerWidth < 520 ? "2vw" : null,
                  }}
                >
                  <div
                    style={{ cursor: "pointer" }}
                    className={
                      shows1 === true
                        ? "ActiveCard-package"
                        : "InactiveCardPack"
                    }
                    onClick={bt1}
                  >
                    <p className="tile1Pack">
                      Wheel <b className="tile2Pack">Services</b>
                    </p>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className={
                      shows2 === true
                        ? "ActiveCard-package"
                        : "InactiveCardPack"
                    }
                    onClick={bt2}
                  >
                    <p className="tile1Pack">
                      Exterior <b className="tile2Pack">Cleaning</b>
                    </p>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className={
                      shows3 === true
                        ? "ActiveCard-package"
                        : "InactiveCardPack"
                    }
                    onClick={bt3}
                  >
                    <p className="tile1Pack">
                      Interior <b className="tile2Pack">Cleaning</b>
                    </p>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className={
                      shows4 === true
                        ? "ActiveCard-package"
                        : "InactiveCardPack"
                    }
                    onClick={bt4}
                  >
                    <p className="tile1Pack">
                      Engine <b className="tile2Pack">Services</b>
                    </p>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className={
                      shows5 === true
                        ? "ActiveCard-package"
                        : "InactiveCardPack"
                    }
                    onClick={bt5}
                  >
                    <p className="tile1Pack">
                      Paint <b className="tile2Pack">Services</b>
                    </p>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className={
                      shows6 === true
                        ? "ActiveCard-package"
                        : "InactiveCardPack"
                    }
                    onClick={bt6}
                  >
                    <p className="tile1Pack">
                      Labour <b className="tile2Pack">Charges</b>
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <hr
                  style={{
                    width: "55vw",
                    backgroundColor: "#fe5236",
                    opacity: 0.2,
                  }}
                />
              </div>
              {shows1 === true ? (
                <div
                  style={{
                    width: "95%",
                    display: "flex",
                    flexWrap: "wrap",
                    marginLeft: "-1.5%",
                  }}
                  className="package-screen-container"
                >
                  {wheelServices.map(showCard)}
                </div>
              ) : null}
              {shows2 === true ? (
                <div
                  style={{
                    width: "95%",
                    display: "flex",
                    flexWrap: "wrap",
                    marginLeft: "-1.5%",
                  }}
                  className="package-screen-container"
                >
                  {exteriorCleaning.map(showCard)}
                </div>
              ) : null}
              {shows3 === true ? (
                <div
                  style={{
                    width: "95%",
                    display: "flex",
                    flexWrap: "wrap",
                    marginLeft: "-1.5%",
                  }}
                  className="package-screen-container"
                >
                  {interiorCleaning.map(showCard)}
                </div>
              ) : null}
              {shows4 === true ? (
                <div
                  style={{
                    width: "95%",
                    display: "flex",
                    flexWrap: "wrap",
                    marginLeft: "-1.5%",
                  }}
                  className="package-screen-container"
                >
                  {engineServices.map(showCard)}
                </div>
              ) : null}
              {shows5 === true ? (
                <div
                  style={{
                    width: "95%",
                    display: "flex",
                    flexWrap: "wrap",
                    marginLeft: "4.5%",
                  }}
                  className="package-screen-container"
                >
                  {paintServices.map(showCard)}
                </div>
              ) : null}
              {shows6 === true ? (
                <div
                  style={{
                    width: "95%",
                    display: "flex",
                    flexWrap: "wrap",
                    marginLeft: "4.5%",
                  }}
                  className="package-screen-container"
                >
                  {labourCharges.map(showCard)}
                </div>
              ) : null}
            </div>
          ) : null}
          {show2 === true ? <PackageScreen list={serviceList} /> : null}
          {show3 === true ? <Inventory /> : null}
          {show4 === true ? <CreateCustomer /> : null}
          {show5 === true ? <CreateEmp /> : null}
          {show6 === true ? <SalesTable /> : null}
        </div>
      </div>
    );
  }
}
