import React from "react";
import AdminApp from "./AdminScreens/AdminApp";

import WebApp from "./AppScreens/WebApp";
import Packages from "../src/AppScreens/Packages";
import NonPackages from "../src/AppScreens/NonPackages";
import InvoicePrint from "./AppScreens/InvoicePrint";
import EmployeePage from "./AdminScreens/EmployeePage";
import GstReports from "./Components/SalesTable/GstReports";
import NewInvoice from "./Components/Invoice/NewInvoice";
import "./App.css";
import db from "./firebase/database";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          {/* <Route exact path="/" component={HomePage} /> */}
          <Route exact path="/" component={WebApp} />
          <Route path="/packages" component={Packages} />
          <Route path="/service" component={NonPackages} />
          <Route path="/admin" component={AdminApp} />

          <Route path="/receipt" component={InvoicePrint} />
          <Route path="/employee" component={EmployeePage} />
          <Route path="/gstdetails" component={GstReports} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
