import React, { useState, useEffect } from "react";
import "./Styling/Main.css";
import Kilometer from "../../Components/KilometerBar/Kilometer";
import db from "../../firebase/database";
import firebase from "../../firebase/firebase";
import { useLocation } from "react-router-dom";
import Services from "../../Components/Services/Services";
import CustomersTable from "../../Components/Customers/CustomersTable";
import Date from "../../Components/Date/Date";
import ServiceLeft from "../../Components/ServiceLeft/ServiceLeft";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from "react-router-dom";
import {
  FcPackage,
  FcBusinessman,
  FcPhoneAndroid,
  FcVoicemail,
  FcSupport,
  FcDisplay,
  FcCurrencyExchange,
  FcSearch,
  FcCheckmark,
} from "react-icons/fc";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";
import IcoInput from "../../Components/IcoInput";
import Drop from "../../Components/Drop";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

let arr;

//Company Icons
const chevrolet = require("../../assets/company/chevrolet.png");
const ford = require("../../assets/company/ford.png");
const honda = require("../../assets/company/honda.png");
const hyundai = require("../../assets/company/hyundai.png");
const kia = require("../../assets/company/kia.png");
const mg = require("../../assets/company/mg.png");
const mahindra = require("../../assets/company/mahindra.png");
const maruti = require("../../assets/company/maruti.png");
const mitsubishi = require("../../assets/company/mitsubishi.png");
const renault = require("../../assets/company/renault.png");
const skoda = require("../../assets/company/skoda.png");
const tata = require("../../assets/company/tata.png");
const toyota = require("../../assets/company/toyota.png");
const volkswagen = require("../../assets/company/volkswagen.png");
const datsun = require("../../assets/company/datsun.png");
const fiat = require("../../assets/company/fiat.png");
//Company Icons

function MyVerticallyCenteredModal1(props) {
  const [serviceLeftDesc, setServiceLeftDesc] = useState([]);
  useEffect(() => {
    if (props.custIsc) {
      db.collection("customers")
        .doc(props.custIsc)
        .collection("services")
        .get()
        .then((doc) => {
          const tmp = [];

          doc.forEach((documentSnapshot) => {
            tmp.push({
              // ...documentSnapshot.data(),
              // key: documentSnapshot.id,
              name: documentSnapshot.data().name,
              count: documentSnapshot.data().count,
            });
          });
          setServiceLeftDesc(tmp);
        });
    }
  }, [props.custIsc, props.modalShow1]);

  const showerService = (item) => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
          alignItems: "center",
          fontFamily: "Montserrat",
          fontSize: window.innerWidth < 520 ? "3.5vw" : "1vw",
        }}
      >
        <label>{item.name}</label>
        <label>{item.count}</label>
      </div>
    );
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Services Left Description
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            alignItems: "center",
            fontFamily: "Montserrat",
            fontSize: window.innerWidth < 520 ? "5vw" : "1vw",
          }}
        >
          <label>Service Name</label>
          <label>Services Left</label>
        </div>
        <div>{serviceLeftDesc.map(showerService)}</div>
      </Modal.Body>
      <Modal.Footer>
        <button type="submit" class="btn btn-success" onClick={props.onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
//Modal Code Ends Here

function MyVerticallyCenteredModal(props) {
  const history = useHistory();
  const [packageSelectedDuration, setpackageSelectedDuration] = useState("");
  const [off, setOff] = useState();

  const [loading, setloading] = useState(0);

  const [modeOfTransaction, setmodeOfTransaction] = useState("Cash");
  const [disableButton, setdisableButton] = useState(false);

  let expiry = moment(props.customerDetails.EXPIRY, "DD/MM/YYYY");
  expiry.month();
  expiry.add(packageSelectedDuration, "month");
  expiry.add(-1, "day");
  let packageExpiry = expiry.format("DD/MM/YYYY");

  //Packages Dropdown
  const [NewPackPrice, setNewPackPrice] = useState();
  const [NewPackCount, setNewPackCount] = useState();
  const [packageList, setPackageList] = useState([]);
  // const [pack, setPack] = React.useState([]);
  const [selected, setSelected] = React.useState("");
  // useEffect(() => {
  //   const subscriber = db.collection("packages").onSnapshot((querySnapshot) => {
  //     const tmp = [];

  //     querySnapshot.forEach((documentSnapshot) => {
  //       tmp.push({
  //         ...documentSnapshot.data(),
  //         key: documentSnapshot.id,
  //       });
  //     });
  //     const list = [];

  //     tmp.forEach((item, index) => {
  //       list.push({ value: item.name, label: item.name });
  //     });

  //     setPack(list);
  //   });

  //   // Unsubscribe from events when no longer in use
  //   return () => subscriber();
  // }, []);

  useEffect(() => {
    db.collection("packages")
      .get()
      .then((item) => {
        let temp = [];
        item.forEach((item) => {
          temp.push(item.data().name);
        });
        setPackageList(temp);
      });
  }, []);

  useEffect(() => {
    let removeArr = ["GOLD", "NORMAL 500"];

    if (!removeArr.includes(props.customerDetails.PACKAGE)) {
      let val = 0;
      let noto = [
        "GLASS CLEANING",
        "Interior Cleaning",
        "ENGINE CLEANING",
        "3 TIME ENGINE CLEAN (S)",
        "3 TIME INTERIOR (S)",
        "Carpet Cleaning,Fabric Protector & Upholstery",
        "Full Interior & Exterior Treatment",
        "1 TIME GLASS CLEANING (S)",
        "ENGINE CLEANING",
        "Carpet Cleaning,Fabric Protector & Upholstery",
        "GLASS CLEANING",
      ]; // will be ignored

      if (selected) {
        db.collection("customers")
          .doc(props.customerDetails.ISCNO)
          .collection("services")
          .get()
          .then((item) => {
            item.forEach((item) => {
              if (item.data().count > 0) {
                if (!noto.includes(item.data().name)) {
                  val = val + item.data().count * 60;
                }
              }
            });
          })
          .then(() => {
            db.collection("customers")
              .doc(props.customerDetails.ISCNO)
              .get()
              .then(function (doc) {
                if (doc.exists) {
                  console.log(doc.data());
                  db.collection("packages")
                    .doc(selected.value)
                    .get()
                    .then((snap) => {
                      setpackageSelectedDuration(snap.data().duration);
                      setNewPackCount(snap.data().count);
                      if (doc.data().SIZE === "small")
                        setNewPackPrice(
                          snap.data().cost.small - val > 0
                            ? snap.data().cost.small - val
                            : 10
                        );
                      else if (doc.data().SIZE === "medium")
                        setNewPackPrice(
                          snap.data().cost.medium - val > 0
                            ? snap.data().cost.medium - val
                            : 10
                        );
                      else if (doc.data().SIZE === "large")
                        setNewPackPrice(
                          snap.data().cost.large - val > 0
                            ? snap.data().cost.large - val
                            : 10
                        );
                    });
                } else {
                  alert("No such Package!");
                }
              })
              .catch(function (error) {
                alert("Error getting document:", error);
              });
          });
      }
    } else {
      const val = 0;
      db.collection("customers")
        .doc(props.customerDetails.ISCNO)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            console.log(doc.data());
            db.collection("packages")
              .doc(selected.value)
              .get()
              .then((snap) => {
                setpackageSelectedDuration(snap.data().duration);
                if (doc.data().SIZE === "small")
                  setNewPackPrice(snap.data().cost.small);
                else if (doc.data().SIZE === "medium")
                  setNewPackPrice(snap.data().cost.medium);
                else if (doc.data().SIZE === "large")
                  setNewPackPrice(snap.data().cost.large);
              });
          } else {
            alert("No such Package!");
          }
        })
        .catch(function (error) {
          alert("Error getting document:", error);
        });
    }
  }, [selected]);

  const [empPIN, setempPIN] = useState("0000");
  const [empName, setempName] = useState("");
  const handleChange = (event) => {
    setempPIN(event.target.value);
  };
  const getEmpDetails = async () => {
    await db
      .collection("employee")
      .doc(empPIN)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          if (doc.data().employeePin !== "deactivated") {
            setempName(doc.data().employeeName);
          } else if (doc.data().employeePin === "deactivated") {
            setempName("Deactivated Employee, Contact Owner");
          } else {
            setempName("No Employee With This PIN, Contact Owner");
          }
        } else {
          setempName("No Employee With This PIN, Contact Owner");
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const whatsapp = () => {
    window.open(urlDone);
  };
  let number = "+91" + props.customerDetails.MOBILE;
  let message =
    "Hi, This is a Package Subscription Reminder from Innovative Service Center%0D%0A========================%0D%0AYour Package : " +
    props.customerDetails.PACKAGE +
    " which was Purchased on : " +
    props.customerDetails.PURCHASED +
    " has Expired On : " +
    props.customerDetails.EXPIRY +
    " for " +
    props.customerDetails.ISCNO +
    ", Vehicle : " +
    props.customerDetails.COMPANY +
    "-" +
    props.customerDetails.MODEL +
    "%0D%0AYour Renewal Amount is : " +
    parseInt(NewPackPrice, 10) +
    "%0D%0APlease Visit Us and Renew Your Subscription." +
    "%0D%0AThanks for Reading Mr/Mrs , " +
    props.customerDetails.NAME;
  let urlDone =
    "https://api.whatsapp.com/send?phone=" + number + "&text=" + message;

  const completePayment = async () => {
    setdisableButton(true);
    setloading(15);
    await db
      .collection("customers")
      .doc(props.customerDetails.ISCNO)
      .update({
        PACKAGE: selected.value || props.customerDetails.PACKAGE,
        LASTPRICE: parseInt(NewPackPrice, 10) || parseInt(props.packPriceRef),
        PURCHASED: props.customerDetails.EXPIRY,
        EXPIRY: packageExpiry,
        SERVICES: NewPackCount || props.totalServiceCount,
      })
      .then(() => {
        setloading(35);
        db.collection("employee")
          .doc(empPIN)
          .collection("transactions")
          .doc(
            props.newcustomer === true
              ? props.newInvoice.toString()
              : props.invoice
          )
          .set({
            invoice:
              props.newcustomer === true
                ? props.newInvoice.toString()
                : props.invoice,
            employeeName: empName,
            approve: false,
            employeePin: empPIN,
            vehicleNumber: props.customerDetails.VEHICLENUMBER,
            customerName: props.customerDetails.NAME,
            customerMobile: props.customerDetails.MOBILE,
            date: props.date,
            amount: parseInt(NewPackPrice, 10) || parseInt(props.packPriceRef),
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(() => {
            setloading(55);
            db.collection("sales")
              .doc(
                props.newcustomer === true
                  ? props.newInvoice.toString()
                  : props.invoice
              )
              .set({
                invoice:
                  props.newcustomer === true
                    ? props.newInvoice.toString()
                    : props.invoice,
                vehicleNumber: props.customerDetails.VEHICLENUMBER,
                vehicleCompany: props.customerDetails.COMPANY, //need
                vehicleModel: props.customerDetails.MODEL, //need
                vehicleKilometer:
                  props.currentKM || props.customerDetails.KILOMETERDRIVEN,
                gst: 0, //need
                discount: 0, //need
                totalAmount:
                  parseInt(NewPackPrice, 10) || props.packPriceRef - off, //need
                count: props.count || NewPackCount, //need
                totalServiceCount: props.totalServiceCount || NewPackCount, //need
                customerName: props.customerDetails.NAME,
                customerMobile: props.customerDetails.MOBILE,
                package: selected.value || props.customerDetails.PACKAGE,
                mode: "Cash",
                bill: [
                  {
                    name: selected.value || props.customerDetails.PACKAGE,
                    small: NewPackPrice || props.packPriceRef - off,
                    medium: NewPackPrice || props.packPriceRef - off,
                    large: NewPackPrice || props.packPriceRef - off,
                    key: selected.value || props.customerDetails.PACKAGE,
                  },
                ],
                employeeName: empName,
                employeePin: empPIN,
                approve: false,
                date: props.date,
                amount: parseInt(NewPackPrice, 10) || props.packPriceRef - off,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                purchase: props.customerDetails.EXPIRY,
                expiry: packageExpiry,
              });
            // db.collection("extras")
            //   .doc("sales")
            //   .update({
            //     total: parseInt(NewPackPrice,10) || parseInt(props.packPriceRef,10),
            //     sales: firebase.firestore.FieldValue.arrayUnion({
            //       invoice:
            //         props.newcustomer === true
            //           ? props.newInvoice.toString()
            //           : props.invoice,
            //       vehicleNumber: props.customerDetails.VEHICLENUMBER,
            //       vehicleCompany: props.customerDetails.COMPANY, //need
            //       vehicleModel: props.customerDetails.MODEL, //need
            //       vehicleKilometer: props.currentKM || props.customerDetails.KILOMETERDRIVEN,
            //       gst: 0, //need
            //       discount: 0, //need
            //       totalAmount: parseInt(NewPackPrice,10) || props.packPriceRef - off, //need
            //       count: props.count||NewPackCount, //need
            //       totalServiceCount: props.totalServiceCount||NewPackCount, //need
            //       customerName: props.customerDetails.NAME,
            //       customerMobile: props.customerDetails.MOBILE,
            //       package: selected.value || props.customerDetails.PACKAGE,
            //       mode: "Cash",
            //       bill: [
            //         {
            //           name: selected.value || props.customerDetails.PACKAGE,
            //           small: NewPackPrice || props.packPriceRef - off,
            //           medium: NewPackPrice || props.packPriceRef - off,
            //           large: NewPackPrice || props.packPriceRef - off,
            //           key: selected.value || props.customerDetails.PACKAGE,
            //         },
            //       ],
            //       employeeName: empName,
            //       employeePin: empPIN,
            //       approve: false,
            //       date: props.date,
            //       amount: parseInt(NewPackPrice,10) || props.packPriceRef - off,
            //       timestamp: props.date,
            //       purchase: props.customerDetails.EXPIRY,
            //       expiry: packageExpiry,
            //     }),
            //   })
            setloading(70);
            db.collection("customers")
              .doc(props.customerDetails.ISCNO)
              .collection("services")
              .get()
              .then((det) => {
                det.forEach((dat) => {
                  db.collection("customers")
                    .doc(props.customerDetails.ISCNO)
                    .collection("services")
                    .doc(dat.data().name)
                    .delete();
                });
              })
              .then(() => {
                setloading(85);
                db.collection("packages")
                  .doc(selected.value || props.customerDetails.PACKAGE)
                  .get()
                  .then((item) => {
                    item.data().services.forEach(async (item) => {
                      await db
                        .collection("customers")
                        .doc(props.customerDetails.ISCNO)
                        .collection("services")
                        .doc(item.name)
                        .set(item);
                    });
                  });
              })
              .then(() => {
                db.collection("extras")
                  .doc("invoice")
                  .update({
                    base:
                      props.newcustomer === true
                        ? props.invoiceNum
                        : firebase.firestore.FieldValue.increment(+1),
                    newbase:
                      props.newcustomer === true
                        ? firebase.firestore.FieldValue.increment(+1)
                        : props.newInvoice,
                  });
              })
              .then(() => {
                setloading(90);
                db.collection("customers")
                  .doc(props.customerDetails.ISCNO)
                  .update({
                    KILOMETERDRIVEN:
                      props.currentKM || props.customerDetails.KILOMETERDRIVEN,
                  })
                  .then(() => {
                    setloading(100);
                    db.collection("packages")
                      .doc(selected.value)
                      .collection("history")
                      .doc()
                      .set({
                        name: selected.value,
                        price: parseInt(NewPackPrice, 10) || props.packPriceRef,
                        type: "Paid",
                        category: "Package",
                        servicedCity:
                          props.customerDetails.CITY === "banda"
                            ? "banda"
                            : "sagar",
                        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                      });
                    history.push({
                      pathname: "/receipt",
                      isc: props.customerDetails.ISCNO,
                      mode: modeOfTransaction.value || "Cash",
                      billList: [
                        {
                          name: selected.value || props.customerDetails.PACKAGE,
                          small: NewPackPrice || props.packPriceRef,
                          medium: NewPackPrice || props.packPriceRef,
                          large: NewPackPrice || props.packPriceRef,
                        },
                      ],
                      invoice:
                        props.newcustomer === true
                          ? props.newInvoice.toString()
                          : props.invoice,
                      vehicleCompany: props.customerDetails.COMPANY, //need
                      city: props.customerDetails.CITY || "sagar",
                      vehicleModel: props.customerDetails.MODEL, //need
                      gst: 0, //need
                      discount: 0, //need
                      totalAmount:
                        parseInt(NewPackPrice, 10) || props.packPriceRef, //need
                      count: props.count || NewPackCount, //need
                      totalServiceCount:
                        props.totalServiceCount || NewPackCount, //need
                      vehicleNumber: props.customerDetails.VEHICLENUMBER,
                      customerName: props.customerDetails.NAME,
                      customerMobile: props.customerDetails.MOBILE,
                      packageDetails:
                        selected.value || props.customerDetails.PACKAGE,
                      date: props.date,
                      amount: parseInt(NewPackPrice, 10) || props.packPriceRef,
                      vehicleSize: props.customerDetails.SIZE,
                      purchase: props.customerDetails.EXPIRY,
                      expiry: packageExpiry,
                      checkPage: "sale",
                      kilmil:
                        props.currentKM ||
                        props.customerDetails.KILOMETERDRIVEN,
                    });
                  });
              });
          })
          .catch((error) => alert(error));
      });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Loading load={loading} />
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Cash Payment
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <form>
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                id="pinInput"
                aria-describedby="pinHelp"
                placeholder="0000"
                onChange={handleChange}
              />
              <small id="pinHelp" class="form-text text-muted">
                Please Enter Unique PIN Provided by Owner.
              </small>
              <button
                type="button"
                class="btn btn-warning"
                onClick={getEmpDetails}
              >
                Check PIN
              </button>
              <br />
              {!empName ? null : (
                <>
                  <label>Employee Name : {empName} ✅</label>
                  {/* <div
                    style={{
                      backgroundColor: "#EEEEEE",
                      marginTop: "10px",
                      marginBottom: "10px",
                      padding: "10px",
                      borderRadius: "16px",
                      width: "43.5%",
                    }}
                  >
                    <label>Select New Package</label>
                    <Drop
                      icon={<FcPackage />}
                      list={packageList}
                      val={selected}
                      setter={setSelected}
                      holder={"Select Package"}
                    />
                    <label>
                      Current Pack Price : {packPriceRef}
                      <br />
                      New Package : {selected.value}
                      <br/>
                      New Price : {NewPackPrice}
                    </label>
                  </div> */}
                </>
              )}
            </div>
            {empName === "No Employee With This PIN, Contact Owner" ||
            empName === "Deactivated Employee, Contact Owner" ||
            !empName ? null : (
              <>
                <div
                  style={{
                    backgroundColor: "#EEEEEE",
                    marginTop: "10px",
                    marginBottom: "10px",
                    padding: "10px",
                    borderRadius: "16px",
                    width: window.innerWidth < 520 ? "100%" : "43.5%",
                  }}
                >
                  <label>Select New Package</label>
                  <Drop
                    icon={<FcPackage />}
                    list={packageList}
                    val={selected}
                    setter={setSelected}
                    holder={"Select Package"}
                  />
                  <label>
                    Current Pack Price : {props.packPriceRef}
                    <br />
                    New Package : {selected.value}
                    <br />
                    New Price : {NewPackPrice}
                  </label>
                  <Drop
                    list={["Cash", "Card", "UPI", "Other"]}
                    val={modeOfTransaction}
                    setter={setmodeOfTransaction}
                    icon={<FcCurrencyExchange />}
                    holder={"Cash"}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={
                          <RiCheckboxBlankCircleLine style={{ color: "red" }} />
                        }
                        checkedIcon={<FcCheckmark />}
                        checked={props.newcustomer}
                        onChange={props.handleChangeNew}
                        name="checkedH"
                      />
                    }
                    label="New Customer"
                  />
                </div>
              </>
            )}
            <h4>
              Amount Collected :{" "}
              {NewPackPrice ? NewPackPrice : props.packPriceRef}
            </h4>
          </form>
        </div>
      </Modal.Body>
      {empName === "No Employee With This PIN, Contact Owner" ||
      !empName ||
      empName === "Deactivated Employee, Contact Owner" ||
      !selected ||
      !NewPackPrice ||
      !props.packPriceRef ||
      disableButton === true ? null : (
        <Modal.Footer>
          <button
            type="submit"
            class="btn btn-success"
            onClick={completePayment}
          >
            Complete Payment
          </button>
          <div
            onClick={whatsapp}
            style={{
              margin: "0.75vw",
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div className="renewWhat">or Send Renew Reminder on</div>
            <img
              style={{ marginLeft: "0.5vw", width: "3vw", height: "3vw" }}
              src={require("../../assets/WhatsApp.svg")}
              alt="Whatsapp"
            />
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
}
//Modal Code Ends Here

export default function Details({
  customerDetails,
  packPriceRef,
  setcurrentKM,
  currentKM,
  monthPur,
  dayPur,
  yearPur,
  monthExp,
  dayExp,
  yearExp,
  count,
  totalServiceCount,
}) {
  const history = useHistory();

  let today = moment().format("DD/MM/YYYY");

  //Modal Code Starts Here

  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => {
    setModalShow(false);
  };
  const handleShow = () => setModalShow(true);

  const [modalShow1, setModalShow1] = useState(false);
  const handleClose1 = () => {
    setModalShow1(false);
  };
  const handleShow1 = () => setModalShow1(true);

  const [pending, setpending] = useState(0);
  const [showPending, setshowPending] = useState(false);
  const [animate, setAnimate] = useState(true);

  let companyIcon;
  if (customerDetails.COMPANY === "Chevrolet") companyIcon = chevrolet;
  if (customerDetails.COMPANY === "Ford") companyIcon = ford;
  if (customerDetails.COMPANY === "Honda") companyIcon = honda;
  if (customerDetails.COMPANY === "Hyundai") companyIcon = hyundai;
  if (customerDetails.COMPANY === "Kia") companyIcon = kia;
  if (customerDetails.COMPANY === "MG Motor") companyIcon = mg;
  if (customerDetails.COMPANY === "Mahindra") companyIcon = mahindra;
  if (customerDetails.COMPANY === "Maruti Suzuki") companyIcon = maruti;
  if (customerDetails.COMPANY === "Mitsubishi") companyIcon = mitsubishi;
  if (customerDetails.COMPANY === "Renault") companyIcon = renault;
  if (customerDetails.COMPANY === "Skoda") companyIcon = skoda;
  if (customerDetails.COMPANY === "Tata Motors") companyIcon = tata;
  if (customerDetails.COMPANY === "Toyota") companyIcon = toyota;
  if (customerDetails.COMPANY === "Volkswagen") companyIcon = volkswagen;
  if (customerDetails.COMPANY === "Datsun") companyIcon = datsun;
  if (customerDetails.COMPANY === "Fiat") companyIcon = fiat;

  let carSize;
  if (customerDetails.SIZE === "small") carSize = "Small";
  if (customerDetails.SIZE === "medium") carSize = "Medium";
  if (customerDetails.SIZE === "large") carSize = "Large";

  const renewFunction = () => {
    setModalShow(true);
  };

  const showServiceFunction = () => {
    setModalShow1(true);
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //Invoice Fetch and Format Code Starts Here
  const [invoiceNum, setInvoiceNum] = useState();
  const [newInvoice, setnewInvoice] = useState("");
  const [invoiceFormatted, setInvoiceFormatted] = useState("");
  useEffect(() => {
    db.collection("extras")
      .doc("invoice")
      .get()
      .then(function (doc) {
        setInvoiceNum(doc.data().base);
        setInvoiceFormatted("IN" + invoiceNum);
        setnewInvoice(doc.data().newbase);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }, [invoiceNum]);

  const [newcustomer, setnewcustomer] = useState(false);
  const handleChangeNew = (event) => {
    setnewcustomer(!newcustomer);
  };
  //Invoice Fetch and Format Code Ends Here

  if (window.innerWidth < 520) {
    return (
      <div
        style={{
          display: "block",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "white",
        }}
      >
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={handleClose}
          dialogClassName="employeeModal"
          backdrop="static"
          keyboard={false}
          invoice={invoiceFormatted}
          customerDetails={customerDetails}
          date={today}
          packPriceRef={packPriceRef}
          count={count}
          totalServiceCount={totalServiceCount}
          currentKM={currentKM}
          invoiceNum={invoiceNum}
          modalShow={modalShow}
        />
        <MyVerticallyCenteredModal1
          show={modalShow1}
          onHide={handleClose1}
          dialogClassName="serviceShowModal"
          backdrop="static"
          keyboard={false}
          count={count}
          custIsc={customerDetails.ISCNO}
          modalShow1={modalShow1}
        />
        <div
          style={{
            flex: 1,
            backgroundColor: "#EEEEEE",
            display: "block",
            borderRadius: 24,
            marginTop: "14%",
            padding: "5vw",
            marginLeft: "5vw",
            marginRight: "5vw",
          }}
        >
          <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
            <img
              alt=""
              style={{
                height: windowDimensions.width / 15,
              }}
              src={require("../../assets/customerDetails.svg")}
            />
          </div>
          <div>
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Customer Name
            </label>
            <IcoInput
              value={customerDetails.NAME}
              holder={"Customer Name"}
              icon={
                <FcBusinessman style={{ width: windowDimensions.width / 15 }} />
              }
              type={"text"}
              editable={false}
              width="100"
              height={windowDimensions.height / 18}
              fontsize={windowDimensions.width / 40}
            />
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                marginTop: "10px",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Customer ISC Number
            </label>
            <IcoInput
              value={customerDetails.ISCNO}
              holder={"ISC Number"}
              icon={<FcSearch style={{ width: windowDimensions.width / 15 }} />}
              type={"text"}
              editable={false}
              width="100"
              height={windowDimensions.height / 18}
              fontsize={windowDimensions.width / 40}
            />
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                marginTop: "10px",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Mobile Number
            </label>
            <IcoInput
              value={customerDetails.MOBILE}
              holder={"Mobile Number"}
              icon={
                <FcPhoneAndroid
                  style={{ width: windowDimensions.width / 15 }}
                />
              }
              type={"text"}
              editable={false}
              width="100"
              height={windowDimensions.height / 18}
              fontsize={windowDimensions.width / 40}
            />
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                marginTop: "10px",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Email Address
            </label>
            <IcoInput
              value={customerDetails.EMAIL}
              holder={"Email Address"}
              icon={
                <FcVoicemail style={{ width: windowDimensions.width / 15 }} />
              }
              type={"text"}
              editable={false}
              width="100"
              height={windowDimensions.height / 18}
              fontsize={windowDimensions.width / 40}
            />
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                marginTop: "10px",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Full Address
            </label>
            <label
              style={{
                width: "100%",
                height: windowDimensions.height / 7,
                backgroundColor: "white",
                borderRadius: 10,
                padding: 20,
                fontSize: windowDimensions.width / 40,
              }}
            >
              {customerDetails.ADDRESS}
            </label>
          </div>
        </div>

        {/* Vehicle Details Start */}
        <div
          style={{
            flex: 1,
            backgroundColor: "#EEEEEE",
            display: "block",
            borderRadius: 24,
            padding: "5vw",
            marginLeft: "5vw",
            marginRight: "5vw",
            marginTop: "5vw",
          }}
        >
          <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
            <img
              alt=""
              style={{
                height: windowDimensions.width / 15,
              }}
              src={require("../../assets/vehicleDetails.svg")}
            />
          </div>
          <div>
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Vehicle Name
            </label>
            <IcoInput
              value={customerDetails.MODEL + " (" + carSize + ")"}
              holder={"Vehicle Name"}
              icon={
                <img
                  alt=""
                  src={companyIcon}
                  style={{
                    width: windowDimensions.width / 15,
                    height: windowDimensions.width / 15,
                  }}
                />
              }
              width="100"
              height={windowDimensions.height / 18}
              fontsize={windowDimensions.width / 40}
              type={"text"}
              editable={false}
            />
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                marginTop: "5px",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Vehicle Number
            </label>
            <IcoInput
              value={customerDetails.VEHICLENUMBER}
              holder={"Vehicle Number"}
              icon={
                <FcSupport style={{ width: windowDimensions.width / 15 }} />
              }
              type={"text"}
              editable={false}
              width="100"
              height={windowDimensions.height / 18}
              fontsize={windowDimensions.width / 40}
            />
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                marginTop: "5px",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Current Kilometer
            </label>
            <IcoInput
              holder={"Current KM"}
              icon={
                <FcDisplay style={{ width: windowDimensions.width / 15 }} />
              }
              type={"text"}
              setter={setcurrentKM}
              width="100"
              height={windowDimensions.height / 18}
              fontsize={windowDimensions.width / 40}
            />
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "700",
                marginTop: "2.5vw",
                fontsize: windowDimensions.width / 20,
                color: "#2A3C48",
              }}
            >
              Last<span style={{ fontWeight: "400" }}> Service</span>
            </label>
            <div
              style={{
                position: "relative",
                textAlign: "center",
              }}
            >
              <img
                alt=""
                src={require("../../Components/KilometerBar/kmBar.svg")}
                style={{ width: "100%", height: "100%" }}
              />
              <label
                style={{
                  position: "absolute",
                  top: "20%",
                  display: "flex",
                  flex: 1,
                  width: "100%",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "white",
                  fontFamily: "Montserrat",
                  fontWeight: "900",
                  fontSize: windowDimensions.width / 20,
                }}
              >
                {customerDetails.KILOMETERDRIVEN}{" "}
                <span style={{ fontWeight: "400" }}>KM</span>
              </label>
            </div>
          </div>
        </div>

        {/* Package Details Starts */}
        <div
          style={{
            flex: 1,
            backgroundColor: "#EEEEEE",
            display: "block",
            borderRadius: 24,
            padding: "5vw",
            margin: "5vw",
          }}
        >
          <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
            <img
              alt=""
              style={{
                height: windowDimensions.width / 15,
              }}
              src={require("../../assets/packageDetails.svg")}
            />
          </div>
          <label
            style={{
              fontFamily: "Montserrat",
              fontWeight: "900",
              color: "#34324C",
              fontSize: "5vw",
            }}
          >
            {customerDetails.PACKAGE}
          </label>
          <div
            style={{
              width: "100%",
              height: "85%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                width: "100%",
                flex: 1,
                display: "block",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  marginBottom: "0.5vw",
                  fontSize: "4vw",
                }}
              >
                Purchased On
              </div>
              <Date
                month={monthPur}
                date={dayPur}
                year={yearPur}
                width={windowDimensions.width / 2.75}
                height={windowDimensions.width / 2.75}
                fontSize={windowDimensions.width / 25}
              />
              <div
                style={{ marginTop: "2.5vw", cursor: "pointer" }}
                onClick={showServiceFunction}
              >
                <ServiceLeft
                  value={count}
                  totalServiceCount={totalServiceCount}
                  valuelabel="Services Left"
                  size={windowDimensions.width / 2.75}
                  strokewidth={windowDimensions.width / 40}
                />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                flex: 1,
                display: "block",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "100%",
                  flex: 1,
                  display: "block",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    marginBottom: "0.5vw",
                    fontSize: "4vw",
                  }}
                >
                  Expiring On
                </div>
                <Date
                  month={monthExp}
                  date={dayExp}
                  year={yearExp}
                  width={windowDimensions.width / 2.75}
                  height={windowDimensions.width / 2.75}
                  fontSize={windowDimensions.width / 25}
                />
                <div style={{ position: "relative", marginTop: "1vw" }}>
                  <div
                    style={{
                      position: "absolute",
                      top: "18%",
                      display: "flex",
                      flex: 1,
                      width: "90%",
                      justifyContent: "center",
                      textAlign: "center",
                      fontFamily: "Montserrat",
                      fontWeight: "900",
                      color: "#34324C",
                      fontSize: "5vw",
                    }}
                  >
                    <label
                      style={{
                        textAlign: "center",
                        fontFamily: "Montserrat",
                        fontWeight: "900",
                        color: "#34324C",
                        fontSize: "5vw",
                      }}
                    >
                      {packPriceRef}
                    </label>
                  </div>
                  <img
                    alt=""
                    style={{
                      marginTop: "1.5vw",
                      width: windowDimensions.width / 2.75,
                      height: windowDimensions.width / 2.75,
                      cursor: "pointer",
                    }}
                    src={require("../../assets/renew.svg")}
                    onClick={renewFunction}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else
    return (
      // Main Division
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "white",
          width: "100%",
          height: "auto",
          paddingTop: "14%",
        }}
      >
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={handleClose}
          dialogClassName="employeeModal"
          backdrop="static"
          keyboard={false}
          invoice={invoiceFormatted}
          customerDetails={customerDetails}
          date={today}
          packPriceRef={packPriceRef}
          count={count}
          currentKM={currentKM}
          invoiceNum={invoiceNum}
          newInvoice={newInvoice}
          newcustomer={newcustomer}
          handleChangeNew={handleChangeNew}
          totalServiceCount={totalServiceCount}
        />
        <MyVerticallyCenteredModal1
          show={modalShow1}
          onHide={handleClose1}
          dialogClassName="serviceShowModal"
          backdrop="static"
          keyboard={false}
          count={count}
          custIsc={customerDetails.ISCNO}
        />
        {/* ISC Number */}
        <img
          alt=""
          src={require("../../assets/regNumber.svg")}
          style={{
            position: "absolute",
            width: windowDimensions.width / 4,
            top: "9%",
            left: "4%",
          }}
        />
        <div style={{ position: "absolute", top: "14%", left: "4%" }}>
          <div
            style={{
              width: windowDimensions.width / 5.5,
              height: windowDimensions.width / 35,
              backgroundColor: "#EEEEEE",
              borderRadius: 6,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <label
              style={{
                width: windowDimensions.width / 4,
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: windowDimensions.width / 90,
                paddingLeft: 20,
                paddingTop: 10,
              }}
            >
              {customerDetails.ISCNO}
            </label>
          </div>
        </div>
        {/* ISC Number */}
        {/* 3 Columns */}
        {/* Customer Details */}
        <div
          style={{
            flex: 1.5,
            backgroundColor: "#EEEEEE",
            display: "block",
            borderRadius: 24,
            marginLeft: "4%",
            marginRight: 20,
            marginBottom: 40,
            height: "75vw%",
            width: "100%",
          }}
        >
          <img
            alt=""
            style={{
              margin: 20,
              height: windowDimensions.height / 15,
              width: windowDimensions.width / 8,
              marginLeft: windowDimensions.width / 8,
            }}
            src={require("../../assets/customerDetails.svg")}
          />
          <div style={{ paddingLeft: "8%" }}>
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Customer Name
            </label>
            <IcoInput
              value={customerDetails.NAME}
              holder={"Customer Name"}
              icon={
                <FcBusinessman style={{ width: windowDimensions.width / 60 }} />
              }
              type={"text"}
              editable={false}
              width="78"
              height={windowDimensions.height / 18}
              fontsize={windowDimensions.width / 100}
            />
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                marginTop: "10px",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Mobile Number
            </label>
            <IcoInput
              value={customerDetails.MOBILE}
              holder={"Mobile Number"}
              icon={
                <FcPhoneAndroid
                  style={{ width: windowDimensions.width / 60 }}
                />
              }
              type={"text"}
              editable={false}
              width="78"
              height={windowDimensions.height / 18}
              fontsize={windowDimensions.width / 100}
            />
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                marginTop: "10px",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Email Address
            </label>
            <IcoInput
              value={customerDetails.EMAIL}
              holder={"Email Address"}
              icon={
                <FcVoicemail style={{ width: windowDimensions.width / 60 }} />
              }
              type={"text"}
              editable={false}
              width="78"
              height={windowDimensions.height / 18}
              fontsize={windowDimensions.width / 100}
            />
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                marginTop: "10px",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Full Address
            </label>
            <label
              style={{
                width: "90%",
                height: windowDimensions.height / 7,
                backgroundColor: "white",
                borderRadius: 10,
                padding: 20,
                fontSize: windowDimensions.width / 100,
              }}
            >
              {customerDetails.ADDRESS}
            </label>
          </div>
        </div>
        {/* Vehicle Details */}
        <div
          style={{
            flex: 1.25,
            backgroundColor: "#EEEEEE",
            display: "block",
            borderRadius: 24,
            marginRight: 20,
            marginBottom: 40,
            height: "75vw%",
            width: "100%",
          }}
        >
          <img
            alt=""
            style={{
              margin: 20,
              width: windowDimensions.width / 8,
              height: windowDimensions.height / 15,
              marginLeft: windowDimensions.width / 10,
            }}
            src={require("../../assets/vehicleDetails.svg")}
          />
          <div style={{ paddingLeft: "6%" }}>
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Vehicle Name
            </label>
            <IcoInput
              value={customerDetails.MODEL + " (" + carSize + ")"}
              holder={"Vehicle Name"}
              icon={
                <img
                  alt=""
                  src={companyIcon}
                  style={{
                    width: "2.5vw",
                    height: "2.5vw",
                  }}
                />
              }
              width="71"
              height={windowDimensions.height / 18}
              fontsize={windowDimensions.width / 100}
              type={"text"}
              editable={false}
            />
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                marginTop: "5px",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Vehicle Number
            </label>
            <IcoInput
              value={customerDetails.VEHICLENUMBER}
              holder={"Vehicle Number"}
              icon={
                <FcSupport style={{ width: windowDimensions.width / 60 }} />
              }
              type={"text"}
              editable={false}
              width="78"
              height={windowDimensions.height / 18}
              fontsize={windowDimensions.width / 100}
            />
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                marginTop: "5px",
                fontSize: windowDimensions.height / 45,
              }}
            >
              Current Kilometer
            </label>
            <IcoInput
              holder={"Current KM"}
              icon={
                <FcDisplay style={{ width: windowDimensions.width / 60 }} />
              }
              type={"text"}
              setter={setcurrentKM}
              width="78"
              height={windowDimensions.height / 18}
              fontsize={windowDimensions.width / 100}
            />
            <label
              style={{
                fontFamily: "Montserrat",
                fontWeight: "700",
                marginTop: "5px",
                fontSize: "1.5vw",
                color: "#2A3C48",
              }}
            >
              Last<span style={{ fontWeight: "400" }}> Service</span>
            </label>
            <div
              style={{
                position: "relative",
                textAlign: "center",
              }}
            >
              <img
                alt=""
                src={require("../../Components/KilometerBar/kmBar.svg")}
                style={{ width: "80%", height: "100%" }}
              />
              <label
                style={{
                  position: "absolute",
                  top: "15%",
                  right: "30%",
                  textAlign: "center",
                  color: "white",
                  fontFamily: "Montserrat",
                  fontWeight: "900",
                  fontSize: windowDimensions.width / 65,
                }}
              >
                {customerDetails.KILOMETERDRIVEN}{" "}
                <span style={{ fontWeight: "400" }}>KM</span>
              </label>
            </div>
          </div>
        </div>
        {/* Package Details */}
        <div
          style={{
            flex: 1.5,
            backgroundColor: "#EEEEEE",
            display: "block",
            borderRadius: 24,
            marginRight: 20,
            marginBottom: 40,
            height: "75vw%",
            width: "100%",
            paddingLeft: "2%",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: windowDimensions.height / 25,
                top: "30.5%",
                right: "31.5%",
              }}
              class={
                animate
                  ? "bookingsAnimation bookingsButton2"
                  : "bookingsButton2"
              }
              onClick={() => setshowPending(!showPending)}
            >
              <div style={{ fontWeight: "400" }}>
                Service <span style={{ fontWeight: "700" }}>Bookings</span>
              </div>
              {pending > 0 ? <div className="circle">{pending}</div> : null}
            </div>
            <img
              alt=""
              style={{
                margin: 20,
                width: windowDimensions.width / 8,
                height: windowDimensions.height / 15,
              }}
              src={require("../../assets/packageDetails.svg")}
            />
          </div>
          {customerDetails.ISCNO ? (
            <Bookings
              setpending={setpending}
              showPending={showPending}
              iscNumber={customerDetails.ISCNO}
            />
          ) : null}
          <label
            style={{
              fontFamily: "Montserrat",
              fontWeight: "900",
              color: "#34324C",
              fontSize: "1.5vw",
            }}
          >
            {customerDetails.PACKAGE}
          </label>
          <div
            style={{
              width: "100%",
              height: "85%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                width: "100%",
                flex: 1,
                display: "block",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  marginBottom: "0.5vw",
                  fontSize: "1.25vw",
                }}
              >
                Purchased On
              </div>
              <Date
                month={monthPur}
                date={dayPur}
                year={yearPur}
                width={windowDimensions.width / 10}
                height={windowDimensions.width / 10}
                fontSize={windowDimensions.width / 90}
              />
              <div
                style={{ marginTop: "1.5vw", cursor: "pointer" }}
                onClick={showServiceFunction}
              >
                <ServiceLeft
                  value={count}
                  totalServiceCount={totalServiceCount}
                  valuelabel="Services Left"
                  size={windowDimensions.width / 9}
                  strokewidth={windowDimensions.width / 125}
                />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                flex: 1,
                display: "block",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "100%",
                  flex: 1,
                  display: "block",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    marginBottom: "0.5vw",
                    fontSize: "1.25vw",
                  }}
                >
                  Expiring On
                </div>
                <Date
                  month={monthExp}
                  date={dayExp}
                  year={yearExp}
                  width={windowDimensions.width / 10}
                  height={windowDimensions.width / 10}
                  fontSize={windowDimensions.width / 90}
                />
                <div style={{ position: "relative" }}>
                  <label
                    style={{
                      position: "absolute",
                      top: "24%",
                      left: "29.5%",
                      fontFamily: "Montserrat",
                      fontWeight: "900",
                      color: "#34324C",
                      fontSize: "1.6vw",
                    }}
                  >
                    {packPriceRef}
                  </label>
                  <img
                    alt=""
                    style={{
                      marginTop: "1.5vw",
                      width: windowDimensions.width / 8.75,
                      height: windowDimensions.width / 8.75,
                      cursor: "pointer",
                    }}
                    src={require("../../assets/renew.svg")}
                    onClick={renewFunction}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Car SVG Right */}
        <div
          style={{
            flex: 0.75,
            display: "flex",
            height: "95%",
            width: "100%",
            marginBottom: 40,
          }}
        >
          <img
            alt=""
            style={{
              position: "absolute",
              right: "-2%",
              top: "27.5%",
              width: windowDimensions.width / 7.5,
              height: windowDimensions.height / 1.75,
            }}
            src={require("../../assets/CarSVGRight.svg")}
          />
        </div>
        {/* Main divison End */}
      </div>
    );
}

function Bookings({ setpending, showPending, iscNumber }) {
  const [servicesList, setservicesList] = useState([]);
  useEffect(() => {
    const subscriber = db
      .collection("bookings")
      .where("isc", "==", iscNumber)
      .where("status", "==", "Confirmed")
      .onSnapshot((querySnapshot) => {
        const tmp = [];

        querySnapshot.forEach((documentSnapshot) => {
          tmp.push({
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          });
        });
        setservicesList(tmp);
        setpending(querySnapshot.size);
      });

    return () => subscriber();
  }, []);

  const bookingsDisplay = (item) => {
    const completeBooking = () => {
      db.collection("bookings").doc(item.isc).update({
        completed: true,
        status: "Completed",
      });
    };
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: "10vw",
        }}
      >
        {servicesList ? (
          <>
            <div
              style={{
                display: "flex",
                width: "100%",
                paddingLeft: "3.5vw",
                flex: 0.4,
                paddingRight: "1.5vw",
                paddingTop: "1.5vw",
              }}
            >
              <label
                style={{
                  textAlign: "right",
                  fontSize: "0.65vw",
                  color: "black",
                  fontWeight: "400",
                }}
              >
                Customer has Confirmed Booking For These Services. Please
                PROVIDE Servicing and Approve By Ticking.
              </label>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                flex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flex: 0.9,
                  flexDirection: "column",
                  paddingLeft: "1.5vw",
                }}
              >
                <label
                  style={{
                    fontSize: "0.65vw",
                    fontWeight: "400",
                    color: " black",
                  }}
                >
                  Services Booked
                </label>
                <label
                  style={{
                    fontSize: "0.8vw",
                    fontWeight: "700",
                    color: " black",
                  }}
                >
                  {item.services.toString()}
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flex: 0.1,
                  padding: "1vw",
                  cursor: "pointer",
                }}
                onClick={() => completeBooking()}
              >
                <img
                  alt="tick"
                  src={require("../../assets/tick.svg")}
                  width={"100%"}
                  height={"100%"}
                />
              </div>
            </div>
            <hr className="hrline" />
          </>
        ) : null}
      </div>
    );
  };
  return (
    <div
      style={{ top: "35%", right: "24%" }}
      className={showPending === true ? "glass2 glassActive2" : "glass2"}
      // className="glass"
    >
      <div className="bookingsMapper">
        {servicesList ? servicesList.map(bookingsDisplay) : null}
        {servicesList.length < 1 ? (
          <label
            style={{
              width: "100%",
              textAlign: "center",
              lineHeight: "100%",
              marginTop: "1.5vw",
              fontSize: "1vw",
              fontWeight: "400",
              color: "black",
            }}
          >
            No Bookings Yet
          </label>
        ) : null}
      </div>
    </div>
  );
}
