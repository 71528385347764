import React from "react";
import "./Drop.css";

import Select from "react-select";
export default function Drop(props) {
  const list = props.list.map((item) => {
    return { value: item, label: item };
  });

  const style = {
    control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      minWidth: "15rem",
      boxShadow: "0 0 0 1px #fff",
      backgroundColor: "white",
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      borderRadius: 0,
      border: 0,
      minHeight: "2.5rem",
      outline: "none",
      fontFamily: "Montserrat",
      fontWeight: "600",
      ":hover ": {
        borderWidth: 0,
      },
    }),
  };
  const handleChange = (newValue, actionMeta) => {
    console.group("Value Changed");

    props.setter(newValue);
    if(props.remover){
      props.remover("")
    }

    console.groupEnd();
  };
  
  const noOption = (newValue, actionMeta) => {
    console.log("HEllo");
  };
  return (
    <div className="drop-container">
      <span className="drop-icon">{props.icon}</span>
      <span className="drop.div">
        <Select
          onChange={handleChange}
          placeholder={props.holder}
          options={list}
          styles={style}
          onKeyDown={(e) => {
            if (props.setterList) {
              if (e.key === "Enter") {
                const demo = list;
                demo.push(e.target.value);
                props.setterList([...props.list, e.target.value]);
              }
            }
          }}
        />
      </span>
    </div>
  );
}
