import React, { useState, useEffect } from "react";
import "./style.css";
import { useHistory } from "react-router-dom";
import db from "../../../firebase/database";
import { dbr } from "../../../firebase/database";

export default function HomePage() {
  const history = useHistory();
  const [isc, setisc] = useState();
  const [vehicleNumber, setvehicleNumber] = useState();
  const [packageInput, setPackageInput] = useState(false);
  const [pending, setpending] = useState(0);
  const [showPending, setshowPending] = useState(false);
  useEffect(() => {
    db.collection("customers")

      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          db.collection("customers").doc(String(doc.data().ISCNO)).update({
            CITY: "sagar",
          });
        });
      })
      .then(() => {
        console.log("done");
      });
  }, []);
  // useEffect(() => {
  //   const listener = event => {
  //     if (event.code === "Enter" || event.code === "NumpadEnter") {
  //       console.log("Enter key was pressed. Run your function.");
  //       // checkUserExist()
  //       checkUserExist()
  //     }
  //   };
  //   document.addEventListener("keydown", listener);
  //   return () => {
  //     document.removeEventListener("keydown", listener);
  //   };
  // }, []);
  const doWithKeyPress = (event) => {
    if (event.keyCode === 13) {
      console.log("enter");
      checkUserExist();
    }
  };

  const checkUserExist = (evt) => {
    // evt.preventDefault();
    if (!isc && vehicleNumber) {
      db.collection("customers")
        .where("VEHICLENUMBER", "==", vehicleNumber.toUpperCase())
        .get()
        .then(function (querySnapshot) {
          if (querySnapshot.size > 0) {
            querySnapshot.forEach(function (doc) {
              console.warn("doc exist", doc.data());
              history.push({
                pathname: "/packages",
                isc: doc.data().ISCNO,
              });
            });
          } else {
            alert("Sorry, No Vehicle with " + vehicleNumber);
          }
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    } else if (isc && !vehicleNumber) {
      db.collection("customers")
        .doc("ISC-" + isc)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            history.push({
              pathname: "/packages",
              isc: "ISC-" + isc,
            });
          } else {
            alert("Sorry, No User Found with ISC-" + isc);
          }
        })
        .catch(function (error) {
          console.log("User Check Error:", error);
        });
    } else if (isc && vehicleNumber) {
      db.collection("customers")
        .doc("ISC-" + isc)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            history.push({
              pathname: "/packages",
              isc: "ISC-" + isc,
            });
          } else {
            alert("Sorry, No User Found with ISC-" + isc);
          }
        })
        .catch(function (error) {
          console.log("User Check Error:", error);
        });
    }
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);

    if (window.sessionStorage.getItem("firstLoadDone") === null) {
      setAnimate(true);
      window.sessionStorage.setItem("firstLoadDone", 1);
    } else {
      setAnimate(false);
    }
  }, [windowDimensions]);

  const [heightt, setheightt] = useState();
  const [finalHeight, setFinalHeight] = useState();
  useEffect(() => {
    sessionStorage.setItem("heightt", windowDimensions.height);
    const isMobile = window.innerWidth;
    sessionStorage.setItem("mobileOrNot", isMobile);
    setisMobile(sessionStorage.getItem("mobileOrNot"));
    if (heightt) {
      setFinalHeight(heightt);
    } else if (!heightt) {
      let tmp = sessionStorage.getItem("heightt");
      setheightt(tmp);
    }
  }, [heightt, windowDimensions]);

  const [animate, setAnimate] = useState(true);

  sessionStorage.setItem("key", "value");
  let data = sessionStorage.getItem("key");
  sessionStorage.removeItem("key");
  sessionStorage.clear();

  const [isMobile, setisMobile] = useState();

  if (isMobile < 520) {
    return (
      <div
        className="App"
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#EFEFEF",
          width: windowDimensions.width,
          height: windowDimensions.height,
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            position: "relative",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "center",
            width: windowDimensions.width - windowDimensions.width / 10,
            height: finalHeight - 100,
            borderRadius: "25px",
            shadowColor: "black",
            shadowRadius: 5,
            shadowOffset: { width: 0, height: 0 },
          }}
          className="shadowBG"
        >
          <img
            alt=""
            style={{ height: windowDimensions.height / 30 }}
            class="bookingsButton"
            src={require("../../../assets/adminLoginButton.svg")}
            onClick={() => setshowPending(!showPending)}
          />
          <img
            alt=""
            style={{ height: windowDimensions.height / 30 }}
            class="adminButton"
            src={require("../../../assets/adminLoginButton.svg")}
            onClick={() => history.push("/admin")}
          />
          {packageInput === true ? (
            <div>
              <img
                alt=""
                className="cancelPackDialog"
                onClick={() => setPackageInput(false)}
                style={{
                  position: "absolute",
                  width: "10vw",
                  height: "10vw",
                  left: "45%",
                  top: "20%",
                }}
                src={require("../../../assets/cancel.png")}
              />
              <form onSubmit={checkUserExist}>
                {/* <label>
                  Customer ISC Number : ISC-
                  <input
                    type="text"
                    value={isc}
                    onChange={(e) => {
                      localStorage.setItem(
                        "ISCNumber",
                        "ISC-" + e.target.value
                      );
                      setisc(e.target.value);
                    }}
                  />
                </label>
                <br />
                <label>
                  or Vehicle Number :
                  <input
                    type="text"
                    value={vehicleNumber}
                    onChange={(e) => {
                      setvehicleNumber(e.target.value);
                    }}
                  />
                </label>
                <input type="submit" value="Submit" /> */}
                <label
                  className="vehNoLabel"
                  style={{
                    position: "absolute",
                    width: "80vw",
                    height: "10vw",
                    fontSize: "5vw",
                    top: "25%",
                    left: "5%",
                  }}
                >
                  Vehicle NO :
                  <input
                    type="text"
                    style={{
                      width: "80vw",
                      height: "10vw",
                      fontSize: "5vw",
                      fontWeight: "900",
                      marginLeft: "0.5vw",
                      paddingLeft: "1vw",
                    }}
                    className="vehNoField"
                    value={vehicleNumber}
                    onKeyDown={(e) => doWithKeyPress(e)}
                    onChange={(e) => {
                      setvehicleNumber(e.target.value);
                    }}
                  />
                </label>
                <label
                  className="orLabelMobile"
                  style={{
                    position: "absolute",
                    top: "33.5%",
                    left: "5%",
                  }}
                >
                  OR
                </label>
                <label
                  className="vehNoLabel"
                  style={{
                    position: "absolute",
                    width: "80vw",
                    height: "10vw",
                    fontSize: "5vw",
                    top: "42%",
                    left: "5%",
                  }}
                >
                  ISCNO : ISC-
                  <input
                    type="text"
                    style={{
                      width: "80vw",
                      height: "10vw",
                      fontSize: "5vw",
                      fontWeight: "900",
                      marginLeft: "0.5vw",
                      paddingLeft: "1vw",
                    }}
                    onKeyDown={(e) => doWithKeyPress(e)}
                    className="vehNoField"
                    value={isc}
                    onChange={(e) => {
                      localStorage.setItem(
                        "ISCNumber",
                        "ISC-" + e.target.value
                      );
                      setisc(e.target.value);
                    }}
                  />
                </label>
                <img
                  alt=""
                  style={{
                    position: "absolute",
                    width: "40vw",
                    top: "55%",
                    left: "5%",
                  }}
                  type="submit"
                  value="Submit"
                  onClick={checkUserExist}
                  className="submitButton"
                  src={require("../../../assets/submit.svg")}
                />
              </form>
            </div>
          ) : null}
          <img
            style={{ height: windowDimensions.height / 2.6 }}
            className="mainSVGMobile"
            src={require("./bg.svg")}
            alt="Cars"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              padding: "4vw",
              marginTop: "4vw",
            }}
          >
            <img
              style={{ width: windowDimensions.width / 3.5 }}
              className="logoMobile"
              src={require("../../../assets/logo.png")}
              alt="Logo"
            />
            <img
              style={{ width: windowDimensions.width / 2 }}
              className="logoMobile"
              src={require("./logo.png")}
              alt="Logo"
            />
          </div>
          {packageInput === false ? (
            <>
              <div
                style={{
                  width: windowDimensions.width / 2.5,
                  height: windowDimensions.width / 2.5,
                  borderRadius: 200,
                }}
                onClick={() => setPackageInput(true)}
                className="button1Mobile"
              >
                <p
                  className="button1Title1Mobile"
                  style={{
                    fontSize: windowDimensions.width / 20,
                    position: "relative",
                    justifyContent: "center",
                  }}
                >
                  Already have a{"\n"}
                  <p className="button1Title2Mobile">PACKAGE ?</p>
                </p>
              </div>

              <div
                style={{
                  width: windowDimensions.width / 2.5,
                  height: windowDimensions.width / 2.5,
                  borderRadius: 300,
                }}
                onClick={() => history.push("service")}
                className="button2Mobile"
              >
                <p
                  className="button1Title1Mobile"
                  style={{
                    fontSize: windowDimensions.width / 20,
                    position: "relative",
                    justifyContent: "center",
                  }}
                >
                  or select from{"\n"}
                  <p className="button1Title2Mobile">SERVICES</p>
                </p>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="App"
        style={{
          position: "relative",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#EFEFEF",
          width: windowDimensions.width,
          height: windowDimensions.height,
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            position: "relative",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "center",
            width: windowDimensions.width - windowDimensions.width / 10,
            height: windowDimensions.height - 100,
            borderRadius: "25px",
            shadowColor: "black",
            shadowRadius: 5,
            shadowOffset: { width: 0, height: 0 },
          }}
          className={animate ? "animationBig shadowBG" : "shadowBG"}
        >
          <div
            style={{ height: windowDimensions.height / 25 }}
            class={
              animate ? "bookingsAnimation bookingsButton" : "bookingsButton"
            }
            onClick={() => setshowPending(!showPending)}
          >
            <div style={{ fontWeight: "400" }}>
              Service <span style={{ fontWeight: "700" }}>Bookings</span>
            </div>
            {pending > 0 ? <div className="circle">{pending}</div> : null}
          </div>
          <img
            alt=""
            style={{ height: windowDimensions.height / 25 }}
            class={animate ? "adminLoginAnimation adminButton" : "adminButton"}
            src={require("../../../assets/adminLoginButton.svg")}
            onClick={() => history.push("admin/")}
          />
          <img
            style={{ height: windowDimensions.height / 1.275 }}
            className={animate ? "animationCars mainSVG" : "mainSVG"}
            src={require("./bg.svg")}
            alt="Cars"
          />
          <img
            style={{ width: windowDimensions.width / 3.4 }}
            className={animate ? "animationLogo2 logo" : "logo"}
            src={require("./logo.png")}
            alt="Logo"
          />
          <img
            style={{ width: windowDimensions.width / 6 }}
            className={animate ? "animationLogo logo2" : "logo2"}
            src={require("../../../assets/logo.png")}
            alt="Logo"
          />
          {packageInput === false ? (
            <div
              style={{
                width: windowDimensions.width / 6.25,
                height: windowDimensions.width / 6.25,
                borderRadius: 200,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => setPackageInput(true)}
              className={animate ? "animationButton1 button1" : "button1"}
            >
              <label
                className="button1Title1"
                style={{
                  fontSize: windowDimensions.width / 60,
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                Already have a<br />
                <label className="button1Title2">PACKAGE ?</label>
              </label>
            </div>
          ) : null}
          <div
            style={{
              width: windowDimensions.width / 6.25,
              height: windowDimensions.width / 6.25,
              borderRadius: 200,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft:
                packageInput === true ? windowDimensions.width / 11 : 0,
            }}
            onClick={() => history.push("service")}
            className={animate ? "animationButton1 button2" : "button2"}
          >
            <label
              className="button1Title1"
              style={{
                fontSize: windowDimensions.width / 60,
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              or select from
              <br />
              <label className="button1Title2">SERVICES</label>
            </label>
          </div>
          {packageInput === true ? (
            <div>
              <img
                alt=""
                className="cancelPackDialog"
                onClick={() => setPackageInput(false)}
                style={{
                  position: "absolute",
                  width: "3vw",
                  height: "3vw",
                  left: windowDimensions.width / 30,
                  top: windowDimensions.width / 4.175,
                }}
                src={require("../../../assets/cancel.png")}
              />
              <form onSubmit={checkUserExist}>
                {/* <label>
                  Customer ISC Number : ISC-
                  <input
                    type="text"
                    value={isc}
                    onChange={(e) => {
                      localStorage.setItem(
                        "ISCNumber",
                        "ISC-" + e.target.value
                      );
                      setisc(e.target.value);
                    }}
                  />
                </label>
                <br />
                <label>
                  or Vehicle Number :
                  <input
                    type="text"
                    value={vehicleNumber}
                    onChange={(e) => {
                      setvehicleNumber(e.target.value);
                    }}
                  />
                </label>
                <input type="submit" value="Submit" /> */}
                <label
                  className="vehNoLabel"
                  style={{
                    position: "absolute",
                    width: windowDimensions.width / 5,
                    height: windowDimensions.height / 15,
                    fontSize: windowDimensions.width / 100,
                    top: "20vw",
                    left: windowDimensions.width / 12,
                  }}
                >
                  Vehicle NO :
                  <input
                    type="text"
                    style={{
                      width: windowDimensions.width / 8,
                      height: windowDimensions.height / 18,
                      fontSize: windowDimensions.width / 100,
                      fontWeight: "900",
                      marginLeft: 10,
                      paddingLeft: 20,
                    }}
                    className="vehNoField"
                    value={vehicleNumber}
                    onKeyDown={(e) => doWithKeyPress(e)}
                    onChange={(e) => {
                      setvehicleNumber(e.target.value);
                    }}
                  />
                </label>
                <label
                  className="orLabel"
                  style={{
                    position: "absolute",
                    top: "23vw",
                    left: windowDimensions.width / 12,
                  }}
                >
                  OR
                </label>
                <label
                  className="vehNoLabel"
                  style={{
                    position: "absolute",
                    width: windowDimensions.width / 5,
                    height: windowDimensions.height / 15,
                    fontSize: windowDimensions.width / 100,
                    top: "28vw",
                    left: windowDimensions.width / 12,
                  }}
                >
                  ISCNO : ISC-
                  <input
                    type="text"
                    style={{
                      width: windowDimensions.width / 8,
                      height: windowDimensions.height / 18,
                      fontSize: windowDimensions.width / 100,
                      fontWeight: "900",
                      marginLeft: 10,
                      paddingLeft: 20,
                    }}
                    onKeyDown={(e) => doWithKeyPress(e)}
                    className="vehNoField"
                    value={isc}
                    onChange={(e) => {
                      localStorage.setItem(
                        "ISCNumber",
                        "ISC-" + e.target.value
                      );
                      setisc(e.target.value);
                    }}
                  />
                </label>
                <img
                  alt=""
                  style={{
                    position: "absolute",
                    width: windowDimensions.width / 8,
                    top: "34.5vw",
                    left: windowDimensions.width / 6.6,
                  }}
                  type="submit"
                  value="Submit"
                  onClick={checkUserExist}
                  className="submitButton"
                  src={require("../../../assets/submit.svg")}
                />
              </form>
            </div>
          ) : null}
        </div>
        <img
          className={animate ? "animationHaspr haspr" : "haspr"}
          alt="haspr.in"
          style={{ width: windowDimensions.width / 5 }}
          src={require("../../../assets/haspr.svg")}
          onClick={() => window.open("https://haspr.in/", "_blank")}
        />
        <Bookings setpending={setpending} showPending={showPending} />
      </div>
    );
  }
}

function Bookings({ setpending, showPending }) {
  const [citySelector, setcitySelector] = useState("sagar");
  const [servicesList, setservicesList] = useState([]);
  useEffect(() => {
    const subscriber = db
      .collection("bookings")
      .where("city", "==", "sagar")
      .where("completed", "==", false)
      .onSnapshot((querySnapshot) => {
        const tmp = [];

        querySnapshot.forEach((documentSnapshot) => {
          tmp.push({
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          });
        });
        setservicesList(tmp);
        setpending(querySnapshot.size);
      });

    return () => subscriber();
  }, []);

  const sagarBooking = async () => {
    await db
      .collection("bookings")
      .where("city", "==", "sagar")
      .where("completed", "==", false)
      .onSnapshot((querySnapshot) => {
        const tmp = [];

        querySnapshot.forEach((documentSnapshot) => {
          tmp.push({
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          });
        });
        setservicesList(tmp);
        setpending(querySnapshot.size);
      });
  };
  const bandaBooking = async () => {
    await db
      .collection("bookings")
      .where("city", "==", "banda")
      .where("completed", "==", false)
      .onSnapshot((querySnapshot) => {
        const tmp = [];

        querySnapshot.forEach((documentSnapshot) => {
          tmp.push({
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
          });
        });
        setservicesList(tmp);
        setpending(querySnapshot.size);
      });
  };

  const bookingsDisplay = (item) => {
    const completeBooking = () => {
      db.collection("bookings").doc(item.isc).update({
        completed: true,
        status: "Confirmed",
      });
    };
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: "10vw",
        }}
      >
        {servicesList ? (
          <>
            <div
              style={{
                display: "flex",
                width: "100%",
                paddingLeft: "3.5vw",
                flex: 0.4,
                paddingRight: "1.5vw",
                paddingTop: "1.5vw",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  textAlign: "right",
                  fontSize: "0.65vw",
                  color: "black",
                  fontWeight: "400",
                }}
              >
                <span style={{ fontWeight: "700" }}>
                  {item.isc} , {item.name} , {item.vehicleNumber}
                </span>{" "}
                has booked service on{" "}
                <span style={{ fontWeight: "700" }}>{item.date}</span> , contact
                on <span style={{ fontWeight: "700" }}>{item.mobile}</span>
              </label>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                flex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flex: 0.9,
                  flexDirection: "column",
                  paddingLeft: "1.5vw",
                }}
              >
                <label
                  style={{
                    fontSize: "0.65vw",
                    fontWeight: "400",
                    color: " black",
                  }}
                >
                  Services Booked
                </label>
                <label
                  style={{
                    fontSize: "0.8vw",
                    fontWeight: "700",
                    color: " black",
                  }}
                >
                  {item.services.toString()}
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flex: 0.1,
                  padding: "1vw",
                  cursor: "pointer",
                }}
                onClick={() => completeBooking()}
              >
                <img
                  alt="tick"
                  src={require("../../../assets/tick.svg")}
                  width={"100%"}
                  height={"100%"}
                />
              </div>
            </div>
            <hr className="hrline" />
          </>
        ) : null}
      </div>
    );
  };
  return (
    <div className={showPending === true ? "glass glassActive" : "glass"}>
      <div>
        <div
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <label
            onClick={() => {
              setcitySelector("sagar");
              sagarBooking();
            }}
            style={{
              flex: 1,
              paddingLeft: "1vw",
              paddingRight: "1vw",
              paddingTop: "0.3vw",
              paddingBottom: "0.3vw",
              borderRadius: "1vw",
              backgroundColor:
                citySelector === "sagar" ? "#e52520" : "transparent",
              marginLeft: "1.5vw",
              marginTop: "1vw",
              cursor: "pointer",
              fontSize: "1vw",
              fontWeight: "400",
              color: citySelector === "sagar" ? "white" : "black",
            }}
          >
            Sagar
          </label>
          <label
            onClick={() => {
              setcitySelector("banda");
              bandaBooking();
            }}
            style={{
              flex: 1,
              paddingLeft: "1vw",
              paddingRight: "1vw",
              paddingTop: "0.3vw",
              paddingBottom: "0.3vw",
              borderRadius: "1vw",
              backgroundColor:
                citySelector === "banda" ? "#e52520" : "transparent",
              marginLeft: "1vw",
              marginTop: "1vw",
              cursor: "pointer",
              fontSize: "1vw",
              fontWeight: "400",
              color: citySelector === "banda" ? "white" : "black",
            }}
          >
            Banda
          </label>
        </div>
        {servicesList ? servicesList.map(bookingsDisplay) : null}
        {servicesList.length < 1 ? (
          <label
            style={{
              width: "100%",
              textAlign: "center",
              lineHeight: "100%",
              marginTop: "1.5vw",
              fontSize: "1vw",
              fontWeight: "400",
              color: "black",
            }}
          >
            No Bookings Yet
          </label>
        ) : null}
      </div>
    </div>
  );
}
