import React, { useState, useEffect } from "react";
import "./Inventory.css";

import EditProduct from "../Inventory/EditProduct";

import db from "../../firebase/database";

import { makeStyles } from "@material-ui/core/styles";

import NewProduct from "./NewProduct";
import AddProduct from "./AddProduct";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: "100%",
  },
});
export default function Inventory() {
  const [adminType, setAdminType] = useState();
  useEffect(() => {
    setAdminType(window.sessionStorage.getItem("adminType"));
  }, []);
  console.log("admin type", adminType);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(true);
  const [show3, setShow3] = useState(false);

  const tab1 = () => {
    setShow1(true);
    setShow2(false);
    setShow3(false);
  };
  const tab2 = () => {
    setShow1(false);
    setShow2(true);
    setShow3(false);
  };
  const tab3 = () => {
    setShow1(false);
    setShow2(false);
    setShow3(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [itemNumber, setitemNumber] = useState("");
  const [itemName, setitemName] = useState("");
  const [itemAmount, setitemAmount] = useState();
  const [itemPrice, setitemPrice] = useState();
  const [itemMrp, setitemMrp] = useState();
  const [vendor, setVendor] = useState("");
  const [vendorGSTN, setVendorGSTN] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [partList, setpartList] = useState([]);

  return (
    <div>
      {adminType === "banda" ? (
        <div></div>
      ) : (
        <>
          {" "}
          <div className="top-tap-bar-invent">
            <div
              style={{ cursor: "pointer" }}
              className={
                show1 === true
                  ? "ActiveCard-customer-invent"
                  : "InactiveCard-invent"
              }
              onClick={tab1}
            >
              <p className="tile1cust-invent">
                New <b className="tile2cust-invent">Product</b>
              </p>
            </div>

            <div
              style={{ cursor: "pointer" }}
              className={
                show2 === true
                  ? "ActiveCard-customer-invent"
                  : "InactiveCard-invent"
              }
              onClick={tab2}
            >
              <p className="tile1cust-invent">
                Add <b className="tile2cust-invent">Product</b>
              </p>
            </div>
            <div
              style={{ cursor: "pointer" }}
              className={
                show3 === true
                  ? "ActiveCard-customer-invent"
                  : "InactiveCard-invent"
              }
              onClick={tab3}
            >
              <p className="tile1cust-invent">
                Edit <b className="tile2cust-invent">Product</b>
              </p>
            </div>
          </div>
          <hr
            style={{
              width: "50%",
              marginTop: "25px",
              backgroundColor: "red",
              opacity: 0.2,
              marginLeft: "3.5vw",
            }}
          />
        </>
      )}

      {show1 === true ? (
        <div>
          <NewProduct />
        </div>
      ) : null}
      {show2 === true ? (
        <div>
          <AddProduct />
        </div>
      ) : null}
      {show3 === true ? <EditProduct /> : null}
    </div>
  );
}
