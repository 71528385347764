import React from "react";
import st from "./ProductDisplay.module.css";

export default function ProductDisplay({ item }) {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        backgroundColor: "#fe5236",
        borderRadius: "0.5vw",
        padding: "1vw",
        marginBottom: "1.5vw",
        marginTop: "-1.5vw",
        width: '85%'
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontSize: "1.5vw",
          fontWeight: "400",
        }}
      >
        <text
          style={{
            color: "white",
            fontFamily: "Montserrat",
            fontWeight: "400",
          }}
        >
          Name
        </text>
        <text
          style={{
            color: "white",
            fontFamily: "Montserrat",
            fontWeight: "900",
            fontSize: '1.25vw',
            marginLeft: '1vw'
          }}
        >
          {item.name}
        </text>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontSize: "1.5vw",
        }}
      >
        <text
          style={{
            color: "white",
            fontFamily: "Montserrat",
            fontWeight: "400",
          }}
        >
          Price
        </text>
        <text
          style={{
            color: "white",
            fontFamily: "Montserrat",
            fontWeight: "900",
          }}
        >
          ₹{item.category==="Products" ? item.MRP : item.price}
        </text>
      </div>
    </div>
  );
}
